import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    offer: null,
    cleanOffer: null,
    offers: [],
    offersItemsNumber: 0,
    offersPage: 1,
    offersTotalPages: 1,
    offersLoading: false
  },
  getters: {
    offer: state => state.offer,
    cleanOffer: state => state.cleanOffer,
    offers: state => state.offers,
    offersLoading: state => state.offersLoading,
    offersItemsNumber: state => state.offersItemsNumber,
    offersPage: state => state.offersPage,
    offersTotalPages: state => state.offersTotalPages
  },
  mutations: {
    setOffer (state, payload) {
      state.offer = payload
      state.cleanOffer = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setOffersData (state, { items, totalItems, page, totalPages }) {
      state.offers = items
      state.offersItemsNumber = totalItems
      state.offersPage = page
      state.offersTotalPages = totalPages
    },
    setCleanOffer (state, payload) {
      state.cleanOffer = payload
    },
    setNewOffer (state) {
      state.offer = {
        // extId: Math.floor(Math.random() * (9999999999) + 1),
        state: 'normal',
        type: 'simple',
        dimensions: {
          x: 0,
          y: 0,
          z: 0
        },
        name: '',
        price: 0,
        image: null,
        barcodes: [],
        sku: null,
        _embedded: {
          shop: null
        }
      }
    },
    updateOffer (state, payload) {
      state.offer = {
        ...state.offer,
        ...payload
      }
    },
    updateOfferEmbedded (state, payload) {
      state.offer._embedded = {
        ...state.offer._embedded,
        ...payload
      }
    },
    updateOfferList (state, payload) {
      state.offers = state.offers.map(x => {
        return x.id === payload.id
          ? payload
          : x
      })
    },
    setOffers (state, payload) {
      state.offers = payload
    },
    setOffersItemsNumber (state, payload) {
      state.offersItemsNumber = payload
    },
    setOffersPage (state, payload) {
      state.offersPage = payload
    },
    setOffersTotalPages (state, payload) {
      state.offersTotalPages = payload
    },
    startOfferLoading (state) {
      state.offersLoading = true
    },
    stopOfferLoading (state) {
      state.offersLoading = false
    },
    upsertOffer (state, payload) {
      let isExist = false

      state.offers = state.offers.map(x => {
        if (`${x.id}` === `${payload.id}`) {
          isExist = true
          return payload
        }

        return x
      })

      if (!isExist) {
        state.offers.unshift(payload)
      }
    }
  },
  actions: {
    loadOffers ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.offers)
      return services.offer.getAll(query)
        .then(data => {
          commit('setOffersData', data)
          return data
        })
    },
    loadOffer ({ commit }, { id, shop }) {
      return services.offer.get(id, shop)
        .then(item => {
          commit('setOffer', item)
          return item
        })
    },
    saveOffer ({ commit, state }) {
      const ignoredFields = ['shop']
      const data = state.offer.id
        ? convertEmbedded(difference(state.offer, state.cleanOffer), ignoredFields)
        : convertEmbedded(state.offer)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.offer)
      }

      if (data.eav && data.eav['products-offer-bundle']) {
        data.eav['products-offer-bundle'] = state.offer.eav['products-offer-bundle']
      }

      // Difference extract only changed values in array
      // But we need to pass the whole array to back-end
      if (data.barcodes) {
        data.barcodes = state.offer.barcodes
      }

      if (data.price !== undefined) {
        data.price = Number(data.price).toFixed(2)
      }

      const header = state.offer.id && state.offer.updated
        ? { 'X-Entity-Updated': state.offer.updated }
        : {}

      return services.offer.save(data, state.offer.id, (state.cleanOffer || { _embedded: { shop: {} } })._embedded.shop.id, header)
    }
  }
}
