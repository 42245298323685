import { createItemsData } from '../utils/request.utils'
import { Service } from './base-service'
import { HALApiService } from './hal-api.service'

class StorageItemEntityService extends Service {
  constructor (httpClient, middleware) {
    super('/storage/item', httpClient, middleware)
  }

  post(data, query, accept, place, headers = {}) {
    if (place) {
      headers['X-Storage-Location'] = place
    }

    return this._httpClient.post(`/storage/item`, data)
  }

  /**
   * @param {object} data
   * @param {object} query
   * @param {String} accept
   * @param {String|Number|Undefined} place
   * @param {Object} headers
   *
   * @returns {Promise<object>}
   */
  patch (data, query, accept, place, headers = {}) {
    if (place) {
      headers['X-Storage-Location'] = place
    }

    return this._httpClient.patch(this._url, data, accept, undefined, headers, query)
  }

  /**
   * @param {object} query
   * @param {string|number|undefined} place
   * @param {object} headers
   *
   * @returns {Promise<object>}
   */
  getAll (query, place, headers = {}) {
    if (place) {
      headers['X-Storage-Location'] = place
    }

    return super.getAll(query, undefined, headers)
  }

  patchBatch (data, query, accept, place, headers = {}) {
    if (place) {
      headers['X-Storage-Location'] = place
    }

    return this._httpClient.patch(this._url + `/bulk`, data, accept, undefined, headers, query)
  }

  putBatch (data, query, accept, place, headers = {}) {
    if (place) {
      headers['X-Storage-Location'] = place
    }

    return this._httpClient.put(this._url + `/bulk`, data, accept, query)
  }
}

export const storageItemEntityService = new StorageItemEntityService(HALApiService, createItemsData)
