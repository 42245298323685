class Filter {
  /**
   * Create special like filter with keys as barcodes::text
   *
   * @param {string} field
   * @param {string} value
   * @param {string|undefined} alias
   *
   * @returns {object}
   */
  includes (field, value, alias) {
    let updatedValue = value
  
    if (updatedValue[0] !== '%') {
      updatedValue = `%${updatedValue}`
    }
  
    if (updatedValue[updatedValue.length - 1] !== '%') {
      updatedValue = `${updatedValue}%`
    }
  
    return {
      type: 'like',
      field,
      alias,
      value: updatedValue
    }
  }

  /**
   * Create instanceOf filter
   *
   * @param {string} value
   * @param {string|undefined} alias
   *
   * @returns {object}
   */
  insanceof (value,alias) {
    return {
      type: 'instanceof',
      alias : alias,
      class : value
    }
  }

  /**
   * Create in filter
   *
   * @param {string} field
   * @param {string} value
   * @param {string|undefined} alias
   *
   * @returns {object}
   */
  in (field, values, alias) {
    const query = {
      type: 'in',
      field,
      values
    }
  
    if (alias) {
      query.alias = alias
    }
  
    return query
  }

  /**
   * Create in filter
   *
   * @param {string} field
   * @param {string} value
   * @param {string|undefined} alias
   *
   * @returns {object}
   */
  notin(field,values,alias) {
    const query = {
      type: 'notin',
      field,
      values
    }

    if (alias) {
      query.alias = alias
    }

    return query
  }

  /**
   * Create eq filter
   *
   * @param {string} field
   * @param {string} value
   * @param {string|undefined} alias
   *
   * @returns {object}
   */
  eq (field, value, alias) {
    const isLike = `${value}`.includes('%')
    const query = {
      type: isLike
        ? 'like'
        : 'eq',
      value,
      field
    }
  
    if (alias) {
      query.alias = alias
    }
  
    return query
  }

  /**
   * Create eq filter
   *
   * @param {string} field
   * @param {string} value
   * @param {string|undefined} alias
   *
   * @returns {object}
   */
  neq (field, value, alias) {
    const isLike = `${value}`.includes('%')
    const query = {
      type: isLike
          ? 'notlike'
          : 'neq',
      value,
      field
    }

    if (alias) {
      query.alias = alias
    }

    return query
  }

  /**
   * Create between filter
   *
   * @param {string} field
   * @param {object} value
   * @param {string|undefined} alias
   *
   * @returns {object}
   */
  where (field, value, alias, where, type = 'between') {
    const query = {
      field,
      where,
      type,
      from: value.from || 0,
      to: value.to
    }
  
    if (alias) {
      query.alias = alias
    }
  
    return query
  }

  /**
   * Create gte filter
   *
   * @param {string} field
   * @param {string} value
   * @param {string|undefined} alias
   *
   * @returns {object}
   */
  gte (field, value, alias) {
    const query = {
      field,
      type: 'gte',
      value
    }
  
    if (alias) {
      query.alias = alias
    }
  
    return query
  }

  /**
   * Create lte filter
   *
   * @param {string} field
   * @param {string} value
   * @param {string|undefined} alias
   *
   * @returns {object}
   */
  lte (field, value, alias) {
    const query = {
      field,
      type: 'lte',
      value
    }
  
    if (alias) {
      query.alias = alias
    }
  
    return query
  }

  /**
   * Create filter
   *
   * @param {string} key
   * @param {string|number|object} value
   * @param {string|undefined} alias
   *
   * @returns {array}
   */
  create (key, value, alias, not = false) {
    if (!value) {
      return []
    }

    if (Array.isArray(value)) {
      if (value.length === 0) {
        return []
      }

      if (value.length === 1) {
        return this.create(key, value[0], alias , not)
      }
  
      const values = value.map(value => {
        return typeof value === 'object' && value.id
          ? value.id
          : value
      })
      if (key === 'id') {
        return [this.in('id', value, alias)]
      }

      if (not && key === 'shop') {
        return [this.notin(key, values, alias)]
      }
      return [this.in(key, values, alias)]
    }
  
    if (typeof value === 'string' || typeof value === 'number') {
      if (key.includes('::')) {
        return [this.includes(key, value, alias)]
      }
      if (key === 'class') {
        return [this.insanceof(value, alias)]
      }
      if (key === 'id') {
        return [this.eq('id', value, alias)]
      }

      if (not && key === 'shop') {
        return [this.neq(key, value, alias)]
      }
      return [this.eq(key, value, alias)]
    }
  
    if (value.id) {
      if (not && key === 'shop') {
        return [this.neq(key, value, alias)]
      }
      return [this.eq(key, value.id, alias)]
    }
  
    if (value.from && value.to) {
      return [
        this.gte(key, value.from, alias),
        this.lte(key, value.to, alias)
      ]
    }
  
    if (value.from && !value.to) {
      return [this.gte(key, value.from, alias)]
    }
  
    if (!value.from && value.to) {
      return [this.lte(key, value.to, alias)]
    }
  
    return []
  }

  /**
   * Convert eq filter to object
   *
   * @param {object} filter
   *
   * @returns {object}
   */
  convertEQ (filter) {
    return { [filter.field]: filter.value }
  }

  /**
   * Convert instanseOf filter to object
   *
   * @param {object} filter
   *
   * @returns {object}
   */
  convertInsatanceof (filter) {
    return { class: filter.class}
  }

  /**
   * Convert neq filter to object
   *
   * @param {object} filter
   *
   * @returns {object}
   */
  convertNEQ (filter) {
    return { [filter.field]: filter.value }
  }

  /**
   * Convert in filter to object
   *
   * @param {object} filter
   *
   * @returns {object}
   */
  convertIN (filter) {
    return { [filter.field]: filter.values }
  }

  /**
   * Convert notin filter to object
   *
   * @param {object} filter
   *
   * @returns {object}
   */
  convertNOTIN (filter) {
    return { [filter.field]: filter.values }
  }

  /**
   * Convert lte filter to object
   *
   * @param {object} filter
   *
   * @returns {object}
   */
  convertLTE (filter) {
    return {
      [filter.field]: {
        to: filter.value
      }
    }
  }

  /**
   * Convert gte filter to object
   *
   * @param {object} filter
   *
   * @returns {object}
   */
  convertGTE (filter) {
    return {
      [filter.field]: {
        from: filter.value
      }
    }
  }

  /**
   * Convert between filter to object
   *
   * @param {object} filter
   *
   * @returns {object}
   */
  convertWHERE (filter) {
    return {
      [filter.field]: {
        from: filter.from,
        to: filter.to
      }
    }
  }

  /**
   * Convert between filter to object
   *
   * @param {object} filter
   *
   * @returns {object}
   */
  convertORX (filter) {
    return filter.conditions.reduce((acc, filter) => {
      if (filter.type.toLowerCase() === 'isnull') {
        return acc
      }

      return { ...acc, ...this.filterToObject(filter) }
    }, {})
  }

  /**
   * Convert filter to object
   *
   * @param {object} filter
   *
   * @returns {object}
   */
  filterToObject (filter) {
    const filters = {
      eq: this.convertEQ.bind(this),
      neq: this.convertNEQ.bind(this),
      in: this.convertIN.bind(this),
      notin: this.convertNOTIN.bind(this),
      lt: this.convertLTE.bind(this),
      gt: this.convertGTE.bind(this),
      lte: this.convertLTE.bind(this),
      gte: this.convertGTE.bind(this),
      between: this.convertWHERE.bind(this),
      orx: this.convertORX.bind(this),
      instanceof: this.convertInsatanceof.bind(this)
    }

    if (!filters[filter.type]) {
      console.error(`No convertor for filter ${filter.type}`)
      return {}
    }

    return filters[filter.type](filter)
  }
}

export const filterUtil = new Filter()