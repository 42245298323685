import { HALApiService } from '../hal-api.service'
import { createItemsData } from './../../helpers/request-helpers'
import { CachedService } from './base-service'
import entities from '../../../../config/Entities'

class ReportService extends CachedService {
  _fields

  constructor (httpClient, middleware) {
    super(entities.Orderadmin_Accounts_Entity_Report, '/reports/report', httpClient, middleware)

    this._fields = [
      'beginningPeriod',
      'created',
      'endPeriod',
      'handler',
      'id',
      'name',
      'rate',
      'settings',
      'state',
      'updated',
      'data'
    ]
  }

  /**
   * @param {object} query
   *
   * @returns {Promise<object>}
   */
   getAll (query) {
    const newQuery = { ...query }

    if (!newQuery.fields) {
      newQuery.fields = query.fields || this._fields
    }

    return super.getAll(query)
  }

  download (template, accept,itemId) {
    return this._httpClient.downloadRaw(template, accept,itemId)
  }
}

export const reportService = new ReportService(HALApiService, createItemsData)

/**
 * Get Report For Print
 *
 * @param {Number} id
 *
 * @returns {Object}
 */
export function printReportService (id) {
  return HALApiService.getRaw('/reports/report/' + id, {}, 'text/html')
}

/**
 * Load Report Types
 *
 * @param {Object} query
 *
 * @returns {Object}
 */
export function loadReportTypesService (query) {
  return HALApiService.get('/reports/report-type', query).then(createItemsData)
}
