import { HALApiService } from './hal-api.service'
import { createItemsData } from '../utils/request.utils'
import { Service } from './base-service'

class StoragePlacesService extends Service {
  constructor (httpClient, middleware) {
    super('/storage/place', httpClient, middleware)
  }
}

export const storagePlacesService = new StoragePlacesService(HALApiService, createItemsData)
