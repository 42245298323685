import { HALApiService } from '../hal-api.service'
import { createItemsData } from '../../helpers/request-helpers'
import { store } from '../../vueX/store'
import { errorCatcher } from '../handlers'
import entities from '../../../../config/Entities'
import qs from 'qs'
import { Cache } from '../../../../packages/cache'

class OrdersService {
  httpClient;
  url;
  productsUrl;
  middleware;
  fields;
  _entityClass = entities.Orderadmin_Products_Entity_Order
  _expireTime = 360000

  static cache = new Cache()
  static errorCatcher = errorCatcher
  static qs = qs

  static get onError () {
    return (err) => store.commit('addErrorNotification', err)
  }

  constructor (httpClient, middleware) {
    this.fields = [
      'shipmentDate',
      'id',
      'created',
      'reserves',
      'raw',
      'state',
      'extId',
      'date',
      'paymentState',
      'orderPrice',
      'totalPrice',
      'comment',
      'discountPrice',
      'eav',
      'stateDescription',
      'orderErrors',
      'type',
      'clientId',
      'source'
    ]
    this.url = '/products/order'
    this.productsUrl = '/products/order/product'
    this.httpClient = httpClient
    this.middleware = middleware
  }

  /**
   * @param {string|number} id
   * @param {boolean} forceReload
   *
   * @returns {Promise<object>} 
   */
  find (id, forceReload = false) {
    const item = OrdersService.cache.getValue(this._entityClass, id)

    if (item && !forceReload) {
      return Promise.resolve(item)
    }

    return this.get(id)
  }

  /**
   * @param {object} item
   *
   * @returns {array}
   */
  upsert (item) {
    OrdersService.cache.dispatchValue(this._entityClass, item)
  }

  /**
   * @param {String | Number} id
   *
   * @returns {Promise<object>}
   */
  get (id) {
    const item = OrdersService.cache.getValue(this._entityClass, id)

    if (item) {
      return Promise.resolve(item)
    }

    const query = {
      fields: this.fields,
      filter: [
        { field: 'id', type: 'eq', value: id }
      ]
    }

    return this.httpClient.get(this.url, query)
      .then(result => {
        return result._embedded.order.length > 0
          ? result._embedded.order[0]
          : Promise.reject(new Error('Order is not found!'))
      })
      .then(item => {
        if (!item.type) {
          item.type = 'retail'
        }

        this.upsert(item)

        return item
      })
      .catch(err => {
        OrdersService.onError(err)
        return Promise.reject(err)
      })
  }

  /**
   * @param {object} get
   *
   * @returns {string}
   */
   getURL (get) {
    return `${this._url}${(Object.keys(get).length > 0 ? '?' + OrdersService.qs.stringify(get) : '')}`
  }

  /**
   * @param {object} query
   *
   * @returns {Promise<object>}
   */
  getAll (query) {
    const newQuery = { ...query }

    if (!newQuery.fields) {
      newQuery.fields = this.fields
    }

    delete newQuery.forceReload

    const data = OrdersService.cache.getValues(this._entityClass, this.getURL(newQuery))

    if (!data.isExpired && !query.forceReload) {
      return Promise.resolve(data.value)
    }

    return this.httpClient.get(this.url, newQuery)
      .then(this.middleware)
      .then(result => {
        OrdersService.cache.dispatchValues(this._entityClass, this.getURL(newQuery), result, this._expireTime)
        return result
      })
  }

  /**
   * @param {object} query
   *
   * @returns {Promise<array>}
   */
  getProducts (query) {
    return this.httpClient.get(this.productsUrl, query).then(this.middleware)
  }

  /**
   * @param {array} items
   *
   * @returns {Promise<array>}
   */
  saveProducts (queue, results = []) {
    if (queue.length <= 0) {
      return Promise.resolve(results)
    }

    return this.httpClient.bulk(this.productsUrl, queue)
      .then(data => {
        return data.map(x => {
          const key = Object.keys(x)[0]
          return x[key]
        })
      })
      .catch(err => OrdersService.errorCatcher(err, `${this.productsUrl}/bulk`))
  }

  /**
   * @param {object} data
   * @param {String | Number | undefined} id
   * @param {String} type
   * @param {object | undefined} headers
   *
   * @returns {Promise<object>}
   */
  save (data, id, type, headers) {
    const updatedData = {
      ...data,
      type: undefined
    }

    return this.httpClient.saveRequest(`${this.url}/${type}`, updatedData, id, undefined, undefined, headers)
      .then(item => {
        if (!item.type) {
          item.type = type
        }

        this.upsert(item)

        return item
      })
  }

  /**
   * @param {String | Number} id
   * @param {String | Number} template
   *
   * @returns {Promise<String>}
  */
  getTemplate (entity, template) {
    return this.httpClient.getRaw(`${this.url}${entity.id ? `/${entity.id}` : ''}`, {}, `application/${template}+document`)
      .then(response => {
        if (response.status !== 200 && response.status !== 201) {
          return Promise.reject(new Error(response.statusText))
        }

        return response.text().then(data => {
          if (data.match('<div')) {
            data = `<html lang="en-us" dir="ltr"><head><meta charset="utf-8"></head><body>${data}</body></html>`
          }

          return data
        })
      })
  }

  /**
   * @param {object} query
   *
   * @returns {Promise<excel>}
   */
   downloadAll (query) {
    const updatedQuery = {
      page: 1,
      per_page: 25,
      sort: {
        id: 'desc'
      },
      'order-by': [
        { type: 'field', field: 'id', direction: 'desc' }
      ],
      ...query
    }

    return this.httpClient.download(this.url, updatedQuery, 'application/xls')
  }
}

export const ordersService = new OrdersService(HALApiService, createItemsData)
