import entities from '../../../../config/Entities'
import { HALApiService } from '../hal-api.service'
import { createItemsData } from './../../helpers/request-helpers'
import { CachedService } from './base-service'

class DocumentTemplateService extends CachedService {
  constructor(httpClient, middleware) {
    super(
      entities.Orderadmin_Documents_Entity_Template,
      '/documents-template',
      httpClient,
      middleware
    )
  }

  get(id) {
    const query = {
      per_page: 5,
      page: 1,
      filter: [{ type: 'eq', field: 'id', value: id }],
    }

    return this.getAll(query).then(({ items }) => {
      if (items.length <= 0) {
        return Promise.reject(new Error('Entity is not found!'))
      }

      return items[0]
    })
  }
}

export const documentTemplatesService = new DocumentTemplateService(
  HALApiService,
  createItemsData
)
