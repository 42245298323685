import { HALApiService } from './../hal-api.service'
import { createItemsData } from './../../helpers/request-helpers'
import { Service } from './base-service'

class DictionaryService extends Service {
  getShops (id, query) {
    return this._httpClient.get(`${this._url}/${id}/shop`, query).then(this._middleware)
  }
}

export const dictionaryService = new DictionaryService('/integrations/source-dictionaries', HALApiService, createItemsData)
