import { rmCodeService } from '../services/requests/rm-code.service'
import { FiedUtil } from './field.utils'
import { filterUtil } from './filter.util'
import { QueueUtils } from './queue.utils'
import { StoreUtils } from './store.utils'
import _ from 'lodash'
import { createService } from '../services/services'
import { Validation } from './validation.utils'
import { generateCodes, convertToNumber } from './convert.utils'

/**
 * Get Keyword
 *
 * @param {string|number} id
 * @param {array} keywords
 *
 * @returns {object}
*/
function getKeyword (id, keywords) {
  return keywords.find(x => x.id === id) || { id, name: id, notFound: true }
}

/**
 * @param {string} text
 * @param {boolean} isP 
 *
 * @returns {object}
*/
function createNode (text, isP) {
  if (!isP) {
    const keyword = getKeyword(text, rmCodeService.keywords)
    const error = 'border-bottom: 1px solid red;'
    return {
      type: 'pre',
      content: [
        {
          type: 'text',
          text: JSON.stringify({
            textContent: `${keyword.name}`,
            props: {
              class: 'q-mb-xs q-mx-xs',
              style: `${keyword.notFound ? error : ''} background: orange;`,
              'data-id': text
            }
          })
        }
      ]
    }
  }

  return {
    type: 'paragraph',
    content: [
      {
        type: 'text',
        text: ` ${text} `
      }
    ]
  }
}

/**
 * Convert text to document
 *
 * @param {string} text
 *
 * @returns {object}
 */
function convertTextToDocument (text) {
  if (!text || !text.trim()) {
    return {
      type: 'doc',
      content: []
    }
  }

  return {
    type: 'doc',
    content: text.split(/[{}]/)
      .reduce((acc, word, i) => {
        if (!word || !word.trim()) {
          return acc
        }
    
        acc.push(createNode(word.trim(), i % 2 === 0))
        return acc
      }, [])
    }
}

/**
 * Decode HTML to text
 *
 * @param {string} comment
 * @param {boolean} doubled
 *
 * @returns {string}
*/
function decodeHTMLToText (comment, doubled = false) {
  const div = document.createElement('div')
  div.innerHTML = comment

  let convertedComment = ''

  for (let i = 0; i < div.childNodes.length; i++) {
    const element = div.childNodes[i]

    if (element.tagName === 'SPAN' && element.dataset.id) {
      if (doubled) {
        convertedComment += ` {{${element.dataset.id}}} `
      } else {
        convertedComment += ` {${element.dataset.id}} `
      }
    } else {
      convertedComment += ` ${element.textContent.trim()} `
    }
  }

  return convertedComment
}

/**
 * Convert text to html
 * Handle string with '{value}' and convert it to span element with value as data-id
 *
 * @param {string} text
 * @param {regex} regex
 * @param {boolean} ignoreError
 *
 * @returns {string}
 */
function convertTextToHTML (text, regex = /[{}]/, ignoreError = false) {
  // It is possible text to be any falsy value
  if (!text) {
    return ''
  }

  return text.split(regex)
    .map(x => x.trim())
    .reduce((acc, word, i) => {
      if (!word) {
        return acc
      }

      if (i % 2 === 0) {
        acc.push(word)
      } else {
        const keyword = getKeyword(word, rmCodeService.keywords)
        const error = 'border-bottom: 1px solid red;'

        acc.push(`<span data-id="${word}" style="${keyword.notFound && !ignoreError ? error : ''} background: orange;">${keyword.name}</span>`)
      }

      return acc
    }, [])
    .join(' ')
}

/**
 * Get is international address
 * Check app options for default settings
 * Depend on them we load right form for address
 *
 * @returns {boolean}
 */
function getIsInternationalAddress () {
  if (!window.appOptions.defaults || !window.appOptions.defaults.addressFormat) {
    return true
  }

  return window.appOptions.defaults.addressFormat === 'international'
}

/**
 * Check is user pass requirments to see wizard
 *
 * @param {object} user
 *
 * @returns {boolean}
 */
function passWizardRequirments (user) {
  const pass = []

  // Check if user has certain role
  pass.push(!!window.appOptions.wizardRequirments.roles && !!user.roles.find(({ id }) => window.appOptions.wizardRequirments.roles.includes(id)))

  // Check is already completed setup
  pass.push(!!user.eav && !!user.eav['users-app-setup'] && user.eav['users-app-setup'] < window.appOptions.wizardRequirments.setup)

  return !pass.includes(false)
}

/**
 * Create statuses
 *
 * @returns {Array}
 */
function createStatuses (states, matrix = []) {
  if (states) {
    return states.map(({ id, title }) => ({ name: title, code: id }))
  }

  return matrix.reduce((acc, group) => {
    return [...acc, ...group.buttons.map(({ id, title }) => ({ name: title, code: id }))]
  }, [])
}

/**
 * Reset Storage
 *
 * @returns {object}
 */
export function resetStorage () {
  const keys = ['printerSettings']
  const values = keys.map(key => ({ key, value: JSON.parse(localStorage.getItem(key)) }))

  localStorage.clear()

  values.forEach(({ key, value }) => {
    localStorage.setItem(key, JSON.stringify(value))
  })

  return values
}

export default {
  resetStorage,
  storeUtils: new StoreUtils(),
  generateCodes,
  convertToNumber,
  convertTextToDocument,
  decodeHTMLToText,
  convertTextToHTML,
  getIsInternationalAddress,
  passWizardRequirments,
  filter: filterUtil,
  queue: new QueueUtils(filterUtil),
  createStatuses,
  field: new FiedUtil(createService, _),
  validate: new Validation(window.appOptions)
}
