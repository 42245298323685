let lastUser = null

/**
 * Get Userdata
 *
 * @returns {object}
 */
function getUserdata () {
  const userdata = JSON.parse(localStorage.getItem('userData')) || lastUser

  // On logout we clean userdata but sometimes event is sended after that
  // So we save value of userdata
  lastUser = userdata
  return userdata
}

/**
 * Create User Channel
 *
 * @returns {String}
 */
function createUserChannel () {
  const userdata = getUserdata()
  return userdata && `$usr_${userdata.id}`
}

const actions = {
  'storage.place.opened': ({ commit }, payload) => {
    return commit('addStoragePlaceEvent', payload)
  },
  'storage.tasks.scan.place': ({ commit }, payload) => {
    return commit('addStoragePlaceEvent', payload)
  },
  'storage.place.closed': ({ commit }, payload) => {
    return commit('removeStoragePlaceEvent', payload.entity)
  },
  'orderadmin.user.logout': () => {
    localStorage.clear()
    window.location.reload()
  }
}

export const channels = {
  user: {
    error (socket, store, payload) {
      const actions = {
        0: () => {
          store.commit('openAuthModal')
        }
      }

      if (actions[payload.code]) {
        return actions[payload.code]()
      }

      store.commit('addErrorNotification', payload.message)
    },
    subscribe (socket, store, payload) {
      if (!payload.data) {
        return
      }

      if (payload.data && payload.data.data && payload.data.data.user) {
        const userdata = JSON.parse(localStorage.getItem('userData'))

        if (userdata.id === payload.data.data.user) {
          return
        }
      }

      if (actions[payload.data.event]) {
        return actions[payload.data.event](store, payload.data)
      }
    },
    unsubscribe () {
    },
    logOut () {
      return {
        event: 'orderadmin.user.logout',
        timestamp: new Date().toISOString()
      }
    },
    publish: {
      openPlace (entity, place) {
        return {
          event: 'storage.place.opened',
          entityClass: 'Orderadmin\\Storage\\Entity\\AbstractPlace',
          entity,
          timestamp: new Date().toISOString(),
          data: { place }
        }
      },
      closePlace (entity) {
        return {
          event: 'storage.place.closed',
          entityClass: 'Orderadmin\\Storage\\Entity\\AbstractPlace',
          entity,
          timestamp: new Date().toISOString(),
          data: {}
        }
      }
    }
  }
}

export const channelAdapters = {
  user: createUserChannel
}
