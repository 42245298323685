import { HALApiService } from './../hal-api.service'
import { createItemsData } from './../../helpers/request-helpers'
import { CachedService } from './base-service'
import entities from '../../../../config/Entities'

class ConsumptionsService extends CachedService {
  constructor (httpClient, middleware) {
    super(entities.Orderadmin_Storage_Entity_Movement_Consumption, '/delivery-services/movements/consumption', httpClient, middleware)
  }

  /**
   * @param {String | Number} id
   *
   * @returns {Promise<object>}
  */
  getPDF (id) {
    return this._httpClient.getRaw(`${this._url}/${id}`, {}, 'application/pdf,text/html')
      .then(response => {
        if (response.status !== 200 && response.status !== 201) {
          return Promise.reject(response.statusText)
        }

        return response
      })
  }
}

export const consumptionsService = new ConsumptionsService(HALApiService, createItemsData)
