import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    integration: null,
    cleanIntegration: null,
    integrations: [],
    totalIntegrationsNumber: 0,
    integrationsTotalPages: 1,
    integrationsPage: 1,
    integrationsLoading: false
  },
  getters: {
    integration: state => state.integration,
    cleanIntegration: state => state.cleanIntegration,
    integrations: state => state.integrations,
    integrationsLoading: state => state.integrationsLoading,
    totalIntegrationsNumber: state => state.totalIntegrationsNumber,
    integrationsTotalPages: state => state.integrationsTotalPages,
    integrationsPage: state => state.integrationsPage
  },
  mutations: {
    setIntegrationsData (state, { items, page, totalPages, totalItems }) {
      state.integrations = items
      state.totalIntegrationsNumber = totalItems
      state.integrationsPage = page
      state.integrationsTotalPages = totalPages
    },
    setIntegrations (state, payload) {
      state.integrations = payload
    },
    setTotalIntegrationsNumber (state, payload) {
      state.totalIntegrationsNumber = payload
    },
    setIntegrationsPage (state, payload) {
      state.integrationsPage = payload
    },
    setIntegrationsTotalPages (state, payload) {
      state.integrationsTotalPages = payload
    },
    setIntegration (state, payload) {
      state.integration = payload
      state.cleanIntegration = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setNewIntegration (state) {
      state.integration = {
        name: null,
        comment: null,
        settings: {},
        _embedded: {
          deliveryService: null,
          sender: null
        }
      }
    },
    updateIntegration (state, payload) {
      state.integration = {
        ...state.integration,
        ...payload
      }
    },
    updateIntegrationEmbedded (state, payload) {
      state.integration._embedded = {
        ...state.integration._embedded,
        ...payload
      }
    },
    updateIntegrationSettings (state, payload) {
      state.integration.settings = {
        ...state.integration.settings,
        ...payload
      }
    },
    setCleanIntegration (state, payload) {
      state.cleanIntegration = payload
    },
    startIntegrationsLoading (state) {
      state.integrationsLoading = true
    },
    stopIntegrationsLoading (state) {
      state.integrationsLoading = false
    }
  },
  actions: {
    loadIntegrations ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.history)
      return services.deliveryServiceIntegration.getAll(query)
        .then(data => {
          commit('setIntegrationsData', data)
          return data
        })
    },
    saveIntegration ({ commit, state }) {
      const ignoredFields = ['deliveryService', 'sender']
      const data = state.integration.id
        ? convertEmbedded(difference(state.integration, state.cleanIntegration), ignoredFields)
        : convertEmbedded(state.integration)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.integration)
      }

      const header = state.integration.id && state.integration.updated
        ? { 'X-Entity-Updated': state.integration.updated }
        : {}

      return services.deliveryServiceIntegration.save(data, state.integration.id, header)
    },
    loadIntegration ({ commit }, payload) {
      return services.deliveryServiceIntegration.get(payload)
        .then(item => {
          commit('setIntegration', item)
          return item
        })
    }
  }
}
