const Products = () => import('../../pages/wms/Products')
const Product = () => import('./../../pages/wms/Product.vue')

export default [
      {
        entityClass: 'Orderadmin\\Products\\Entity\\Product\\Offer',
        path: '/products',
        name: 'Products',
        icon: 'inventory_2',
        noChild: true,
        component: Products,
        options: {
          query: {
            page: 1,
            per_page: 25,
            'order-by': [
              { type: 'field', field: 'created', direction: 'desc' }
            ]
          }
        },
        children: [
          {
            entityClass: 'Orderadmin\\Products\\Entity\\Product\\Offer',
            path: '/entity/:type/:shop/:id',
            component: Product
          },
          {
            entityClass: 'Orderadmin\\Products\\Entity\\Product\\Offer',
            path: '/entity/:type',
            component: Product
          }
        ]
      },
]
