<template>
  <q-dialog v-model="isOpen" :maximized="true">
    <q-card style="min-width: 45vw; max-width: 100vw; min-height: 80vh; max-height: 100vh;">
      <q-card-section class="row items-center q-py-sm">
        <div class="text-h6">
          {{ $t('Product') }}
        </div>

        <q-space />

        <q-btn
          icon="close"
          flat
          round
          dense
          @click="close"
        />
      </q-card-section>

      <q-card-section
        v-if="product"
        class="q-px-none q-py-xs"
        :key="`${product.id}:${product.sku}`"
      >
        <product-section :data="product" />

        <quantity-section
          :data="quantityData"
          :options="quantityOptions"
          @click="handleEvent"
        />

        <counter-section :data="counter" @click="handleEvent" />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'CounterModal',
  emits: ['click'],
  data () {
    return {
      isOpen: false,
      product: null
    }
  },
  computed: {
    quantityOptions () {
      return {
        isZero: true,
        max: 'Left to take',
        current: 'You\'ve taken'
      }
    },
    quantityData () {
      return {
        barcode: this.product && this.product.barcodes[0],
        max: (this.product && this.product.count) || 0,
        current: (this.product && this.product.scannedCount) || 0
      }
    },
    counter () {
      const counterObj = {
        max: (this.product && this.product.count) || 0,
        current: (this.product && this.product.scannedCount) || 0
      }

      return counterObj
    }
  },
  methods: {
    handleEvent (value) {
      if (!value.event) {
        return
      }

      const events = {
        'counter.submit': (data) => {
          return this.handleClick({ event: 'counter.submit', data: { value: data.value + 1 } })
        },
        'counter.focus': () => {
          return this.handleClick({ event: 'counter.focus' })
        },
        'counter.focusOut': () => {
          return this.handleClick({ event: 'counter.focusOut' })
        }
      }

      if (typeof events[value.event] === 'function') {
        return events[value.event](value.data)
      }

      console.error(`Event is not recognized! ${value.event}`)
    },
    handleClick (data) {
      this.$emit('click', data)

      if (data.event === 'counter.submit') {
        this.isOpen = false
      }
    },
    open (product) {
      this.product = product
      this.isOpen = true
    },
    close () {
      this.isOpen = false
      this.$emit('close')
    }
  }
}
</script>
