import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    payment: null,
    cleanPayment: null,
    payments: [],
    totalPaymentsNumber: 0,
    paymentsPage: 1,
    paymentsTotalPages: 1,
    paymentsLoading: false
  },
  getters: {
    payment: state => state.payment,
    cleanPayment: state => state.cleanPayment,
    payments: state => state.payments,
    paymentsLoading: state => state.paymentsLoading,
    totalPaymentsNumber: state => state.totalPaymentsNumber,
    paymentsPage: state => state.paymentsPage,
    paymentsTotalPages: state => state.paymentsTotalPages
  },
  mutations: {
    setPayment (state, payload) {
      state.payment = payload
      state.cleanPayment = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanPayment (state, payload) {
      state.cleanPayment = payload
    },
    setNewPayment (state) {
      state.payment = {
        value: 0,
        state: 'new',
        comment: '',
        transactionDate: new Date(),
        _embedded: {
          owner: null,
          account: null
        }
      }
    },
    updatePayment (state, payload) {
      state.payment = {
        ...state.payment,
        ...payload
      }
    },
    updatePaymentEmbedded (state, payload) {
      state.payment = {
        ...state.payment,
        _embedded: {
          ...state.payment._embedded,
          ...payload
        }
      }
    },
    setPaymentsData (state, { items, page, totalPages, totalItems }) {
      state.payments = items
      state.totalPaymentsNumber = totalItems
      state.paymentsPage = page
      state.paymentsTotalPages = totalPages
    },
    setPayments (state, payload) {
      state.payments = payload
    },
    setTotalPaymentsNumber (state, payload) {
      state.totalPaymentsNumber = payload
    },
    setPaymentsPage (state, payload) {
      state.paymentsPage = payload
    },
    setPaymentsTotalPages (state, payload) {
      state.paymentsTotalPages = payload
    },
    startPaymentLoading (state) {
      state.paymentsLoading = true
    },
    stopPaymentLoading (state) {
      state.paymentsLoading = false
    },
    upsertPayment (state, payload) {
      let isExist = false

      state.payments = state.payments.map(x => {
        if (x.id === payload.id) {
          isExist = true
          return payload
        }

        return x
      })

      if (!isExist) {
        state.payments.unshift(payload)
      }
    }
  },
  actions: {
    loadPayments ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.payments)
      return services.payment.getAll(query)
        .then(data => {
          commit('setPaymentsData', data)
          return data
        })
    },
    loadPayment ({ commit }, payload) {
      return services.payment.get(payload)
        .then(item => {
          commit('setPayment', item)
          return item
        })
    },
    savePayment ({ commit, state }) {
      const data = state.payment.id
        ? convertEmbedded(difference(state.payment, state.cleanPayment))
        : convertEmbedded(state.payment)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.payment)
      }

      const header = state.payment.id
        ? { 'X-Entity-Updated': state.payment.updated }
        : {}

      return services.payment.save(data, state.payment.id, header)
    }
  }
}
