const Users = () => import('./../../pages/settings/Users.vue')
const User = () => import('./../../pages/settings/User.vue')
const Sender = () => import('../../pages/settings/Sender')
const Shop = () => import('../../pages/wms/Shop')

export default [
    {
        entityClass: 'Users\\Entity\\User',
        path: '/customer-portals',
        name: 'Customer Portals',
        icon: 'person',
        noChild: true,
        component: Users,
        options: {
            query: {
                per_page: 25,
                page: 1,
                'order-by': [
                    { type: 'field', field: 'created', direction: 'desc' }
                ],
                filter: [
                    { type: 'eq', field: 'state', value: 'active' }
                ]
            }
        },
        children: [
            {
                entityClass: 'Users\\Entity\\User',
                path: 'entity',
                component: User
            },
            {
                entityClass: 'Users\\Entity\\User',
                path: 'entity/:id',
                component: User
            },
            {
                entityClass: 'Orderadmin\\DeliveryServices\\Entity\\Sender',
                path: 'sender/entity',
                component: Sender
            },
            {
                entityClass: 'Orderadmin\\DeliveryServices\\Entity\\Sender',
                path: 'sender/entity/:id',
                component: Sender
            },
            {
                entityClass: 'Orderadmin\\Products\\Entity\\Shop',
                path: 'store/entity',
                component: Shop
            },
            {
                entityClass: 'Orderadmin\\Products\\Entity\\Shop',
                path: 'store/entity/:id',
                component: Shop
            }
        ]
    },
]