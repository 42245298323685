export class InstructionsService {
  _refs
  _printerUtils
  _httpClient
  _router
  _route

  constructor (refs, printerUtils, httpClient, router, route) {
    this._refs = refs
    this._printerUtils = printerUtils
    this._httpClient = httpClient
    this._router = router
    this._route = route
  }

  /**
   * @param {array} instructions
   *
   * @returns {Promise<boolean>}
   */
  execute (instructions = [], results = []) {
    // console.log(instructions)
    // console.trace('123')
    if (results.includes(false)) {
      return Promise.resolve(false)
    }

    if (instructions.length <= 0) {
      return Promise.resolve(!results.includes(false))
    }

    const actions = {
      message: data => this.message(data),
      'label-print': data => this.labelPrint(data),
      'document-print': data => this.documentPrint(data),
      'barcode-scan': data => this.barcodeScan(data),
      'entity-update': data => this.entityUpdate(data),
      'confirm': data => this.confirm(data),
      'measuring-fast': data => this.measureFast(data),
      'bundle': data => this.bundle(data),
    }

    return Promise.resolve(true)
      .then(() => {
        let instruction = instructions[0]

        if (typeof actions[instruction.type] === 'function') {
          return actions[instruction.type](instruction)
        }

        console.error(`Instruction with type ${instruction.type} is not found!`)

        return true
      })
      .then(result => {
        results.push(!!result)

        return this.execute(instructions.slice(1), results)
      })
  }

  /**
   * @param {object} data
   *
   * @returns {Promise<boolean}
   */
  measureFast (data) {
    let url = this._route.path

    this._router.push(`/workstation/labelling/regular/0/${data.data.deliveryRequest}?back=${url}`)

    // return this._refs.measuringFastModal.show(data)
  }

  bundle (data) {
    return Promise.resolve(data)
  }

  /**
   * @param {object} data
   *
   * @returns {Promise<boolean}
   */
  confirm (data) {
    return this._refs.confirmModal.show(data.options)
      .then(isOk => {
        if (isOk && data.data.onOk) {
          return this.execute(data.data.onOk.instructions)
        }

        return isOk
      })
  }

  /**
   * @param {object} data
   *
   * @returns {Promise<boolean}
   */
  message (data) {
    let message = data.data?.message
    if (data.message) {
      message = data.message
    }

    return this._refs.confirmModal.show({ description: message })
  }

  /**
   * @param {object} data
   *
   * @returns {Promise<boolean}
   */
  printData (data) {
    if (typeof data.data === 'object') {
      return this._printerUtils.generateBarcode(data.data)
    }

    if (data.options && data.options.url) {
      return this._httpClient(data.data)
        .then(response => response.text())
        .then(str => {
          this._printerUtils.print(str)
        })
    }

    return this._printerUtils.print(data.data)
  }

  /**
   * @param {object} data
   *
   * @returns {Promise<boolean}
   */
  labelPrint (data) {
    this.printData(data)

    if (!data.options || !data.options.confirm) {
      return Promise.resolve(true)
    }

    if (typeof data.options.confirm === 'boolean') {
      return this._refs.barcodeInputModal.show(data.options.confirm, { okOnly: true })
        .then(result => {
          this._refs.barcodeInputModal.hide()
          return result
        })
    }

    return this._refs.barcodeInputModal.show(data.options.confirm, { noSkip: true, print: () => this.printData(data) })
      .then(result => {
        this._refs.barcodeInputModal.hide()
        return result
      })
  }

  documentPrint (data) {
    this.printData(data)

    if (!data.options || !data.options.confirm) {
      return Promise.resolve(true)
    }

    if (typeof data.options.confirm === 'boolean') {
      return this._refs.barcodeInputModal.show(data.options.confirm, { okOnly: true })
        .then(result => {
          this._refs.barcodeInputModal.hide()
          return result
        })
    }

    return this._refs.barcodeInputModal.show(data.options.confirm, { noSkip: true, print: () => this.printData(data) })
      .then(result => {
        this._refs.barcodeInputModal.hide()
        return result
      })
  }

  /**
   * @param {object} data
   *
   * @returns {Promise<boolean}
   */
  entityUpdate (data) {
    return this._refs.entityUpdateModal.show(data.data, data.options)
      .then(data => {
        this._refs.entityUpdateModal.hide()
        return data
      })
  }

  /**
   * @param {object} data
   *
   * @returns {Promise<boolean}
   */
  barcodeScan (data) {
    if (!data.options || !data.options.confirm) {
      return Promise.resolve(true)
    }

    return this._refs.barcodeInputModal.show(data.data.barcode, data)
      .then(() => {
        this._refs.barcodeInputModal.hide()
        return true
      })
  }
}
