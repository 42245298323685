const register = {
  '/products/order': {
    400: 'Please create a Store'
  },
  '/products/offer': {
    400: 'Please create a Store'
  },
  '/delivery-services/senders': {
    400: 'Please create a Sender'
  }
}

/**
 * Get Message
 *
 * @param {object} error
 * @param {string|undefined} url
 *
 * @returns {string} 
 */
export function getMessage (error, url) {
  if (register[url] && register[url][error.status]) {
    return register[url][error.status]
  }

  return error.message
}
