import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery,
} from './../../../helpers/request-helpers'

export default {
  state: {
    documentTemplate: null,
    cleanDocumentTemplate: null,
    documentTemplates: [],
    totalDocumentTemplatesNumber: 0,
    documentTemplatesTotalPages: 1,
    documentTemplatesPage: 1,
    documentTemplatesLoading: false,
  },
  getters: {
    documentTemplate: (state) => state.documentTemplate,
    cleanDocumentTemplate: (state) => state.cleandDcumentTemplate,
    documentTemplates: (state) => state.documentTemplates,
    documentTemplatesLoading: (state) => state.documentTemplatesLoading,
    totalDocumentTemplatesNumber: (state) => state.totalDocumentTemplatesNumber,
    documentTemplatesTotalPages: (state) => state.documentTemplatesTotalPages,
    documentTemplatesPage: (state) => state.documentTemplatesPage,
  },
  mutations: {
    setDocumentTemplate(state, payload) {
      state.documentTemplate = payload
      state.cleanDocumentTemplate =
        typeof payload === 'object' ? _.cloneDeep(payload) : payload
    },
    updateDocumentTemplate(state, payload) {
      state.documentTemplate = {
        ...state.documentTemplate,
        ...payload,
      }
    },
    updateDocumentTemplateEmbedded(state, payload) {
      state.documentTemplate._embedded = {
        ...state.documentTemplate._embedded,
        ...payload,
      }
    },
    setNewDocumentTemplate(state) {

      state.documentTemplate = {
        state: 'active',
        isFile: false,
        documentType: 'text/html',
        conditions: [],
        _embedded: {
          owner: state.currentUser,
        },
        template: '',
      }
    },
    setCleanDocumentTemplate(state, payload) {
      state.cleanDocumentTemplate = payload
    },
    setDocumentTemplatesData(state, { items, page, totalPages, totalItems }) {
      state.documentTemplates = items
      state.documentTemplatesPage = page
      state.documentTemplatesTotalPages = totalPages
      state.totalDocumentTemplatesNumber = totalItems
    },
    setDocumentTemplates(state, payload) {
      state.documentTemplates = payload
    },
    setDocumentTemplatesPage(state, payload) {
      state.documentTemplatesPage = payload
    },
    setDocumentTemplatesTotalPages(state, payload) {
      state.documentTemplatesTotalPages = payload
    },
    setTotalDocumentTemplatesNumber(state, payload) {
      state.totalDocumentTemplatesNumber = payload
    },
    startDocumentTemplatesLoading(state) {
      state.documentTemplatesLoading = true
    },
    stopDocumentTemplatesLoading(state) {
      state.documentTemplatesLoading = false
    },
    upsertDocumentTemplate(state, payload) {
      let isExist = false

      state.documentTemplates = state.documentTemplates.map((x) => {
        if (x.id === payload.id) {
          isExist = true
          return payload
        }

        return x
      })

      if (!isExist) {
        state.documentTemplates.unshift(payload)
      }
    },
  },
  actions: {
    loadDocumentTemplates({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.documentTemplates)
      return services.documentTemplate.getAll(query).then((data) => {
        commit('setDocumentTemplatesData', data)
        return data
      })
    },
    loadDocumentTemplate({ commit }, payload) {
      return services.documentTemplate.get(payload).then((item) => {
        commit('setDocumentTemplate', item)
        return item
      })
    },
    saveDocumentTemplate({ commit, state }) {
      const ignoredFields = []
      const data = state.documentTemplate.id
        ? convertEmbedded(
            difference(state.documentTemplate, state.cleanDocumentTemplate),
            ignoredFields
          )
        : convertEmbedded(state.documentTemplate)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.documentTemplate)
      }

      const header =
        state.documentTemplate.id && state.documentTemplate.updated
          ? { 'X-Entity-Updated': state.documentTemplate.updated }
          : {}

      return services.documentTemplate.save(
        data,
        state.documentTemplate.id,
        header
      )
    },
  },
}
