import { HALApiService } from '../hal-api.service'
import { createItemsData } from '../../helpers/request-helpers'
import { Service } from './base-service'

class AcceptanceItemsService extends Service {
  constructor (httpClient, middleware) {
    super('/storage/movements/document/item', httpClient, middleware)
  }

  /**
   * @param {object} data
   * @param {String | Number | undefined} id
   * @param {string} state
   * @param {string | number | undefined} place
   * @param {object | undefined} headers
   * @param {object | undefined} query
   *
   * @returns {Promise<object>}
   */
  save (data, id, state, place, headers, query = {}) {
    const updatedData = this._transformBackItem(data, state)

    const updatedHeaders = { ...headers || {} }

    if (place) {
      updatedHeaders['X-Storage-Location'] = place
    }

    return super.save(updatedData, id, updatedHeaders, query).then(x => this._transformItem(x, state))
  }

  /**
   * @param {object} item
   *
   * @returns {object}
   */
  _transformBackItem (item, state) {
    const acceptanceItem = {
      ...item,
      count: undefined,
      expected: undefined
    }

    if (state === 'new') {
      acceptanceItem.quantityExpected = item.count
    } else {
      acceptanceItem.quantityReal = item.count
      acceptanceItem.quantityExpected = item.expected
    }

    return acceptanceItem
  }

  /**
   * @param {object} item
   * @param {string} state
   *
   * @returns {object}
   */
  _transformItem (item, state) {
    if (!item._embedded.productOffer) {
      return item
    }

    const acceptanceItem = {
      ...item._embedded.productOffer,
      ...item,
    }

    if (state === 'new') {
      acceptanceItem.count = item.quantityExpected
      acceptanceItem.expected = 0
    } else {
      acceptanceItem.count = item.quantityReal
      acceptanceItem.expected = item.quantityExpected - item.quantityReal

      if (acceptanceItem.expected < 0) {
        acceptanceItem.expected = 0
      }
    }

    return acceptanceItem
  }

  /**
   * @param {object} query
   * @param {string} state
   * @param {string|number|undefined} place
   * @param {object} headers
   *
   * @returns {Promise<object>}
   */
  getAllForAcceptance (query, state, place, headers = {}) {
    if (place) {
      headers['X-Storage-Location'] = place
    }

    return super.getAll(query, undefined, headers)
      .then(data => {
        return {
          ...data,
          items: data.items.map(x => {
            const item = this._transformItem(x, state)

            if (item.expires && item.expires.date) {
              item.expires = item.expires.date.split(' ')[0]
            }

            return item
          })
        }
      })
  }

  /**
   * @param {array} items
   * @param {string} state
   *
   * @returns {Promise<array>}
   */
  saveAll (items, state) {
    return this._httpClient.bulk(this._url, items.reduce((acc, x) => {
      if (x.id) {
        // 0 is falsy value so we strictly check it
        if (x.count === 0 || x.count === '0') {
          acc.push({ delete: { id: x.id } })
          return acc
        }

        acc.push({ update: this._transformBackItem(x, state) })
        return acc
      }

      if (x.count > 0) {
        acc.push({ create: this._transformBackItem(x, state) })
      }

      return acc
    }, []))
  }

  saveBulk (items, update) {
    let itemsUpdate = items.reduce((acc, x) => {
      if (x.id) {
        acc.push({ update: {...update, id: x.id} })
        return acc
      }

      if ( x.productOffer && x.shop) {
        acc.push({ create: {...update, productOffer : {id: x.productOffer, shop: x.shop}, id: x.id} })
        return acc
      }

      if (x.count > 0) {
        acc.push({ create: {...update, id: x.id} })
      }

      return acc
    }, [])

    return this._httpClient.bulk(this._url, itemsUpdate)
  }
}

export const acceptanceItemsService = new AcceptanceItemsService(HALApiService, createItemsData)
