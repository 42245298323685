import documentsRoutes from './routes/documents.routes'
import ordersRoutes from './routes/orders.routes'
import inboundRoutes from './routes/inbound.routes'
import outboundRoutes from "./routes/outbound.routes";
import storageRoutes from './routes/storage.routes'
import settingsRoutes from './routes/settings.routes'
import workstationRoutes from './routes/workstation.routes'
import billingRoutes from './routes/billing.routes'
import customerPortalsRoutes from './routes/users.routes'
import appsRoutes from './routes/apps.routes'
// import reportsRoutes from './routes/reports.routes'

const Home = () => import('../pages/Home')
const DRServicePoint = () => import('../pages/widgets/DRServicePoint')
const ShopifyCheckout = () => import('../pages/integrations/shopify/widgets/Checkout')
const NotFound = () => import('../pages/NotFound')
const Unauthorized = () => import('../pages/Unauthorized')
const Shopify = () => import('./../pages/settings/Shopify')

export const rawRoutes = [
  {
    entityClass: '',
    path: '/',
    icon: 'home',
    name: 'Home',
    component: Home
  },
  {
    entityClass: '',
    path: '/login',
    redirect: '/'
  },
  {
    entityClass: '',
    path: '/register',
    redirect: '/'
  },
  {
    entityClass: 'Picking',
    path: '/terminal',
    name: 'Terminal',
    icon: 'qr_code',
    options: {
      styleClasses: window.innerWidth < 700
        ? 'd-none'
        : ''
    },
    ignoreRedirect: true,
    redirect: '/'
  },
  ...workstationRoutes,
  ...ordersRoutes,
  ...inboundRoutes,
  ...outboundRoutes,
  ...storageRoutes,
  ...documentsRoutes,
  // ...reportsRoutes,
  ...billingRoutes,
  ...appsRoutes,
  ...settingsRoutes,
  ...customerPortalsRoutes
]

function createRoutes (parentRoute, routes) {
  return routes.reduce((acc, route) => {
    const updatedRoute = {
      ...route,
      name: undefined,
      children: undefined
    }

    if (parentRoute) {
      updatedRoute.path = `${parentRoute.path}/${updatedRoute.path}`
    }

    if (updatedRoute.component || updatedRoute.redirect) {
      acc.push(updatedRoute)
    }

    if (route.children) {
      acc = [...acc, ...createRoutes(updatedRoute, route.children)]
    }

    return acc
  }, [])
}

const routes = [
  ...createRoutes(undefined, rawRoutes),
  {
    path: '/shopify/:id',
    component: Shopify
  },
  // Olds
  {
    path: '/widgets/delivery-services/service-points',
    component: DRServicePoint
  },
  {
    path: '/widgets/shopify/checkout',
    component: ShopifyCheckout,
    props: {
      unAuthAccess: true,
      disableHeader: true,
      disableSidebar: true
    }
  },
  {
    path: '/unauthorized',
    component: Unauthorized,
    props: {
      disableHeader: true,
      disableSidebar: true
    }
  },
  // Not Found
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    props: {
      disableHeader: true,
      disableSidebar: true
    }
  }
]

/**
 * Get All Routes
 *
 * @returns {Array}
 */
export function getAllRoutes () {
  return routes.map(({ path }) => path).filter(path => !path.includes(':id'))
}

export default routes
