import { authHeader } from '../helpers'

import {
  errorCatcher,
  handleResponse,
  handleStartFetch
} from './handlers'

import { store } from '../vueX/store'
import { router } from '../router'
import { resetStorage } from '../utils'
import services from './services'
import { HALApiService } from './hal-api.service'

export const UserService = {
  login,
  refreshAuth,
  logout,
  register,
  getAll,
  getById,
  update,
  delete: _delete,
  save
}

/**
 * Login
 *
 * @param {String} username
 * @param {String} password
 * @param {String} server
 *
 * @returns {Promise<Object>}
 */
function login (username, password, server = null) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify({
      grant_type: 'password',
      client_id: window.appOptions.app,
      username: username,
      password: password,
      domain: window.appOptions.domain
    })
  }

  if (server === null) {
    server = window.appOptions.defaultServer
  }

  handleStartFetch('/', undefined, requestOptions)

  return fetch(server + '/oauth', requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
    .catch(errorCatcher)
}

/**
 * Login with refresh token
 *
 * @param {String} refreshToken
 * @param {String} server
 *
 * @returns {Promise<Object>}
 */
function refreshAuth (refreshToken, server = null) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify({
      grant_type: 'refresh_token',
      client_id: window.appOptions.app,
      refresh_token: refreshToken,
      domain: window.appOptions.domain
    })
  }

  if (server == null) {
    server = window.appOptions.defaultServer
  }

  handleStartFetch('/', undefined, requestOptions)

  return fetch(server + '/oauth', requestOptions)
    .then(handleResponse)
    .then(user => {
      return user
    })
    .catch(errorCatcher)
}

/**
 * Log Out
 */
function logout () {
  resetStorage()
  router.push('/login')

  Object.values(services).forEach(instance => {
    if (instance) {
      instance.cache && typeof instance.cache === 'object' && instance.cache?.clearAll()
    }
  })

  Object.values(HALApiService.requests).forEach(request => {
    request && request.abort && request.abort()
  })

  store.commit('resetState')
}

/**
 * Register
 *
 * @param {Object} user
 *
 * @returns {Promise<Object>}
 */
function register (user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  }

  handleStartFetch('/', undefined, requestOptions)
  return fetch(window.appOptions.defaultServer + `/users/register`, requestOptions).then(handleResponse).catch(errorCatcher)
}

/**
 * Get All
 *
 * @returns {Promise<Object>}
 */
function getAll () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  handleStartFetch('/', undefined, requestOptions)
  return fetch(window.appOptions.defaultServer + `/users`, requestOptions).then(handleResponse).catch(errorCatcher)
}

/**
 * Get User by ID
 *
 * @param {Number} id
 *
 * @returns {Promise<Object>}
 */
function getById (id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  handleStartFetch('/', undefined, requestOptions)
  return fetch(window.appOptions.defaultServer + `/api/users/users/${id}`, requestOptions).then(handleResponse).catch(errorCatcher)
}

/**
 * Update User
 *
 * @param {Object} user
 *
 * @returns {Promise<Object>}
 */
function update (user) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  }

  handleStartFetch('/', undefined, requestOptions)
  return fetch(window.appOptions.defaultServer + `/api/users/users/${user.id}`, requestOptions).then(handleResponse).catch(errorCatcher)
}

/**
 * Save User
 *
 * @param {Number} id
 * @param {Object} data
 *
 * @returns {Promise<Object>}
 */
function save (id, data) {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify(data)
  }

  handleStartFetch('/', undefined, requestOptions)
  return fetch(window.appOptions.defaultServer + `/api/users/users/${id}`, requestOptions).then(handleResponse).catch(errorCatcher)
}

/**
 * Delete User - prefixed function name with underscore because delete is a reserved word in javascript
 *
 * @param {Number} id
 *
 * @returns {Promise<Object>}
 */
function _delete (id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  }

  handleStartFetch('/', undefined, requestOptions)
  return fetch(window.appOptions.defaultServer + `/api/users/users/${id}`, requestOptions).then(handleResponse).catch(errorCatcher)
}
