import { HALApiService } from './../hal-api.service'
import { createItemsData } from './../../helpers/request-helpers'
import { CachedService } from './base-service'
import { printerUtils } from '../../utils/printer-utils'
import entities from '../../../../config/Entities'

class InvoiceService extends CachedService {
  _utils

  constructor (httpClient, middleware, utils) {
    super(entities.Orderadmin_Accounts_Entity_Invoice, '/accounts/invoice', httpClient, middleware)
    this._utils = utils
  }

  print (id) {
    // return this._httpClient.getRaw(`/accounts/dashboard/print-invoice/${id}`, undefined, 'text/html', {}, true)
    //   .then(data => {
    //     console.debug(data)
    //   })
  }
}

export const invoicesService = new InvoiceService(HALApiService, createItemsData, { printer: printerUtils })
