import { getUserPermissionsByRole } from '../../config/TemporaryPermissions'
import { HALApiService } from '../hal-api.service'
import { createItemsData } from './../../helpers/request-helpers'
import { Service } from './base-service'

class PermissionsService extends Service {
  _userUrl

  constructor (httpClient, middleware) {
    super('/users/permissions', httpClient, middleware)
    this._userUrl = '/users/users/0'
  }

  /**
   * @param {array} object
   *
   * @returns {Promise<object>}
   */
  getUserPermission (objects) {
    return Promise.resolve(getUserPermissionsByRole(objects))
    // const query = {
    //   page: 1,
    //   per_page: 250,
    //   filter: [
    //     { type: 'eq', field: 'state', value: 'active' },
    //     { type: 'neq', field: 'type', value: 'object' },
    //     { type: 'in', field: 'object', values: objects }
    //   ]
    // }

    // return this._httpClient.get(this._userUrl, query, 'application/permissions+json')
    //   .then(({ items }) => {
    //     return items.reduce((acc, item) => {
    //       acc[items.object] = item
    //       return acc
    //     }, {})
    //   })
  }
}

export const permissionsService = new PermissionsService(HALApiService, createItemsData)
