import { Centrifuge } from 'centrifuge'

export function authHeader () {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem('user'))

  if (user && user.access_token) {
    return {'Authorization': 'Bearer ' + user.access_token}
  } else {
    return {}
  }
}

export async function getToken (ctx) {
  const res = await fetch(`${window.appOptions.defaultServer || 'https://alpha.orderadmin.eu'}/apps/centrifugo/subscribe`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      ...authHeader()
    },
    body: JSON.stringify(ctx)
  });

  if (!res.ok) {
    if (res.status === 403) {
      // Return special error to not proceed with token refreshes, client will be disconnected.
      throw new Centrifuge.UnauthorizedError();
    }
    // Any other error thrown will result into token refresh re-attempts.
    throw new Error(`Unexpected status code ${res.status}`);
  }
  const data = await res.json();
  return data.token;
}
