<script>
import container from './container'

export default {
  name: 'PortalTarget',
  emits: ['change'],
  props: {
    name: {
      type: String,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      nodes: [],
      sub: null
    }
  },
  mounted () {
    
    this.nodes = this.children()
    
    this.$emit('change', this.nodes.length > 0)

    this.sub = container.addTarget(this.name).subscribe(() => {
      this.nodes = this.children()
      this.$emit('change', this.nodes.length > 0)
    })
  },
  unmounted () {
    if (this.sub) {
      this.sub.unsubscribe()
    }
  },
  render () {

    return this.nodes[0]
  },
  methods: {
    children () {
      return container.getTargetValue(this.name) || []
    }
  }
}
</script>
