import { HALApiService } from '../hal-api.service'
import { createItemsData } from './../../helpers/request-helpers'

class InputFilterService {
  _httpClient;
  _middleware;
  _url;

  _cache;

  constructor (httpClient, middleware) {
    this._httpClient = httpClient
    this._middleware = middleware
    this._url = '/input-filter'
    this._cache = new Map()
  }

  /**
   * @param {string} entityClass
   *
   * @returns {Promise<array>}
   */
  get (entityClass) {
    if (this._cache.has(entityClass)) {
      return Promise.resolve(this._cache.get(entityClass))
    }

    const query = {
      per_page: 250,
      page: 1,
      filter: [
        { type: 'eq', field: '', value: entityClass }
      ]
    }

    return this._httpClient.get(this._url, query)
      .then(this.middleware)
      .then(({ items }) => {
        this._cache.set(entityClass, items)
        return items
      })
  }
}

export const inputFilterService = new InputFilterService(HALApiService, createItemsData)
