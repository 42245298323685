import { HALApiService } from './../hal-api.service'
import { createItemsData } from './../../helpers/request-helpers'
import { Service } from './base-service'

class StorageWarehousesInventoryService extends Service {
    constructor (httpClient, middleware) {
        super('/storage/view/warehouses-availability', httpClient, middleware)
    }

    /**
     * @param {object} data
     * @param {object} query
     * @param {String} accept
     *
     * @returns {Promise<object>}
     */
    patch (data, query, accept, place , headers= {}) {
        if (place) {
            headers['X-Storage-Location'] = place
        }

        return this._httpClient.patch(this._url, data, accept, undefined, headers, query)
    }

    /**
     * @param {object} query
     * @param {string|number|undefined} place
     * @param {object} headers
     *
     * @returns {Promise<object>}
     */
    getAll (query, place, headers = {}) {
        if (place) {
            headers['X-Storage-Location'] = place
        }

        return super.getAll(query, undefined, headers)
    }
}

export const storageWarehousesInventoryService = new StorageWarehousesInventoryService(HALApiService, createItemsData)
