import _ from 'lodash'
import services from '../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    accountRate: null,
    cleanAccountRate: null,
    accountRates: [],
    accountRatesLoading: false,
    accountRatesNumber: 0,
    accountRatesPage: 1,
    accountRatesTotalPages: 1
  },
  getters: {
    accountRate: state => state.accountRate,
    cleanAccountRate: state => state.cleanAccountRate,
    accountRates: state => state.accountRates,
    accountRatesLoading: state => state.accountRatesLoading,
    accountRatesNumber: state => state.accountRatesNumber,
    accountRatesPage: state => state.accountRatesPage,
    accountRatesTotalPages: state => state.accountRatesTotalPages
  },
  mutations: {
    setAccountRate (state, payload) {
      state.accountRate = payload

      state.cleanAccountRate = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanAccountRate (state, payload) {
      state.cleanAccountRate = payload
    },
    updateAccountRateValues (state, payload) {
      state.accountRate.rateModifiers = {
        ...state.accountRate.rateModifiers,
        ...payload
      }
    },
    setNewAccountRate (state) {
      state.accountRate = {
        name: null,
        type: 'default',
        state: 'active',
        _embedded: {
          owner: { ...state.currentUser }
        }
      }
    },
    updateAccountRate (state, payload) {
      state.accountRate = {
        ...state.accountRate,
        ...payload
      }
    },
    updateAccountRateEmbedded (state, payload) {
      state.accountRate = {
        ...state.accountRate,
        _embedded: {
          ...state.accountRate._embedded,
          ...payload
        }
      }
    },
    setAccountRatesData (state, { items, page, totalPages, totalItems }) {
      state.accountRates = items
      state.accountRatesNumber = totalItems
      state.accountRatesPage = page
      state.accountRatesTotalPages = totalPages
    },
    setAccountRates (state, payload) {
      state.accountRates = payload
    },
    setTotalAccountRatesNumber (state, payload) {
      state.accountRatesNumber = payload
    },
    setAccountRatePage (state, payload) {
      state.accountRatesPage = payload
    },
    setAccountRatesTotalPages (state, payload) {
      state.accountRatesTotalPages = payload
    },
    startRateLoading (state) {
      state.accountRatesLoading = true
    },
    stopRateLoading (state) {
      state.accountRatesLoading = false
    },
    upsertAccountRate (state, payload) {
      let isExist = false

      state.accountRates = state.accountRates.map(x => {
        if (x.id === payload.id) {
          isExist = true
          return payload
        }

        return x
      })

      if (!isExist) {
        state.accountRates.unshift(payload)
      }
    }
  },
  actions: {
    loadAccountRates ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.accountRates)

      return services.accountRate.getAll(query)
        .then(data => {
          commit('setAccountRatesData', data)
          return data
        })
    },
    loadAccountRate ({ commit }, payload) {
      return services.accountRate.get(payload)
        .then(item => {
          commit('setAccountRate', item)
          return item
        })
    },
    saveAccountRate ({ commit, state }) {
      const data = state.accountRate.id
        ? convertEmbedded(difference(state.accountRate, state.cleanAccountRate))
        : convertEmbedded(state.accountRate)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.accountRate)
      }

      const header = state.accountRate.id && state.accountRate.updated
        ? { 'X-Entity-Updated': state.accountRate.updated }
        : {}

      return services.accountRate.save(data, state.accountRate.id, header)
    }
  }
}
