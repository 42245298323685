import { authHeader } from '../../helpers'
import { errorCatcher, handleResponse } from '../handlers'

import qs from 'qs'

export const DSRequestService = {
  getById,
  searchById,
  searchByDeliveryRequestId,
  searchByLinkedOrderId,
  searchByPlaceId
}

function getById (id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(`https://pro.oawms.com/api/users/users/${id}`, requestOptions).then(handleResponse).catch(errorCatcher)
}

function searchById (id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  let get = {
    filter: [
      {
        field: 'state',
        type: 'in',
        values: [
          'pre_processing'
        ]
      },
      {
        type: 'orx',
        conditions: [
          {
            field: 'id',
            type: 'eq',
            value: id
          },
          {
            field: 'extId',
            type: 'eq',
            value: id
          },
          {
            field: 'clientExtId',
            type: 'eq',
            value: id
          }
        ],
        where: 'and'
      }
    ]
  }

  return fetch(window.appOptions.defaultServer + '/api/delivery-services/requests?' + qs.stringify(get), requestOptions).then(handleResponse).catch(errorCatcher)
}

function searchByDeliveryRequestId (id, states = ['pre_processing']) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  let get = {
    filter: [
      {
        field: 'state',
        alias: 'dr',
        type: 'in',
        values: states
      },
      {
        type: 'orx',
        conditions: [
          {
            field: 'id',
            alias: 'dr',
            type: 'eq',
            value: id
          },
          {
            field: 'extId',
            alias: 'dr',
            type: 'eq',
            value: id
          },
          {
            field: 'clientExtId',
            alias: 'dr',
            type: 'eq',
            value: id
          }
        ],
        where: 'and'
      }
    ]
  }

  return fetch(window.appOptions.defaultServer + '/api/delivery-services/delivery-request-service?action=tasks&' + qs.stringify(get), requestOptions).then(handleResponse).catch(errorCatcher)
}

// eslint-disable-next-line
function searchByLinkedOrderId (id, states = ['pre_processing']) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  let get = {
    filter: [
      {
        field: 'state',
        alias: 'dr',
        type: 'in',
        values: states
      },
      {
        type: 'leftjoin',
        field: 'order',
        parentAlias: 'dr',
        alias: 'o',
        conditionType: 'WITH',
        condition: 'dr.id=o.deliveryRequest'
      },
      {
        field: 'id',
        alias: 'o',
        type: 'eq',
        value: id
      }
    ]
  }

  return fetch(window.appOptions.defaultServer + '/api/delivery-services/delivery-request-service?action=tasks&' + qs.stringify(get), requestOptions).then(handleResponse).catch(errorCatcher)
}

function searchByPlaceId (id, states = ['pre_processing']) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  let get = {
    filter: [
      {
        field: 'state',
        alias: 'dr',
        type: 'in',
        values: states
      },
      {
        type: 'leftjoin',
        field: 'places',
        parentAlias: 'dr',
        alias: 'drp',
        conditionType: 'WITH',
        condition: 'dr.id = drp.deliveryRequest'
      },
      {
        field: 'id',
        alias: 'drp',
        type: 'eq',
        value: id
      }
    ]
  }

  return fetch(window.appOptions.defaultServer + '/api/delivery-services/delivery-request-service?action=tasks&' + qs.stringify(get), requestOptions).then(handleResponse).catch(errorCatcher)
}
