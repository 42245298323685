import { HALApiService } from './../hal-api.service'
import { createItemsData } from './../../helpers/request-helpers'
import { Service } from './base-service'

class Transactions extends Service {
  constructor (httpClient, middleware) {
    super('/accounts/transaction', httpClient, middleware)
  }

  /**
   * @param {object} query
   *
   * @returns {Promise<object>}
   */
  getStatistic (query) {
    return this._httpClient.get(`/statistics/accounts/transactions/date`, query)
      .then(items => {
        return {
          items,
          totalItems: items.length,
          page: 1,
          totalPages: 1
        }
      })
  }

  /**
   * @param {object} query
   *
   * @returns {Promise<excel>}
   */
   downloadAll (query) {
    const updatedQuery = {
      page: 1,
      per_page: 25,
      sort: {
        id: 'desc'
      },
      'order-by': [
        { type: 'field', field: 'id', direction: 'desc' }
      ],
      ...query
    }

    return this._httpClient.download(this._url, updatedQuery, 'application/xls')
  }
}

export const transactionsService = new Transactions(HALApiService, createItemsData)
