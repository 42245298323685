const OrderProducts = () => import('../../pages/wms/OrderProducts')
const Orders = () => import('../../pages/wms/Orders')
const Order = () => import('../../pages/wms/Order')
const Shipments = () => import('../../pages/delivery-manager/DMParcels')
const DMRequest = () => import('../../pages/delivery-manager/DMRequest')
const ProductMatching = () => import('./../../pages/wms/ProductMatching')

export default [
  {
    entityClass: '',
    name: 'Outbound',
    icon: 'output',
    path: '/outbound',
    children: [
      {
        entityClass: 'Orderadmin\\Products\\Entity\\AbstractOrder',
        path: 'orders',
        name: 'Orders',
        component: Orders,
        options: {
          query: {
            per_page: 25,
            page: 1,
            'order-by': [
              {
                type: 'field',
                field: 'created',
                direction: 'desc'
              }
            ]
          }
        },
        children: [
          {
            entityClass: 'Orderadmin\\Products\\Entity\\AbstractOrder',
            path: '/entity/:type',
            component: Order
          },
          {
            entityClass: 'Orderadmin\\Products\\Entity\\AbstractOrder',
            path: '/entity/:type/:id',
            component: Order
          }
        ]
      },
      {
        entityClass: 'Orderadmin\\DeliveryServices\\Entity\\DeliveryRequest',
        path: 'shipments',
        name: 'Shipments',
        component: Shipments,
        options: {
          query: {
            per_page: 25,
            page: 1,
            'order-by': [
              {
                type: 'field',
                field: 'created',
                direction: 'desc'
              }
            ]
          }
        },
        children: [
          {
            entityClass: 'Orderadmin\\DeliveryServices\\Entity\\DeliveryRequest',
            path: '/entity/:id',
            component: DMRequest
          },
          {
            entityClass: 'Orderadmin\\DeliveryServices\\Entity\\DeliveryRequest',
            path: '/entity',
            component: DMRequest
          }
        ]
      },
      {
        entityClass: '',
        path: 'order',
        name: 'Outbound inventory',
        component: OrderProducts,
        options: {
          query: {
            per_page: 25,
            page: 1,
            'order-by': [
              { type: 'field', field: 'created', direction: 'desc' }
            ]
          }
        },
      },
      // {
      //   entityClass: 'Orderadmin\\Storage\\Entity\\View\\OfferInventory',
      //   path: 'product-matching',
      //   name: 'Products Matching β',
      //   component: ProductMatching,
      //   options: {
      //     query: {
      //       page: 1,
      //       per_page: 25,
      //       'order-by': [
      //         { type: 'field', field: 'created', direction: 'desc' }
      //       ]
      //     }
      //   },
      // }
    ]
  }
]