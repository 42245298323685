import { store } from '../vueX/store'

export class StoreUtils {
  /**
   * @param {string | number} place
   *
   * @returns {object | undefined}
   */
  getLockedPlaceEvent (place) {
    // For some unknown reason in moment that we create utils store is not created
    // So we don't inject it via contructor
    return store.getters.storagePlaceEvents.find(x => {
      return `${x.entity}` === `${place}`
    })
  }
}
