/**
 * Get terminal settings
 *
 * @returns {object}
 */
function getTerminalSettings () {
  const terminalSettings = JSON.parse(localStorage.getItem('terminalSettings'))
  const defaultSettings = {
    hideBarcodeField: false,
    catchAll: true,
    hideKeyboard: false
  }

  return terminalSettings
    ? { ...defaultSettings, ...terminalSettings }
    : defaultSettings
}

export default {
  state: {
    terminalSettings: getTerminalSettings()
  },
  getters: {
    terminalSettings: state => state.terminalSettings
  },
  mutations: {
    updateTerminalSettings (state, payload) {
      state.terminalSettings = {
        ...state.terminalSettings,
        ...payload
      }

      localStorage.setItem('terminalSettings', JSON.stringify(state.terminalSettings))
    }
  },
  actions: {}
}
