import { authHeader } from '../../helpers'
import qs from 'qs'
import { errorCatcher, handleResponse } from '../handlers'
import { HALApiService } from '..'
import { createItemsData } from '../../helpers/request-helpers'
import { Service } from './base-service'

class DSPTaskService extends Service {
  constructor (httpClient, middleware) {
    super('/delivery-services/preprocessing-task', httpClient, middleware)
  }

  /**
   * @param {String | Number | Undefined} id
   * @param {object} data
   *
   * @returns {Promise<object>}
   */
  process (id, data) {
    return this._httpClient.saveRequest(this._url, data, id, 'application/pdf,text/html')
  }

  /**
   * @param {String | Number} value
   *
   * @returns {Promise<object>}
  */
  getByDeliveryRequest (value) {
    const query = {
      filter: [
        { field: 'deliveryRequest', type: 'eq', value }
      ]
    }

    return this._httpClient.get(this._url, query)
      .then(result => {
        if (result._embedded.preprocessingTasks.length <= 0) {
          return Promise.reject(new Error('Preprocessing Task is not found!'))
        }

        return result._embedded.preprocessingTasks[0]
      })
  }

  /**
   * @param {string | number} id
   *
   * @returns {Promise<object>}
   */
  getLabel (id, accept = 'application/pdf,text/html') {
    return this.get(id, {}, accept)
  }

  /**
   * @param {String | Number} value
   *
   * @returns {Promise<object>}
  */
  getPDFByDeliveryRequest (value) {
    return this.getByDeliveryRequest(value)
      .then(result => {
        if (result._embedded.preprocessingTasks.length <= 0) {
          return Promise.reject(new Error('Preprocessing Task is not found!'))
        }

        return result._embedded.preprocessingTasks[0]
      })
      .then(task => {
        return this._httpClient.get(`${this._url}/${task.id}`, {}, 'application/pdf,text/html')
      })
  }
}

export const dspTaskService = new DSPTaskService(HALApiService, createItemsData)

export const DSProcessingTaskService = {
  getById,
  searchByDeliveryRequestId,
  patch,
  post
}

/**
 * Get By ID
 *
 * @param {Number} id
 * @param {string} accept
 *
 * @returns {Promise<Object>}
 */
function getById (id, accept = 'application/json') {
  const requestOptions = {
    method: 'GET',
    headers: Object.assign(authHeader(), {
      'Accept': accept
    })
  }

  return fetch(window.appOptions.defaultServer + '/api/delivery-services/preprocessing-task/' + id, requestOptions)
    .then(data => handleResponse(data, 'GET', accept))
    .catch(errorCatcher)
}

/**
 * Search by Delivery Request ID
 *
 * @param {Number} id
 *
 * @returns {Promise<Array>}
 */
function searchByDeliveryRequestId (id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  const get = {
    filter: [
      {
        field: 'state',
        alias: 't',
        type: 'eq',
        value: 'new'
      },
      {
        type: 'leftjoin',
        field: 'deliveryRequest',
        parentAlias: 't',
        alias: 'dr',
        conditionType: 'WITH',
        condition: 'dr.id=t.deliveryRequest'
      },
      {
        field: 'state',
        alias: 'dr',
        type: 'in',
        values: [
          'pre_processing'
        ]
      },
      {
        type: 'orx',
        conditions: [
          {
            field: 'id',
            alias: 'dr',
            type: 'eq',
            value: id
          },
          {
            field: 'extId',
            alias: 'dr',
            type: 'eq',
            value: id
          },
          {
            field: 'clientExtId',
            alias: 'dr',
            type: 'eq',
            value: id
          }
        ],
        where: 'and'
      }
    ]
  }

  return fetch(window.appOptions.defaultServer + '/api/delivery-services/preprocessing-task?' + qs.stringify(get), requestOptions).then(handleResponse).catch(errorCatcher)
}

/**
 * Patch
 *
 * @param {Number} id
 * @param {Object} data
 * @param {String} accept
 *
 * @returns {Promise<Object>}
 */
function patch (id, data, accept = 'application/json') {
  const requestOptions = {
    method: 'PATCH',
    headers: Object.assign(authHeader(), {
      'Content-Type': 'application/json',
      'Accept': accept
    }),
    body: JSON.stringify(data)
  }

  return fetch(window.appOptions.defaultServer + '/api/delivery-services/preprocessing-task/' + id, requestOptions)
    .then(data => handleResponse(data, 'PATCH', accept))
    .catch(errorCatcher)
}

/**
 * Post
 *
 * @param {Object} data
 * @param {String} accept
 *
 * @returns {Promise<Object>}
 */
function post (data, accept = 'application/json') {
  const requestOptions = {
    method: 'POST',
    headers: Object.assign(authHeader(), {
      'Content-Type': 'application/json',
      'Accept': accept
    }),
    body: JSON.stringify(data)
  }

  return fetch(window.appOptions.defaultServer + '/api/delivery-services/preprocessing-task', requestOptions)
    .then(data => handleResponse(data, 'POST', accept))
    .catch(errorCatcher)
}
