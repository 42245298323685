import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    storagePlaces: [],
    totalStoragePlacesNumber: 0,
    storagePlacesPage: 1,
    storagePlacesTotalPages: 1,
    storagePlace: null,
    cleanStoragePlace: null,
    storagePlacesLoading: false
  },
  getters: {
    storagePlaces: state => state.storagePlaces,
    storagePlacesLoading: state => state.storagePlacesLoading,
    totalStoragePlacesNumber: state => state.totalStoragePlacesNumber,
    storagePlacesPage: state => state.storagePlacesPage,
    storagePlacesTotalPages: state => state.storagePlacesTotalPages,
    storagePlace: state => state.storagePlace,
    cleanStoragePlace: state => state.cleanStoragePlace
  },
  mutations: {
    setNewStoragePlace (state) {
      state.storagePlace = {
        dimensions: {
          x: 0,
          y: 0,
          z: 0
        },
        placeAlias: null,
        rack: 0,
        room: 0,
        row: 0,
        shelf: 0,
        type: null,
        geoArray: {
          x: null,
          y: null
        },
        volume: 0,
        _embedded: {
          warehouse: null
        }
      }
    },
    setStoragePlaces (state, payload) {
      state.storagePlaces = payload
    },
    setStoragePlacesData (state, { items, page, totalPages, totalItems }) {
      state.storagePlaces = items
      state.totalStoragePlacesNumber = totalItems
      state.storagePlacesPage = page
      state.storagePlacesTotalPages = totalPages
    },
    setTotalStoragePlacesNumber (state, payload) {
      state.totalStoragePlacesNumber = payload
    },
    insertStoragePlaces (state, payload) {
      state.storagePlaces = [
        ...payload,
        ...state.storagePlaces
      ]
    },
    upsertStoragePlace (state, payload) {
      let isExist = false

      state.storagePlaces = state.storagePlaces.map(x => {
        if (x.id === payload.id) {
          isExist = true
          return payload
        }

        return x
      })

      if (!isExist) {
        state.storagePlaces = [
          payload,
          ...state.storagePlaces
        ]
      }
    },
    addStoragePlace (state, payload) {
      state.storagePlaces = [
        ...state.storagePlaces,
        payload
      ]
      state.totalStoragePlacesNumber = state.totalStoragePlacesNumber + 1
    },
    setStoragePlacesPage (state, payload) {
      state.storagePlacesPage = payload
    },
    setStoragePlacesTotalPages (state, payload) {
      state.storagePlacesTotalPages = payload
    },
    setStoragePlace (state, payload) {
      state.storagePlace = payload
      state.cleanStoragePlace = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    updateStoragePlace (state, payload) {
      state.storagePlace = {
        ...state.storagePlace,
        ...payload
      }
    },
    updateStoragePlaceEmbedded (state, payload) {
      state.storagePlace._embedded = {
        ...state.storagePlace._embedded,
        ...payload
      }
    },
    setCleanStoragePlace (state, payload) {
      state.cleanStoragePlace = payload
    },
    startStoragePlaceLoading (state) {
      state.storagePlacesLoading = true
    },
    stopStoragePlaceLoading (state) {
      state.storagePlacesLoading = false
    }
  },
  actions: {
    loadStoragePlaces ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.storagePlaces)
      return services.storagePlace.getAll(query)
        .then(data => {
          commit('setStoragePlacesData', data)
          return data
        })
    },
    loadStoragePlace ({ commit }, payload) {
      return services.storagePlace.get(payload)
        .then(item => {
          commit('setStoragePlace', item)
          return item
        })
    },
    saveStoragePlace ({ commit, state }) {
      const data = state.storagePlace.id
        ? convertEmbedded(difference(state.storagePlace, state.cleanStoragePlace))
        : convertEmbedded(state.storagePlace)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.storagePlace)
      }

      const header = state.storagePlace.id && state.storagePlace.updated
        ? { 'X-Entity-Updated': state.storagePlace.updated }
        : {}

      return services.storagePlace.save(data, state.storagePlace.id, header)
    }
  }
}
