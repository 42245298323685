import { store } from '../../vueX/store'
import { HALApiService } from '../hal-api.service'

import { createItemsData } from '../../helpers/request-helpers'
import { CachedService } from './base-service'
import entities from '../../../../config/Entities'

/**
 * Acceptance Service
 *
 * Store is a side effect. It is used without DI because it is created after service.
 */
class AcceptanceService extends CachedService {
  constructor (httpClient, middleware) {
    super(entities.Orderadmin_Storage_Entity_Movement_Acceptance, '/storage/movements/document', httpClient, middleware)
  }

  /**
   * @param {String} id
   *
   * @returns {Promise<any>}
   */
  getPDF (url) {
    return this._httpClient.getRaw(`${url}`, {}, 'application/pdf', { 'x-document-type': 'mx-1' })
      .then(response => {
        store.commit('stopLoading')

        if (response.status !== 200 && response.status !== 201) {
          const error = response.statusText

          return Promise.reject(error)
        }

        return response
      })
  }

  /**
   * @param {String | Number} id
   * @param {String | Number} template
   *
   * @returns {Promise<String>}
  */
  getTemplate (url, template) {
    return this._httpClient.getRaw(`${url}`, {}, `application/${template}+document`)
      .then(response => {
        if (response.status !== 200 && response.status !== 201) {
          return Promise.reject(new Error(response.statusText))
        }

        return response.text().then(data => {
          if (data.match('<div')) {
            data = `<html lang="en-us" dir="ltr"><head><meta charset="utf-8"></head><body>${data}</body></html>`
          }

          return data
        })
      })
  }

  downloadAll (query) {
    const updatedQuery = {
      page: 1,
      per_page: 25,
      sort: {
        id: 'desc'
      },
      'order-by': [
        { type: 'field', field: 'id', direction: 'desc' }
      ],
      ...query
    }

    return this._httpClient.download(this._url, updatedQuery, 'application/xls')
  }
}

export const acceptanceService = new AcceptanceService(HALApiService, createItemsData)
