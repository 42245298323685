import { HALApiService } from './../hal-api.service'
import { createItemsData } from './../../helpers/request-helpers'
import { Service } from './base-service'

class DigitalMarkService extends Service {
  constructor (httpClient, middleware) {
    super('/integrations/chestnyznak/digital-marks', httpClient, middleware)
  }

  /**
   * @param {object} query
   *
   * @returns {Promise<excel>}
   */
   downloadAll (query) {
    const updatedQuery = {
      page: 1,
      per_page: 25,
      sort: {
        id: 'desc'
      },
      'order-by': [
        { type: 'field', field: 'id', direction: 'desc' }
      ],
      ...query
    }

    return this._httpClient.download(this._url, updatedQuery, 'application/xls')
  }
}

export const digitalMarkService = new DigitalMarkService(HALApiService, createItemsData)
