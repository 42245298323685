import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    storageItems: [],
    totalStorageItemsNumber: 0,
    storageItemsPage: 1,
    storageItemsTotalPages: 1,
    storageItem: null,
    cleanStorageItem: null,
    storageItemsLoading: false
  },
  getters: {
    storageItems: state => state.storageItems,
    storageItemsLoading: state => state.storageItemsLoading,
    totalStorageItemsNumber: state => state.totalStorageItemsNumber,
    storageItemsPage: state => state.storageItemsPage,
    storageItemsTotalPages: state => state.storageItemsTotalPages,
    storageItem: state => state.storageItem,
    cleanStorageItem: state => state.cleanStorageItem
  },
  mutations: {
    setStorageItems (state, payload) {
      state.storageItems = payload
    },
    setStorageItemsData (state, { items, page, totalPages, totalItems }) {
      state.storageItems = items
      state.totalStorageItemsNumber = totalItems
      state.storageItemsPage = page
      state.storageItemsTotalPages = totalPages
    },
    setTotalStorageItemsNumber (state, payload) {
      state.totalStorageItemsNumber = payload
    },
    addStorageItem (state, payload) {
      state.storageItems = [
        ...state.storageItems,
        payload
      ]
      state.totalStorageItemsNumber = state.totalStorageItemsNumber + 1
    },
    setStorageItemsPage (state, payload) {
      state.storageItemsPage = payload
    },
    setStorageItemsTotalPages (state, payload) {
      state.storageItemsTotalPages = payload
    },
    setStorageItem (state, payload) {
      state.storageItem = payload
      state.cleanStorageItem = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanStorageItem (state, payload) {
      state.cleanStorageItem = payload
    },
    startStorageItemLoading (state) {
      state.storageItemsLoading = true
    },
    stopStorageItemLoading (state) {
      state.storageItemsLoading = false
    }
  },
  actions: {
    loadStorageItems ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.storageItems)
      return services.storageItemEntity.getAll(query)
        .then(data => {
          commit('setStorageItemsData', data)
          return data
        })
    },
    loadStorageItem ({ commit }, payload) {
      return services.storageItemEntity.get(payload)
        .then(item => {
          commit('setStorageItem', item)
          return item
        })
    },
    saveStorageItem ({ commit, state }) {
      const data = state.storageItem.id
        ? convertEmbedded(difference(state.storageItem, state.cleanStorageItem))
        : convertEmbedded(state.storageItem)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.storageItem)
      }

      const header = state.storageItem.id && state.storageItem.updated
        ? { 'X-Entity-Updated': state.storageItem.updated }
        : {}

      return services.storageItemEntity.save(data, state.storageItem.id, header)
    }
  }
}
