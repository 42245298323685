import { store } from '../../app/vueX/store'
import { getMessage } from './../../app/services/messages'

/**
 * Error Catcher
 *
 * @param {Object} error
 * @param {String|undefined} url
 *
 * @returns {Promise.reject<Object>}
 */
export function errorCatcher (error, url) {
  if (error.message.includes('The user aborted a request.')) {
    console.debug(error.message)
  } else {
    store.commit('addErrorNotification', getMessage(error, url))
  }

  commitStopLoading(error.loadingPrefix)

  return Promise.reject(error)
}

/**
 * Parse Response
 *
 * @param {String} text
 * @param {Object} response
 * @param {String} accept
 *
 * @returns {Any}
 */
function parseResponse (text, response, accept) {
  if (
    accept &&
    (accept.includes('application/pdf') || accept.includes('text/html'))
  ) {
    const value = response.headers
      .get('Content-Type')
      .split(';')
      .map(val => val.split('='))
      .find(val => (val[0] || '').includes('size'))

    return {
      text,
      pageSize: value
        ? value[1]
        : value
    }
  }

  if (text && (text[0] === '[' || text[0] === '{')) {
    return JSON.parse(text)
  }

  return text
}

/**
 * Parse Text
 *
 * @param {String} text
 *
 * @returns {Any}
 */
function parseText (text) {
  if (text && (text[0] === '[' || text[0] === '{')) {
    return JSON.parse(text)
  }

  return text
}

/**
 * Handle Response
 *
 * @param {Object} response
 * @param {String} action
 * @param {String} accept
 *
 * @returns {Promise<Object>}
 */
export function handleResponse (response, action, accept) {
  const saveActions = ['POST', 'PUT', 'PATCH']
  const bannedAPIs = ['calculator', 'event', 'message', 'bulk']

  return response.text().then(text => {
    const data = parseResponse(text, response, accept)

    if (window.appOptions && window.appOptions.debug) {
      store.commit('addResponseDebugNotification', { action, accept, response, data })
    }

    const loadingPrefix = createLoadingPrefix(response.url)

    if (response.status !== 200 && response.status !== 201) {
      const result = parseText(text)

      response.message = (result && result.detail) || response.statusText
      response.loadingPrefix = loadingPrefix

      return Promise.reject(response)
    }

    if (saveActions.includes(action)) {
      const arr = response.url.split('/')
      const str = action === 'PATCH'
        ? arr[arr.length - 2]
        : arr[arr.length - 1]

      const message = str.replace('-', ' ')

      if (!bannedAPIs.includes(message)) {
        store.commit('addNotification', `You successful save ${message}!`)
      }
    }

    commitStopLoading(loadingPrefix)

    return data
  })
}

/**
 * Commit Start Loading
 *
 * @param {String} prefix
 *
 * @returns {Void}
 */
function commitStartLoading (prefix) {
  const mutation = `start${prefix}Loading`

  if (store.getters.isInterceptedItemsLoading) {
    return
  }

  if (store._mutations[mutation]) {
    store.commit(mutation)
  } else {
    store.commit('startLoading')
  }
}

/**
 * Commit Stop Loading
 *
 * @param {String} prefix
 *
 * @returns {Void}
 */
function commitStopLoading (prefix) {
  const mutation = `stop${prefix}Loading`

  if (store.getters.isInterceptedItemsLoading) {
    return
  }

  if (store._mutations[mutation]) {
    store.commit(mutation)
  } else {
    store.commit('stopLoading')
  }
}

/**
 * Handle Start Fetch
 *
 * @param {String} url
 * @param {Object | Undefined} query
 *
 * @returns {Void}
 */
export function handleStartFetch (url, query, options) {
  if (window.appOptions && window.appOptions.debug) {
    store.commit('addRequestDebugNotification', { url, query, options })
  }

  const loadingPrefix = createLoadingPrefix(url, query)
  commitStartLoading(loadingPrefix)
}

/**
 * Create Loading Prefix
 *
 * @param {String} url
 * @param {Object | Undefined} query
 *
 * @returns {String}
 */
function createLoadingPrefix (url, query = {}) {
  const index = url.lastIndexOf('/') + 1
  const indexOfQuery = url.lastIndexOf('?')
  let str = ''

  if (indexOfQuery > -1) {
    str = url.slice(index, indexOfQuery)
  }

  if (Object.keys(query).length > 0 && indexOfQuery === -1) {
    str = url.slice(index)
  }

  return str
    .split('-')
    .map(x => {
      return x[0]
        ? x[0].toUpperCase() + x.slice(1)
        : x
    })
    .join('')
}
