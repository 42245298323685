import _ from 'lodash'
import services from './../../../services/services'
import AppAdapters from '../../../config/AppAdapters'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    app: null,
    cleanApp: null,
    apps: [],
    appAdapters: AppAdapters,
    totalAppsNumber: 0,
    appsTotalPages: 1,
    appsPage: 1,
    appsLoading: false
  },
  getters: {
    app: state => state.app,
    cleanApp: state => state.cleanApp,
    apps: state => state.apps,
    appAdapters: state => state.appAdapters,
    appsLoading: state => state.appsLoading,
    totalAppsNumber: state => state.totalAppsNumber,
    appsTotalPages: state => state.appsTotalPages,
    appsPage: state => state.appsPage
  },
  mutations: {
    setAppsData (state, { items, page, totalPages, totalItems }) {
      state.apps = items
      state.totalAppsNumber = totalItems
      state.appsPage = page
      state.appsTotalPages = totalPages
    },
    setApps (state, payload) {
      state.apps = payload
    },
    setApp (state, payload) {
      state.app = payload
      state.cleanApp = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setNewApp (state) {
      state.app = {}
    },
    updateapp (state, payload) {
      state.app = {
        ...state.app,
        ...payload
      }
    },
    updateAppEmbedded (state, payload) {
      state.app._embedded = {
        ...state.app._embedded,
        ...payload
      }
    },
    setCleanApp (state, payload) {
      state.cleanApp = payload
    }
  },
  actions: {
    loadApps ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.history)
      return services.iIntegrationService.getAll(query)
        .then(data => {
          commit('setAppsData', data)
          return data
        })
    },
    saveApp ({ commit, state }) {
      const ignoredFields = []
      const data = state.app.id
        ? convertEmbedded(difference(state.app, state.cleanApp), ignoredFields)
        : convertEmbedded(state.app)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.app)
      }

      const header = state.app.id && state.app.updated
        ? { 'X-Entity-Updated': state.app.updated }
        : {}

      return services.iIntegrationService.save(data, state.app.id, header)
    },
    loadApp ({ commit }, payload) {
      return services.iIntegrationService.get(payload)
        .then(item => {
          commit('setApp', item)
          return item
        })
    }
  }
}
