import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

function getWarehouseType (user) {
  if (user.roles.find(({ id }) => `${id}` === '8')) {
    return 'virtual'
  }

  if (user.roles.find(({ id }) => id === 7)) {
    return 'client'
  }

  return ''
}

export default {
  state: {
    fulfillmentWarehouses: [],
    totalFulfillmentWarehouseNumber: 0,
    fulfillmentWarehousePage: 1,
    fulfillmentWarehouseTotalPages: 1,
    warehouse: null,
    cleanWarehouse: null,
    warehouses: [],
    totalWarehouseNumber: 0,
    warehousePage: 1,
    warehouseTotalPages: 1,
    warehouseLoading: false
  },
  getters: {
    fulfillmentWarehouses: state => state.fulfillmentWarehouses,
    totalFulfillmentWarehouseNumber: state => state.totalFulfillmentWarehouseNumber,
    fulfillmentWarehousePage: state => state.fulfillmentWarehousePage,
    fulfillmentWarehouseTotalPages: state => state.fulfillmentWarehouseTotalPages,
    warehouse: state => state.warehouse,
    cleanWarehouse: state => state.cleanWarehouse,
    warehouses: state => state.warehouses,
    warehouseLoading: state => state.warehouseLoading,
    totalWarehouseNumber: state => state.totalWarehouseNumber,
    warehousePage: state => state.warehousePage,
    warehouseTotalPages: state => state.warehouseTotalPages
  },
  mutations: {
    setWarehouses (state, payload) {
      state.warehouses = payload
    },
    setWarehousesData (state, { items, page, totalPages, totalItems }) {
      state.warehouses = items
      state.totalWarehouseNumber = totalItems
      state.warehousePage = page
      state.warehouseTotalPages = totalPages
    },
    setFulfillmentWarehousesData (state, { items, page, totalPages, totalItems }) {
      state.fulfillmentWarehouses = items
      state.totalFulfillmentWarehouseNumber = totalItems
      state.fulfillmentWarehousePage = page
      state.fulfillmentWarehouseTotalPages = totalPages
    },
    setWarehouse (state, payload) {
      state.warehouse = payload
      state.cleanWarehouse = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanWarehouse (state, payload) {
      state.cleanWarehouse = payload
    },
    setNewWarehouse (state) {
      state.warehouse = {
        name: null,
        code: null,
        state: 'active',
        type: getWarehouseType(JSON.parse(localStorage.getItem('userData'))),
        _embedded: {
          country: null,
          currency: null,
          length: null,
          owner: null,
          weight: null
        }
      }
    },
    updateWarehouse (state, payload) {
      state.warehouse = {
        ...state.warehouse,
        ...payload
      }
    },
    updateWarehouseEmbedded (state, payload) {
      state.warehouse = {
        ...state.warehouse,
        _embedded: {
          ...state.warehouse._embedded,
          ...payload
        }
      }
    },
    setTotalWarehousesNumber (state, payload) {
      state.totalWarehouseNumber = payload
    },
    setWarehousePage (state, payload) {
      state.warehousePage = payload
    },
    setWarehouseTotalPages (state, payload) {
      state.warehouseTotalPages = payload
    },
    startWarehouseLoading (state) {
      state.warehouseLoading = true
    },
    stopWarehouseLoading (state) {
      state.warehouseLoading = false
    },
    upsertWarehouse (state, payload) {
      let isExist = false

      state.warehouses = state.warehouses.map(x => {
        if (x.id === payload.id) {
          isExist = true
          return payload
        }

        return x
      })

      if (!isExist) {
        state.warehouses.unshift(payload)
      }
    }
  },
  actions: {
    loadFulfillmentWarehouses ({ commit }, payload) {
      const query = {
        ...payload,
        fields: ['extended'],
        filter: [
          { type: 'eq', field: 'type', value: 'fulfillment' }
        ]
      }

      return services.warehouse.getAll(query)
        .then(data => {
          data.items = data.items.map(item => {
            if (!item.eav) {
              item.eav = {}
            }

            return Object.keys(item).reduce((acc, key) => {
              acc[key] = typeof item[key] === 'string' && item[key][0] === '{'
                ? JSON.parse(item[key])
                : item[key]

              return acc
            }, {})
          })
          commit('setFulfillmentWarehousesData', data)
          return data
        })
    },
    loadWarehouses ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.warehouse)

      return services.warehouse.getAll(query)
        .then(({ items, totalItems, page, totalPages }) => {
          if (totalItems === 1) {
            commit('setWarehouse', items[0])
          }

          commit('setWarehousesData', { items, totalItems, page, totalPages })
          return { items, totalItems, page, totalPages }
        })
    },
    loadWarehouse ({ commit }, payload) {
      return services.warehouse.get(payload)
        .then(item => {
          commit('setWarehouse', item)
          return item
        })
    },
    saveWarehouse ({ commit, state }) {
      const ignoredFields = ['country', 'currency', 'length', 'owner', 'weight']
      const data = state.warehouse.id
        ? convertEmbedded(difference(state.warehouse, state.cleanWarehouse), ignoredFields)
        : convertEmbedded(state.warehouse)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.warehouse)
      }

      const header = state.warehouse.id && state.warehouse.updated
        ? { 'X-Entity-Updated': state.warehouse.updated }
        : {}

      return services.warehouse.save(data, state.warehouse.id, header)
    }
  }
}
