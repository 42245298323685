import _ from 'lodash'
import services from '../../../services/services'
import { filterUtil } from '../../../utils/filter.util'
import { QueueUtils } from '../../../utils/queue.utils'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    assemblingQueue: null,
    cleanAssemblingQueue: null,
    assemblingQueues: [],
    assemblingQueuesLoading: false,
    totalAssemblingQueuesNumber: 0,
    assemblingQueuesPage: 1,
    assemblingQueuesTotalPages: 1
  },
  getters: {
    assemblingQueue: state => state.assemblingQueue,
    cleanAssemblingQueue: state => state.cleanAssemblingQueue,
    assemblingQueues: state => state.assemblingQueues,
    assemblingQueuesLoading: state => state.assemblingQueuesLoading,
    totalAssemblingQueuesNumber: state => state.totalAssemblingQueuesNumber,
    assemblingQueuesPage: state => state.assemblingQueuesPage,
    assemblingQueuesTotalPages: state => state.assemblingQueuesTotalPages
  },
  mutations: {
    setAssemblingQueue (state, payload) {
      state.assemblingQueue = payload
      state.cleanAssemblingQueue = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanAssemblingQueue (state, payload) {
      state.cleanAssemblingQueue = payload
    },
    addAssemblingQueue (state, payload) {
      state.assemblingQueues = [payload, ...state.assemblingQueues]
    },
    setNewAssemblingQueue (state) {
      state.assemblingQueue = {
        name: '',
        adapter: null,
        settings: {
          filters: {},
          'orders-assembling-limit': null,
          'assembling-endpoint-place-type': null,
          'wave-fill-type': null,
          'wave-order-limit': null,
          'wave-state': null
        },
        _embedded: {
          warehouse: null
        }
      }
    },
    updateAssemblingQueue (state, payload) {
      state.assemblingQueue = {
        ...state.assemblingQueue,
        ...payload
      }
    },
    setAssemblingQueuesData (state, { items, page, totalPages, totalItems }) {
      state.totalAssemblingQueuesNumber = totalItems
      state.assemblingQueues = items
      state.assemblingQueuesPage = page
      state.assemblingQueuesTotalPages = totalPages
    },
    setAssemblingQueues (state, payload) {
      state.assemblingQueues = payload
    },
    setTotalAssemblingQueuesNumber (state, payload) {
      state.totalAssemblingQueuesNumber = payload
    },
    setAssemblingQueuesPage (state, payload) {
      state.assemblingQueuesPage = payload
    },
    setAssemblingQueuesTotalPages (state, payload) {
      state.assemblingQueuesTotalPages = payload
    }
  },
  actions: {
    loadAssemblingQueues ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.assemblingQueues)
      return services.assemblingQueue.getAll(query)
        .then(data => {
          commit('setAssemblingQueuesData', data)
          return data
        })
    },
    loadAssemblingQueue ({ commit }, payload) {
      return services.assemblingQueue.get(payload)
        .then(item => {
          commit('setAssemblingQueue', item)
          return item
        })
    },
    loadAssemblingQueueRawSettings ({commit}, payload) {
      return services.assemblingQueue.getRawSettings(payload)
          .then(item => {
            return item
          })
    },
    saveAssemblingQueue ({ commit, state }) {
      const data = state.assemblingQueue.id
        ? convertEmbedded(difference(state.assemblingQueue, state.cleanAssemblingQueue))
        : convertEmbedded(state.assemblingQueue)

        
      if (data.settings && data.settings.filters) {
        const utils = new QueueUtils(filterUtil)
        data.settings.filters = utils.convertObjectToFilter(data.settings.filters)
      }

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.assemblingQueue)
      }

      const header = state.assemblingQueue.id
        ? { 'X-Entity-Updated': state.assemblingQueue.updated }
        : {}

      return services.assemblingQueue.save(data, state.assemblingQueue.id, header)
    }
  }
}
