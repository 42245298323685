const Accounts = () => import('../../pages/accounts/Accounts')
const Invoices = () => import('../../pages/accounts/Invoices')
const AccountDetails = () => import('../../pages/accounts/AccountDetails')
const AccountRates = () => import('./../../pages/settings/AccountRates')
const AccountRate = () => import('./../../pages/settings/AccountRate')

export default [
  {
    entityClass: '',
    name: 'Billing',
    icon: 'attach_money',
    path: '/billing',
    children: [
      {
        entityClass: 'Orderadmin\\Accounts\\Entity\\Account',
        path: 'accounts',
        name: 'Accounts',
        component: Accounts,
        options: {
          query: {
            per_page: 25,
            page: 1,
            'order-by': [
              { type: 'field', field: 'created', direction: 'desc' },
              { type: 'field', field: 'owner', direction: 'desc' }
            ],
            filter: [
              { type: 'in', field: 'state', values: ['active', 'frozen', 'blocked'] }
            ]
          }
        },
        children: [
          {
            entityClass: 'Orderadmin\\Accounts\\Entity\\Account',
            path: '/entity/:id',
            component: AccountDetails
          },
        ]
      },
      {
        entityClass: 'Orderadmin\\Accounts\\Entity\\Invoice',
        path: 'invoices',
        name: 'Invoices',
        component: Invoices,
        options: {
          query: {
            page: 1,
            per_page: 25,
            'order-by': [
              { type: 'field', field: 'created', direction: 'desc' }
            ]
          }
        }
      },
      {
        entityClass: 'Orderadmin\\Accounts\\Entity\\Rate',
        path: 'billings',
        name: '3PL Billing',
        component: AccountRates,
        options: {
          query: {
            per_page: 25,
            page: 1,
            'order-by': [
              { type: 'field', field: 'created', direction: 'desc' }
            ],
            filter: [
              { type: 'eq', field: 'state', value: 'active' }
            ]
          }
        },
        children: [
          {
            entityClass: 'Orderadmin\\Accounts\\Entity\\Rate',
            path: 'entity',
            component: AccountRate
          },
          {
            entityClass: 'Orderadmin\\Accounts\\Entity\\Rate',
            path: 'entity/:id',
            component: AccountRate
          }
        ]
      },
    ]
  }
]
