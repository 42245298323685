import entities from '../../../../config/Entities'
import { createItemsData } from '../../helpers/request-helpers'
import { drUtils } from '../../utils/delivery-request-utils'
import { printerUtils } from '../../utils/printer-utils'
import { HALApiService } from '../hal-api.service'
import { dspTaskService } from './preprocessing-tasks.service'
import qs from 'qs'
import { Cache } from '../../../../packages/cache'

class DeliveryRequestsService {
  httpClient;
  url;
  middleware;
  _lastGetAll;
  _entityClass = entities.Orderadmin_DeliveryServices_Entity_DeliveryRequest
  _expireTime = 360000

  static cache = new Cache()
  static qs = qs

  constructor (
    httpClient,
    middleware
  ) {
    this.url = '/delivery-services/requests'
    this.httpClient = httpClient
    this.middleware = middleware

    this._lastGetAll = {
      page: 0,
      totalPages: 0,
      items: [],
      totalItems: 0
    }
  }

  /**
   * @param {string|number} id
   * @param {boolean} forceReload
   *
   * @returns {Promise<object>} 
   */
   find (id, forceReload = false) {
    const item = DeliveryRequestsService.cache.getValue(this._entityClass, id)

    if (item && !forceReload) {
      return Promise.resolve(item)
    }

    return this.get(id)
  }

  /**
   * @param {object} item
   *
   * @returns {array}
   */
  upsert (item) {
    DeliveryRequestsService.cache.dispatchValue(this._entityClass, item)
  }

  /**
   * @param {object} deliveryRequest
   *
   * @returns {object}
   */
  getMiddleware (deliveryRequest) {
    const defaultDimensions = window.appOptions.defaultDimensions
  
    if (!deliveryRequest.weight) {
      deliveryRequest.weight = defaultDimensions.weight
    }
  
    if (!deliveryRequest.dimensions) {
      deliveryRequest.dimensions = defaultDimensions.dimensions
    }
  
    if (!deliveryRequest._embedded.recipientAddress) {
      deliveryRequest._embedded.recipientAddress = {
        _embedded: {}
      }
    }
  
    if (!deliveryRequest._embedded.recipientAddress._embedded) {
      deliveryRequest._embedded.recipientAddress._embedded = {}
    }
  
    deliveryRequest._embedded.recipientAddress._embedded.locality = deliveryRequest._embedded.recipientLocality
  
    return deliveryRequest
  }

  /**
   * @param {String | Number} id
   *
   * @returns {Promise<object>}
   */
  get (id) {
    const item = DeliveryRequestsService.cache.getValue(this._entityClass, id)

    if (item) {
      return Promise.resolve(item)
    }

    return this.httpClient.get(`${this.url}/${id}`)
      .then(result => {
        return result
          ? this.getMiddleware(result)
          : Promise.reject(new Error('Shipping Request is not found!'))
      })
      .then(item => {
        this.upsert(item)
        return item
      })
  }

  /**
   * @param {object} get
   *
   * @returns {string}
   */
   getURL (get) {
    return `${this._url}${(Object.keys(get).length > 0 ? '?' + DeliveryRequestsService.qs.stringify(get) : '')}`
  }

  /**
   * @param {object} query
   *
   * @returns {Promise<object>}
   */
  getAll (query) {
    const newQuery = { ...query }

    delete newQuery.forceReload

    const data = DeliveryRequestsService.cache.getValues(this._entityClass, this.getURL(newQuery))

    if (!data.isExpired && !query.forceReload) {
      return Promise.resolve(data.value)
    }

    return this.httpClient.get(this.url, newQuery)
      .then(this.middleware)
      .then(result => {
        result.items = result.items.map(x => this.getMiddleware(x))
        DeliveryRequestsService.cache.dispatchValues(this._entityClass, this.getURL(newQuery), result, this._expireTime)
        return result
      })
  }

  /**
   * @param {object} data
   * @param {String | Number | undefined} id
   * @param {object | undefined} headers
   *
   * @returns {Promise<object>}
   */
  save (data, id, headers) {
    return this.httpClient.saveRequest(this.url, data, id, undefined, undefined, headers)
    .then(item => {
      this.upsert(item)
      return item
    })
  }

  /**
 * @param {String | Number} id
 * @param {String} pageSize
 *
 * @returns {Promise<Object>}
 */
  getPDF (id, size = 'A6') {
    return this.httpClient.get(`${this.url}/${id}`, {}, 'application/pdf', { 'content-type': 'application/json', 'X-Page-Size': size })
  }

  /**
   * @param {object} query
   *
   * @returns {Promise<excel>}
   */
  downloadAll (query) {
    const updatedQuery = {
      fields: [
        'id',
        'state',
        'order',
        'paymentState',
        'extId',
        'clientExtId',
        'outExtId',
        'deliveryService',
        'source',
        'integration',
        'rate',
        'createdByDocument',
        'shippedByDocument',
        'currency',
        'estimatedCost',
        'price',
        'retailPrice',
        'payment',
        'payoff',
        'pickupDate',
        'sender',
        'senderComment',
        'deliveryDate',
        'deliveryTimeStart',
        'deliveryTimeEnd',
        'recipient',
        'recipientPhone',
        'recipientComment',
        'servicePoint',
        'weight',
        'dimensions',
        'volume',
        'trackingNumber',
        'deliveryServiceStatus',
        'created',
        'updated'
      ],
      page: 1,
      per_page: 25,
      sort: {
        id: 'desc'
      },
      'order-by': [
        { type: 'field', field: 'id', direction: 'desc' }
      ],
      ...query
    }

    return this.httpClient.download(this.url, updatedQuery, 'application/xls')
  }
}

class DeliveryRequestProcess {
  url;
  httpClient;
  taskService;
  drUtils;
  printerUtils;

  constructor (
    httpClient,
    taskService,
    drUtils,
    printerUtils
  ) {
    this.url = '/delivery-services/delivery-request-service'
    this.httpClient = httpClient
    this.taskService = taskService
    this.drUtils = drUtils
    this.printerUtils = printerUtils
  }

  /**
   * @param {String | Number} id
   * @param {array | undefined} filters
   * @param {object | undefined} data
   *
   * @returns {Promise<Boolean>}
   */
  processByID (id, filters = [], data = {}) {
    const query = this.drUtils.buildProcessQuery(id, filters)

    const additionalData = {
      weight: 100,
      dimensions: { x: 100, y: 100, z: 100 },
      ...data
    }

    additionalData.weight = Number(additionalData.weight)
    additionalData.dimensions = Object.keys(additionalData.dimensions || {}).reduce((acc, key) => {
      acc[key] = Number(additionalData.dimensions[key])
      return acc
    }, {})

    return this.httpClient.get(this.url, query)
      .then(result => {
        const entities = this.drUtils.getMeasuringEntities(result)

        if (!entities.deliveryRequest) {
          return Promise.reject(new Error(`Shipment ${id} is not found or it is not ready to be sended!`))
        }

        if (!entities.queue) {
          return Promise.reject(new Error(`Queue for shipment ${id} is not found!`))
        }

        const task = entities.task || {}
        const data = this.drUtils.buildPTaskData(id, entities.queue.id, additionalData)

        return this.taskService.process(task.id, data)
      })
      .then((result) => {
        return this.printerUtils.print(result.text, result.pageSize)
      })
  }
}

export const deliveryRequestsService = new DeliveryRequestsService(
  HALApiService,
  createItemsData
)

export const deliveryRequestProcess = new DeliveryRequestProcess(
  HALApiService,
  dspTaskService,
  drUtils,
  printerUtils
)
