import qs from 'qs'
import { HALApiService } from '../hal-api.service'
import { createItemsData } from '../../helpers/request-helpers'
import { QueueService } from './base-service'
import { QueueUtils } from '../../utils/queue.utils'
import { filterUtil } from '../../utils/filter.util'

const utils = new QueueUtils(filterUtil)

export const pickingQueueService = new QueueService('/storage/tasks/queue', HALApiService, createItemsData, utils, qs)
export const assemblingQueueService = new QueueService('/storage/assembling-queue', HALApiService, createItemsData, utils, qs)
export const preprocessingQueueService = new QueueService('/delivery-services/preprocessing-queue', HALApiService, createItemsData, utils, qs)
