import _ from 'lodash'
import services from '../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    cleanAddress: null,
    address: null,
    addresses: [],
    addressesLoading: false,
    totalAddressesNumber: 0,
    addressesPage: 1,
    addressesTotalPages: 1
  },
  getters: {
    cleanAddress: state => state.cleanAddress,
    address: state => state.address,
    addresses: state => state.addresses,
    addressesLoading: state => state.addressesLoading,
    totalAddressesNumber: state => state.totalAddressesNumber,
    addressesPage: state => state.addressesPage,
    addressesTotalPages: state => state.addressesTotalPages
  },
  mutations: {
    setAddress (state, payload) {
      state.address = payload
      state.cleanAddress = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload

      if (state.order) {
        state.order._embedded.address = payload
        state.cleanOrder = _.cloneDeep(state.order)
      }
    },
    setNewAddress (state) {
      state.address = {
        notFormal: null,
        postcode: null,
        state: 'active',
        street: null,
        _embedded: {
          locality: null,
          owner: null,
          profile: null
        }
      }
    },
    setCleanAddress (state, payload) {
      state.cleanAddress = payload
    },
    updateAddress (state, payload) {
      state.address = {
        ...state.address,
        ...payload
      }
    },
    setAddressesData (state, { items, page, totalPages, totalItems }) {
      state.addresses = items
      state.totalAddressesNumber = totalItems
      state.addressesPage = page
      state.addressesTotalPages = totalPages

      if (totalItems === 1) {
        state.address = items[0]
      }
    },
    setAddresses (state, payload) {
      state.addresses = payload
    },
    setTotalAddressesNumber (state, payload) {
      state.totalAddressesNumber = payload
    },
    setAddressesPage (state, payload) {
      state.addressesPage = payload
    },
    setAddressesTotalPages (state, payload) {
      state.addressesTotalPages = payload
    },
    startAddressesLoading (state) {
      state.addressesLoading = true
    },
    stopAddressesLoading (state) {
      state.addressesLoading = false
    }
  },
  actions: {
    loadAddresses ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.address)
      return services.address.getAll(query)
        .then(data => {
          commit('setAddressesData', data)
          return data
        })
    },
    loadAddress ({ commit }, payload) {
      return services.address.get(payload)
        .then(item => {
          commit('setAddress', item)
          return item
        })
    },
    saveAddress ({ commit, state }) {
      const ignoredFields = ['locality', 'owner', 'profile']
      const data = state.address.id
        ? convertEmbedded(difference(state.address, state.cleanAddress), ignoredFields)
        : convertEmbedded(state.address)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.address)
      }

      const header = state.address.id && state.address.updated
        ? { 'X-Entity-Updated': state.address.updated }
        : {}

      return services.address.save(data, state.address.id, header)
    }
  }
}
