import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    message: null,
    cleanMessage: null,
    messages: [],
    totalMessagesNumber: 0,
    messagesPage: 1,
    messagesTotalPages: 1,
    messagesLoading: false,
    allMessages: [],
    isOpenMessageModal: false,
    messageEntities: []
  },
  getters: {
    message: state => state.message,
    cleanMessage: state => state.cleanMessage,
    messages: state => state.messages,
    totalMessagesNumber: state => state.totalMessagesNumber,
    messagesPage: state => state.messagesPage,
    messagesTotalPages: state => state.messagesTotalPages,
    messagesLoading: state => state.messagesLoading,
    allMessages: state => state.allMessages,
    isOpenMessageModal: state => state.isOpenMessageModal,
    messageEntities: state => state.messageEntities,
    chatHeads: state => {
      return state.allMessages.reduce((acc, message) => {
        if (acc.find(x => JSON.stringify(x) === JSON.stringify(message.entity))) {
          return acc
        }

        acc.push(message.entity)
        return acc
      }, [])
    },
    getChatMessages: state => (entities, fn) => {
      const str = JSON.stringify(entities)

      return state.allMessages.filter(message => {
        const isEq = str === JSON.stringify(message.entity)

        if (!fn) {
          return isEq
        }

        return isEq && fn(message)
      })
    }
  },
  mutations: {
    deleteMessagesByEntity (state, payload) {
      const str = JSON.stringify(payload)
      state.allMessages = state.allMessages.filter(({ entity }) => {
        return str !== JSON.stringify(entity)
      })
    },
    openMessageModal (state, payload) {
      state.isOpenMessageModal = true
      state.messageEntities = payload
    },
    closeMessageModal (state) {
      state.isOpenMessageModal = true
      state.messageEntities = []
    },
    addMessage (state, payload) {
      state.allMessages = [...state.allMessages, payload]
    },
    setMessage (state, payload) {
      state.message = payload
      state.cleanMessage = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanMessage (state, payload) {
      state.cleanMessage = payload
    },
    setNewMessage (state) {
      state.message = {}
    },
    updateMessage (state, payload) {
      state.message = {
        ...state.message,
        ...payload
      }
    },
    setMessagesData (state, { items, page, totalPages, totalItems }) {
      state.totalMessagesNumber = totalItems
      state.messages = items
      state.messagesPage = page
      state.messagesTotalPages = totalPages
    },
    setMessages (state, payload) {
      state.messages = payload
    },
    setTotalMessagesNumber (state, payload) {
      state.totalMessagesNumber = payload
    },
    setMessagesPage (state, payload) {
      state.messagesPage = payload
    },
    setMessagesTotalPages (state, payload) {
      state.messagesTotalPages = payload
    },
    startMessageLoading (state) {
      state.messagesLoading = true
    },
    stopMessageLoading (state) {
      state.messagesLoading = false
    }
  },
  actions: {
    loadMessages ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.messages)
      return services.message.getAll(query)
        .then(data => {
          commit('setMessagesData', data)
          return data
        })
    },
    loadMessage ({ commit }, payload) {
      return services.message.get(payload)
        .then(item => {
          commit('setMessage', item)
          return item
        })
    },
    saveMessage ({ commit, state }) {
      const data = state.message.id
        ? convertEmbedded(difference(state.message, state.cleanMessage))
        : convertEmbedded(state.message)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.message)
      }

      const header = state.message.id
        ? { 'X-Entity-Updated': state.message.updated }
        : {}

      return services.message.save(data, state.message.id, header)
    }
  }
}
