// Utils
import { convertEmbedded, difference } from '../../helpers/request-helpers'
import _ from 'lodash'

export class DeliveryOrder {
  _order
  _deliveryRequest
  _address
  _shop

  requiredAddressFields
  requiredDeliveryRequestFields

  static get convert () {
    return convertEmbedded
  }

  static get lodash () {
    return _
  }

  static get diff () {
    return difference
  }

  constructor (order, deliveryRequest, address, shop) {
    this._order = order
    this._deliveryRequest = deliveryRequest
    this._address = address
    this._shop = shop

    this.requiredAddressFields = [
      'postcode',
      'locality',
      'street',
      'house'
    ]

    this.requiredDeliveryRequestFields = [
      'rate',
      'deliveryService',
      'servicePoint'
    ]
  }

  /**
   * @param {object} order
   * @param {object} deliveryRequest
   * @param {object} address
   * @param {object} products
   *
   * @returns {Promise<object>} 
   */
  save (order, deliveryRequest, address, products) {
    if (!order.clean) {
      return this.createNewDeliveryOrder(order.update, deliveryRequest.update, address.update, products.update)
    }

    const queue = this._getChanges(order, deliveryRequest, address, products)

    return this.process(queue).then((results) => {
      const data = {
        order: order.update,
        deliveryRequest: deliveryRequest.update,
        address: address.update,
        products: products.update,
        ...results.reduce((acc, data) => {
          acc[data.type] = data.result
          return acc
        }, {})
      }

      const drResult = results.find(x => x.type === 'deliveryRequest')

      if (drResult) {
        data.deliveryRequest = drResult.result
        data.address = {
          ...drResult.result._embedded.recipientAddress,
          _embedded: {
            ...drResult.result._embedded.recipientAddress._embedded
          }
        }
      }

      return data
    })
  }

  /**
   * @param {object} order
   * @param {object} deliveryRequest
   * @param {object} address
   * @param {object} products
   *
   * @returns {array} 
   */
  _getChanges (order, deliveryRequest, address, products) {
    const queue = []

    const orderDiff = DeliveryOrder.convert(DeliveryOrder.diff(order.update, order.clean), ['shop'])
    delete orderDiff.warehouse

    if (orderDiff.phone && typeof orderDiff.phone === 'object') {
      orderDiff.phone = orderDiff.phone.phone
    }

    if (this._hasChange(orderDiff)) {
      queue.push({
        type: 'order',
        fn: () => this._order.save(orderDiff, order.clean.id, order.clean.type)
      })
    }

    if (deliveryRequest.clean && deliveryRequest.clean.id) {
      const update = {
        ...deliveryRequest.update,
        _embedded: {
          ...deliveryRequest.update._embedded,
          recipientPhone: {
            ...deliveryRequest.update._embedded.recipientPhone || {},
            phone: order.update._embedded.phone && typeof order.update._embedded.phone === 'object'
              ? order.update._embedded.phone.phone
              : order.update._embedded.phone
          },
          recipient: {
            ...deliveryRequest.update._embedded.recipient || {},
            name: (order.update._embedded.profile || {}).name,
            surname: (order.update._embedded.profile || {}).surname
          }
        }
      }

      const deliveryRequestDiff = DeliveryOrder.convert(
        DeliveryOrder.diff(update, deliveryRequest.clean),
        ['currency', 'deliveryService', 'integration', 'rate', 'recipient', 'recipientLocality', 'servicePoint', 'sender', 'estimatedCost']
      )

      if(deliveryRequestDiff.dimensions || deliveryRequestDiff.weight) {
        deliveryRequestDiff.dimensions = deliveryRequest.update.dimensions
        deliveryRequestDiff.weight = deliveryRequest.update.weight
      }

      deliveryRequestDiff.totalSum = undefined
      deliveryRequestDiff.payment = undefined

      if (deliveryRequestDiff.recipientPhone && typeof deliveryRequestDiff.recipientPhone === 'object') {
        deliveryRequestDiff.recipientPhone = deliveryRequestDiff.recipientPhone.phone
      }

      if (address.clean && address.clean.id) {
        const addressDiff = DeliveryOrder.convert(DeliveryOrder.diff(address.update, address.clean), ['locality'])

        if (addressDiff.locality) {
          deliveryRequestDiff.recipientLocality = addressDiff.locality
        }
  
        if (this._hasChange(addressDiff)) {
          queue.push({
            type: 'address',
            fn: () => this._address.save(addressDiff, address.clean.id)
          })
        }
      } else {
        deliveryRequestDiff.recipientLocality = (address.update._embedded.locality && address.update._embedded.locality.id) || undefined
        deliveryRequestDiff.recipientAddress = { ...address.update, _embedded: undefined, id: undefined, state: undefined }
      }

      if (this._hasChange(deliveryRequestDiff)) {
        queue.push({
          type: 'deliveryRequest',
          fn: () => this._deliveryRequest.save(deliveryRequestDiff, deliveryRequest.clean.id)
        })
      }
    } else {
      const deliveryRequestDiff = DeliveryOrder.convert(deliveryRequest.update)
      deliveryRequestDiff.recipientLocality = (address.update._embedded.locality && address.update._embedded.locality.id) || undefined
      deliveryRequestDiff.recipientAddress = { ...address.update, _embedded: undefined, id: undefined, state: undefined }
      deliveryRequestDiff.order = order.clean.id
      deliveryRequestDiff.totalSum = undefined
      deliveryRequestDiff.estimatedCost = undefined
      deliveryRequestDiff.payment = undefined
      deliveryRequestDiff.recipientPhone = order.update._embedded.phone && typeof order.update._embedded.phone === 'object'
        ? order.update._embedded.phone.phone
        : order.update._embedded.phone
      deliveryRequestDiff.recipient = {
        name: (order.update._embedded.profile || {}).name,
        surname: (order.update._embedded.profile || {}).surname,
        email: (order.update._embedded.profile || {}).email
      }

      if (deliveryRequestDiff.recipientLocality || deliveryRequestDiff.rate || deliveryRequestDiff.deliveryService) {
        queue.push({
          type: 'deliveryRequest',
          fn: () => this._deliveryRequest.save(deliveryRequestDiff).then(deliveryRequest => {
            this._order.upsert({
              ...order.update,
              _embedded: {
                ...order.update,
                deliveryRequest: DeliveryOrder.lodash.cloneDeep(deliveryRequest)
              }
            })

            return deliveryRequest
          })
        })
      }
    }

    if (products.update.length > 0) {
      queue.push({
        type: 'products',
        fn: () => this._order.saveProducts(this.diffProducts(products.update, order.clean.id))
      })
    }

    return queue
  }

  /**
   * @param {object} data
   *
   * @returns {boolean} 
   */
  _hasChange (data) {
    return Object.values(data).filter(x => x !== undefined).length > 0
  }

  /**
   * @param {array} queue
   * @param {array} results
   *
   * @returns {Promise<array>}
   */
  process (queue, results = []) {
    if (queue.length <= 0) {
      return Promise.resolve(results)
    }

    return queue[0].fn().then(result => {
      results.push({
        ...queue[0],
        result
      })

      return this.process(queue.slice(1), results)
    })
  }

  /**
   * @param {array} products
   * @param {number|string} order
   *
   * @returns {array} 
   */
  diffProducts (products, order) {
    return products.map(val => {
      if (val.id) {
        if (val.count <= 0) {
          return {
            update: { id: val.id, state: 'deleted' }
          }
        }
        if( val.eav) {
          return {
            update: {
              id: val.id,
              count: val.count,
              price: val.price,
              eav: val.eav
            }
          }
        }
        return {
          update: {
            id: val.id,
            count: val.count,
            price: val.price
          }
        }
      }

      return {
        create: {
          order,
          extId: val.extId,
          count: val.count,
          price: val.price,
          productOffer: val._embedded.productOffer.id,
          shop: val._embedded.shop.id
          // productOffer: {
          //   extId: val._embedded.productOffer.extId,
          //   name: val._embedded.productOffer.name,
          //   shop: val._embedded.shop.id
          // }
        }
      }
    })
  }

  /**
   * @param {object} order
   * @param {object} deliveryRequest
   * @param {object} address
   * @param {array} products
   *
   * @returns {Promise<object>} 
   */
  createNewDeliveryOrder (order, deliveryRequest, address, products) {
    const convertedOrder = this.convertOrder(order, products)
    const convertedProducts = this.convertProducts(products, convertedOrder.shop)
    const convertedDeliveryRequest = this.convertDeliveryRequest(deliveryRequest)

    convertedDeliveryRequest.recipientAddress = { ...address, _embedded: undefined, id: undefined, state: undefined }
    convertedDeliveryRequest.recipientLocality = (address._embedded.locality && address._embedded.locality.id) || undefined
    convertedDeliveryRequest.totalSum = undefined

    convertedOrder.orderProducts= convertedProducts

    if (this.requiredAddressFields.find(x => convertedDeliveryRequest.recipientAddress[x]) || convertedDeliveryRequest.recipientLocality || this.requiredDeliveryRequestFields.find(x => convertedDeliveryRequest[x])) {
      convertedOrder.deliveryRequest = convertedDeliveryRequest
    }
    
    return this._order.save(convertedOrder, undefined, order.type)
      .then(order => {
        return this.get(order.id)
      })
  }

  /**
   * @param {object} order
   * @param {array} products
   *
   * @returns {object} 
   */
  convertOrder (order, products) {
    let data = DeliveryOrder.lodash.cloneDeep(order)
  
    if (data._embedded.warehouse) {
      data.eav['order-reserve-warehouse'] = data._embedded.warehouse.id
    }

    delete data._embedded.warehouse
  
    data.orderPrice = products.reduce((acc, val) => {
      return acc + val.price * val.count
    }, 0)
  
    data.totalPrice = data.orderPrice
  
    let covertedData = DeliveryOrder.convert(data)

    covertedData.address = undefined
  
    if (typeof covertedData.phone === 'object') {
      covertedData.phone = covertedData.phone.phone
    }
  
    return covertedData
  }

  /**
   * @param {object} deliveryRequest
   *
   * @returns {object} 
   */
  convertDeliveryRequest (deliveryRequest) {
    const data = DeliveryOrder.convert(deliveryRequest)
    return Object.keys(data).reduce((acc, key) => {
      return typeof data[key] === 'string' || typeof data[key] === 'number'
        ? { ...acc, [key]: data[key] }
        : acc
    }, {})
  }

  /**
   * @param {array} products
   * @param {number|string} shop
   *
   * @returns {array} 
   */
  convertProducts (products, shop) {
    return products.map(product => {
      const item = DeliveryOrder.convert(product)
  
      if (!item.shop) {
        item.shop = shop
      }
  
      item.payment = item.price
      return item
    })
  }

  /**
   * @param {string|number} id
   * @param {boolean} forceReload
   *
   * @returns {Promise<object>} 
   */
  get (id, forceReload) {
    return this._order.find(id, forceReload)
      .then(item => {
        return Promise.all([item, this.loadDeliveryRequest(item._embedded.deliveryRequest, forceReload)])
      })
      .then(([order, deliveryRequest]) => {
        if (!order._embedded.profile) {
          order._embedded.profile = {}
        }

        return { order, deliveryRequest, address: { ...deliveryRequest._embedded.recipientAddress, _embedded: { ...deliveryRequest._embedded.recipientAddress._embedded } } }
      })
      .then(data => {
        if (data.order._embedded.shop && !data.order._embedded.shop._embedded) {
          return this._shop.get(data.order._embedded.shop.id)
            .then(shop => {
              data.order._embedded.shop = shop
              return data
            })
        }

        return data
      })
  }

  /**
   * @param {object} item
   * @param {boolean} forceReload
   *
   * @returns {Promise<object>} 
   */
  loadDeliveryRequest (item, forceReload) {
    return item && item.id
      ? this._deliveryRequest.find(item.id, forceReload)
      : Promise.resolve(this._getNewDeliveryRequest())
  }

  /**
   * @returns {object}
   */
  _getNewDeliveryRequest () {
    return {
      deliveryDate: null,
      sendDate: new Date().toDateString(),
      places: [],
      weight: null,
      eav: {},
      dimensions: null,
      _embedded: {
        sender: null,
        rate: null,
        recipient: {
          email: null,
          name: null
        },
        recipientPhone: {
          phone: null
        },
        recipientLocality: null,
        recipientAddress: {
          postcode: null,
          house: null,
          notFormal: null,
          street: null,
          _embedded: {
            locality: null
          }
        }
      }
    }
  }
}