export default {
  'assistant': 'assistant-object',
  'Orderadmin\\Products\\Entity\\AbstractOrder': 'picking-order',
  'Orderadmin\\Products\\Entity\\Order': 'picking-order',
  'Orderadmin\\Products\\Entity\\Order\\RetailOrder': 'picking-order',
  'Orderadmin\\Products\\Entity\\Order\\WholesaleOrder': 'picking-order',
  'Orderadmin\\Products\\Entity\\Order\\ReturnOrder': 'picking-order',
  'Orderadmin\\Storage\\Entity\\AbstractPlace': 'place-object',
  'Orderadmin\\Storage\\Entity\\Place': 'place-object',
  'message': 'picking-message',
  'counter': 'dynamic-counter',
  'Orderadmin\\Storage\\Entity\\Item': 'product-object',
  'Orderadmin\\Storage\\Entity\\Picking\\Queue': 'picking-queue',
  'Orderadmin\\Products\\Entity\\Product\\Offer': 'picking-offer',
  'Orderadmin\\Storage\\Entity\\Tasks\\Task': 'picking-task',
  collection: 'dynamic-collection',
  'Orderadmin\\Storage\\Entity\\Tasks\\Assistent': 'assistant-object',
  'Orderadmin\\Storage\\Entity\\Movement\\Acceptance': 'acceptance-object',
  batch: 'batch-object',
  'Orderadmin\\Storage\\Entity\\Place\\Row': 'place-object',
  'Orderadmin\\Storage\\Entity\\Place\\Section': 'place-object',
  'Orderadmin\\Storage\\Entity\\Place\\StaticLocation': 'place-object',
  'Orderadmin\\Storage\\Entity\\Place\\Dynamic': 'place-object',
  'Orderadmin\\Storage\\Entity\\Place\\Employee': 'place-object',
  'Orderadmin\\Storage\\Entity\\Place\\Sorting': 'place-object',
  'Orderadmin\\Storage\\Entity\\Place\\Distribution': 'place-object',
  'Orderadmin\\Storage\\Entity\\Place\\DistributionRejected': 'place-object',
  'Orderadmin\\Storage\\Entity\\Place\\Assembly': 'place-object',
  'Orderadmin\\Storage\\Entity\\Place\\Universal': 'place-object',
  'Orderadmin\\Storage\\Entity\\Place\\Pallet': 'place-object',
  'Orderadmin\\Storage\\Entity\\Place\\Defected': 'place-object',
  'Orderadmin\\Storage\\Entity\\Place\\Room': 'place-object',
  'Orderadmin\\Storage\\Entity\\Movement\\Inventory': 'inventory-object',
  'question': 'question-modal',
}
