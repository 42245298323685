import _ from 'lodash'
import services from '../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    legalEntities: [],
    legalEntity: null,
    cleanLegalEntity: null,
    totalLegalEntitiesNumber: 0,
    legalEntitiesPage: 1,
    legalEntitiesTotalPages: 1,
    legalEntitiesLoading: false
  },
  getters: {
    legalEntities: state => state.legalEntities,
    legalEntitiesLoading: state => state.legalEntitiesLoading,
    legalEntity: state => state.legalEntity,
    cleanLegalEntity: state => state.cleanLegalEntity,
    totalLegalEntitiesNumber: state => state.totalLegalEntitiesNumber,
    legalEntitiesPage: state => state.legalEntitiesPage,
    legalEntitiesTotalPages: state => state.legalEntitiesTotalPages
  },
  mutations: {
    setLegalEntity (state, payload) {
      state.legalEntity = payload
      state.cleanLegalEntity = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setNewLegalEntity (state) {
      state.legalEntity = {
        state: 'active',
        type: 'customer',
        name: '',
        contractNumber: null,
        tax: 0,
        eav: {
          'legal-entity-inn': null,
          'legal-entity-kpp': null,
          'legal-entity-bank-bik': null,
          'legal-entity-bank-corresponding-account': null,
          'legal-entity-payment-account': null,
          'legal-entity-bank-name': null
        },
        _embedded: {
          owner: null
        }
      }
    },
    updateLegalEntity (state, payload) {
      state.legalEntity = {
        ...state.legalEntity,
        ...payload
      }
    },
    updateLegalEntityEav (state, payload) {
      state.legalEntity = {
        ...state.legalEntity,
        eav: {
          ...state.legalEntity.eav,
          ...payload
        }
      }
    },
    setCleanLegalEntity (state, payload) {
      state.cleanLegalEntity = payload
    },
    setLegalEntitiesData (state, { items, page, totalPages, totalItems }) {
      state.legalEntities = items
      state.totalLegalEntitiesNumber = totalItems
      state.legalEntitiesPage = page
      state.LegalEntitiesTotalPages = totalPages
    },
    setLegalEntities (state, payload) {
      state.legalEntities = payload
    },
    setTotalLegalEntitiesNumber (state, payload) {
      state.totalLegalEntitiesNumber = payload
    },
    setLegalEntitiesPage (state, payload) {
      state.legalEntitiesPage = payload
    },
    setLegalEntitiesTotalPages (state, payload) {
      state.LegalEntitiesTotalPages = payload
    },
    startLegalEntityLoading (state) {
      state.legalEntitiesLoading = true
    },
    stopLegalEntityLoading (state) {
      state.legalEntitiesLoading = false
    },
    upsertLegalEntity (state, payload) {
      let isExist = false

      state.legalEntities = state.legalEntities.map(x => {
        if (x.id === payload.id) {
          isExist = true
          return payload
        }

        return x
      })

      if (!isExist) {
        state.legalEntities.unshift(payload)
      }
    }
  },
  actions: {
    loadLegalEntities ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.legalEntity)
      return services.legalEntity.getAll(query)
        .then(data => {
          commit('setLegalEntitiesData', data)
          return data
        })
    },
    loadLegalEntity ({ commit }, payload) {
      return services.legalEntity.get(payload)
        .then(item => {
          commit('setLegalEntity', item)
          return item
        })
    },
    saveLegalEntity ({ commit, state }) {
      const data = state.legalEntity.id
        ? convertEmbedded(difference(state.legalEntity, state.cleanLegalEntity))
        : convertEmbedded(state.legalEntity)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.legalEntity)
      }

      if (data.tax) {
        data.tax = Number(data.tax).toFixed(5)
      }

      const header = state.legalEntity.id && state.legalEntity.updated
        ? { 'X-Entity-Updated': state.legalEntity.updated }
        : {}

      return services.legalEntity.save(data, state.legalEntity.id, header)
    }
  }
}
