/**
 * Get Notification
 *
 * @param {string} message
 * @param {string} type
 *
 * @returns {object}
 */
function getNotification (message, type) {
  return {
    id: new Date().getUTCMilliseconds(),
    message,
    type
  }
}

export default {
  state: {
    helpers: [],
    lastHelper: null
  },
  getters: {
    helpers: state => state.helpers,
    lastHelper: state => state.lastHelper
  },
  mutations: {
    hideLastHelper (state) {
      if (!state.lastHelper || state.lastHelper.state === 'inactive') {
        return
      }

      state.lastHelper.state = 'inactive'

      state.helpers = state.helpers.map(x => {
        if (x.id === state.lastHelper.id) {
          return state.lastHelper
        }

        return x
      })
    },
    commitHelper (state, payload) {
      state.helpers.push(payload)
      state.lastHelper = payload
    },
    updateLastHelper (state, payload) {
      state.lastHelper = { ...state.lastHelper, ...payload }
    },
    removeHelper (state, payload) {
      if (state.lastHelper && state.lastHelper.id === payload) {
        state.lastHelper = null
      }

      state.helpers = state.helpers.filter(({ id }) => id !== payload)
    },
    updateHelper (state, payload) {
      state.helpers = state.helpers.map(x => {
        if (x.id === payload.id) {
          const helper = { ...x, ...payload }

          if (!state.lastHelper || state.lastHelper.id === helper.id) {
            state.lastHelper = helper
          }

          return helper
        }

        return x
      })
    },
    deleteAllHelpers (state) {
      state.helpers = []
      state.lastHelper = null
    }
  },
  actions: {
    reactivateHelper ({ commit }, payload) {
      commit('updateHelper', { ...payload, state: 'active' })

      setTimeout(() => {
        commit('updateHelper', { id: payload.id, state: 'inactive' })
      }, 13000)

      return payload
    },
    addErrorHelper ({ dispatch }, payload) {
      return dispatch('addHelper', { ...payload, type: 'error' })
    },
    addWarningHelper ({ dispatch }, payload) {
      return dispatch('addHelper', { ...payload, type: 'warning' })
    },
    addInstructionHelper ({ dispatch }, payload) {
      return dispatch('addHelper', { ...payload, type: 'instruction' })
    },
    addHelper ({ commit }, payload) {
      const helper = payload && typeof payload === 'object'
        ? { ...payload, ...getNotification(payload.message, payload.type) }
        : getNotification(payload, 'message')

      if (!payload.disabledNotifications) {
        commit('addNotification', helper.message || helper.title)
      }

      helper.state = 'active'
      commit('commitHelper', helper)

      setTimeout(() => {
        commit('updateHelper', { id: helper.id, state: 'inactive' })
      }, 13000)

      return Promise.resolve(helper)
    }
  }
}
