const Acceptances = () => import('../../pages/wms/Acceptances')
const Acceptance = () => import('../../pages/wms/Acceptance')
const InboundInventory = () => import('../../pages/wms/InboundInventory')
const CartonForwardings = () => import('../../pages/wms/CartonForwardings')
const CartonForwarding = () => import('../../pages/wms/CartonForwarding')
const ExpectedItems = () => import('../../pages/wms/ExpectedItems')

export default [
  {
    entityClass: '',
    name: 'Inbound',
    icon: 'input',
    path: '/inbound',
    children: [
      {
        entityClass: 'Orderadmin\\Storage\\Entity\\Movement\\Acceptance',
        path: 'purchase-orders',
        name: 'Purchase orders',
        component: Acceptances,
        options: {
          query: {
            per_page: 25,
            page: 1,
            'order-by': [
              { type: 'field', field: 'created', direction: 'desc' }
            ]
          }
        },
        children: [
          {
            entityClass: 'Orderadmin\\Storage\\Entity\\Movement\\Acceptance',
            path: 'entity/:type',
            component: Acceptance
          },
          {
            entityClass: 'Orderadmin\\Storage\\Entity\\Movement\\Acceptance',
            path: 'entity/:type/:id',
            component: Acceptance
          }
        ]
      },
      {
        entityClass: '',
        path: 'expected-items',
        name: 'Expected Inventory',
        component: ExpectedItems,
        options: {
          query: {
            per_page: 25,
            page: 1,
            'order-by': [
              { type: 'field', field: 'created', direction: 'desc' }
            ]
          }
        },
      },
      {
        entityClass: 'Orderadmin\\Storage\\Entity\\Movement\\Acceptance\\Item',
        path:'inventory',
        name:'Inbound Inventory',
        component: InboundInventory,
        options: {
          query: {
            per_page: 25,
            page: 1,
            'order-by': [
              { type: 'field', field: 'created', direction: 'desc' }
            ],
            filter: [
              {type: 'eq', field: 'state', value: 'new'}
            ]
          }
        },
      },
      {
        entityClass: 'Orderadmin\\DeliveryServices\\Entity\\Movements\\Acceptance',
        path:'carton-forwarding',
        name:'Carton Forwarding',
        component: CartonForwardings,
        options: {
          query: {
            per_page: 25,
            page: 1,
            'order-by': [
              { type: 'field', field: 'id', direction: 'desc' }
            ]
          }
        },
        children: [
          {
            entityClass: 'Orderadmin\\DeliveryServices\\Entity\\Movements\\Acceptance',
            path: 'entity/:id',
            component: CartonForwarding
          }
        ]
      }
    ]
  }
]
