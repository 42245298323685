const DMConsumptions = () => import('../../pages/delivery-manager/DMConsumptions')
const DMConsumption = () => import('../../pages/delivery-manager/DMConsumption')
const InventoryDocuments = () => import('../../pages/delivery-manager/Inventories')
const InventoryDocument = () => import('../../pages/delivery-manager/Inventory')
const ReportCategories = () => import('../../pages/wms/ReportCategories')
const Reports = () => import('../../pages/wms/Reports')
const Report = () => import('../../pages/wms/Report')
const ReportTypeDetails = () => import('../../pages/wms/ReportTypeDetails')

export default [
  {
    entityClass: '',
    name: 'Documents',
    icon: 'description',
    path: '/documents',
    children: [
      {
        entityClass: 'Orderadmin\\Storage\\Entity\\Movement\\Consumption',
        path: 'shipping-manifests',
        name: 'Shipping manifests',
        component: DMConsumptions,
        options: {
          query: {
            page: 1,
            per_page: 25,
            'order-by': [
              { type: 'field', field: 'created', direction: 'desc' }
            ]
          }
        },
        children: [
          {
            entityClass: 'Orderadmin\\Storage\\Entity\\Movement\\Consumption',
            path: 'entity',
            component: DMConsumption
          },
          {
            entityClass: 'Orderadmin\\Storage\\Entity\\Movement\\Consumption',
            path: 'entity/:id',
            component: DMConsumption
          }
        ]
      },
      {
        entityClass: 'Orderadmin\\Storage\\Entity\\Movement\\Inventory',
        path: 'inventory-documents',
        name: 'Inventory documents',
        component: InventoryDocuments,
        options: {
          query: {
            page: 1,
            per_page: 25,
            'order-by': [
              { type: 'field', field: 'created', direction: 'desc' }
            ]
          }
        },
        children: [
          {
            entityClass: 'Orderadmin\\Storage\\Entity\\Movement\\Inventory',
            path: 'entity',
            component: InventoryDocument
          },
          {
            entityClass: 'Orderadmin\\Storage\\Entity\\Movement\\Inventory',
            path: 'entity/:id',
            component: InventoryDocument
          }
        ]
      },
      {
        entityClass: 'Orderadmin\\Reports\\Entity\\Report',
        path: 'reports',
        name: 'Reports',
        noChild: true,
        component: ReportCategories,
        children: [
          {
            entityClass: 'Orderadmin\\Reports\\Entity\\Report',
            path: 'reports',
            name: 'ReportsReports',
            component: Reports,
            options: {
              query: {
                per_page: 25,
                page: 1,
                'order-by': [
                  { type: 'field', field: 'created', direction: 'desc' }
                ]
              }
            },
            noChild: true,
            children: [
              {
                entityClass: 'Orderadmin\\Reports\\Entity\\Report',
                path: 'entity/:type',
                component: Report
              }
            ]
          },
          {
            path: 'entity/:id',
            component: ReportTypeDetails
          }
        ]
      }
    ]
  }
]
