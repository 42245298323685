import _ from 'lodash'
import services from '../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    clientProfile: null,
    cleanClientProfile: null,
    clientProfiles: [],
    clientProfilesLoading: false,
    totalClientProfilesItemsNumber: 0,
    clientProfilesPage: 1,
    clientProfilesTotalPages: 1
  },
  getters: {
    clientProfile: state => state.clientProfile,
    cleanClientProfile: state => state.cleanClientProfile,
    clientProfiles: state => state.clientProfiles,
    clientProfilesLoading: state => state.clientProfilesLoading,
    totalClientProfilesItemsNumber: state => state.totalClientProfilesItemsNumber,
    clientProfilesPage: state => state.clientProfilesPage,
    clientProfilesTotalPages: state => state.clientProfilesTotalPages
  },
  mutations: {
    setClientProfile (state, payload) {
      state.clientProfile = payload
      state.cleanClientProfile = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    updateClientProfile (state, payload) {
      state.clientProfile = {
        ...state.clientProfile,
        ...payload
      }
    },
    setCleanClientProfile (state, payload) {
      state.cleanClientProfile = payload
    },
    setNewClientProfile (state) {
      state.clientProfile = {
        extId: '',
        name: null,
        email: null,
        phones: null
      }
    },
    setClientProfilesData (state, { items, page, totalPages, totalItems }) {
      state.clientProfiles = items
      state.totalClientProfilesItemsNumber = totalItems
      state.clientProfilesPage = page
      state.clientProfilesTotalPages = totalPages
    },
    setClientProfiles (state, payload) {
      state.clientProfiles = payload
    },
    setTotalClientProfilesItemsNumber (state, payload) {
      state.totalClientProfilesItemsNumber = payload
    },
    setClientProfilesPage (state, payload) {
      state.clientProfilesPage = payload
    },
    setClientProfilesTotalPages (state, payload) {
      state.clientProfilesTotalPages = payload
    },
    startProfileLoading (state) {
      state.clientProfilesLoading = true
    },
    stopProfileLoading (state) {
      state.clientProfilesLoading = false
    }
  },
  actions: {
    loadClientProfiles ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.address)
      return services.clientProfile.getAll(query)
        .then(data => {
          commit('setClientProfilesData', data)
          return data
        })
    },
    saveClientProfile ({ commit, state }) {
      const data = state.clientProfile.id
        ? convertEmbedded(difference(state.clientProfile, state.cleanClientProfile))
        : convertEmbedded(state.clientProfile)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.clientProfile)
      }

      const header = state.clientProfile.id && state.clientProfile.updated
        ? { 'X-Entity-Updated': state.clientProfile.updated }
        : {}

      return services.clientProfile.save(data, state.clientProfile.id, header)
    }
  }
}
