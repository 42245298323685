const Items = () => import('../../pages/wms/Items')
const Warehouses = () => import('../../pages/settings/Warehouses')
const Warehouse = () => import('../../pages/settings/Warehouse')
const StoragePlaces = () => import('./../../pages/settings/StoragePlaces.vue')
const StoragePlace = () => import('./../../pages/settings/StoragePlace.vue')
const Supplies = () => import('./../../pages/settings/Supplies')
const Supply = () => import('./../../pages/settings/Supply')
const WarehouseList = () => import('./../../pages/wms/WarehouseList.vue')

export default [
    {
        entityClass: '',
        name: 'Storage',
        icon: 'storage',
        path: '/storage',
        children: [
            {
                entityClass: 'Items',
                path: 'items',
                name: 'All items',
                component: Items,
                options: {
                    query: {
                        per_page: 25,
                        page: 1,
                        'order-by': [
                            { type: 'field', field: 'id', direction: 'desc' }
                        ]
                    }
                }
            },
            {
                entityClass: 'Orderadmin\\Storage\\Entity\\Warehouse',
                path: 'warehouses',
                name: 'Warehouses',
                component: Warehouses,
                options: {
                    query: {
                        per_page: 25,
                        page: 1,
                        'order-by': [
                            { type: 'field', field: 'created', direction: 'desc' }
                        ],
                        filter: [
                            { type: 'eq', field: 'state', value: 'active' }
                        ]
                    }
                },
                children: [
                    {
                        entityClass: 'Orderadmin\\Storage\\Entity\\Warehouse',
                        path: 'entity',
                        component: Warehouse
                    },
                    {
                        entityClass: 'Orderadmin\\Storage\\Entity\\Warehouse',
                        path: 'entity/:id',
                        component: Warehouse
                    }
                ]
            },
            {
                entityClass: 'Orderadmin\\Storage\\Entity\\Place',
                path: 'locations',
                name: 'Storage locations and products',
                component: StoragePlaces,
                options: {
                    query: {
                        per_page: 25,
                        page: 1,
                        'order-by': [
                            { type: 'field', field: 'created', direction: 'desc' }
                        ],
                        filter: [
                            { type: 'in', field: 'state', values: ['active', 'normal', 'blocked'] }
                        ]
                    }
                },
                children: [
                    {
                        entityClass: 'Orderadmin\\Storage\\Entity\\Place',
                        path: 'entity',
                        component: StoragePlace
                    },
                    {
                        entityClass: 'Orderadmin\\Storage\\Entity\\Place',
                        path: 'entity/:id',
                        component: StoragePlace
                    }
                ]
            },
            {
                entityClass: 'Orderadmin\\Storage\\Entity\\Supply',
                path: 'supplies',
                name: 'Supplies',
                component: Supplies,
                options: {
                    query: {
                        per_page: 25,
                        page: 1,
                        'order-by': [
                            { type: 'field', field: 'created', direction: 'desc' }
                        ],
                        filter: [
                            { type: 'eq', field: 'state', value: 'active' }
                        ]
                    }
                },
                children: [
                    {
                        entityClass: 'Orderadmin\\Storage\\Entity\\Supply',
                        path: 'entity/:type',
                        component: Supply
                    },
                    {
                        entityClass: 'Orderadmin\\Storage\\Entity\\Supply',
                        path: 'entity/:type/:id',
                        component: Supply
                    }
                ]
            },
            {
                entityClass: 'WarehouseList',
                path: 'warehouseList',
                name: 'Inventory',
                component: WarehouseList,
                options: {
                    query: {
                        per_page: 50,
                        page: 1,
                        'order-by': [
                            { type: 'field', field: 'total', direction: 'desc' }
                        ]
                    }
                },
            },
        ]
    }
]
