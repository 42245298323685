import _ from 'lodash'
import services from '../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    channel: null,
    cleanChannel: null,
    channels: [],
    channelsLoading: false,
    totalChannelsNumber: 0,
    channelsPage: 1,
    channelsTotalPages: 1
  },
  getters: {
    channel: state => state.channel,
    cleanChannel: state => state.cleanChannel,
    channels: state => state.channels,
    channelsLoading: state => state.channelsLoading,
    totalChannelsNumber: state => state.totalChannelsNumber,
    channelsPage: state => state.channelsPage,
    channelsTotalPages: state => state.channelsTotalPages
  },
  mutations: {
    setChannel (state, payload) {
      state.channel = payload

      state.cleanChannel = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanChannel (state, payload) {
      state.cleanChannel = payload
    },
    setNewChannel (state) {
      state.channel = {
        access: null,
        handler: null,
        name: null,
        state: 'active',
        type: null,
        _embedded: {
          owner: null
        }
      }
    },
    updateChannel (state, payload) {
      state.channel = {
        ...state.channel,
        ...payload
      }
    },
    setChannelsData (state, { items, page, totalPages, totalItems }) {
      state.channels = items
      state.totalChannelsNumber = totalItems
      state.channelsPage = page
      state.channelsTotalPages = totalPages
    },
    setChannels (state, payload) {
      state.channels = payload
    },
    setTotalChannelsNumber (state, payload) {
      state.totalChannelsNumber = payload
    },
    setChannelsPage (state, payload) {
      state.channelsPage = payload
    },
    setChannelsTotalPages (state, payload) {
      state.channelsTotalPages = payload
    },
    startChannelsLoading (state) {
      state.channelsLoading = true
    },
    stopChannelsLoading (state) {
      state.channelsLoading = false
    },
    upsertChannel (state, payload) {
      let isExist = false

      state.channels = state.channels.map(x => {
        if (x.id === payload.id) {
          isExist = true
          return payload
        }

        return x
      })

      if (!isExist) {
        state.channels.unshift(payload)
      }
    }
  },
  actions: {
    loadChannels ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.accounts)
      return services.notificationChannel.getAll(query)
        .then(data => {
          commit('setChannelsData', data)
          return data
        })
    },
    loadChannel ({ commit }, payload) {
      return services.notificationChannel.get(payload)
        .then(item => {
          commit('setChannel', item)
          return item
        })
    },
    saveChannel ({ commit, state }) {
      const ignoredFields = []
      const data = state.channel.id
        ? convertEmbedded(difference(state.channel, state.cleanChannel), ignoredFields)
        : convertEmbedded(state.channel)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.channel)
      }

      const header = state.channel.id && state.channel.updated
        ? { 'X-Entity-Updated': state.channel.updated }
        : {}

      return services.notificationChannel.save(data, state.channel.id, header)
    }
  }
}
