import _ from 'lodash'
import { accountsService } from '../../../services/requests/accounts.service'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    account: null,
    cleanAccount: null,
    accounts: [],
    accountsLoading: false,
    totalAccountsNumber: 0,
    accountsPage: 1,
    accountsTotalPages: 1
  },
  getters: {
    account: state => state.account,
    cleanAccount: state => state.cleanAccount,
    accounts: state => state.accounts,
    accountsLoading: state => state.accountsLoading,
    totalAccountsNumber: state => state.totalAccountsNumber,
    accountsPage: state => state.accountsPage,
    accountsTotalPages: state => state.accountsTotalPages
  },
  mutations: {
    setAccount (state, payload) {
      state.account = payload

      state.cleanAccount = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanAccount (state, payload) {
      state.cleanAccount = payload
    },
    setNewAccount (state) {
      state.account = {
        name: '',
        type: 'ip',
        inn: '',
        ogrn: '',
        kpp: '',
        bankBik: '',
        bankAccountCorresponding: '',
        bankAccountPayment: '',
        bankName: '',
        contractNumber: '',
        contractScan: ''
      }
    },
    updateAccount (state, payload) {
      state.account = {
        ...state.account,
        ...payload
      }
    },
    setAccountsData (state, { items, page, totalPages, totalItems }) {
      state.accounts = items
      state.totalAccountsNumber = totalItems
      state.accountsPage = page
      state.accountsTotalPages = totalPages
    },
    setAccounts (state, payload) {
      state.accounts = payload
    },
    setTotalAccountsNumber (state, payload) {
      state.totalAccountsNumber = payload
    },
    setAccountsPage (state, payload) {
      state.accountsPage = payload
    },
    setAccountsTotalPages (state, payload) {
      state.accountsTotalPages = payload
    },
    startAccountLoading (state) {
      state.accountsLoading = true
    },
    stopAccountLoading (state) {
      state.accountsLoading = false
    },
    upsertAccount (state, payload) {
      let isExist = false

      state.accounts = state.accounts.map(x => {
        if (x.id === payload.id) {
          isExist = true
          return payload
        }

        return x
      })

      if (!isExist) {
        state.accounts.unshift(payload)
      }
    }
  },
  actions: {
    loadAccounts ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.accounts)
      return accountsService.getAll(query)
        .then(data => {
          commit('setAccountsData', data)
          return data
        })
    },
    loadAccount ({ commit }, payload) {
      return accountsService.get(payload)
        .then(item => {
          commit('setAccount', item)
          return item
        })
    },
    saveAccount ({ commit, state }) {
      const ignoredFields = ['contractor', 'currency', 'customer', 'owner', 'rate']
      const data = state.account.id
        ? convertEmbedded(difference(state.account, state.cleanAccount), ignoredFields)
        : convertEmbedded(state.account)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.account)
      }

      const header = state.account.id && state.account.updated
        ? { 'X-Entity-Updated': state.account.updated }
        : {}

      return accountsService.save(data, state.account.id, header)
    }
  }
}
