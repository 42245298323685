import { HALApiService } from '../hal-api.service'
import { createItemsData } from './../../helpers/request-helpers'
import { CachedService } from './base-service'
import entities from '../../../../config/Entities'

class ReportTypeService extends CachedService {
  constructor (httpClient, middleware) {
    super(entities.Orderadmin_Accounts_Entity_ReportType, '/reports/report-type', httpClient, middleware)
  }

  /**
   * @param {String | Number} id
   *
   * @returns {Promise<object>}
   */
  get (id) {
    const query = {
      per_page: 1,
      page: 1,
      filter: [{ type: 'eq', field: 'id', value: id }]
    }

    return this.getAll(query)
      .then(({ items }) => {
        return items[0]
          ? items[0]
          : Promise.reject(new Error('Report type is not found!'))
      })
  }
}

export const reportTypeService = new ReportTypeService(HALApiService, createItemsData)
