export class Printer {
  _url
  _factory
  _printer
  _onOpen
  _onClose
  _onMessage
  _onStatusChange
  _isConnected
  _timeout
  test

  get isConnected () {
    return this._isConnected
  }

  constructor (url, factory, timeout = 0) {
    this._url = url
    this._factory = factory
    this._isConnected = true
    this._timeout = timeout
    this._onOpen = []
    this._onClose = []
    this._onMessage = []
    this._onStatusChange = []
    this.test = 0
    this.connect()
  }

  /**
   * @param {number} timeout
   *
   * @returns {void}
   */
  setTimeout (timeout) {
    this._timeout = timeout

    if (timeout > 0 && !this.isConnected) {
      this.connect()
    }
  }

  /**
   * @returns {void}
   */
  connect () {
    this._printer = this._factory.createWebsocket(this._url)

    this._printer.onopen = () => {
      this._isConnected = true
      this._callSub(this._onOpen)
      this._callSub(this._onStatusChange, true)
    }

    this._printer.onclose = () => {
      this._isConnected = false
      this._callSub(this._onClose)
      this._callSub(this._onStatusChange, false)
      if (this._timeout > 0 && this.test < 5) {
        setTimeout(() => {
          this.test++

          this.connect()
        }, this.timeout)
      }
    }

    this._printer.onmessage = (e) => {
      if (e.data && e.data[0] === '{' && e.data[e.data.length - 1] === '}') {
        const data = JSON.parse(e.data)

        if (data.message.includes('Exception')) {
          console.error(data.message)
        }

        this._callSub(this._onMessage, data)
        return
      }

      this._callSub(this._onMessage, e.data)
    }
  }

  /**
   * @param {array} arr
   * @param {any} val
   *
   * @returns {void}
   */
  _callSub (arr, val) {
    arr.forEach(sub => {
      sub.fn(val)
    })
  }

  /**
   * @param {function} fn
   *
   * @returns {object}
   */
  _getSub (fn) {
    return {
      id: Math.floor(Math.random() * (9999999999) + 1),
      fn
    }
  }

  /**
   * @param {string | number} id
   * @param {array} subs
   *
   * @returns {function}
   */
  _getUnsubscribe (id, subs) {
    return {
      unsubscribe: () => {
        subs = subs.filter(val => val.id !== id)
      }
    }
  }

  /**
   * @param {string} key
   * @param {function} fn
   *
   * @returns {function}
   */
  subscribe (key, fn) {
    const actions = {
      stateChange: () => {
        const sub = this._getSub(fn)
        this._onStatusChange.push(sub)
        fn(this.isConnected)

        return this._getUnsubscribe(sub.id, this._onStatusChange)
      },
      connect: () => {
        const sub = this._getSub(fn)
        this._onOpen.push(sub)

        return this._getUnsubscribe(sub.id, this._onOpen)
      },
      disconnect: () => {
        const sub = this._getSub(fn)

        this._onClose.push(sub)

        return this._getUnsubscribe(sub.id, this._onClose)
      },
      message: () => {
        const sub = this._getSub(fn)

        this._onMessage.push(sub)

        return this._getUnsubscribe(sub.id, this._onMessage)
      }
    }

    if (!actions[key]) {
      throw new Error(`Subscribe for ${key} not exist!`)
    }

    return actions[key]()
  }

  /**
   * @param {any} data
   *
   * @returns {boolean}
   */
  submit (data) {
    if (!this._isConnected) {
      return false
    }

    const arr = !Array.isArray(data)
      ? [data]
      : data

    arr.forEach(element => {
      this._printer.send(JSON.stringify(element))
    })

    return true
  }
}
