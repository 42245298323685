/**
 * Create Order By
 *
 * @param {String} field
 * @param {Boolean | undefined | null} isDesc
 * @param {Boolean} disableFieldsAdapter
 *
 * @returns {object}
 */
export function createOrderBy (field, isDesc, disableFieldsAdapter) {
  const fields = {
    id: 'created',
    details: 'state'
  }

  if (disableFieldsAdapter) {
    return {
      type: 'field',
      field,
      direction: isDesc
        ? 'desc'
        : 'asc'
    }
  }

  return {
    type: 'field',
    field: fields[field] || field,
    direction: isDesc
      ? 'desc'
      : 'asc'
  }
}

/**
 * Build Query
 *
 * @param {object} pagination
 * @param {Boolean} disableFieldsAdapter
 *
 * @returns {object}
 */
export function buildQuery (pagination = {}, disableFieldsAdapter) {
  const keys = {
    rowsPerPage: 'per_page'
  }

  const ignoredKeys = ['rowsNumber', 'descending']

  return Object.keys(pagination).reduce((acc, key) => {
    if (!pagination[key] || ignoredKeys.includes(key)) {
      return acc
    }

    if (key === 'sortBy') {
      if (!acc['order-by']) {
        acc['order-by'] = []
      }

      acc['order-by'].push(createOrderBy(pagination.sortBy, pagination.descending, disableFieldsAdapter))

      return acc
    }

    acc[keys[key] || key] = pagination[key]
    return acc
  }, {})
}
