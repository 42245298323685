/**
 * Get terminal settings
 *
 * @returns {object}
 */
function getTerminalSettings () {
  const terminalSettings = JSON.parse(localStorage.getItem('terminalSettings'))
  const defaultSettings = {
    hideBarcodeField: false,
    catchAll: true,
    hideKeyboard: false
  }

  return terminalSettings
    ? { ...defaultSettings, ...terminalSettings }
    : defaultSettings
}

/**
 * Get Notification
 *
 * @param {string} message
 * @param {string} type
 *
 * @returns {object}
 */
function getNotification (message, type) {
  return {
    id: new Date().getUTCMilliseconds(),
    message,
    type
  }
}

export default {
  state: {
    isOpenCamera: false,
    isOpenAuthModal: false,
    loading: 0,
    notifications: [],
    terminalSettings: getTerminalSettings(),
    storagePlaceEvents: [],
    terminalContainerHeight: window.innerHeight - 55
  },
  getters: {
    isOpenCamera: state => state.isOpenCamera,
    terminalContainerHeight: state => state.terminalContainerHeight,
    isOpenAuthModal: state => state.isOpenAuthModal,
    isLoading: state => state.loading > 0,
    notifications: state => state.notifications,
    terminalSettings: state => state.terminalSettings,
    storagePlaceEvents: state => state.storagePlaceEvents,
    findStoragePlaceEvent: state => (id) => state.storagePlaceEvents.find(({ entity }) => `${entity}` === `${id}`)
  },
  mutations: {
    openCloseCamera (state) {
      state.isOpenCamera = !state.isOpenCamera
    },
    openCamera (state) {
      state.isOpenCamera = true
    },
    closeCamera (state) {
      state.isOpenCamera = false
    },
    setTerminalContainerHeight (state, payload) {
      state.terminalContainerHeight = payload
    },
    openAuthModal (state) {
      state.isOpenAuthModal = true
    },
    closeAuthModal (state) {
      state.isOpenAuthModal = false
    },
    addStoragePlaceEvent (state, payload) {
      state.storagePlaceEvents.push(payload)
    },
    removeStoragePlaceEvent (state, payload) {
      state.storagePlaceEvents = state.storagePlaceEvents.filter(({ entity }) => entity !== payload)
    },
    startLoading (state) {
      state.loading += 1
    },
    stopLoading (state) {
      state.loading -= 1
    },
    removeNotification (state, id) {
      state.notifications = state.notifications.filter(x => x.id !== id)
    },
    addErrorNotification (state, message) {
      const notification = getNotification(message, 'danger')
      state.notifications.push(notification)
    },
    addWarningNotification (state, message) {
      const notification = getNotification(message, 'warning')
      state.notifications.push(notification)
    },
    addNotification (state, message) {
      const notification = getNotification(message, '')
      state.notifications.push(notification)
    },
    addSountNotification (state) {
      const notification = getNotification('', '')
      notification.mode = 'sound'
      state.notifications.push(notification)
    }
  },
  actions: {}
}
