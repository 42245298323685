import service from '../../services'
import { mergeQuery } from '../../utils/request.utils'

export default {
  state: {
    inventories: [],
    totalInventoryNumber: 0,
    inventoryPage: 1,
    inventoryTotalPages: 1,
    inventoryLoading: false
  },
  getters: {
    inventories: state => state.inventories,
    totalInventoryNumber: state => state.totalInventoryNumber,
    inventoryPage: state => state.inventoryPage,
    inventoryTotalPages: state => state.inventoryTotalPages,
    inventoryLoading: state => state.inventoryLoading
  },
  mutations: {
    setInventoriesData (state, { items, page, totalPages, totalItems }) {
      state.inventories = items
      state.totalInventoryNumber = totalItems
      state.inventoryPage = page
      state.inventoryTotalPages = totalPages
    },
    setInventories (state, payload) {
      state.inventories = payload
    }
  },
  actions: {
    loadInventories ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.inventory)
      return service.inventory.getAll(query)
        .then(data => {
          commit('setInventoriesData', data)
          return data
        })
    }
  }
}
