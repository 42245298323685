// Vue
import { createStore } from 'vuex'

// Utils
import { storeUtils } from './utils/store.utils'

// Modules
import sender from './vuex/sender'
import returnParcels from './vuex/return-parcels'
import inventory from './vuex/inventory'
import terminalSettings from './vuex/terminal-settings'
import common from './vuex/common'
import helper from './vuex/helper'
import shortcuts from './vuex/shortcuts'

const modules = [
  common,
  sender,
  returnParcels,
  inventory,
  terminalSettings,
  helper,
  shortcuts
]

export default createStore(storeUtils.mergeModules(modules))
