import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    notificationTask: null,
    cleanNotificationTask: null,
    notificationTasks: [],
    totalNotificationTasksNumber: 0,
    notificationTasksPage: 1,
    notificationTasksTotalPages: 1,
    notificationTasksLoading: false
  },
  getters: {
    notificationTask: state => state.notificationTask,
    cleanNotificationTask: state => state.cleanNotificationTask,
    notificationTasks: state => state.notificationTasks,
    totalNotificationTasksNumber: state => state.totalNotificationTasksNumber,
    notificationTasksPage: state => state.notificationTasksPage,
    notificationTasksTotalPages: state => state.notificationTasksTotalPages,
    notificationTasksLoading: state => state.notificationTasksLoading
  },
  mutations: {
    setNotificationTask (state, payload) {
      state.notificationTask = payload
      state.cleanNotificationTask = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanNotificationTask (state, payload) {
      state.cleanNotificationTask = payload
    },
    setNewNotificationTask (state) {
      state.notificationTask = {
        delay: null,
        event: null,
        name: null,
        conditions: [],
        state: null,
        template: null,
        title: null,
        _embedded: {
          owner: null,
          channel: null
        }
      }
    },
    updateNotificationTask (state, payload) {
      state.notificationTask = {
        ...state.notificationTask,
        ...payload
      }
    },
    updateNotificationTaskEmbedded (state, payload) {
      state.notificationTask._embedded = {
        ...state.notificationTask._embedded,
        ...payload
      }
    },
    setNotificationTasksData (state, { items, page, totalPages, totalItems }) {
      state.notificationTasks = items
      state.totalNotificationTasksNumber = totalItems
      state.notificationTasksPage = page
      state.notificationTasksTotalPages = totalPages
    },
    setNotificationTasks (state, payload) {
      state.notificationTasks = payload
    },
    setTotalNotificationTasksNumber (state, payload) {
      state.totalNotificationTasksNumber = payload
    },
    setNotificationTasksPage (state, payload) {
      state.notificationTasksPage = payload
    },
    setNotificationTasksTotalPages (state, payload) {
      state.notificationTasksTotalPages = payload
    },
    startTasksLoading (state) {
      state.notificationTasksLoading = true
    },
    stopTasksLoading (state) {
      state.notificationTasksLoading = false
    }
  },
  actions: {
    loadNotificationTasks ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.notificationTasks)
      return services.notificationTask.getAll(query)
        .then(data => {
          commit('setNotificationTasksData', data)
          return data
        })
    },
    loadNotificationTask ({ commit }, payload) {
      return services.notificationTask.get(payload)
        .then(item => {
          commit('setNotificationTask', item)
          return item
        })
    },
    saveNotificationTask ({ commit, state }) {
      const ignoredFields = ['channel']
      const data = state.notificationTask.id
        ? convertEmbedded(difference(state.notificationTask, state.cleanNotificationTask), ignoredFields)
        : convertEmbedded(state.notificationTask)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.notificationTask)
      }

      const header = state.notificationTask.id && state.notificationTask.updated
        ? { 'X-Entity-Updated': state.notificationTask.updated }
        : {}

      return services.notificationTask.save(data, state.notificationTask.id, header)
    }
  }
}
