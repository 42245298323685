import { HALApiService } from '../hal-api.service'
import { createItemsData } from '../../helpers/request-helpers'
import { store } from '../../vueX/store'
import { errorCatcher } from '../handlers'
import entities from '../../../../config/Entities'
import qs from 'qs'
import { Cache } from '../../../../packages/cache'

class ShippingPlanService {
    httpClient;
    shippingPlansUrl;
    shippingPlanItemUrl;
    middleware;
    fields;
    _entityClass = entities.Orderadmin_Integrations_Amazon_Entity_FBA_ShippingPlan
    _expireTime = 360000

    static cache = new Cache()
    static errorCatcher = errorCatcher
    static qs = qs

    static get onError () {
        return (err) => store.commit('addErrorNotification', err)
    }

    constructor (httpClient, middleware) {
        this.fields = [
            'shipmentDate',
            'id',
            'reserves',
            'state',
            'extId',
            'date',
            'paymentState',
            'orderPrice',
            'totalPrice',
            'comment',
            'eav',
            'stateDescription',
            'orderErrors',
            'type',
            'clientId',
            'source'
        ]
        this.shippingPlansUrl = '/integrations-amazon/fba/shipping-plan'
        this.shippingPlanItemUrl = '/integrations-amazon/fba/shipping-plan/item'
        this.httpClient = httpClient
        this.middleware = middleware
    }

    /**
     * @param {string|number} id
     * @param {boolean} forceReload
     *
     * @returns {Promise<object>}
     */
    find (id, forceReload = false) {
        const item = ShippingPlanService.cache.getValue(this._entityClass, id)

        if (item && !forceReload) {
            return Promise.resolve(item)
        }

        return this.get(id)
    }

    /**
     * @param {object} item
     *
     * @returns {array}
     */
    upsert (item) {
        ShippingPlanService.cache.dispatchValue(this._entityClass, item)
    }

    /**
     * @param {String | Number} id
     *
     * @returns {Promise<object>}
     */
    get (id) {
        const item = ShippingPlanService.cache.getValue(this._entityClass, id)

        if (item) {
            return Promise.resolve(item)
        }

        const query = {
            fields: this.fields,
            filter: [
                { field: 'id', type: 'eq', value: id }
            ]
        }

        return this.httpClient.get(this.shippingPlansUrl, query)
            .then(result => {
                return result._embedded.plans.length > 0
                    ? result._embedded.plans[0]
                    : Promise.reject(new Error('Shipping Plan is not found!'))
            })
            .then(item => {

                this.upsert(item)

                return item
            })
            .catch(err => {
                ShippingPlanService.onError(err)
                return Promise.reject(err)
            })
    }

    /**
     * @param {object} get
     *
     * @returns {string}
     */
    getURL (get) {
        return `${this.shippingPlansUrl}${(Object.keys(get).length > 0 ? '?' + ShippingPlanService.qs.stringify(get) : '')}`
    }

    /**
     * @param {object} query
     *
     * @returns {Promise<object>}
     */
    getAll (query) {
        const newQuery = { ...query }

        if (!newQuery.fields) {
            newQuery.fields = this.fields
        }

        delete newQuery.forceReload

        const data = ShippingPlanService.cache.getValues(this._entityClass, this.getURL(newQuery))

        if (!data.isExpired && !query.forceReload) {
            return Promise.resolve(data.value)
        }

        return this.httpClient.get(this.shippingPlansUrl, newQuery)
            .then(this.middleware)
            .then(result => {
                ShippingPlanService.cache.dispatchValues(this._entityClass, this.getURL(newQuery), result, this._expireTime)
                return result
            })
    }

    /**
     * @param {object} query
     *
     * @returns {Promise<array>}
     */
    getShippingPlanItem (query) {
        return this.httpClient.get(this.shippingPlanItemUrl, query).then(this.middleware)
    }

    /**
     * @param {array} items
     *
     * @returns {Promise<array>}
     */
    saveShippingPlanItem (queue, results = []) {
        if (queue.length <= 0) {
            return Promise.resolve(results)
        }

        return this.httpClient.bulk(this.shippingPlanItemUrl, queue)
            .then(data => {
                return data.map(x => {
                    const key = Object.keys(x)[0]
                    return x[key]
                })
            })
            .catch(err => ShippingPlanService.errorCatcher(err, `${this.shippingPlanItemUrl}/bulk`))
    }

    /**
     * @param {object} data
     * @param {String | Number | undefined} id
     * @param {String} type
     * @param {object | undefined} headers
     *
     * @returns {Promise<object>}
     */
    save (data, id, type, headers) {
        const updatedData = {
            ...data,
        }

        return this.httpClient.saveRequest(`${this.shippingPlansUrl}/`, updatedData, id, undefined, undefined, headers)
            .then(item => {
                if (!item.type) {
                    item.type = type
                }

                this.upsert(item)

                return item
            })
    }

    /**
     * @param {String | Number} id
     * @param {String | Number} template
     *
     * @returns {Promise<String>}
     */
    getTemplate (entity, template) {
        return this.httpClient.getRaw(`${this.shippingPlansUrl}${entity.id ? `/${entity.id}` : ''}`, {}, `application/${template}+document`)
            .then(response => {
                if (response.status !== 200 && response.status !== 201) {
                    return Promise.reject(new Error(response.statusText))
                }

                return response.text().then(data => {
                    if (data.match('<div')) {
                        data = `<html lang="en-us" dir="ltr"><head><meta charset="utf-8"></head><body>${data}</body></html>`
                    }

                    return data
                })
            })
    }

    /**
     * @param {object} query
     *
     * @returns {Promise<excel>}
     */
    downloadAll (query) {
        const updatedQuery = {
            page: 1,
            per_page: 25,
            sort: {
                id: 'desc'
            },
            'order-by': [
                { type: 'field', field: 'id', direction: 'desc' }
            ],
            ...query
        }

        return this.httpClient.download(this.shippingPlansUrl, updatedQuery, 'application/xls')
    }
}

export const shippingPlanService = new ShippingPlanService(HALApiService, createItemsData)
