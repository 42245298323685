import { Subject } from "../subject"

class Container {
  _targets

  constructor () {
    this._targets = {}
  }

  addTarget (name) {
    if (!this._targets[name]) {
      this._targets[name] = new Subject()
    }
    return this._targets[name]
  }

  removeTarget (name) {
    this._targets[name] = undefined
    return undefined
  }

  getTargetValue (name) {

    return (this._targets[name] || { value: [] }).value
  }

  submitVM (to, vm) {
    if (!this._targets[to]) {
      this.addTarget(to)
    }
    // console.log(vm)
    this._targets[to].publish(vm)
  }
}

export default new Container()
