function updateStorage (shortcuts) {
  localStorage.setItem('routeShortcuts', JSON.stringify(shortcuts))
  return shortcuts
}

export default {
  state: {
    shortcuts: JSON.parse(localStorage.getItem('routeShortcuts')) || []
  },
  getters: {
    shortcuts: state => state.shortcuts
  },
  mutations: {
    addShortcut (state, payload) {
      state.shortcuts = [{ ...payload, app: 'terminal' }, ...state.shortcuts]
      updateStorage(state.shortcuts)
    },
    removeShortcut (state, payload) {
      state.shortcuts = state.shortcuts.filter(({ route }) => route !== payload.route)
      updateStorage(state.shortcuts)
    },
    removeAllShortcuts (state) {
      state.shortcuts = []
      updateStorage(state.shortcuts)
    }
  }
}