import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    rate: null,
    cleanRate: null,
    rates: [],
    ratesItemsNumber: 0,
    ratesPage: 1,
    ratesTotalPages: 1,
    ratesLoading: false
  },
  getters: {
    rate: state => state.rate,
    cleanRate: state => state.cleanRate,
    rates: state => state.rates,
    ratesLoading: state => state.ratesLoading,
    ratesItemsNumber: state => state.ratesItemsNumber,
    ratesPage: state => state.ratesPage,
    ratesTotalPages: state => state.ratesTotalPages
  },
  mutations: {
    setRatesData (state, { items, page, totalPages, totalItems }) {
      state.rates = items
      state.ratesTotalPages = totalPages
      state.ratesPage = page
      state.ratesItemsNumber = totalItems
    },
    setRates (state, payload) {
      state.rates = payload
    },
    setRatesTotalPages (state, payload) {
      state.ratesTotalPages = payload
    },
    setRatesPage (state, payload) {
      state.ratesPage = payload
    },
    setRatesItemsNumber (state, payload) {
      state.ratesItemsNumber = payload
    },
    setRate (state, payload) {
      state.rate = payload
      state.cleanRate = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanRate (state, payload) {
      state.cleanRate = payload
    },
    setNewRate (state) {
      state.rate = {}
    },
    updateRate (state, payload) {
      state.rate = {
        ...state.rate,
        ...payload
      }
    },
    startRatesLoading (state) {
      state.ratesLoading = true
    },
    stopRatesLoading (state) {
      state.ratesLoading = false
    }
  },
  actions: {
    loadRates ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.rates)
      return services.deliveryServiceRate.getAll(query)
        .then(data => {
          commit('setRatesData', data)
          return data
        })
    },
    loadRate ({ commit }, payload) {
      return services.deliveryServiceRate.get(payload)
        .then(item => {
          commit('setRate', item)
          return item
        })
    },
    saveRate ({ commit, state }) {
      const data = state.rate.id
        ? convertEmbedded(difference(state.rate, state.cleanRate))
        : convertEmbedded(state.rate)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.rate)
      }

      const header = state.rate.id && state.rate.updated
        ? { 'X-Entity-Updated': state.rate.updated }
        : {}

      return services.deliveryServiceRate.save(data, state.rate.id, header)
    }
  }
}
