import qs from 'qs'
import store from '../store'

export function authHeader () {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem('user'))

  if (user && user.access_token) {
    return { 'Authorization': 'Bearer ' + user.access_token }
  } else {
    return {}
  }
}

/**
 * Error Catcher
 *
 * @param {object} error
 *
 * @returns {void}
 */
export function errorCatcher (error) {
  if (error instanceof DOMException) {
    if (error.code === error.ABORT_ERR) {
      console.log(error)
    } else {
      store.commit('addErrorNotification', error.message)
    }
  } else {
    store.commit('addErrorNotification', error.message)
  }

  return Promise.reject(error)
}

/**
 * Parse Text
 *
 * @param {String} text
 *
 * @returns {Any}
 */
function parseText (text) {
  if (text && (text[0] === '[' || text[0] === '{')) {
    return JSON.parse(text)
  }

  return text
}

/**
 * Handle Response
 *
 * @param {Object} response
 *
 * @returns {Promise<Object>}
 */
export function handleResponse (response) {
  return response.text().then(text => {
    const data = parseText(text)

    if (response.status !== 200 && response.status !== 201) {
      const result = parseText(text)
      response.message = (result && result.detail) || response.statusText
      return Promise.reject(response)
    }

    return data
  })
}

export const HALApiService = {
  getRaw,
  get,
  post,
  patch,
  put,
  saveRequest
}

let requests = {}

/**
 * Save Request
 *
 * @param {String} url
 * @param {Any} data
 * @param {Number | Undefined} id
 * @param {String | Undefined} accept
 * @param {String | Undefined} api
 * @param {String | Undefined} headers
 * @param {object | Undefined} query
 *
 * @returns {Any}
 */
function saveRequest (url, data, id, accept, api, headers, query) {
  if (url[url.length - 1] !== '/' && id) {
    url = url + '/'
  }

  if (url[url.length - 1] === '/' && !id) {
    url = url.slice(0, url.length - 1)
  }

  if (headers && headers['X-Entity-Updated'] && typeof headers['X-Entity-Updated'] === 'object') {
    headers['X-Entity-Updated'] = headers['X-Entity-Updated'].date
  }

  return id
    ? patch(url + id, data, accept, api, headers, query)
    : post(url, data, accept)
}

/**
 * Get Raw
 *
 * @param {String} url
 * @param {Object} get
 * @param {String} accept
 *
 * @returns {Promise<any>}
 */
function getRaw (url, get = {}, accept = 'application/json', headers = {}) {
  const controller = new AbortController()
  const signal = controller.signal

  const requestOptions = {
    method: 'GET',
    headers: {
      ...authHeader(),
      'Accept': accept,
      'Accept-Language': window.appOptions && window.appOptions.locale
        ? window.appOptions.locale.locale
        : '',
      ...headers
    },
    signal: signal
  }

  if (requests['get:' + url]) {
    requests['get:' + url].abort()
  }

  requests['get:' + url] = controller

  let endpoint = '/api'
  if (url.match(/\/apps\//)) {
    endpoint = ''
  }

  return fetch(window.appOptions.defaultServer + endpoint + url + (Object.keys(get).length > 0 ? '?' + qs.stringify(get) : ''), requestOptions)
}

/**
 * Get
 *
 * @param {String} url
 * @param {Object} get
 * @param {String} accept
 *
 * @returns {Promise<any>}
 */
function get (url, get = {}, accept = 'application/json', headers = {}) {
  store.commit('startLoading')

  return getRaw(url, get, accept, headers)
    .then(data => handleResponse(data, 'GET', accept))
    .catch(errorCatcher)
    .finally(() => {
      store.commit('stopLoading')
    })
}

/**
 * Post
 *
 * @param {String} url
 * @param {Any} get
 * @param {String} accept
 *
 * @returns {Promise<any>}
 */
function post (url, data, accept = 'application/json', contentType = 'application/json', api = '/api') {
  store.commit('startLoading')
  const controller = new AbortController()
  const signal = controller.signal

  const requestOptions = {
    method: 'POST',
    headers: {
      ...authHeader(),
      'Content-Type': contentType,
      'Accept': accept
    },
    body: JSON.stringify(data),
    signal: signal
  }

  if (requests['post:' + url]) {
    requests['post:' + url].abort()
  }

  requests['post:' + url] = controller

  return fetch(window.appOptions.defaultServer + api + url, requestOptions)
    .then(data => handleResponse(data))
    .then(task => {
      return task
    })
    .catch(errorCatcher)
    .finally(() => {
      store.commit('stopLoading')
    })
}

/**
 * Patch
 *
 * @param {String} url
 * @param {Any} get
 * @param {String} accept
 * @param {Object} headers
 * @param {Object} query
 *
 * @returns {Promise<any>}
 */
function patch (url, data, accept = 'application/json', api = '/api', headers = {}, query = {}) {
  store.commit('startLoading')

  const requestOptions = {
    method: 'PATCH',
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
      'Accept': accept,
      ...headers
    },
    body: JSON.stringify(data)
  }

  return fetch(window.appOptions.defaultServer + api + url + (Object.keys(query).length > 0 ? '?' + qs.stringify(query) : ''), requestOptions)
    .then(data => handleResponse(data))
    .then(task => {
      return task
    })
    .catch(errorCatcher)
    .finally(() => {
      store.commit('stopLoading')
    })
}

/**
 * Put
 *
 * @param {String} url
 * @param {Any} get
 * @param {String} accept
 *
 * @returns {Promise<any>}
 */
function put (url, data, accept = 'application/json', query = {}) {
  store.commit('startLoading')
  const requestOptions = {
    method: 'PUT',
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
      'Accept': accept
    },
    body: JSON.stringify(data)
  }

  return fetch(window.appOptions.defaultServer + '/api' + url + (Object.keys(query).length > 0 ? '?' + qs.stringify(query) : ''), requestOptions)
    .then(data => handleResponse(data))
    .then(task => {
      return task
    })
    .catch(errorCatcher)
    .finally(() => {
      store.commit('stopLoading')
    })
}
