import { HALApiService } from './../hal-api.service'
import { createItemsData } from './../../helpers/request-helpers'
import { CachedService } from './base-service'
import entities from '../../../../config/Entities'

class StorageMovementInventoryService extends CachedService {
    constructor (httpClient, middleware) {
        super(entities.Orderadmin_Storage_Entity_Movement_Inventory, '/storage/movements/inventory', httpClient, middleware)
    }


    /**
     * @param {String | Number} id
     *
     * @returns {Promise<object>}
     */
    getPDF (id) {
        return this._httpClient.getRaw(`${this._url}/${id}`, {}, 'application/pdf,text/html')
            .then(response => {
                if (response.status !== 200 && response.status !== 201) {
                    return Promise.reject(response.statusText)
                }

                return response
            })
    }
}

export const storageInventoryDocumentService = new StorageMovementInventoryService(HALApiService, createItemsData)
