import { buildQuery } from './query.utils'
import { convertEmbedded, createItemsData } from './request.utils'
import { storeUtils } from './store.utils'
import _ from 'lodash'
import { FiedUtil } from './field.utils'
import services from './../services'

function createDate (date) {
  return `${date.getFullYear()}-${(date.getMonth() + 1)}-${date.getDate()}`.split('-').map(Number)
}

function getDate () {
  let from = createDate(new Date())

  from[2] -= 1

  if (from[2] < 1) {
    from[2] = 1
  }

  return from.map(value => value < 10 ? `0${value}` : value).join('-')
}

function formatGroupedItemsByOffer (items) {
  return items.reduce((acc, item) => {
    const oldItem = acc.find(x => x.id == item._embedded?.productOffer?.id)
    if (oldItem) {
      oldItem.count += item.count
      return acc
    }

    const product = {
      ...item._embedded?.productOffer,
      ...item
    }

    if (!product._embedded.shop) {
      product._embedded.shop = {
        id: item._embedded?.productOffer?._embedded?.shop?._links.self.href.split('/').pop()
      }
    }

    if (typeof product.items === 'string') {
      product.items = JSON.parse(product.items)
    }

    if (typeof product.barcodes === 'string') {
      product.barcodes = JSON.parse(product.barcodes)
    }

    acc.push(product)
    return acc
  }, [])
}

function groupItemsByOfferAndState (items) {
  return Object.values(
    items.reduce((acc, item) => {
      const product = {
        ...item._embedded.productOffer,
        ...item,
        count: item.state === 'expected'
          ? 0
          : item.count,
        expected: item.state === 'new'
          ? 0
          : item.count
      }

      if (acc[product.id]) {
        acc[product.id].count += product.count
        acc[product.id].expected += product.expected
      } else {
        if (typeof product.items === 'string') {
          product.items = JSON.parse(product.items)
        }

        if (typeof product.barcodes === 'string') {
          product.barcodes = JSON.parse(product.barcodes)
        }

        if (!product._embedded.shop) {
          product._embedded.shop = {
            id: item._embedded.productOffer._embedded.shop._links.self.href.split('/').pop()
          }
        }

        acc[product.id] = product
      }

      return acc
    }, {})
  )
}

function groupItemsByOfferStateAndSku (items) {
  return Object.values(
    items.reduce((acc, item) => {
      const product = {
        ...item._embedded.productOffer,
        ...item,
        count: item.state === 'expected'
          ? 0
          : item.count,
        expected: item.state === 'new'
          ? 0
          : item.count
      }

      const key = `${product.id}:${product.sku}`

      if (acc[key]) {
        acc[key].count += product.count
        acc[key].expected += product.expected
      } else {
        if (typeof product.items === 'string') {
          product.items = JSON.parse(product.items)
        }

        if (typeof product.barcodes === 'string') {
          product.barcodes = JSON.parse(product.barcodes)
        }

        if (!product._embedded.shop) {
          product._embedded.shop = {
            id: item._embedded.productOffer._embedded.shop._links.self.href.split('/').pop()
          }
        }

        acc[key] = product
      }

      return acc
    }, {})
  )
}

export default {
  groupItemsByOfferStateAndSku,
  formatGroupedItemsByOffer,
  groupItemsByOfferAndState,
  getDate,
  storeUtils,
  buildQuery,
  createItemsData,
  convertEmbedded,
  field: new FiedUtil(services.createServicе, _)
}
