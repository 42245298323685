import _ from 'lodash'
import { userService } from './../../../services/requests/users.service'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    currentUser: JSON.parse(localStorage.getItem('userData')),
    user: null,
    cleanUser: null,
    users: [],
    totalUsersNumber: 0,
    usersPage: 1,
    usersTotalPages: 1,
    usersLoading: false,
    isOpenAuthModal: false
  },
  getters: {
    hasBaseAccess: state => !!state.currentUser?.roles?.find(({ id }) => id === 3 || id === 8 || id === 29 || id === 7),
    hasSecondayAccess: state => !!state.currentUser?.roles?.find(({ id }) => id === 3 || id === 7 || id === 29),
    isAdministrator: state => !!state.currentUser?.roles?.find(({ id }) => id === 3),
    isEmployee: state => !!state.currentUser?.roles?.find(({ id }) => id === 7),
    isClient: state => !!state.currentUser?.roles?.find(({ id }) => id === 8),
    isSupervisior: state => !!state.currentUser?.roles?.find(({ id }) => id === 29),
    currentUser: state => state.currentUser,
    user: state => state.user,
    cleanUser: state => state.cleanUser,
    users: state => state.users,
    totalUsersNumber: state => state.totalUsersNumber,
    usersPage: state => state.usersPage,
    usersTotalPages: state => state.usersTotalPages,
    usersLoading: state => state.usersLoading,
    isOpenAuthModal: state => state.isOpenAuthModal
  },
  mutations: {
    openAuthModal (state) {
      state.isOpenAuthModal = true
    },
    closeAuthModal (state) {
      state.isOpenAuthModal = false
    },
    setUsers (state, payload) {
      state.users = payload
    },
    setUsersData (state, { items, page, totalPages, totalItems }) {
      state.users = items
      state.totalUsersNumber = totalItems
      state.usersPage = page
      state.usersTotalPages = totalPages
    },
    setCurrentUser (state, payload) {
      state.currentUser = payload
    },
    setUser (state, payload) {
      state.user = payload
      state.cleanUser = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanUser (state, payload) {
      state.cleanUser = payload
    },
    setNewUser (state, payload = {}) {
      const defaultUser = {
        allowedAdapters: [],
        domain: 'default',
        domains: [],
        eav: {},
        email: null,
        name: null,
        roles: [],
        state: 'active',
        username: null
      }

      const types = {
        client () {
          return {
            ...defaultUser,
            domain: state.currentUser.domain,
            domains: state.currentUser.domains,
            roles: [
              { id: 2, name: 'User' },
              { id: 8, name: 'Customer portal' }
            ],
            eav: {
              'allow-parallel-login': 5
            }
          }
        },
        employee () {
          return {
            ...defaultUser,
            domain: state.currentUser.domain,
            domains: state.currentUser.domains,
            roles: [
              { id: 2, name: 'User' },
              { id: 7, name: 'Employee'},
              { id: 10, name: 'Shipping'},
              { id: 11, name: 'WMS'},
              { id: 12, name: '[FF] Admin'}
            ],
            eav: {
              'allow-parallel-login': 1
            }
          }
        }
      }

      state.user = (types[payload.type] && types[payload.type]()) || defaultUser
    },
    updateUser (state, payload) {
      state.user = {
        ...state.user,
        ...payload
      }
    },
    upsertUser (state, payload) {
      let isExist = false

      state.users = state.users.map(x => {
        if (x.id === payload.id) {
          isExist = true
          return payload
        }

        return x
      })

      if (!isExist) {
        state.users = [
          payload,
          ...state.users
        ]
      }
    },
    setTotalUsersNumber (state, payload) {
      state.totalUsersNumber = payload
    },
    setUsersPage (state, payload) {
      state.usersPage = payload
    },
    setUsersTotalPages (state, payload) {
      state.usersTotalPages = payload
    },
    startUsersLoading (state) {
      state.usersLoading = true
    },
    stopUsersLoading (state) {
      state.usersLoading = false
    }
  },
  actions: {
    loadUserAccount ({ commit }) {
      return userService.getCurrentUser()
        .then(user => {
          commit('setCurrentUser', _.cloneDeep(user))
          localStorage.setItem('userData', JSON.stringify(user))
          commit('setUser', user)
          return user
        })
    },
    loadUsers ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.warehouse)
      return userService.getAll(query)
        .then(data => {
          commit('setUsersData', data)
          return data
        })
    },
    loadUser ({ commit }, payload) {
      return userService.get(payload)
        .then(item => {
          commit('setUser', item)
          return item
        })
    },
    saveUser ({ commit, state }) {
      const data = state.user.id
        ? convertEmbedded(difference(state.user, state.cleanUser))
        : convertEmbedded(state.user)

      // Roles, domains, allowedAdapters are arrays
      // To save them we must pass the whole array instead only change in it
      if (data.domains) {
        data.domains = state.user.domains
      }

      if (data.roles) {
        data.roles = state.user.roles
      }

      if (data.allowedAdapters) {
        data.allowedAdapters = state.user.allowedAdapters
      }

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.user)
      }

      const header = state.user.id && state.user.updated
        ? { 'X-Entity-Updated': state.user.updated }
        : {}

      return userService.save(data, state.user.id, header)
    }
  }
}
