export class QueueUtils {
  _filter
  _aliases
  _settingsKeysAsFilters

  get filterKeys () {
    return Object.keys(this._settingsKeysAsFilters)
  }

  constructor (filter) {
    this._filter = filter
    this._aliases = {
      deliveryService: 'dr',
      recipientLocality: 'dr',
    }

    this._settingsKeysAsFilters = {
      'states-for-assembling': 'state',
      'orders-shipment-date': 'shipmentDate',
      'orders-locality': 'recipientLocality',
      'orders-shop': 'shop',
      'delivery-services': 'deliveryService'
    }
  }

  /**
   * Get alias for certain filters
   *
   * @param {string} key
   *
   * @returns {string}
   */
  _getAlias (key) {
    return this._aliases[key] || 'o'
  }

  /**
   * Get alias for certain filters
   *
   * @param {string} key
   *
   * @returns {string}
   */
  _getAliasRevision (key) {
    if(key === 'productOffer') {
      return this._aliases[key] || 'i'
    }
    if(key === 'id') {
     return  'p'
    }
  }

  /**
   * Convert queue filters
   *
   * @param {object} item
   *
   * @returns {object}
   */
  convertQueue (item) {
    return {
      ...item,
      settings: {
        ...item.settings,
        filters: this.convertFilterToObject((item.settings || {}).filters || [])
      }
    }
  }

  /**
   * Convert filters to object
   *
   * @param {array} item
   *
   * @returns {object}
   */
  convertFilterToObject (filter) {
    const filterObject = filter.reduce((acc, f) => {
      if (f.field === 'deliveryRequest' && f.type === 'innerjoin') {
        return acc
      }

      const data = this._filter.filterToObject(f)

      Object.entries(data).forEach(([key, value]) => {
        if (acc[key] && typeof acc[key] === 'object') {
          data[key] = {
            ...value,
            ...acc[key]
          }
        }
      })

      return { ...acc, ...data }
    }, {})

    return filterObject
  }

  /**
   * Convert object to filters
   *
   * @param {object} item
   *
   * @returns {array}
   */
  convertObjectToFilter (filterObject, not = false) {
    let filter = Object.entries(filterObject).reduce((acc, [key, value]) => {
      return [...acc, ...this._filter.create(key, value, this._getAlias(key), not)]
    }, [])

    if (filterObject.deliveryService) {
      filter.push({
          type: 'leftjoin',
          parentAlias: 'o',
          field: 'deliveryRequest',
          alias: 'dr',
      })
    }

    const index = filter.findIndex((x => x.field === 'deliveryService'))

    if (index !== -1) {
      filter = [
        ...filter.slice(0, index),
        ...filter.slice(index)
      ]
    }

    return filter
  }

  /**
   * Convert object to filters
   *
   * @param {object} item
   *
   * @returns {array}
   */
  convertObjectToFilterRevision (filterObject, not = false) {
    let filter = Object.entries(filterObject).reduce((acc, [key, value]) => {
      return [...acc, ...this._filter.create(key, value, this._getAliasRevision(key), not)]
    }, [])

    if (filterObject.deliveryService) {
      filter.push({
        type: 'leftjoin',
        parentAlias: 'o',
        field: 'deliveryRequest',
        alias: 'dr',
      })
    }

    const index = filter.findIndex((x => x.field === 'deliveryService'))

    if (index !== -1) {
      filter = [
        ...filter.slice(0, index),
        ...filter.slice(index)
      ]
    }

    return filter
  }
}
