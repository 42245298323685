import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    servicePoint: null,
    cleanServicePoint: null,
    servicePoints: [],
    totalServicePointsNumber: 0,
    servicePointsPage: 1,
    servicePointsTotalPages: 1,
    servicePointsArchive: {},
    servicePointsLoading: false,
    cachedServicePoints: {}
  },
  getters: {
    servicePoints: state => state.servicePoints,
    servicePointsLoading: state => state.servicePointsLoading,
    cleanServicePoint: state => state.cleanServicePoint,
    servicePoint: state => state.servicePoint,
    totalServicePointsNumber: state => state.totalServicePointsNumber,
    servicePointsPage: state => state.servicePointsPage,
    servicePointsTotalPages: state => state.servicePointsTotalPages,
    servicePointsArchive: state => state.servicePointsArchive
  },
  mutations: {
    setServicePoints (state, payload) {
      state.servicePoints = payload
    },
    setServicePointsData (state, { items, page, totalPages, totalItems }) {
      state.servicePoints = items
      state.servicePointsPage = page
      state.servicePointsTotalPages = totalPages
      state.totalServicePointsNumber = totalItems
    },
    setServicePointsPage (state, payload) {
      state.servicePointsPage = payload
    },
    setServicePointsTotalPages (state, payload) {
      state.servicePointsTotalPages = payload
    },
    setTotalServicePointsNumber (state, payload) {
      state.totalServicePointsNumber = payload
    },
    saveServicePointsInArchive (state, { id, totalItems, items }) {
      state.servicePointsArchive[id] = { totalItems, items }
    },
    setServicePoint (state, payload) {
      state.servicePoint = payload
      state.cleanServicePoint = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanServicePoint (state, payload) {
      state.cleanServicePoint = payload
    },
    setNewServicePoint (state) {
      state.servicePoint = {}
    },
    updateServicePoint (state, payload) {
      state.servicePoint = {
        ...state.servicePoint,
        ...payload
      }
    },
    startServicePointsLoading (state) {
      state.servicePointsLoading = true
    },
    stopServicePointsLoading (state) {
      state.servicePointsLoading = false
    },
    addCachedServicePoints (state, { query, data }) {
      state.cachedServicePoints[query] = data
    },
    removeCachedServicePoints (state, payload) {
      state.cachedServicePoints[payload] = undefined
    }
  },
  actions: {
    loadServicePoints ({ commit }, payload = {}) {
      const query = mergeQuery(payload, window.appOptions.servicePoints)
      return services.servicePoint.getAll(query)
        .then(data => {
          commit('setServicePointsData', data)
          return data
        })
    },
    loadServicePoint ({ commit }, payload) {
      return services.servicePoint.get(payload)
        .then(item => {
          commit('setServicePoint', item)
          return item
        })
    },
    saveServicePoint ({ commit, state }) {
      const data = state.servicePoint.id
        ? convertEmbedded(difference(state.servicePoint, state.cleanServicePoint))
        : convertEmbedded(state.servicePoint)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.servicePoint)
      }

      const header = state.servicePoint.id && state.servicePoint.updated
        ? { 'X-Entity-Updated': state.servicePoint.updated }
        : {}

      return services.servicePoint.save(data, state.servicePoint.id, header)
    }
  }
}
