import _ from 'lodash'
import services from '../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    consumptions: [],
    consumptionsLoading: false,
    consumption: null,
    cleanConsumption: null,
    totalConsumptionsNumber: 0,
    consumptionsPage: 1,
    consumptionsTotalPages: 1
  },
  getters: {
    consumptions: state => state.consumptions,
    consumptionsLoading: state => state.consumptionsLoading,
    consumption: state => state.consumption,
    cleanConsumption: state => state.cleanConsumption,
    totalConsumptionsNumber: state => state.totalConsumptionsNumber,
    consumptionsPage: state => state.consumptionsPage,
    consumptionsTotalPages: state => state.consumptionsTotalPages
  },
  mutations: {
    setConsumption (state, payload) {
      state.consumption = payload
      state.cleanConsumption = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setNewConsumption (state) {
      state.consumption = {
        comment: '',
        _embedded: {
          deliveryService: null,
          sender: null
        }
      }
    },
    updateConsumption (state, payload) {
      state.consumption = {
        ...state.consumption,
        ...payload
      }
    },
    updateConsumptionEmbedded (state, payload) {
      state.consumption._embedded = {
        ...state.consumption._embedded,
        ...payload
      }
    },
    setCleanConsumption (state, payload) {
      state.cleanConsumption = payload
    },
    setConsumptionsData (state, { items, page, totalPages, totalItems }) {
      state.consumptions = items
      state.totalConsumptionsNumber = totalItems
      state.consumptionsPage = page
      state.consumptionsTotalPages = totalPages
    },
    setConsumptions (state, payload) {
      state.consumptions = payload
    },
    setTotalConsumptionsNumber (state, payload) {
      state.totalConsumptionsNumber = payload
    },
    setConsumptionsPage (state, payload) {
      state.consumptionsPage = payload
    },
    setConsumptionsTotalPages (state, payload) {
      state.consumptionsTotalPages = payload
    },
    startConsumptionLoading (state) {
      state.consumptionsLoading = true
    },
    stopConsumptionLoading (state) {
      state.consumptionsLoading = false
    },
    upsertConsumption (state, payload) {
      let isExist = false

      state.consumptions = state.consumptions.map(x => {
        if (x.id === payload.id) {
          isExist = true
          return payload
        }

        return x
      })

      if (!isExist) {
        state.consumptions.unshift(payload)
      }
    }
  },
  actions: {
    loadConsumptions ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.consumptions)
      return services.deliveryServiceConsumption.getAll(query)
        .then(data => {
          commit('setConsumptionsData', data)
          return data
        })
    },
    loadConsumptionDocument ({ commit }, payload) {
      return services.deliveryServiceConsumption.getPDF(payload)
        .then(response => {
          commit('stopLoading')
          response.text().then(data => {
            if (response.headers.get('Content-Type') === 'application/pdf') {
              try {
                if (data) {
                  data = JSON.parse(data)
                }
              } catch (error) {
                return Promise.reject(error)
              }
            }

            services.printer.createWindow(data, true)
          })
        })
        .catch(err => {
          commit('addErrorNotification', err)
          commit('stopLoading')
        })
    },
    saveConsumption ({ commit, state }) {
      const ignoredFields = ['creator', 'deliveryService', 'sender']
      const data = state.consumption.id
        ? convertEmbedded(difference(state.consumption, state.cleanConsumption), ignoredFields)
        : convertEmbedded(state.consumption)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.consumption)
      }

      const header = state.consumption.id && state.consumption.updated
        ? { 'X-Entity-Updated': state.consumption.updated }
        : {}

      return services.deliveryServiceConsumption.save(data, state.consumption.id, header)
    },
    saveConsumptionByPayload (_, payload) {
      return services.deliveryServiceConsumption.save(payload.data, payload.id, payload.header)
    },
    loadConsumption ({ commit }, payload) {
      return services.deliveryServiceConsumption.get(payload)
        .then(item => {
          commit('setConsumption', item)
          return item
        })
    }
  }
}
