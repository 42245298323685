import { factory } from './../factory'
import { offersService } from './../requests/offers.service'


class OfferImportService {
  _factory
  _offersService

  _offerExample

  constructor (
    factory,
    offersService
  ) {
    this._factory = factory
    this._offersService = offersService
    this._offerExample = [
      ['ExtId', 'Shop*', 'Name*', 'Image', 'Article', 'Sku', 'Price','purchasingPrice', 'Barcode', 'Type*', 'Weight', 'X', 'Y', 'Z']
    ]
  }

  getOfferExample () {
    return this._factory.createTableFile(this._offerExample)
  }

  _createOfferRows (data) {
    return data.map(item => {
      return Object.keys(item).reduce((acc, key) => {
        const actions = {
          type: () => {
            acc.type = (item[key] || '').toLowerCase()
          },
          extid: () => {
            acc.extId = item[key]
          },
          barcode: () => {
            if (!acc.barcodes) {
              acc.barcodes = []
            }

            acc.barcodes.push(item[key])
          },
          x: () => {
            acc.dimensions.x = item[key]
          },
          y: () => {
            acc.dimensions.y = item[key]
          },
          z: () => {
            acc.dimensions.z = item[key]
          }
        }

        let realKey
        if(key !== 'purchasingPrice') {
          realKey = key.toLowerCase()
        } else {
          realKey = key
        }


        if (realKey.endsWith('*')) {
          realKey = realKey.slice(0, realKey.length - 1)
        }

        if (actions[realKey]) {
          actions[realKey]()
          return acc
        }

        return { ...acc, [realKey]: item[key] }
      }, { dimensions: { x: 0, z: 0, y: 0 } })
    })
  }

  getOffers (rows) {
    return this._createOfferRows(rows)
  }

  loadFile (file) {
    return new Promise((resolve, reject) => {
      const reader = this._factory.createFileReader()

      reader.onload = e => {
        const data = e.target.result
        const table = this._factory.createTable(data)

        if (!table.SheetNames[0]) {
          reject(new Error('Invalid table!'))
          return
        }

        const rows = this._factory.createTableArray(table.Sheets[table.SheetNames[0]])

        resolve(this.getOffers(rows))
      }

      reader.readAsBinaryString(file)
    })
  }

  save (row, middleware) {
    row.price = Number(row.price || 0).toFixed(2)

    if (!row.extId) {
      row.extId = Math.floor(Math.random() * (9999999999) + 1)
    }

    return this._offersService.save(row)
      .then(item => {
        if (middleware) {
          middleware(item)
        }

        return item
      })
  }

  processQueueWithItems (saveFn, items, middleware, results = [], errors = []) {
    if (items.length <= 0) {
      return Promise.resolve({ results, errors })
    }

    return saveFn(items[0], middleware)
      .then((result) => {
        results.push(result)
        return this.processQueueWithItems(saveFn, items.slice(1), middleware, results, errors)
      })
      .catch(err => {
        errors.push(err.message)
        return this.processQueueWithItems(saveFn, items.slice(1), middleware, results, errors)
      })
  }

  execute (items, middleware) {
    return this.processQueueWithItems(this.save.bind(this), items, middleware)
  }
}

export const offerImportService = new OfferImportService(
  factory,
  offersService
)
