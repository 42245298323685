import _ from 'lodash'
import services from '../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    automationRule: null,
    cleanAutomationRule: null,
    automationRules: [],
    automationRulesLoading: false,
    automationRulesNumber: 0,
    automationRulesPage: 1,
    automationRulesTotalPages: 1
  },
  getters: {
    automationRule: state => state.automationRule,
    cleanAutomationRule: state => state.cleanAutomationRule,
    automationRules: state => state.automationRules,
    automationRulesLoading: state => state.automationRulesLoading,
    automationRulesNumber: state => state.automationRulesNumber,
    automationRulesPage: state => state.automationRulesPage,
    automationRulesTotalPages: state => state.automationRulesTotalPages
  },
  mutations: {
    setAutomationRule (state, payload) {
      state.automationRule = payload

      state.cleanAutomationRule = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanAutomationRule (state, payload) {
      state.cleanAutomationRule = payload
    },
    updateAutomationRule (state, payload) {
      state.automationRule = {
        ...state.automationRule,
        ...payload
      }
    },
    setAutomationRulesData (state, { items, page, totalPages, totalItems }) {
      state.automationRules = items
      state.automationRulesNumber = totalItems
      state.automationRulesPage = page
      state.automationRulesTotalPages = totalPages
    },
    setAutomationRules (state, payload) {
      state.automationRules = payload
    },
    setTotalAutomationRulesNumber (state, payload) {
      state.automationRulesNumber = payload
    },
    setAutomationRulesPage (state, payload) {
      state.automationRulesPage = payload
    },
    setAutomationRulesTotalPages (state, payload) {
      state.automationRulesTotalPages = payload
    },
    startAutomationRulesLoading(state) {
      state.automationRulesLoading = true
    },
    stopAutomationRulesLoading (state) {
      state.automationRulesLoading = false
    },
    upsertAutomationRule (state, payload) {
      let isExist = false

      state.automationRules = state.automationRules.map(x => {
        if (x.id === payload.id) {
          isExist = true
          return payload
        }

        return x
      })

      if (!isExist) {
        state.automationRules.unshift(payload)
      }
    }
  },
  actions: {
    loadAutomationRules ({ commit }, payload) {

      const query = mergeQuery(payload, window.appOptions.automationRules)

      return services.automationRules.getAll(query)
        .then(data => {
          commit('setAutomationRulesData', data)
          return data
        })
    },
    loadAutomationRule ({ commit }, payload) {
      return services.automationRules.get(payload)
        .then(item => {
          commit('setAutomationRule', item)
          return item
        })
    },
    saveAutomationRule ({ commit, state }) {
      const data = state.automationRule.id
        ? convertEmbedded(difference(state.automationRule, state.cleanAutomationRule))
        : convertEmbedded(state.automationRules)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.automationRule)
      }

      const header = state.automationRule.id && state.automationRule.updated
        ? { 'X-Entity-Updated': state.automationRule.updated }
        : {}

      return services.automationRules.save(data, state.automationRule.id, header)
    }
  }
}
