import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    sourceIntegrations: [],
    sourceIntegration: null,
    cleanSourceIntegration: null,
    totalSourceIntegrationsNumber: 0,
    sourceIntegrationsPage: 1,
    sourceIntegrationsTotalPages: 1,
    sourceIntegrationsLoading: false
  },
  getters: {
    sourceIntegrations: state => state.sourceIntegrations,
    sourceIntegration: state => state.sourceIntegration,
    cleanSourceIntegration: state => state.cleanSourceIntegration,
    totalSourceIntegrationsNumber: state => state.totalSourceIntegrationsNumber,
    sourceIntegrationsPage: state => state.sourceIntegrationsPage,
    sourceIntegrationsTotalPages: state => state.sourceIntegrationsTotalPages,
    sourceIntegrationsLoading: state => state.sourceIntegrationsLoading
  },
  mutations: {
    setSourceIntegration (state, payload) {
      state.sourceIntegration = payload
      state.cleanSourceIntegration = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanSourceIntegration (state, payload) {
      state.cleanSourceIntegration = payload
    },
    setSourceIntegrationsData (state, { items, page, totalPages, totalItems }) {
      state.sourceIntegrations = items
      state.totalSourceIntegrationsNumber = totalItems
      state.sourceIntegrationsPage = page
      state.sourceIntegrationsTotalPages = totalPages
    },
    setSourceIntegrations (state, payload) {
      state.sourceIntegrations = payload
    },
    setTotalSourceIntegrationsNumber (state, payload) {
      state.totalSourceIntegrationsNumber = payload
    },
    setSourceIntegrationsPage (state, payload) {
      state.sourceIntegrationsPage = payload
    },
    setSourceIntegrationsTotalPages (state, payload) {
      state.sourceIntegrationsTotalPages = payload
    },
    setNewSourceIntegration (state) {
      state.sourceIntegration = {
        name: '',
        _embedded: {
          owner: null
        }
      }
    },
    updateSourceIntegration (state, payload) {
      state.sourceIntegration = {
        ...state.SourceIntegration,
        ...payload
      }
    },
    startSourceIntegrationsLoading (state) {
      state.sourceIntegrationsLoading = true
    },
    stopSourceIntegrationsLoading (state) {
      state.sourceIntegrationsLoading = false
    }
  },
  actions: {
    loadSourceIntegrations ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.sourceIntegrations)
      return services.iIntegration.getAll(query)
        .then(data => {
          commit('setSourceIntegrationsData', data)
          return data
        })
    },
    loadSourceIntegration ({ commit }, payload) {
      return services.iIntegration.get(payload)
        .then(item => {
          commit('setSourceIntegration', item)
          return item
        })
    },
    saveSourceIntegration ({ commit, state }) {
      const data = state.sourceIntegration.id
        ? convertEmbedded(difference(state.sourceIntegration, state.cleanSourceIntegration))
        : convertEmbedded(state.sourceIntegration)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.sourceIntegration)
      }

      const header = state.sourceIntegration.id && state.sourceIntegration.updated
        ? { 'X-Entity-Updated': state.sourceIntegration.updated }
        : {}

      return services.iIntegration.save(data, state.sourceIntegration.id, header)
    }
  }
}
