import { HALApiService } from './hal-api.service'
import { createItemsData, extractAcceptanceItems } from './../utils/request.utils'
import { Service } from './base-service'

class AcceptanceService extends Service {
  _itemsMiddleware

  constructor (httpClient, middleware, itemsMiddleware) {
    super ('/storage/movements/document', httpClient, middleware)

    this._itemsMiddleware = itemsMiddleware
  }

  /**
   * @param {object} data
   * @param {String} id
   * @param {object} query
   *
   * @returns {Promise<object>}
   */
  put (data, id, query) {
    return this._httpClient.put(`${this._url}/${id}`, data, 'application/json+offers', query).then(this.middleware)
  }

  /**
   * @param {object} data
   * @param {String} id
   *
   * @returns {Promise<object>}
   */
  post (data, id) {
    return this._httpClient.post(`${this._url}/${id}`, data, 'application/json+offers').then(this._itemsMiddleware)
  }

  /**
   * @param {object} data
   * @param {String} id
   * @param {object} query
   *
   * @returns {Promise<object>}
   */
  patch (data, id, query, contentType = 'application/json') {
    return this._httpClient.patch(`${this._url}/${id}`, data, 'application/json+offers', undefined, { 'Content-Type': contentType }, query).then(this.middleware)
  }

  /**
   * @param {String} id
   *
   * @returns {Promise<object>}
   */
  getItems (id) {
    const query = {
      group: [
        { field: 'productOffer' },
        { field: 'state' },
        { field: 'sku' },
        { field: 'dimensions' }
      ]
    }

    return this._httpClient.get(`${this._url}/${id}`, query, 'application/json+items')
      .then(this._middleware)
      .then(({ items }) => {
        return Object.values(
          items.reduce((acc, item) => {
            const key = `${item._embedded.productOffer.id}/${item.sku}`

            if (!acc[key]) {
              acc[key] = {
                ...item._embedded.productOffer,
                quantity: 0,
                expected: 0,
                sku: item.sku,
                _embedded: item._embedded
              }
            }

            acc[key].quantity += item.count

            if (item.dimensions) {
              acc[key].dimensions = item.dimensions
            }

            if (!acc[key].dimensions || typeof acc[key].dimensions === 'string') {
              acc[key].dimensions = {
                x: 1,
                y: 1,
                z: 1
              }
            }

            if (item.state === 'expected') {
              acc[key].expected += item.count
            }

            return acc
          }, {})
        )
      })
  }
}

export const acceptanceService = new AcceptanceService(HALApiService, createItemsData, extractAcceptanceItems)
