import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    sender: null,
    cleanSender: null,
    senders: [],
    totalSendersNumber: 0,
    sendersTotalPages: 1,
    sendersPage: 1,
    sendersLoading: false
  },
  getters: {
    sender: state => state.sender,
    cleanSender: state => state.cleanSender,
    senders: state => state.senders,
    sendersLoading: state => state.sendersLoading,
    totalSendersNumber: state => state.totalSendersNumber,
    sendersTotalPages: state => state.sendersTotalPages,
    sendersPage: state => state.sendersPage
  },
  mutations: {
    setSender (state, payload) {
      state.sender = payload
      state.cleanSender = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    updateSender (state, payload) {
      state.sender = {
        ...state.sender,
        ...payload
      }
    },
    updateSenderEmbedded (state, payload) {
      state.sender._embedded = {
        ...state.sender._embedded,
        ...payload
      }
    },
    setNewSender (state) {
      state.sender = {
        name: null,
        _embedded: {
        }
      }
    },
    setCleanSender (state, payload) {
      state.cleanSender = payload
    },
    setSendersData (state, { items, page, totalPages, totalItems }) {
      state.senders = items
      state.sendersPage = page
      state.sendersTotalPages = totalPages
      state.totalSendersNumber = totalItems
    },
    setSenders (state, payload) {
      state.senders = payload
    },
    setSendersPage (state, payload) {
      state.sendersPage = payload
    },
    setSendersTotalPages (state, payload) {
      state.sendersTotalPages = payload
    },
    setTotalSendersNumber (state, payload) {
      state.totalSendersNumber = payload
    },
    startSendersLoading (state) {
      state.sendersLoading = true
    },
    stopSendersLoading (state) {
      state.sendersLoading = false
    }
  },
  actions: {
    loadSenders ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.senders)
      return services.sender.getAll(query)
        .then(data => {
          commit('setSendersData', data)
          return data
        })
    },
    loadSender ({ commit }, payload) {
      return services.sender.get(payload)
        .then(item => {
          commit('setSender', item)
          return item
        })
    },
    saveSender ({ commit, state }) {
      const ignoredFields = ['defaultSenderProfile', 'owner', 'parent']
      const data = state.sender.id
        ? convertEmbedded(difference(state.sender, state.cleanSender), ignoredFields)
        : convertEmbedded(state.sender)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.sender)
      }

      const header = state.sender.id && state.sender.updated
        ? { 'X-Entity-Updated': state.sender.updated }
        : {}

      return services.sender.save(data, state.sender.id, header)
    }
  }
}
