import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    phone: null,
    cleanPhone: null,
    phones: [],
    totalPhonesNumber: 0,
    phonesPage: 1,
    phonesTotalPages: 1,
    phonesLoading: false
  },
  getters: {
    phone: state => state.phone,
    cleanPhone: state => state.cleanPhone,
    phones: state => state.phones,
    totalPhonesNumber: state => state.totalPhonesNumber,
    phonesPage: state => state.phonesPage,
    phonesTotalPages: state => state.phonesTotalPages,
    phonesLoading: state => state.phonesLoading
  },
  mutations: {
    setPhone (state, payload) {
      state.phone = payload
      state.cleanPhone = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanPhone (state, payload) {
      state.cleanPhone = payload
    },
    setNewPhone (state) {
      state.phone = {}
    },
    updatePhone (state, payload) {
      state.phone = {
        ...state.phone,
        ...payload
      }
    },
    setPhonesData (state, { items, page, totalPages, totalItems }) {
      state.totalPhonesNumber = totalItems
      state.phones = items
      state.phonesPage = page
      state.phonesTotalPages = totalPages
    },
    setPhones (state, payload) {
      state.phones = payload
    },
    setTotalPhonesNumber (state, payload) {
      state.totalPhonesNumber = payload
    },
    setPhonesPage (state, payload) {
      state.phonesPage = payload
    },
    setPhonesTotalPages (state, payload) {
      state.phonesTotalPages = payload
    },
    startPhoneLoading (state) {
      state.phonesLoading = true
    },
    stopPhoneLoading (state) {
      state.phonesLoading = false
    }
  },
  actions: {
    loadPhones ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.phones)
      return services.phone.getAll(query)
        .then(data => {
          commit('setPhonesData', data)
          return data
        })
    },
    loadPhone ({ commit }, payload) {
      return services.phone.get(payload)
        .then(item => {
          commit('setPhone', item)
          return item
        })
    },
    savePhone ({ commit, state }) {
      const data = state.phone.id
        ? convertEmbedded(difference(state.phone, state.cleanPhone))
        : convertEmbedded(state.phone)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.phone)
      }

      const header = state.phone.id
        ? { 'X-Entity-Updated': state.phone.updated }
        : {}

      return services.phone.save(data, state.phone.id, header)
    }
  }
}
