<script>
import container from './container'
import { watch } from 'vue'

export default {
  name: 'Portal',
  props: {
    to: {
      type: String,
      default () {
        return ''
      }
    }
  },
  mounted () {
    container.submitVM(this.to, this.$slots.default())
    watch(this.$parent.$data, () => {
      container.submitVM(this.to, this.$slots.default())
    })

    watch(this.$parent.$props, () => {
      container.submitVM(this.to, this.$slots.default())
    })
  },
  unmounted () {
    container.submitVM(this.to, [])
  },
  render () {
    return null
  }
}
</script>
