class CacheData {
  value
  expirationTime

  get isExpired () {
    return new Date().getTime() > this.expirationTime
  }

  constructor (data, expirationTime) {
    this.value = data

    // 2 minutes expire time
    this.expirationTime = new Date().getTime() + (expirationTime || 120000)
  }
}

export class Cache {
  entities
  _isStartedCleaning
  _timeout

  static create () {
    return new Cache()
  }

  static createData (value, expirationTime) {
    return new CacheData(value, expirationTime) 
  }

  constructor () {
    this.entities = {}
    this._isStartedCleaning = false
  }

  _startCleaning () {
    if (this._isStartedCleaning) {
      return
    }

    this._isStartedCleaning = true

    this._timeout = setTimeout(() => {
      this.entities = Object.entries(this.entities).reduce((acc, [entityClass, values]) => {
        acc[entityClass] = Object.entries(values).reduce((innerAcc, [url, cacheData]) => {
          if (!cacheData.isExpired) {
            innerAcc[url] = cacheData
          }

          return innerAcc
        }, {})

        return acc
      }, {})

      this._isStartedCleaning = false
      this._timeout = null
    }, 120000)
  }

  destroy () {
    if (this._timeout) {
      clearTimeout(this._timeout)
    }
  }

  dispatchValues (entityClass, url, data, expirationTime) {
    if (!this.entities[entityClass]) {
      this.entities[entityClass] = {}
    }

    this.entities[entityClass][url] = Cache.createData(data, expirationTime)
    this._startCleaning()
    return this.entities[entityClass][url]
  }

  dispatchValue (entityClass, data) {
    if (!this.entities[entityClass]) {
      return {}
    }

    Object.values(this.entities[entityClass]).forEach(cacheData => {
      cacheData.value.items = cacheData.value.items.map(x => {
        return x.id == data.id
          ? data
          : x
      })
    })

    this._startCleaning()
    return this.entities[entityClass]
  }

  getValues (entityClass, url) {
    if (!this.entities[entityClass] || !this.entities[entityClass][url]) {
      return { isExpired: true, value: { items: [] } }
    }

    this._startCleaning()
    return this.entities[entityClass][url]
  }

  getValue (entityClass, id) {
    this._startCleaning()

    const values = Object.values(this.entities[entityClass] || {})

    for (let i = 0; i < values.length; i++) {
      const cacheData = values[i]

      const item = cacheData.value.items.find(x => x.id == id)

      if (item) {
        return item
      }
    }

    return null
  }

  clearAll () {
    this.entities = {}
    if (this._timeout) {
      clearTimeout(this._timeout)
    }
  }
}

const CachePlugin = {
  install (app) {
    app.config.globalProperties.$cache = new Cache()
  }
}

export default CachePlugin
