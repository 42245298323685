import { HALApiService } from '../hal-api.service'
import { createItemsData } from './../../helpers/request-helpers'
import { Service } from './base-service'

class DSPlaceService {
  httpClient
  url
  itemsUrl
  middleware

  constructor (httpClient, middleware) {
    this.url = '/delivery-services/places'
    this.itemsUrl = '/delivery-services/place-items'
    this.httpClient = httpClient
    this.middleware = middleware
  }

  /**
   * @param {String | Number} id
   *
   * @returns {Promise<object>}
   */
  get (id, get, accept) {
    return this.httpClient.get(`${this.url}/${id}`, get, accept)
  }

  getLabel (id) {
    return this.get(id, {}, 'application/pdf,text/html')
  }

  /**
   * @param {object} query
   *
   * @returns {Promise<object>}
   */
  getAll (query) {
    return this.httpClient.get(this.url, query).then(this.middleware)
  }

  /**
   * @param {object} data
   * @param {String | Number | undefined} id
   * @param {object | undefined} headers
   *
   * @returns {Promise<object>}
   */
  save (data, id, headers) {
    return this.httpClient.saveRequest(this.url, data, id, undefined, undefined, headers)
  }

  /**
 * @param {Array} data
 *
 * @returns {Promise<Array>}
 */
  put (data) {
    return this.httpClient.put(this.itemsUrl, data)
  }

  /**
 * @param {Array} data
 *
 * @returns {Promise<Array>}
 */
  putMany (data) {
    return Promise.all(data.map(x => this.put(x)))
  }
}

export const dsPlaceService = new DSPlaceService(HALApiService, createItemsData)

class DSPlaceItemsService extends Service {
  constructor (httpClient, middleware) {
    super('/delivery-services/place-items', httpClient, middleware)
  }

/**
 * @param {Array} data
 *
 * @returns {Promise<Array>}
 */
  put (data) {
    return this.httpClient.put(this._url, data)
  }
}

export const dsPlaceItemsService = new DSPlaceItemsService(HALApiService, createItemsData)
