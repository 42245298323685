import { Centrifuge } from 'centrifuge'

let centrifuge

const WebSocket = {
  install (app, options) {
    app.config.globalProperties.$createSocket = function () {
      let newOptions = typeof options.options === 'function'
        ? options.options()
        : options.options

      centrifuge = new Centrifuge(options.service, newOptions)
      centrifuge.on('disconnect', (context) => {
        console.log('Disconnected:', context.reason);
      });
      // Allocate Subscription to a channel.

      app.config.globalProperties.$centrifuge = centrifuge
      return centrifuge
    }
  }
}

export { centrifuge }
export default WebSocket
