import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    invoices: [],
    invoice: null,
    cleanInvoice: null,
    totalInvoicesNumber: 0,
    invoicesPage: 1,
    invoicesTotalPages: 1,
    invoicesLoading: false
  },
  getters: {
    invoices: state => state.invoices,
    invoice: state => state.invoice,
    cleanInvoice: state => state.cleanInvoice,
    totalInvoicesNumber: state => state.totalInvoicesNumber,
    invoicesPage: state => state.invoicesPage,
    invoicesTotalPages: state => state.invoicesTotalPages,
    invoicesLoading: state => state.invoicesLoading
  },
  mutations: {
    setInvoice (state, payload) {
      state.invoice = payload
      state.cleanInvoice = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setNewInvoice (state) {
      state.invoice = {
        value: '0.00',
        comment: null,
        paymentTerm: null,
        paymentDate: null,
        paymentOrderNumber: null,
        paymentOrderDate: null,
        conditionalPaymentDocument: null,
        conditionalPaymentTerm: null,
        moderatorComment: null,
        _embedded: {
          account: null,
          payer: null,
          payee: null
        }
      }
    },
    updateInvoice (state, payload) {
      state.invoice = {
        ...state.invoice,
        ...payload
      }
    },
    updateInvoiceEmbedded (state, payload) {
      state.invoice = {
        ...state.invoice,
        _embedded: {
          ...state.invoice._embedded,
          ...payload
        }
      }
    },
    setCleanInvoice (state, payload) {
      state.cleanInvoice = payload
    },
    setInvoicesData (state, { items, page, totalPages, totalItems }) {
      state.invoices = items
      state.totalInvoicesNumber = totalItems
      state.invoicesPage = page
      state.invoicesTotalPages = totalPages
    },
    setInvoices (state, payload) {
      state.invoices = payload
    },
    setTotalInvoicesNumber (state, payload) {
      state.totalInvoicesNumber = payload
    },
    setInvoicesPage (state, payload) {
      state.invoicesPage = payload
    },
    setInvoicesTotalPages (state, payload) {
      state.invoicesTotalPages = payload
    },
    startInvoiceLoading (state) {
      state.invoicesLoading = true
    },
    stopInvoiceLoading (state) {
      state.invoicesLoading = false
    },
    removeInvoice (state, payload) {
      state.invoices = state.invoices.filter(x => x.id !== payload.id)
    },
    upsertInvoice (state, payload) {
      let isExist = false

      state.invoices = state.invoices.map(x => {
        if (x.id === payload.id) {
          isExist = true
          return payload
        }

        return x
      })

      if (!isExist) {
        state.invoices.unshift(payload)
      }
    }
  },
  actions: {
    loadInvoices ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.invoice)
      return services.invoice.getAll(query)
        .then(data => {
          commit('setInvoicesData', data)
          return data
        })
    },
    loadInvoice ({ commit }, payload) {
      return services.invoice.get(payload)
        .then(item => {
          commit('setInvoice', item)
          return item
        })
    },
    saveInvoice ({ commit, state }) {
      const data = state.invoice.id
        ? convertEmbedded(difference(state.invoice, state.cleanInvoice))
        : convertEmbedded(state.invoice)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.invoice)
      }

      if (data.value) {
        data.value = parseFloat(data.value)
      }

      const header = state.invoice.id && state.invoice.updated
        ? { 'X-Entity-Updated': state.invoice.updated }
        : {}
        console.log(data)

      return services.invoice.save(data, state.invoice.id, header)
    }
  }
}
