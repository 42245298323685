export class FiedUtil {
  _serviceFactory;
  _lodash;

  constructor (factory, lodash) {
    this._serviceFactory = factory
    this._lodash = lodash

    this.createTextInput = this.createTextInput.bind(this)
    this.createNumberInput = this.createNumberInput.bind(this)
    this.createCheckbox = this.createCheckbox.bind(this)
    this.createSelectField = this.createSelectField.bind(this)
    this.createDateField = this.createDateField.bind(this)
    this.createQSelectField = this.createQSelectField.bind(this)
  }

  /**
   * @param {object} data
   *
   * @returns {string}
   */
  _getLabel (data) {
    if (data.label) {
      return data.label
    }

    if (data.options && data.options.label) {
      return data.options.label
    }

    return ''
  }

  /**
   * @param {object} data
   *
   * @returns {object}
   */
  createTextInput (data) {
    return {
      type: 'input',
      inputType: data.inputType || 'text',
      field: data.name,
      label: this._getLabel(data),
      placeholder: data.placeholder,
      required: !!data.required,
      wrapperStyleClasses: data.wrapperStyleClasses || 'col-12 col-md-6 q-pa-xs'
    }
  }

  /**
   * @param {object} data
   *
   * @returns {object}
   */
  createNumberInput (data) {
    return this.createTextInput({ ...data, inputType: 'number' })
  }

  /**
   * @param {object} data
   *
   * @returns {object}
   */
  createCheckbox (data) {
    return {
      type: 'switch',
      model: data.name,
      label: this._getLabel(data),
      required: !!data.required,
      wrapperStyleClasses: data.wrapperStyleClasses || 'col-12 col-md-6 q-pa-xs'
    }
  }

  /**
   * @param {object} data
   *
   * @returns {object}
   */
  createSelectField (data) {
    const field = {
      type: 'multiselect',
      wrapperStyleClasses: data.wrapperStyleClasses || 'col-12 col-md-6 q-pa-xs',
      field: data.name,
      label: this._getLabel(data),
      required: !!data.require,
      customLabel (row) {
        if (row && typeof row === 'object') {
          return `${row.name} (${row.id})`
        }

        return JSON.stringify(row)
      },
      onScroll: (search, page) => {
        const query = {
          per_page: 25,
          page,
          search
        }

        return this._serviceFactory(data.api.url).getAll(query)
      }
    }

    return field
  }

  /**
   * @param {object} data
   *
   * @returns {object}
   */
  createDateField (data) {
    return {
      type: 'date',
      field: data.name,
      label: this._getLabel(data),
      placeholder: data.placeholder,
      required: !!data.require,
      wrapperStyleClasses: data.wrapperStyleClasses || 'col-12 col-md-6 q-pa-xs'
    }
  }

  /**
   * @param {any} options
   *
   * @returns {array}
   */
  _getOptions (options) {
    if (Array.isArray(options)) {
      return options
    }

    if (options && typeof options === 'object') {
      if (options.values) {
        return options.values
      }

      return Object.entities(options).reduce((acc, value) => {
        // First value is id/key
        // Skip if it miss
        if (!value[0]) {
          return acc
        }

        acc.push({ id: value[0], name: value[1] })

        return acc
      })
    }

    return []
  }

  /**
   * @param {object} data
   *
   * @returns {object}
  */
  createQSelectField (data = {}) {
    return {
      type: 'select',
      label: this._getLabel(data),
      field: data.name,
      wrapperStyleClasses: data.wrapperStyleClasses || 'col-12 col-md-6 q-pa-xs',
      options: this._getOptions(data.values || data.options),
      required: !!data.require,
      customLabel (row) {
        return row && typeof row === 'object'
          ? row.name || row.title
          : row
      }
    }
  }

  /**
   * @param {object} data
   *
   * @returns {object}
   */
  create (model, data) {
    const types = {
      'Laminas\\Form\\Element\\Text': this.createTextInput,
      'Laminas\\Form\\Element\\Number': this.createNumberInput,
      'Laminas\\Form\\Element\\Url': this.createTextInput,
      'DoctrineModule\\Form\\Element\\ObjectSelect': this.createTextInput,
      'Zend\\Form\\Element\\Url': this.createTextInput,
      'Laminas\\Form\\Element\\Checkbox': this.createCheckbox,
      'Application\\Form\\Element\\Select2': this.createSelectField,
      'Laminas\\Form\\Element\\Select': this.createQSelectField,
      'Laminas\\Form\\Element\\Date': this.createDateField,
      'Orderadmin\\Application\\Form\\Element\\ParamsMatrix': this.createTextInput,
      'Orderadmin\\Application\\Form\\Element\\JsonEditor': this.createTextInput
    }

    if (!types[data.type]) {
      throw new Error(`Field is not recognized -> ${JSON.stringify(data)}`)
    }

    const field = types[data.type](data)

    field.value = this._lodash.get(model, data.name)

    if (field.type === 'switch') {
      field.value = !!field.value
    }
    
    field.onChange = (value) => {
      const val = value && typeof value === 'object' && value.id
        ? value.id
        : value

      this._lodash.updateWith(model, data.name, () => val)

      typeof field.watch === 'function' && field.watch(value)
      return value
    }

    return field
  }
}
