import { HALApiService } from './../hal-api.service'
import { createItemsData } from './../../helpers/request-helpers'
import { Service } from './base-service'

class SourceService extends Service {
  _installUrl;
  _specificIntegrations;

  constructor (httpClient, middleware) {
    super('/integrations/sources', httpClient, middleware)
    this._installUrl = '/apps/shopify/install?app=orderadmin-app'

    // Adapter for specific integrations
    this._specificIntegrations = {
      121: this.installShopify
    }
  }

  /**
   * @param {object} data
   *
   * @returns {Promise<object>}
   */
  installShopify (data) {
    const source = {
      ...data,
      owner: JSON.parse(localStorage.getItem('userData')).id
    }

    return this._httpClient.post(this._installUrl, source, undefined, undefined, '')
      .then(result => {
        const url = data.store.includes('http://') || data.store.includes('https://')
          ? data.store
          : `https://${data.store}`

        const shopifyURL = `${url}/admin/oauth/authorize?client_id=${result.client_id}&scope=${result.scope}&redirect_uri=https://alpha.orderadmin.eu/apps/shopify/auth`

        window.open(shopifyURL, '_self')

        return result
      })
  }

  /**
   * @param {Number | String} id
   * @param {object} data
   *
   * @returns {object}
   */
  _getData (id, data) {
    if (id === 121) {
      if (data.store) {
        return data
      }

      return { store: data.settings.url }
    }

    return data
  }

  /**
   * @param {String | Number} id
   * @param {object} data
   *
   * @returns {Promise<object>}
   */
  install (id, data) {
    const updatedData = this._getData(id, data)

    return this._specificIntegrations[id]
      ? this._specificIntegrations[id](updatedData)
      : this.save(updatedData)
  }
}

export const sourceService = new SourceService(HALApiService, createItemsData)
