import _ from 'lodash'
import services from '../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    countries: [],
    countriesLoading: false,
    country: null,
    cleanCountry: null,
    totalCountriesNumber: 0,
    countriesPage: 1,
    countriesTotalPages: 1
  },
  getters: {
    countries: state => state.countries,
    countriesLoading: state => state.countriesLoading,
    country: state => state.country,
    cleanCountry: state => state.cleanCountry,
    totalCountriesNumber: state => state.totalCountriesNumber,
    countriesPage: state => state.countriesPage,
    countriesTotalPages: state => state.countriesTotalPages
  },
  mutations: {
    setCountriesData (state, { items, page, totalPages, totalItems }) {
      state.countries = items
      state.totalCountriesNumber = totalItems
      state.countriesPage = page
      state.countriesTotalPages = totalPages
    },
    setCountries (state, payload) {
      state.countries = payload
    },
    setTotalCountriesNumber (state, payload) {
      state.totalCountriesNumber = payload
    },
    setCountriesPage (state, payload) {
      state.countriesPage = payload
    },
    setCountriesTotalPages (state, payload) {
      state.countriesTotalPages = payload
    },
    setCountry (state, payload) {
      state.country = payload
      state.cleanCountry = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanCountry (state, payload) {
      state.cleanCountry = payload
    },
    setNewCountry (state) {
      state.country = {}
    },
    updateCountry (state, payload) {
      state.country = {
        ...state.country,
        ...payload
      }
    },
    startCountriesLoading (state) {
      state.countriesLoading = true
    },
    stopCountriesLoading (state) {
      state.countriesLoading = false
    }
  },
  actions: {
    loadCountries ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.country)
      return services.country.getAll(query)
        .then(data => {
          commit('setCountriesData', data)
          return data
        })
    },
    loadCountry ({ commit }, payload) {
      return services.country.get(payload)
        .then(item => {
          commit('setCountry', item)
          return item
        })
    },
    saveCountry ({ commit, state }) {
      const data = state.country.id
        ? convertEmbedded(difference(state.country, state.cleanCountry))
        : convertEmbedded(state.country)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.country)
      }

      const header = state.country.id && state.country.updated
        ? { 'X-Entity-Updated': state.country.updated }
        : {}

      return services.country.save(data, state.country.id, header)
    }
  }
}
