import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    supply: null,
    cleanSupply: null,
    supplies: [],
    totalSuppliesNumber: 0,
    suppliesTotalPages: 1,
    suppliesPage: 1,
    supplyLoading: false
  },
  getters: {
    supply: state => state.supply,
    cleanSupply: state => state.cleanSupply,
    supplies: state => state.supplies,
    totalSuppliesNumber: state => state.totalSuppliesNumber,
    suppliesTotalPages: state => state.suppliesTotalPages,
    suppliesPage: state => state.suppliesPage,
    supplyLoading: state => state.supplyLoading
  },
  mutations: {
    setSupply (state, payload) {
      state.supply = payload
      state.cleanSupply = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    updateSupply (state, payload) {
      state.supply = {
        ...state.supply,
        ...payload
      }
    },
    updateSupplyEmbedded (state, payload) {
      state.supply._embedded = {
        ...state.supply._embedded,
        ...payload
      }
    },
    setNewSupply (state, payload) {
      const baseState = {
        _embedded: {}
      }

      const types = {
        package: () => ({ ...baseState, type: 'package', code: 'package' ,isPublic: 0}),
        attachment: () => ({ ...baseState, type: 'attachment', code: 'attachment' ,isPublic: 0}),
        shipping: () => ({ ...baseState, type: 'shipping', code: 'shipping' ,isPublic: 0})
      }

      state.supply = types[payload]
        ? types[payload]()
        : types.package()
    },
    setCleanSupply (state, payload) {
      state.cleanSender = payload
    },
    setSuppliesData (state, { items, page, totalPages, totalItems }) {
      state.supplies = items
      state.suppliesPage = page
      state.suppliesTotalPages = totalPages
      state.totalSuppliesNumber = totalItems
    },
    startSuppliesLoading (state) {
      state.supplyLoading = true
    },
    stopSuppliesLoading (state) {
      state.supplyLoading = false
    },
    upsertSupply (state, payload) {
      let isExist = false

      state.supplies = state.supplies.map(x => {
        if (x.id == payload.id) {
          isExist = true
          return payload
        }

        return x
      })

      if (!isExist) {
        state.supplies.unshift(payload)
      }
    }
  },
  actions: {
    loadSupplies ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.senders)

      return services.supply.getAll(query)
        .then(data => {
          commit('setSuppliesData', data)
          return data
        })
    },
    loadSupply ({ commit }, payload) {
      return services.supply.get(payload)
        .then(item => {
          commit('setSupply', item)
          return item
        })
    },
    saveSupply ({ commit, state }) {
      const ignoredFields = ['sender', 'warehouse']
      const data = state.supply.id
        ? convertEmbedded(difference(state.supply, state.cleanSupply), ignoredFields)
        : convertEmbedded(state.supply)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.supply)
      }

      const header = state.supply.id && state.supply.updated
        ? { 'X-Entity-Updated': state.supply.updated }
        : {}

      return services.supply.save(data, state.supply.id, header)
    }
  }
}
