import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    rateModifier: null,
    cleanRateModifier: null,
    rateModifiers: [],
    rateModifiersItemsNumber: 0,
    rateModifiersPage: 1,
    rateModifiersTotalPages: 1,
    rateModifiersLoading: false
  },
  getters: {
    rateModifier: state => state.rateModifier,
    cleanRateModifier: state => state.cleanRateModifier,
    rateModifiers: state => state.rateModifiers,
    rateModifiersItemsNumber: state => state.rateModifiersItemsNumber,
    rateModifiersPage: state => state.rateModifiersPage,
    rateModifiersTotalPages: state => state.rateModifiersTotalPages,
    rateModifiersLoading: state => state.rateModifiersLoading
  },
  mutations: {
    setRateModifiersData (state, { items, page, totalPages, totalItems }) {
      state.rateModifiers = items
      state.rateModifiersTotalPages = totalPages
      state.rateModifiersPage = page
      state.rateModifiersItemsNumber = totalItems
    },
    setRateModifiers (state, payload) {
      state.rateModifiers = payload
    },
    setRateModifiersTotalPages (state, payload) {
      state.rateModifiersTotalPages = payload
    },
    setRateModifiersPage (state, payload) {
      state.rateModifiersPage = payload
    },
    setRateModifiersItemsNumber (state, payload) {
      state.rateModifiersItemsNumber = payload
    },
    setRateModifier (state, payload) {
      state.rateModifier = payload
      state.cleanRateModifier = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanRateModifier (state, payload) {
      state.cleanRateModifier = payload
    },
    setNewRateModifier (state) {
      state.rateModifier = {}
    },
    updateRateModifier (state, payload) {
      state.rateModifier = {
        ...state.rateModifier,
        ...payload
      }
    },
    startRateModifierLoading (state) {
      state.rateModifiersLoading = true
    },
    stopRateModifierLoading (state) {
      state.rateModifiersLoading = false
    }
  },
  actions: {
    loadRateModifiers ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.rates)
      return services.rateModifier.getAll(query)
        .then(data => {
          commit('setRateModifiersData', data)
          return data
        })
    },
    loadRateModifier ({ commit }, payload) {
      return services.rateModifier.get(payload)
        .then(item => {
          commit('setRateModifier', item)
          return item
        })
    },
    saveRateModifier ({ commit, state }) {
      const data = state.rateModifier.id
        ? convertEmbedded(difference(state.rateModifier, state.cleanRateModifier))
        : convertEmbedded(state.rateModifier)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.rateModifier)
      }

      const header = state.rateModifier.id && state.rateModifier.updated
        ? { 'X-Entity-Updated': state.rateModifier.updated }
        : {}

      return services.rateModifier.save(data, state.rateModifier.id, header)
    }
  }
}
