import _ from 'lodash'
import services from '../../../services/services'

import {
    convertEmbedded,
    difference,
    mergeQuery
} from './../../../helpers/request-helpers'

export default {
    state: {
        inventoryDocuments: [],
        inventoryDocumentsLoading: false,
        inventoryDocument: null,
        cleanInventoryDocument: null,
        totalInventoryDocumentsNumber: 0,
        totalInventoryDocumentsPage: 1,
        totalInventoryDocumentsTotalPage: 1
    },
    getters: {
        inventoryDocuments: state => state.inventoryDocuments,
        inventoryDocumentsLoading: state => state.inventoryDocumentsLoading,
        inventoryDocument: state => state.inventoryDocument,
        cleanInventoryDocument: state => state.cleanInventoryDocument,
        totalInventoryDocumentsNumber: state => state.totalInventoryDocumentsNumber,
        totalInventoryDocumentsPage: state => state.totalInventoryDocumentsPage,
        totalInventoryDocumentsTotalPage: state => state.totalInventoryDocumentsTotalPage
    },
    mutations: {
        setInventoryDocument (state, payload) {
            state.inventoryDocument = payload
            state.cleanInventoryDocument = typeof payload === 'object'
                ? _.cloneDeep(payload)
                : payload
        },
        setNewInventoryDocument (state) {
            state.inventoryDocument = {
                comment: '',
                _embedded: {
                }
            }
        },
        updateInventoryDocument (state, payload) {
            state.inventoryDocument = {
                ...state.inventoryDocument,
                ...payload
            }
        },
        updateInventoryDocumentEmbedded (state, payload) {
            state.inventoryDocument._embedded = {
                ...state.inventoryDocument._embedded,
                ...payload
            }
        },
        setCleanInventoryDocument (state, payload) {
            state.cleanInventoryDocument = payload
        },
        setInventoryDocumentsData (state, { items, page, totalPages, totalItems }) {
            state.inventoryDocuments = items
            state.totalInventoryDocumentsNumber = totalItems
            state.totalInventoryDocumentsPage = page
            state.totalInventoryDocumentsTotalPage = totalPages
        },
        setInventoryDocuments (state, payload) {
            state.inventoryDocuments = payload
        },
        setTotalInventoryDocumentsNumber (state, payload) {
            state.totalInventoryDocumentsNumber = payload
        },
        setInventoryDocumentsPage  (state, payload) {
            state.totalInventoryDocumentsPage = payload
        },
        setInventoryDocumentsTotalPage (state, payload) {
            state.totalInventoryDocumentsTotalPage = payload
        },
        startInventoryDocumentsLoading (state) {
            state.inventoryDocumentsLoading = true
        },
        stopInventoryDocumentsLoading (state) {
            state.inventoryDocumentsLoading = false
        },
        upsertInventoryDocuments (state, payload) {
            let isExist = false

            state.inventoryDocuments = state.inventoryDocuments.map(x => {
                if (x.id === payload.id) {
                    isExist = true
                    return payload
                }

                return x
            })

            if (!isExist) {
                state.inventoryDocuments.unshift(payload)
            }
        }
    },
    actions: {
        loadInventoryDocuments({ commit }, payload) {

            const query = mergeQuery(payload)
            return services.storageInventoryDocuments.getAll(query)
                .then(data => {
                    commit('setInventoryDocumentsData', data)
                    return data
                })
        },
        loadInventoryDocumentDocument ({ commit }, payload) {
            return services.storageInventoryDocuments.getPDF(payload)
                .then(response => {
                    commit('stopLoading')
                    response.text().then(data => {
                        if (response.headers.get('Content-Type') === 'application/pdf') {
                            try {
                                if (data) {
                                    data = JSON.parse(data)
                                }
                            } catch (error) {
                                return Promise.reject(error)
                            }
                        }

                        services.printer.createWindow(data, true)
                    })
                })
                .catch(err => {
                    commit('addErrorNotification', err)
                    commit('stopLoading')
                })
        },
        saveInventoryDocument ({ commit, state }) {
            const data = state.inventoryDocument.id
                ? convertEmbedded(difference(state.inventoryDocument, state.cleanInventoryDocument))
                : convertEmbedded(state.inventoryDocument)

            if (Object.keys(data).length === 0) {
                commit('addWarningNotification', 'No changes!')
                return Promise.resolve(state.inventoryDocument)
            }

            const header = state.inventoryDocument.id && state.inventoryDocument.updated
                ? { 'X-Entity-Updated': state.inventoryDocument.updated }
                : {}

            return services.storageInventoryDocuments.save(data, state.inventoryDocument.id, header)
        },
        saveInventoryDocumentByPayload (_, payload) {
            return services.storageInventoryDocuments.save(payload.data, payload.id, payload.header)
        },
        loadInventoryDocument ({ commit }, payload) {
            return services.storageInventoryDocuments.get(payload)
                .then(item => {
                    commit('setInventoryDocument', item)
                    return item
                })
        }
    }
}
