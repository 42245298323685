const LabellingNew = () => import('./../../pages/delivery-manager/LabellingNew')
const OrderAssembling = () => import('../../pages/wms/OrderAssembling')
const CheckOut = () => import('../../pages/wms/CheckOut')
const Return = () => import('../../pages/wms/return/Return')
const Bundling = () => import('../../pages/wms/bundling/Bundling')
const Queues = () => import('./../../pages/delivery-manager/QueuesNew')
const PickingQueues = () => import('../../pages/settings/PickingQueues')
const RevisionQueues = () => import('../../pages/settings/RevisionQueues')
const RevisionQueue = () => import('../../pages/settings/RevisionQueue')
const PickingByOrderQueues = () => import('../../pages/settings/PickingByOrderQueues')
const TasksTasks = () => import('./../../pages/managers/TasksTasks')
const AssemblingQueues = () => import('../../pages/settings/AssemblingQueues')
const AssemblingByOrderQueues = () => import('../../pages/settings/AssemblingByOrderQueues')
const AssemblingTasks = () => import('./../../pages/managers/AssemblingTasks')
const PickingQueue = () => import('../../pages/settings/PickingQueue')
const AssemblingQueue = () => import('../../pages/settings/AssemblingQueue')
const Measuring = () => import('../../pages/delivery-manager/Measuring')
const SortingQueues = () => import('./../../pages/settings/SortingQueues')
const AllocationQueues = () => import('./../../pages/settings/AllocationQueues')
const MeasuringQueues = () => import('./../../pages/settings/MeasuringQueues')
const ReturnQueues = () => import('./../../pages/settings/ReturnQueues')
const BundlingQueues = () => import('./../../pages/settings/BundlingQueues')
const PalletReceivingQueues = () => import('./../../pages/settings/PalletReceivingQueues')
const PickingTotesQueues = () => import('./../../pages/settings/PickingTotesQueues')
const OrderProcessingQueues = () => import('./../../pages/settings/OrderProcessingQueues')

export default [
  {
    entityClass: '',
    name: 'Workstation',
    icon: 'groups',
    path: '/workstation',
    children: [
      {
        entityClass: 'Labelling',
        path: 'labelling',
        name: 'Labelling',
        component: LabellingNew,
        children: [
          {
            entityClass: '',
            path: 'regular/:id',
            name: 'Scan & Print',
            icon: 'archive',
            description: 'Advanced version of measuring. It is designed for warehouses that need more functions.',
            component: Measuring,
            children: [
              {
                entityClass: '',
                path: '/:deliveryRequest',
                name: 'Scan & Print',
                component: Measuring
              }
            ]
          },
        ]
      },
      {
        entityClass: 'Assembling',
        path: 'packing',
        title: 'Prep&Pack',
        component: OrderAssembling,
        children: [
          {
            entityClass: '',
            path: '/:place',
            name: 'Prep&Pack',
            component: OrderAssembling,
            children: [
              {
                entityClass: '',
                path: '/:queue',
                name: 'Prep&Pack',
                component: OrderAssembling,
                children: [
                  {
                    entityClass: '',
                    path: '/:order',
                    name: 'Prep&Pack',
                    component: OrderAssembling
                  }
                ]
              }
            ]
          },
        ]
      },
      {
        entityClass: 'Bundling',
        path: 'bundling',
        title: 'Bundling',
        component: Bundling
      },
      {
        entityClass: 'Returning',
        path: 'returning',
        name: 'Returning',
        component: Return
      },
      {
        entityClass: 'CheckOut',
        path: 'checkout',
        title: 'Check out',
        component: CheckOut
      },
      {
        entityClass: 'Queues',
        path: 'queues',
        name: 'Ws Settings',
        component: Queues,
        children: [
          {
            entityClass: 'Picking',
            path: 'picking/entity/:id',
            component: PickingQueue
          },
          {
            entityClass: 'Packing',
            path: 'packing/entity/:id',
            component: AssemblingQueue
          },
          {
            entityClass: 'Picking',
            path: 'picking',
            name: 'Wave',
            type: 'picking',
            description: 'Manage your picking. Create the picking queues, assign pickers to any queue or to a single order. Set your picking rules.',
            icon: 'store',
            component: PickingQueues,
            options: {
              query: {
                per_page: 25,
                page: 1,
                'order-by': [
                  {
                    type: 'field',
                    field: 'created',
                    direction: 'desc'
                  }
                ],
                filter: [
                  { type: 'eq', field: 'state', value: 'active' }
                ]
              }
            },
            priority: 100
          },
          {
            entityClass: 'Picking',
            path: 'picking',
            name: 'Discrete',
            type: 'picking',
            description: 'Manage your picking. Create the picking queues, assign pickers to any queue or to a single order. Set your picking rules.',
            icon: 'store',
            component: PickingQueues,
            options: {
              query: {
                per_page: 25,
                page: 1,
                'order-by': [
                  {
                    type: 'field',
                    field: 'created',
                    direction: 'desc'
                  }
                ],
                filter: [
                  { type: 'eq', field: 'state', value: 'active' }
                ]
              }
            },
            priority: 100,
            children: [
              {
                entityClass: 'Picking',
                path: 'order',
                name: 'By ID',
                type: 'picking',
                description: 'Manage your picking. Create the picking queues, assign pickers to any queue or to a single order. Set your picking rules.',
                icon: 'store',
                component: PickingByOrderQueues,
                options: {
                  query: {
                    per_page: 25,
                    page: 1,
                    'order-by': [
                      {
                        type: 'field',
                        field: 'created',
                        direction: 'desc'
                      }
                    ],
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }
                },
                priority: 96
              },
              {
                entityClass: 'Picking',
                name: 'Picking&Transfer',
                type: 'tasks',
                description: 'Manage your tasks for picking, sorting, allocation, receiving and measuring.',
                icon: 'store',
                path: 'tasks',
                component: TasksTasks,
                options: {
                  query: {
                    per_page: 25,
                    page: 1,
                    'order-by': [
                      {
                        type: 'field',
                        field: 'created',
                        direction: 'desc'
                      }
                    ],
                    filter: []
                  }
                },
                priority: 91
              }
            ]
          },
          {
            entityClass: 'Packing',
            path: 'packing',
            name: 'Discrete',
            type: 'packing',
            description: 'Manage your packing. Create the packing queues, assign packers to any queue or to a single order. Set your packing rules.',
            icon: 'storefront',
            component: AssemblingQueues,
            options: {
              query: {
                per_page: 25,
                page: 1,
                'order-by': [
                  {
                    type: 'field',
                    field: 'created',
                    direction: 'desc'
                  }
                ],
                filter: [
                  { type: 'eq', field: 'state', value: 'active' }
                ]
              }
            },
            priority: 99,
            children: [
              {
                entityClass: 'Packing',
                path: 'order',
                name: 'By ID',
                type: 'packing',
                description: 'Manage your packing. Create the packing queues, assign packers to any queue or to a single order. Set your packing rules.',
                icon: 'storefront',
                component: AssemblingByOrderQueues,
                options: {
                  query: {
                    per_page: 25,
                    page: 1,
                    'order-by': [
                      {
                        type: 'field',
                        field: 'created',
                        direction: 'desc'
                      }
                    ],
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }
                },
                priority: 95
              },
              {
                entityClass: 'Packing',
                path: 'tasks',
                name: 'Prep&Pack',
                type: 'tasks',
                description: 'Manage your tasks for packing and returning.',
                icon: 'storefront',
                component: AssemblingTasks,
                options: {
                  query: {
                    per_page: 25,
                    page: 1,
                    'order-by': [
                      {
                        type: 'field',
                        field: 'created',
                        direction: 'desc'
                      }
                    ],
                    filter: []
                  }
                },
                priority: 90
              },
            ]
          },
          {
            entityClass: 'Picking',
            path: 'sorting',
            name: 'Sorting',
            type: 'other',
            description: 'Manage your sorting. Create the sorting queues.',
            icon: 'sort',
            component: SortingQueues,
            options: {
              query: {
                per_page: 25,
                page: 1,
                'order-by': [
                  {
                    type: 'field',
                    field: 'created',
                    direction: 'desc'
                  }
                ],
                filter: [
                  { type: 'eq', field: 'state', value: 'active' }
                ]
              }
            },
            priority: 98
          },
          {
            entityClass: 'Picking',
            path: 'allocate',
            name: 'Shelves Allocation',
            type: 'other',
            description: 'Manage your allocation. Create the allocation queues.',
            icon: 'room',
            component: AllocationQueues,
            options: {
              query: {
                per_page: 25,
                page: 1,
                'order-by': [
                  {
                    type: 'field',
                    field: 'created',
                    direction: 'desc'
                  }
                ],
                filter: [
                  { type: 'eq', field: 'state', value: 'active' }
                ]
              }
            },
            priority: 97
          },
          {
            entityClass: 'Picking',
            path: 'measuring',
            name: 'Parcel measuring',
            type: 'other',
            description: 'Manage your measuring of products. Create the queues, assign employees to any queue.',
            icon: 'square_foot',
            component: MeasuringQueues,
            options: {
              query: {
                per_page: 25,
                page: 1,
                'order-by': [
                  {
                    type: 'field',
                    field: 'created',
                    direction: 'desc'
                  }
                ],
                filter: [
                  { type: 'eq', field: 'state', value: 'active' }
                ]
              }
            },
            priority: 93
          },
          {
            entityClass: 'Packing',
            path: 'returning',
            name: 'Returns',
            type: 'other',
            description: 'Manage your return parcels. Create the queues, assign employees to any queue.',
            icon: 'reply',
            component: ReturnQueues,
            options: {
              query: {
                per_page: 25,
                page: 1,
                'order-by': [
                  {
                    type: 'field',
                    field: 'created',
                    direction: 'desc'
                  }
                ],
                filter: [
                  { type: 'eq', field: 'state', value: 'active' }
                ]
              }
            },
            priority: 92
          },
          {
            entityClass: 'Bundling',
            path: 'bundling',
            name: 'Pre-Bundling',
            type: 'other',
            description: 'Manage your bundles. Create the queues, assign employees to any queue.',
            icon: 'inventory_2',
            component: BundlingQueues,
            options: {
              query: {
                per_page: 25,
                page: 1,
                'order-by': [
                  {
                    type: 'field',
                    field: 'created',
                    direction: 'desc'
                  }
                ],
                filter: [
                  { type: 'eq', field: 'state', value: 'active' }
                ]
              }
            },
            priority: 92
          },
          {
            entityClass: 'Picking',
            path: 'totes',
            name: 'Totes',
            type: 'picking',
            description: 'Manage your totes picking. Create the queues, assign employees to any queue.',
            icon: 'store',
            component: PickingTotesQueues,
            options: {
              query: {
                per_page: 25,
                page: 1,
                'order-by': [
                  {
                    type: 'field',
                    field: 'created',
                    direction: 'desc'
                  }
                ],
                filter: [
                  { type: 'eq', field: 'state', value: 'active' }
                ]
              }
            },
            priority: 94
          },
          {
            entityClass: 'Picking',
            path: 'receiving',
            name: 'Pallet receiving',
            type: 'other',
            description: 'Manage your pallet receiving. Create the queues, assign employees to any queue.',
            icon: 'markunread_mailbox',
            component: PalletReceivingQueues,
            options: {
              query: {
                per_page: 25,
                page: 1,
                'order-by': [
                  {
                    type: 'field',
                    field: 'created',
                    direction: 'desc'
                  }
                ],
                filter: [
                  { type: 'eq', field: 'state', value: 'active' }
                ]
              }
            },
            priority: 94
          },
          {
            entityClass: 'Picking',
            path: 'delivery',
            name: 'Order processing',
            type: 'other',
            description: 'Manage your order pre-processing queues. Create the queues, assign employees to any queue.',
            icon: 'check',
            component: OrderProcessingQueues,
            options: {
              query: {
                per_page: 25,
                page: 1,
                'order-by': [
                  {
                    type: 'field',
                    field: 'id',
                    direction: 'desc'
                  }
                ],
                filter: [
                  { type: 'eq', field: 'state', value: 'active' }
                ],
                criteria: {
                  state: 'active'
                }
              }
            },
            priority: 88
          },
          {
            entityClass: 'Picking',
            path: 'revision',
            name: 'Revision',
            type: 'picking',
            description: 'Manage revision tasks.',
            icon: 'storefront',
            component: RevisionQueues,
            options: {
              query: {
                per_page: 25,
                page: 1,
                'order-by': [
                  {
                    type: 'field',
                    field: 'created',
                    direction: 'desc'
                  }
                ],
                filter: []
              }
            },
            priority: 90
          },
          {
            entityClass: 'Picking',
            path: 'revision/entity/:id',
            component: RevisionQueue
          },
        ]
      }
    ]
  }
]
