import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    reserve: null,
    cleanReserve: null,
    reserves: [],
    totalReservesNumber: 0,
    reservesPage: 1,
    reservesTotalPages: 1,
    reservesArchive: {},
    reservesLoading: false
  },
  getters: {
    reserves: state => state.reserves,
    reservesLoading: state => state.reservesLoading,
    cleanReserve: state => state.cleanReserve,
    reserve: state => state.reserve,
    totalReservesNumber: state => state.totalReservesNumber,
    reservesPage: state => state.reservesPage,
    reservesTotalPages: state => state.reservesTotalPages
  },
  mutations: {
    setReserves (state, payload) {
      state.reserves = payload
    },
    setReservesData (state, { items, page, totalPages, totalItems }) {
      state.reserves = items
      state.reservesPage = page
      state.reservesTotalPages = totalPages
      state.totalReservesNumber = totalItems
    },
    setReservesPage (state, payload) {
      state.reservesPage = payload
    },
    setReservesTotalPages (state, payload) {
      state.reservesTotalPages = payload
    },
    setTotalReservesNumber (state, payload) {
      state.totalReservesNumber = payload
    },
    setReserve (state, payload) {
      state.reserve = payload
      state.cleanReserve = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanReserve (state, payload) {
      state.cleanreserve = payload
    },
    setNewReserve (state) {
      state.reserve = {}
    },
    updateReserve (state, payload) {
      state.reserve = {
        ...state.reserve,
        ...payload
      }
    },
    startReservesLoading (state) {
      state.reservesLoading = true
    },
    stopReservesLoading (state) {
      state.reservesLoading = false
    }
  },
  actions: {
    loadReserves ({ commit }, payload = {}) {
      const query = mergeQuery(payload, window.appOptions.reserves)
      return services.reserve.getAll(query)
        .then(data => {
          commit('setReservesData', data)
          return data
        })
    },
    loadReserve ({ commit }, payload) {
      return services.reserve.get(payload)
        .then(item => {
          commit('setReserve', item)
          return item
        })
    },
    saveReserve ({ commit, state }) {
      const data = state.reserve.id
        ? convertEmbedded(difference(state.reserve, state.cleanReserve))
        : convertEmbedded(state.reserve)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.reserve)
      }

      const header = state.reserve.id && state.reserve.updated
        ? { 'X-Entity-Updated': state.reserve.updated }
        : {}

      return services.reserve.save(data, state.reserve.id, header)
    }
  }
}
