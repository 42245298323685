// const codes = [
//   // '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
//   'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'V', 'X', 'Y', 'Z',
//   'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'v', 'x', 'y', 'z',
// ]
 
// function incrementChar (l) {
//   const lastChar = l[l.length - 1]

//   if (!lastChar) {
//     return codes[0]
//   }

//   const remString = l.slice(0, l.length - 1)

//   if (lastChar === codes[codes.length - 1]) {
//     return incrementChar(remString) + codes[0]
//   }
  
//   return remString + codes[codes.indexOf(lastChar) + 1]
// }

// function generate (start, end) {
//   const arr = [start]

//   while (arr[arr.length - 1] !== end) {
//     arr.push(incrementChar(arr[arr.length - 1]))
//   }

//   return arr
// }

// console.debug(generate('B', 'BC'))


/**
 * Generate codes from letter
 *
 * @param {number} from
 * @param {number} to
 *
 * @returns {array}
 */
export function generateCodesFromNumbers (from, to) {
  const result = []

  for (let i = from; i <= to; i++) {
    result.push(i)
  }

  return result
}

/**
 * Generate codes from letter
 *
 * @param {string} from
 * @param {string} to
 *
 * @returns {array}
 */
export function generateCodesFromLetter (from, to) {
  const result = []

  const fromN = parseInt(from, 36)
  const toN = parseInt(to, 36)

  for (let i = fromN; i <= toN; i++) {
    result.push(i.toString(36).toUpperCase())
  }

  return result
}

/**
 * Generate codes between two numbers or strings
 *
 * @param {string|number} from
 * @param {string|number} to
 *
 * @returns {array}
 */
export function generateCodes (from, to) {
  if (from === to) {
    return [from]
  }

  if (!isNaN(from) && !isNaN(to)) {
    return generateCodesFromNumbers(Number(from), Number(to))
  }

  if (!isNaN(from) && isNaN(to)) {
    return [
      ...generateCodesFromNumbers(from, 9),
      ...generateCodesFromLetter('A', to)
    ]
  }

  return generateCodesFromLetter(from, to)
}

/**
 * Convert number or string to object with raw and real values
 *
 * @param {number|string} value
 *
 * @returns {object}
 */
export function convertToNumber (value) {
  if (!isNaN(Number(value))) {
    return { raw: value, value }
  }

  const number = `${value}`.split('').reduce((acc, value) => {
    const n = Number(value)

    if (!isNaN(n)) {
      return acc + n
    }

    return acc + generateCodes('@', value).length
  }, 0)

  return { raw: value, value: number }
}