import { HALApiService } from '../hal-api.service'
import { createItemsData, mergeQuery } from './../../helpers/request-helpers'
import { CachedService } from './base-service'
import entities from '../../../../config/Entities'

export const dsRatesService = new CachedService(
  entities.Orderadmin_DeliveryServices_Entity_Rate,
  '/delivery-services/rates',
  HALApiService,
  createItemsData,
  { mergeQuery, query: window.appOptions.rates }
)
