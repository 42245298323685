import services from '../../../services/services'
import { mergeQuery } from './../../../helpers/request-helpers'

export default {
  state: {
    task: null,
    queue: null,
    tasks: [],
    totalTasksNumber: 0,
    tasksPage: 1,
    tasksTotalPages: 1,
    tasksLoading: false
  },
  getters: {
    task: state => state.task,
    queue: state => state.queue,
    tasks: state => state.tasks,
    tasksLoading: state => state.tasksLoading,
    totalTasksNumber: state => state.totalTasksNumber,
    tasksPage: state => state.tasksPage,
    tasksTotalPages: state => state.tasksTotalPages
  },
  mutations: {
    setTask (state, payload) {
      state.task = payload
    },
    setTasksData (state, { items, page, totalPages, totalItems }) {
      state.tasks = items
      state.totalTasksNumber = totalItems
      state.tasksPage = page
      state.tasksTotalPages = totalPages
    },
    setTasks (state, payload) {
      state.tasks = payload
    },
    setTotalTasksNumber (state, payload) {
      state.totalTasksNumber = payload
    },
    setTasksPage (state, payload) {
      state.tasksPage = payload
    },
    setTasksTotalPages (state, payload) {
      state.tasksTotalPages = payload
    },
    startTasksLoading (state) {
      state.tasksLoading = true
    },
    stopTasksLoading (state) {
      state.tasksLoading = false
    }
  },
  actions: {
    printLabelByRequest ({ state, commit, dispatch }) {
      const request = state.deliveryRequest

      return services.deliveryServicePreprocessingTask.getByDeliveryRequest(request.id)
        .then(result => {
          if (!result) {
            dispatch('printLabelByRequestBySize', { pageSize: 'label' })
            return
          }

          dispatch('getLabels', { id: result.id, noAutoPrint: true })
          return result
        })
        .catch(error => {
          commit('addErrorNotification', error.message)
        })
    },
    printLabelByRequestBySize ({ state }, payload = {}) {
      const request = state.deliveryRequest
      return services.deliveryRequest.getPDF(request.id, payload.pageSize || 'A6')
        .then(({ text }) => {
          return services.printer.createWindow(text)
        })
    },
    printLabelByRequestByID ({ state, commit, dispatch } , payload = {}) {
      const request = payload.deliveryRequest

      return services.deliveryServicePreprocessingTask.getByDeliveryRequest(request.id)
          .then(result => {
            if (!result) {
              dispatch('printLabelByRequestBySize', { pageSize: 'label' })
              return
            }

            dispatch('getLabels', { id: result.id, noAutoPrint: true })
            return result
          })
          .catch(error => {
            commit('addErrorNotification', error.message)
          })
    },
    loadPreprocessingTask ({ commit }, payload) {
      return services.deliveryServicePreprocessingTask.getByDeliveryRequest(payload)
        .then(task => {
          commit('setTask', task)
          return task
        })
    },
    loadPreprocessingTaskQuery ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.preprocessingTasks)
      return services.deliveryServicePreprocessingTask.getAll(query)
        .then(data => {
          commit('setTasksData', data)
          return data
        })
    },
    updatePreprocessingTask ({ state }, payload) {
      const request = state.tasks[0]
      return services.deliveryServicePreprocessingTask.save(payload, request.id)
    }
  }
}
