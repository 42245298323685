import { HALApiService } from '../hal-api.service'
import { createItemsData } from './../../helpers/request-helpers'
import { CachedService } from './base-service'
import entities from '../../../../config/Entities'

/**
 * Fake service which will be replaced with real one when it is done
 */
class RMCodeService extends CachedService {
  keywords

  constructor (httpCLient, middleware) {
    super (entities.Orderadmin_Accounts_Entity_BillingAdapter, '/accounts/billing-adapter', httpCLient, middleware)

    this.keywords = [
      { id: 'deliveryService', name: 'Delivery Service' },
      { id: 'deliveryServiceRate', name: 'Delivery Service Rate' },
      { id: 'order', name: 'Order' },
      { id: 'count', name: 'Count' },
      { id: 'value', name: 'Value' },
      { id: 'weight', name: 'Weight' },
      { id: 'warehouse', name: 'Warehouse' },
      { id: 'warehouseid', name: 'Warehouse ID' },
      { id: 'currency', name: 'Currency' },
      { id: 'acceptance', name: 'Acceptance' },
      { id: 'volume', name: 'Volume' },
      { id: 'price', name: 'Price' }
    ]
  }

  /**
   * Find codes by grouped RMs
   *
   * @param {array} items
   *
   * @returns {Promise<object>}
   */
  findItems (items) {

    const query = {
      page: 1,
      per_page: items.length,
      filter: [
        { type: 'in', field: 'object', values: items.length ? items.map(x => x.object) : []}
      ]
    }

    return this.getAll(query)
  }
}

export const rmCodeService = new RMCodeService(HALApiService, createItemsData)
