import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    helpSections: [],
    helpSection: null,
    cleanHelpSection: null,
    totalHelpSectionsNumber: 0,
    helpSectionsPage: 1,
    helpSectionsTotalPages: 1,
    helpSectionsLoading: false
  },
  getters: {
    helpSections: state => state.helpSections,
    helpSectionsLoading: state => state.helpSectionsLoading,
    helpSection: state => state.helpSection,
    cleanHelpSection: state => state.cleanHelpSection,
    totalHelpSectionsNumber: state => state.totalHelpSectionsNumber,
    helpSectionsPage: state => state.helpSectionsPage,
    helpSectionsTotalPages: state => state.helpSectionsTotalPages
  },
  mutations: {
    setHelpSection (state, payload) {
      state.helpSection = payload
      state.cleanHelpSection = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanHelpSection (state, payload) {
      state.cleanHelpSection = payload
    },
    setHelpSectionsData (state, { items, page, totalPages, totalItems }) {
      state.helpSections = items
      state.totalHelpSectionsNumber = totalItems
      state.helpSectionsPage = page
      state.helpSectionsTotalPages = totalPages
    },
    setNewHelpSection (state) {
      state.helpSection = {
        name: '',
        route: '',
        description: '',
        // state: 'active'
      }
    },
    updateHelpSection (state, payload) {
      state.helpSection = {
        ...state.helpSection,
        ...payload
      }
    },
    updateHelpSectionEmbedded (state, payload) {
      state.helpSection = {
        ...state.helpSection,
        _embedded: {
          ...state.helpSection._embedded,
          ...payload
        }
      }
    },
    startHelpSectionsLoading (state) {
      state.helpSectionsLoading = true
    },
    stopHelpSectionsLoading (state) {
      state.helpSectionsLoading = false
    },
    upsertHelpSection (state, payload) {
      let isExist = false

      state.helpSections = state.helpSections.map(x => {
        if (x.id === payload.id) {
          isExist = true
          return payload
        }

        return x
      })

      if (!isExist) {
        state.helpSections.unshift(payload)
      }
    }
  },
  actions: {
    loadHelpSections ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.helpSections)
      return services.helpSection.getAll(query)
        .then(({ items, totalItems, page, totalPages }) => {
          commit('setHelpSectionsData', { items, totalItems, page, totalPages })
          return { items, totalItems, page, totalPages }
        })
    },
    loadHelpSection ({ commit }, payload) {
      return services.helpSection.get(payload)
        .then(item => {
          commit('setHelpSection', item)
          return item
        })
    },
    saveHelpSection ({ commit, state }) {
      const data = state.helpSection.id
        ? convertEmbedded(difference(state.helpSection, state.cleanHelpSection))
        : convertEmbedded(state.helpSection)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.helpSection)
      }

      const header = state.helpSection.id && state.helpSection.updated
        ? { 'X-Entity-Updated': state.helpSection.updated }
        : {}

      return services.helpSection.save(data, state.helpSection.id, header)
    }
  }
}
