import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    expectedOffer: null,
    cleanExpectedOffer: null,
    expectedOffers: [],
    expectedOffersLoading: false,
    totalExpectedOffersNumber: 0,
    expectedOffersPage: 1,
    expectedOffersTotalPages: 1
  },
  getters: {
    expectedOffer: state => state.expectedOffer,
    cleanExpectedOffer: state => state.cleanExpectedOffer,
    expectedOffers: state => state.expectedOffers,
    expectedOffersLoading: state => state.expectedOffersLoading,
    totalExpectedOffersNumber: state => state.totalExpectedOffersNumber,
    expectedOffersPage: state => state.expectedOffersPage,
    expectedOffersTotalPages: state => state.expectedOffersTotalPages
  },
  mutations: {
    setExpectedOffer (state, payload) {
      state.expectedOffer = payload
      state.cleanExpectedOffer = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanExpectedOffer (state, payload) {
      state.cleanExpectedOffer = payload
    },
    setNewExpectedOffer (state) {
      state.expectedOffer = {
      }
    },
    updateExpectedOffer (state, payload) {
      state.expectedOffer = {
        ...state.expectedOffer,
        ...payload
      }
    },
    setExpectedOffersData (state, { items, page, totalPages, totalItems }) {
      state.expectedOffers = items
      state.totalExpectedOffersNumber = totalItems
      state.expectedOffersPage = page
      state.expectedOffersTotalPages = totalPages
    },
    setExpectedOffers (state, payload) {
      state.expectedOffers = payload
    },
    setTotalExpectedOffersNumber (state, payload) {
      state.totalExpectedOffersNumber = payload
    },
    setExpectedOffersPage (state, payload) {
      state.expectedOffersPage = payload
    },
    setExpectedOffersTotalPages (state, payload) {
      state.expectedOffersTotalPages = payload
    },
    startExpectedOfferLoading (state) {
      state.expectedOffersLoading = true
    },
    stopExpectedOfferLoading (state) {
      state.expectedOffersLoading = false
    }
  },
  actions: {
    loadExpectedOffers ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.expectedOffers)
      return services.expectedOffer.getAll(query)
        .then(data => {
          commit('setExpectedOffersData', data)
          return data
        })
    },
    loadExpectedOffer ({ commit }, payload) {
      return services.expectedOffer.get(payload)
        .then(item => {
          commit('setExpectedOffer', item)
          return item
        })
    },
    saveExpectedOffer ({ commit, state }) {
      const data = state.expectedOffer.id
        ? convertEmbedded(difference(state.expectedOffer, state.cleanExpectedOffer))
        : convertEmbedded(state.expectedOffer)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.expectedOffer)
      }

      const header = state.expectedOffer.id && state.expectedOffer.updated
        ? { 'X-Entity-Updated': state.expectedOffer.updated }
        : {}

      return services.expectedOffer.save(data, state.expectedOffer.id, header)
    }
  }
}
