import { ref } from 'vue'

export class Service {
  _httpClient
  _url
  _middleware
  _lastGetAll
  _lastGet
  _isLoading

  get isLoading () {
    return this._isLoading.value
  }

  get lastGetAll () {
    return this._lastGetAll.value
  }

  get lastGet () {
    return this._lastGet
  }

  constructor (url, httpClient, middleware) {
    this._url = url
    this._httpClient = httpClient
    this._middleware = middleware
    this._lastGet = null

    this._isLoading = ref(false)

    this._lastGetAll = ref({
      items: [],
      totalItems: 0,
      page: 0,
      totalPages: 0
    })
  }

  _startLoading () {
    this._isLoading.value = true
  }

  _stopLoading () {
    this._isLoading.value = false
  }

  /**
   * @param {String | Number} id
   *
   * @returns {Promise<object>}
  */
  find (id) {
    const item = this._lastGetAll.value.items.find(x => `${x.id}` === `${id}`)

    if (item) {
      return Promise.resolve(item)
    }

    return this.get(id)
  }

  /**
   * @param {String | Number} id
   * @param {object} get
   * @param {String} accept
   *
   * @returns {Promise<object>}
  */
  get (id, get, accept) {
    this._startLoading()

    return this._httpClient.get(`${this._url}/${id}`, get, accept)
      .then(item => {
        this._lastGet = item
        return item
      })
      .finally(() => {
        this._stopLoading()
      })
  }

  /**
   * @param {object} query
   * @param {String} accept
   * @param {object} headers
   *
   * @returns {Promise<object>}
   */
  getAll (query, accept, headers) {
    this._startLoading()

    return this._httpClient.get(this._url, query, accept, headers)
      .then(this._middleware)
      .then(data => {
        this._lastGetAll.value = data
        return data
      })
      .finally(() => {
        this._stopLoading()
      })
  }

  /**
   * 
   * @param {object} item
   *
   * @returns {array}
   */
  upsert (item) {
    let hasItem = false

    this._lastGetAll.value.items = this._lastGetAll.value.items.map(x => {
      if (x.id === item.id) {
        hasItem = true
        return item
      }

      return x
    })

    if (!hasItem) {
      this._lastGetAll.value.items = [item, ...this._lastGetAll.value.items]
    }

    return this._lastGetAll.value.items
  }

  /**
   * @param {object} data
   * @param {String | Number | undefined} id
   * @param {object | undefined} headers
   * @param {object | undefined} query
   *
   * @returns {Promise<object>}
   */
  save (data, id, headers, query = {}) {
    this._startLoading()

    return this._httpClient.saveRequest(this._url, data, id, undefined, undefined, headers, query)
      .then(item => {
        this.upsert(item)
        return item
      })
      .finally(() => {
        this._stopLoading()
      })
  }
}

export class ItemsService {
  _httpClient;
  _url;
  _qs;

  constructor (url, httpClient, qs) {
    this._url = url
    this._httpClient = httpClient
    this._qs = qs
  }

  /**
   * @param {object} query
   *
   * @returns {Promise<object>}
  */
  getAll (query) {
    return this._httpClient.get(this._url, query)
  }

  /**
   * Patch Items from Sorting
   *
   * @param {number} id
   * @param {object} data
   * @param {object} query
   *
   * @returns {Promise<object>}
  */
  patch (id, data, query = {}) {
    const url = `${this._url}/${id}${Object.keys(query).length > 0 ? '?' + this._qs.stringify(query) : ''}`
    return this._httpClient.patch(url, data)
  }
}

export class QueueService {
  _httpClient
  _url
  _qs
  _middleware

  constructor (url, httpClient, middleware, qs) {
    this._url = url
    this._httpClient = httpClient
    this._middleware = middleware
    this._qs = qs
  }

  /**
   * @param {String | Number} id
   *
   * @returns {Promise<object>}
   */
  get (id) {
    return this._httpClient.get(`${this._url}/${id}`)
  }

  /**
   * @param {object} query
   *
   * @returns {Promise<object>}
   */
  getAll (query) {
    return this._httpClient.get(this._url, query)
      .then(this._middleware)
  }

  /**
   * @param {String | Number | Undefined} id
   * @param {object} data
   * @param {object} query
   *
   * @returns {Promise<object>}
   */
  process (id, data, query = {}) {
    return this._httpClient.post(`${this._url}/${id}` + (Object.keys(query).length > 0 ? '?' + this._qs.stringify(query) : ''), data)
  }
}
