import service from '../../services'
import { mergeQuery } from '../../utils/request.utils'

export default {
  state: {
    senders: [],
    totalSendersNumber: 0,
    sendersTotalPages: 1,
    sendersPage: 1,
    sendersLoading: false
  },
  getters: {
    senders: state => state.senders,
    totalSendersNumber: state => state.totalSendersNumber,
    sendersTotalPages: state => state.sendersTotalPages,
    sendersPage: state => state.sendersPage,
    sendersLoading: state => state.sendersLoading
  },
  mutations: {
    setSendersData (state, { items, page, totalPages, totalItems }) {
      state.senders = items
      state.sendersPage = page
      state.sendersTotalPages = totalPages
      state.totalSendersNumber = totalItems
    }
  },
  actions: {
    loadSenders ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.senders)
      return service.sender.getAll(query)
        .then(data => {
          commit('setSendersData', data)
          return data
        })
    }
  }
}
