/**
 * Create Settings State
 *
 * @returns {Object}
 */
function createSettingsState () {
  const appOptions = JSON.parse(localStorage.getItem('appOptions'))
  const settings = {
    mode: 'measuring',
    scanType: 'delivery-request',
    width: null,
    height: null,
    length: null,
    weight: null,
    timestamp: null,
    printer: null,
    settings: {
      immediatelySubmit: false,
      acceptanceControl: false,
      consumptionControl: false,
      weightControl: true,
      maxWeight: 14000,
      consumption: null,
      checkParcelContent: false,
      trustIncomingMeasurements: false,
      setPredefinedMeasurements: false,
      isEnabledDeliveryRequestTable: false,
      default: {
        weight: 10,
        width: 10,
        length: 10,
        height: 10,
        sendDate: null
      },
      printing: {
        interceptAutoClose: false,
        autoClosePrintWindow: true,
        modal: false,
        timeout: 4500
      }
    }
  }

  if (appOptions && appOptions.packagingSettings) {
    settings.settings = {
      ...settings.settings,
      ...appOptions.packagingSettings
    }
  }

  if (!settings.settings.default) {
    settings.settings.default = {}
  }

  if (!settings.settings.default) {
    settings.settings.printing = {}
  }

  return settings
}

export default {
  state: createSettingsState(),
  getters: {
    mode: state => state.mode,
    scanType: state => state.scanType,
    width: state => state.width,
    height: state => state.height,
    length: state => state.length,
    weight: state => state.weight,
    timestamp: state => state.timestamp,
    printer: state => state.printer,
    settings: state => state.settings,
    interceptAutoClose: state => state.settings.printing.interceptAutoClose
  },
  mutations: {},
  actions: {}
}
