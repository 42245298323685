import _ from 'lodash'
import services from '../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    pickingSequence: null,
    cleanPickingSequence: null,
    pickingSequences: [],
    pickingSequencesLoading: false,
    totalpickingSequencesNumber: 0,
    pickingSequencesPage: 1,
    pickingSequencesTotalPages: 1
  },
  getters: {
    pickingSequence: state => state.pickingSequence,
    cleanPickingSequence: state => state.cleanPickingSequence,
    pickingSequences: state => state.pickingSequences,
    pickingSequencesLoading: state => state.pickingSequencesLoading,
    totalpickingSequencesNumber: state => state.totalpickingSequencesNumber,
    pickingSequencesPage: state => state.pickingSequencesPage,
    pickingSequencesTotalPages: state => state.pickingSequencesTotalPages
  },
  mutations: {
    setPickingSequence (state, payload) {
      state.pickingSequence = payload
      state.cleanPickingSequence = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanPickingSequence (state, payload) {
      state.cleanPickingSequence = payload
    },
    setNewPickingSequence (state) {
      state.pickingSequence = {}
    },
    updatePickingSequence (state, payload) {
      state.pickingSequence = {
        ...state.pickingSequence,
        ...payload
      }
    },
    setPickingSequencesData (state, { items, page, totalPages, totalItems }) {
      state.totalPickingSequencesNumber = totalItems
      state.pickingSequences = items
      state.pickingSequencesPage = page
      state.pickingSequencesTotalPages = totalPages
    },
    setPickingSequences (state, payload) {
      state.pickingSequences = payload
    },
    setTotalPickingSequencesNumber (state, payload) {
      state.totalPickingSequencesNumber = payload
    },
    setPickingSequencesPage (state, payload) {
      state.pickingSequencesPage = payload
    },
    setPickingSequencesTotalPages (state, payload) {
      state.pickingSequencesTotalPages = payload
    },
    startSequenceLoading (state) {
      state.pickingSequencesLoading = true
    },
    stopSequenceLoading (state) {
      state.pickingSequencesLoading = false
    },
    upsertPickingSequence (state, payload) {
      let isExist = false

      state.pickingSequences = state.pickingSequences.map(x => {
        if (x.id === payload.id) {
          isExist = true
          return payload
        }

        return x
      })

      if (!isExist) {
        state.pickingSequence.push(payload)
      }
    }
  },
  actions: {
    loadPickingSequences ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.pickingSequences)
      return services.pickingSequence.getAll(query)
        .then(data => {
          commit('setPickingSequencesData', data)
          return data
        })
    },
    loadPickingSequence ({ commit }, payload) {
      return services.pickingSequence.get(payload)
        .then(item => {
          commit('setPickingSequence', item)
          return item
        })
    },
    savePickingSequence ({ commit, state }) {
      const data = state.pickingSequence.id
        ? convertEmbedded(difference(state.pickingSequence, state.cleanPickingSequence))
        : convertEmbedded(state.pickingSequence)

      if (state.pickingSequence.id && data.settings) {
        delete data.settings
      }

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.pickingSequence)
      }

      const header = state.pickingSequence.id
        ? { 'X-Entity-Updated': state.pickingSequence.updated }
        : {}

      return services.pickingSequence.save(data, state.pickingSequence.id, header)
    }
  }
}
