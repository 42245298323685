class DeliveryRequestUtils {
  /**
   * @param {array} data
   *
   * @returns {object}
   */
  getMeasuringEntities (data) {
    return data.reduce((acc, value) => {
      Object.keys(value).forEach(key => {
        if (value[key] && !acc[key]) {
          acc[key] = value[key]
        }
      })

      return acc
    }, {})
  }

  /**
   * @param {String | Number} id
   * @param {array} filters
   *
   * @returns {object}
   */
  buildProcessQuery (id, filters = []) {
    const query = {
      action: 'tasks',
      'order-by': [
        { type: 'field', field: 'created', direction: 'desc' }
      ],
      filter: [
        { field: 'id', alias: 'dr', type: 'eq', value: id },
        ...filters
      ]
    }

    return query
  }

  /**
   * @param {String | Number} id
   * @param {String | Number} queue
   * @param {object} additionalData
   *
   * @returns {object}
   */
  buildPTaskData (id, queue, additionalData = { weight: 1, dimensions: { x: 1, y: 1, z: 1 } }) {
    if (!additionalData.sendDate) {
      additionalData['sendDate'] = new Date().toDateString()
    }

    return {
      state: 'measured',
      deliveryRequest: {
        sendDate: additionalData.sendDate,
        weight: additionalData.weight,
        dimensions: additionalData.dimensions,
        shippedByDocument: additionalData.shippedByDocument,
        id
      },
      queue
    }
  }
}

export const drUtils = new DeliveryRequestUtils()
