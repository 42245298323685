import _ from 'lodash'
import services from '../../../services/services'
import { filterUtil } from '../../../utils/filter.util'
import { QueueUtils } from '../../../utils/queue.utils'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    pickingQueue: null,
    cleanPickingQueue: null,
    pickingQueues: [],
    pickingQueuesLoading: false,
    totalPickingQueuesNumber: 0,
    pickingQueuesPage: 1,
    pickingQueuesTotalPages: 1
  },
  getters: {
    pickingQueue: state => state.pickingQueue,
    cleanPickingQueue: state => state.cleanPickingQueue,
    pickingQueues: state => state.pickingQueues,
    pickingQueuesLoading: state => state.pickingQueuesLoading,
    totalPickingQueuesNumber: state => state.totalPickingQueuesNumber,
    pickingQueuesPage: state => state.pickingQueuesPage,
    pickingQueuesTotalPages: state => state.pickingQueuesTotalPages
  },
  mutations: {
    setPickingQueue (state, payload) {
      state.pickingQueue = payload
      state.cleanPickingQueue = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanPickingQueue (state, payload) {
      state.cleanPickingQueue = payload
    },
    addPickingQueue (state, payload) {
      state.pickingQueues = [payload, ...state.pickingQueues]
    },
    setNewPickingQueue (state) {
      state.pickingQueue = {
        name: '',
        adapter: null,
        settings: {
          filters: {},
          'orders-picking-limit': null,
          'picking-endpoint-place-type': null,
          'wave-fill-type': null,
          'wave-order-limit': null,
          'wave-state': null
        },
        _embedded: {
          warehouse: null
        }
      }
    },
    updatePickingQueue (state, payload) {
      state.pickingQueue = {
        ...state.pickingQueue,
        ...payload
      }
    },
    setPickingQueuesData (state, { items, page, totalPages, totalItems }) {
      state.totalPickingQueuesNumber = totalItems
      state.pickingQueues = items
      state.pickingQueuesPage = page
      state.pickingQueuesTotalPages = totalPages
    },
    setPickingQueues (state, payload) {
      state.pickingQueues = payload
    },
    setTotalPickingQueuesNumber (state, payload) {
      state.totalPickingQueuesNumber = payload
    },
    setPickingQueuesPage (state, payload) {
      state.pickingQueuesPage = payload
    },
    setPickingQueuesTotalPages (state, payload) {
      state.pickingQueuesTotalPages = payload
    },
    startQueueLoading (state) {
      state.pickingQueuesLoading = true
    },
    stopQueueLoading (state) {
      state.pickingQueuesLoading = false
    }
  },
  actions: {
    loadPickingQueues ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.pickingQueues)
      return services.pickingQueue.getAll(query)
        .then(data => {
          commit('setPickingQueuesData', data)
          return data
        })
    },
    loadPickingQueue ({ commit }, payload) {
      return services.pickingQueue.get(payload)
        .then(item => {
          commit('setPickingQueue', item)
          return item
        })
    },
    loadPickingQueueRawSettings ({commit}, payload) {
      return services.pickingQueue.getRawSettings(payload)
          .then(item => {
            return item
          })
    },
    savePickingQueue ({ commit, state }) {
      const data = state.pickingQueue.id
        ? convertEmbedded(difference(state.pickingQueue, state.cleanPickingQueue))
        : convertEmbedded(state.pickingQueue)

      if (data.settings && data.settings.filters) {
        const utils = new QueueUtils(filterUtil)
        data.settings.filters = utils.convertObjectToFilter(data.settings.filters)
      }

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.pickingQueue)
      }

      const header = state.pickingQueue.id
        ? { 'X-Entity-Updated': state.pickingQueue.updated }
        : {}

      return services.pickingQueue.save(data, state.pickingQueue.id, header)
    }
  }
}
