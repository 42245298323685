<template>
  <div v-if="hasCounter">
    <div class="row" style="min-height: 40px;">
      <div v-if="!this.data.ignoreMax" class="col-3 row q-px-xs">
        <q-btn
          color="light-blue-9"
          text-color="white"
          class="col-12"
          :label="data.ignoreMax? $t('Max') + ': ' + '-':$t('Max') + ': ' + max"
          no-caps
          unelevated
          @click="handleAddAll"
        />
      </div>

      <div v-if="data.reduce" :class="`${colStyle} row q-px-xs`">
          <q-btn
                  color="negative"
                  text-color="white"
                  class="col-12"
                  :label="$t('Reduce')"
                  no-caps
                  unelevated
                  @click="addItems('reduce')"
          />
      </div>

      <div :class="`${colStyle} row q-px-xs`">
        <q-btn
          color="negative"
          text-color="white"
          class="col-12"
          icon="remove"
          :disable="quantity <= 1"
          no-caps
          unelevated
          @click="handleDecrease"
        />
      </div>

      <div :class="`${colStyle} row q-px-xs`">
        <q-btn
          color="positive"
          text-color="white"
          class="col-12"
          icon="add"
          :disable="data.ignoreMax ? false : max === 1 || quantity >= max"
          no-caps
          unelevated
          @click="handleIncrease"
        />
      </div>

      <div :class="`${colStyle} row q-px-xs`">
        <q-btn
          color="light-blue-9"
          text-color="white"
          class="col-12"
          :label="$t('Add')"
          no-caps
          unelevated
          @click="addItems('add')"
        />
      </div>
    </div>

    <div class="q-mt-xs">
      <q-input
        standout="bg-teal text-white"
        type="number"
        class="q-mx-xs full-width"
        :model-value="quantity"
        :disable="data.ignoreMax ? false : quantity === 1 && max === 1"
        :label="$t('Amount')"
        denses
        @update:model-value="handleAmount"
        @focus="onFocus"
        @blur="onFocusOut"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DynamicCounter',
  emits: ['click'],
  props: {
    noMax: {
      type: Boolean,
      default () {
        return false
      }
    },
    hasCounter: {
      type: Boolean,
      default () {
        return true
      }
    },
    data: {
      type: Object,
      default () {
        return {}
      }
    },
    rawData: {
      type: Object,
      default () {
        return null
      }
    },
  },
  data () {
    return {
      quantity: 1
    }
  },
  computed: {
    colStyle () {
      if(this.data.ignoreMax && !this.data.reduce) {
        return  'col-4'
      } else if (this.data.ignoreMax && this.data.reduce) {
        return  'col-3'
      } else if (!this.data.ignoreMax && !this.data.reduce) {
        return 'col-3'
      }
      return this.noMax
        ? 'col-4'
        : 'col-3'
    },
    max () {
      return this.data.max - (this.data.current || 0)
    }
  },
  watch: {
    quantity (quantity) {
      this.$emit('click', { event: 'quantityChange', data: { quantity } })
    },
    data: {
      handler () {
        this.quantity = 1
      },
      deep: true
    }
  },
  methods: {
    handleAddAll () {
      this.quantity = this.max
      this.addItems()
    },
    handleDecrease () {
      if (this.quantity <= 1) {
        return
      }

      this.quantity -= 1
    },
    handleIncrease () {
      if (!this.data.ignoreMax) {
        if (this.quantity >= this.max && !this.noMax) {
          return
        }
      }

      this.quantity += 1
    },
    handleAmount (e) {
      const value = Number(e)

      if(this.data.ignoreMax) {
        if (value <= 1) {
          this.quantity = 1
        } else {
          this.quantity = value
        }
      } else {
        if (value >= this.max && !this.noMax) {
          this.quantity = this.max
        } else if (value <= 1) {
          this.quantity = 1
        } else {
          this.quantity = value
        }
      }

    },
    onFocus () {
      this.$emit('click', { event: 'focus' })
    },
    onFocusOut () {
      this.$emit('click', { event: 'focusOut' })
    },
    addItems (way) {
      if(this.rawData?.options?.entity) {
        if(way === 'reduce') {
          let count = -this.quantity
          this.$emit('click', { event: 'counterSubmit', data: { quantity: count, barcode: this.rawData.options.entity , barcodeID: this.data.barcode } })
        } else {
          this.$emit('click', { event: 'counterSubmit', data: { quantity: this.quantity, barcode: this.rawData.options.entity , barcodeID: this.data.barcode} })
        }
      } else {
        if(way === 'reduce') {
          let count = -this.quantity
          this.$emit('click', { event: 'counterSubmit', data: { quantity: count, barcode: this.data.barcode } })
        } else {
          this.$emit('click', { event: 'counterSubmit', data: { quantity: this.quantity, barcode: this.data.barcode } })
        }
      }
    }
  }
}
</script>
