import _ from 'lodash'
import {
  reportService,
  printReportService,
  loadReportTypesService
} from './../../../services/requests/reports.service'

import { printerUtils } from '../../../utils/printer-utils'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    report: null,
    cleanReport: null,
    reportTypes: [],
    totalReportTypesNumber: 0,
    reportTypesPage: 1,
    reportTypesTotalPages: 1,
    reportTypesLoading: false,
    reports: [],
    totalReportsNumber: 0,
    reportsPage: 1,
    reportsTotalPages: 1,
    reportsLoading: false
  },
  getters: {
    report: state => state.report,
    cleanReport: state => state.cleanReport,
    reportTypes: state => state.reportTypes,
    totalReportTypesNumber: state => state.totalReportTypesNumber,
    reportTypesPage: state => state.reportTypesPage,
    reportTypesTotalPages: state => state.reportTypesTotalPages,
    reportTypesLoading: state => state.reportTypesLoading,
    reports: state => state.reports,
    reportsLoading: state => state.reportsLoading,
    totalReportsNumber: state => state.totalReportsNumber,
    reportsPage: state => state.reportsPage,
    reportsTotalPages: state => state.reportsTotalPages
  },
  mutations: {
    setReport (state, payload) {
      state.report = payload
      state.cleanReport = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanReport (state, payload) {
      state.cleanReport = payload
    },
    setNewReport (state) {
      state.report = {
        handler: '',
        beginningPeriod: '',
        endPeriod: '',
        settings: {},
        _embedded: {
          reportType: null
        }
      }
    },
    updateReportEmbedded (state, payload) {
      state.report._embedded = {
        ...state.report._embedded,
        ...payload
      }
    },
    updateReport (state, payload) {
      state.report = {
        ...state.report,
        ...payload
      }
    },
    deleteReport (state) {
      state.report = null
    },
    setReportTypesData (state, { items, page, totalPages, totalItems }) {
      state.reportTypes = items
      state.totalReportTypesNumber = totalItems
      state.reportTypesPage = page
      state.reportTypesTotalPages = totalPages
    },
    setReportsData (state, { items, page, totalPages, totalItems }) {
      state.reports = items
      state.totalReportsNumber = totalItems
      state.reportsPage = page
      state.reportsTotalPages = totalPages
    },
    removeReportFromList (state, payload) {
      state.reports = state.reports.filter(r => `${r.id}` !== `${payload}`)
    },
    addReportFromList (state, payload) {
      state.reports = [
        payload,
        ...state.reports
      ]
    },
    setReports (state, payload) {
      state.reports = payload
    },
    setTotalReportsNumber (state, payload) {
      state.totalReportsNumber = payload
    },
    setReportsPage (state, payload) {
      state.reportsPage = payload
    },
    setReportsTotalPages (state, payload) {
      state.reportsTotalPages = payload
    },
    startReportsLoading (state) {
      state.reportsLoading = true
    },
    stopReportsLoading (state) {
      state.reportsLoading = false
    }
  },
  actions: {
    loadReportTypes ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.reportTypes)
      return loadReportTypesService(query)
        .then(data => {
          commit('setReportTypesData', data)
          return data
        })
    },
    loadReports ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.reports)
      return reportService.getAll(query)
        .then(data => {
          commit('setReportsData', data)
          return data
        })
    },
    loadReport ({ commit }, payload) {
      return reportService.get(payload)
        .then(item => {
          commit('setReport', item)
          return item
        })
    },
    printReport (_, payload) {
      return printReportService(payload)
        .then(response => {
          response.text().then(data => {
            if (response.headers.get('Content-Type') === 'application/pdf') {
              try {
                if (data) {
                  data = JSON.parse(data)
                }
              } catch (error) {
                return Promise.reject(error)
              }
            }
            printerUtils.print(data, undefined, true)
          })
          return response
        })
    },
    saveReport ({ commit, state }) {
      const data = state.report.id
        ? convertEmbedded(difference(state.report, state.cleanReport))
        : convertEmbedded(state.report)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.report)
      }

      const header = state.report.id && state.report.updated
        ? { 'X-Entity-Updated': state.report.updated }
        : {}

      return reportService.save(data, state.report.id, header)
    }
  }
}
