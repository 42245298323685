import { Service } from './base-service'

export class RateModifierService extends Service {
  _helper

  constructor (httpClient, middleware, helper) {
    super('/accounts/rate-modifier', httpClient, middleware)
    this._helper = helper
  }

  save (data, id, headers, query) {
    if (data.comment) {
      data.comment = this._helper.decodeHTMLToText(data.comment)
    }

    return super.save(data, id, headers, query)
      .then(item => {
        item.comment = this._helper.convertTextToDocument(item.comment)
        return item
      })
  }

  get (...params) {
    return super.get(...params)
      .then(item => {
        item.comment = this._helper.convertTextToHTML(item.comment)
        return item
      })
  }

  getAll (...params) {
    return super.getAll(...params)
      .then(data => {
        return {
          ...data,
          items: data.items.map(x => ({ ...x, comment: this._helper.convertTextToHTML(x.comment) }))
        }
      })
  }

  getAllGroups (query = {}) {
    const updatedQuery = {
      ...query
    }

    if (!updatedQuery.per_page) {
      updatedQuery.per_page = 25
    }

    if (!updatedQuery.page) {
      updatedQuery.page = 1
    }

    if (!updatedQuery.groups) {
      updatedQuery.group = [{ field: 'object' }]
    }

    return this.getAll(updatedQuery)
  }
}
