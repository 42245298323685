import { HALApiService } from './../hal-api.service'
import { createItemsData } from './../../helpers/request-helpers'
import { CachedService } from './base-service'
import entities from '../../../../config/Entities'

class StorageTasksFinishedLocationServices extends CachedService {
  constructor(httpClient, middleware) {
    super(entities.Orderadmin_Storage_Entity_Movement_Inventory, '/storage/tasks/finished-location', httpClient, middleware)
  }
}

export const storageTasksFinishedLocationService = new StorageTasksFinishedLocationServices(HALApiService, createItemsData)
