import qs from 'qs'
import { HALApiService } from '../hal-api.service'
import { createItemsData } from './../../helpers/request-helpers'
import { Service} from './base-service'
import entities from '../../../../config/Entities'

class DeliveryServicesMovementService extends Service {
    constructor (httpClient, middleware) {
        super('/delivery-services/movements/acceptance', httpClient, middleware)
    }

}

export const deliveryServicesMovementService = new DeliveryServicesMovementService(HALApiService, createItemsData)

