import service from '../../services'
import { mergeQuery } from '../../utils/request.utils'

export default {
  state: {
    returnParcels: [],
    totalReturnParcelsNumber: 0,
    returnParcelsTotalPages: 1,
    returnParcelsPage: 1,
    returnParcelsLoading: false
  },
  getters: {
    returnParcels: state => state.returnParcels,
    totalReturnParcelsNumber: state => state.totalReturnParcelsNumber,
    returnParcelsTotalPages: state => state.returnParcelsTotalPages,
    returnParcelsPage: state => state.returnParcelsPage,
    returnParcelsLoading: state => state.returnParcelsLoading
  },
  mutations: {
    setReturnParcelsData (state, { items, page, totalPages, totalItems }) {
      state.returnParcels = items
      state.returnParcelsPage = page
      state.returnParcelsTotalPages = totalPages
      state.totalReturnParcelsNumber = totalItems
    },
    setReturnParcels (state, payload) {
      state.returnParcels = payload
    }
  },
  actions: {
    loadReturnParcels ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.returnParcels)
      return service.returnParcel.getAll(query)
        .then(data => {
          commit('setReturnParcelsData', data)
          return data
        })
    }
  }
}
