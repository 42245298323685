import { HALApiService } from '../hal-api.service'
import { createItemsData } from '../../helpers/request-helpers'
import { Service } from './base-service'

class StoragePlacesService extends Service {
  constructor (httpClient, middleware) {
    super('/storage/place', httpClient, middleware)
  }

  /**
   * @param {string} geo
   *
   * @returns {object}
   */
  _convertGeo (geo) {
    if (!geo) {
      return {
        x: null,
        y: null
      }
    }

    let value = (geo.match(/\(([^)]+)\)/g) || [])[0] || ''
    value = value.slice(1, value.length - 1).split(' ')

    return {
      x: value[1] || null,
      y: value[0] || null
    }
  }

  /**
   * @param {String | Number} id
   * @param {object} get
   * @param {String} accept
   *
   * @returns {Promise<object>}
   */
   get (id, get, accept) {
    return super.get(id, get, accept)
      .then(item => {
        return {
          ...item,
          geoArray: this._convertGeo(item.geo)
        }
      })
  }

  /**
   * @param {object} query
   * @param {String} accept
   *
   * @returns {Promise<object>}
   */
  getAll (query, accept) {
    return super.getAll(query, accept)
      .then(data => {
        return {
          ...data,
          items: data.items.map(item => {
            return {
              ...item,
              geoArray: this._convertGeo(item.geo)
            }
          })
        }
      })
  }

  /**
   * @param {object} data
   * @param {String | Number | undefined} id
   * @param {object | undefined} headers
   * @param {object | undefined} query
   *
   * @returns {Promise<object>}
   */
   save (data, id, headers, query = {}) {
    return super.save(data, id, headers, query)
      .then(item => {
        return {
          ...item,
          geoArray: this._convertGeo(item.geo)
        }
      })
  }

  /**
   * @param {String | Number} id
   *
   * @returns {Promise<object>}
  */
  getWarehouseMap (id) {
    const query = {
      filter: [
        { type: 'eq', field: 'warehouse', value: id },
        { type: 'eq', field: 'root', value: 33453 }
      ]
    }

    return this._httpClient.get(this._url, query, 'application/geo+json')
  }

  /**
   * @param {number|string|undefined} id
   *
   * @returns {Promise<object>} 
   */
  print (id) {
    return this._httpClient.get(`${this._url}${id ? `/${id}` : ''}`, undefined, 'application/document')
  }

  /**
   * @param {object} extQuery
   *
   * @returns {Promise<object>}
   */
  printAll (extQuery = {}) {
    const query = {
      print_case: 'printByPlace',
      page_dimensions: '70/25.4',
      draw: 1,
      page: 1,
      per_page: 1000,
      sort: {
        id: 'desc'
      },
      'order-by': [
        { type: 'field', field: 'lft', direction: 'asc' }
      ],
      ...extQuery
    }

    return this._httpClient.get(this._url, query, 'application/pdf')
  }
}

export const storagePlacesService = new StoragePlacesService(HALApiService, createItemsData)
