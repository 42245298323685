import { HALApiService } from '../hal-api.service'
import { createItemsData } from '../../helpers/request-helpers'
import { CachedService } from './base-service'
import entities from '../../../../config/Entities'

class ClientProfileService extends CachedService {
  constructor (httpClient, middleware) {
    super (entities.Orderadmin_Clients_Entity_Profile, '/clients/profile', httpClient, middleware)
  }

  _loadPhones (item) {
    item.phone = (item.phones || []).map(x => `+${x.id}`).join(', ')
    return item
  }

  /**
   * @param {String | Number} id
   * @param {object} get
   * @param {String} accept
   *
   * @returns {Promise<object>}
   */
   get (id, get, accept) {
    const value = CachedService.cache.getValue(this._entityClass, id)

    if (value) {
      return Promise.resolve(value)
    }

    return this._httpClient.get(`${this._url}/${id}`, get, accept)
      .then(this._loadPhones)
      .then(item => {
        CachedService.cache.dispatchValue(this._entityClass, item)
        return item
      })
  }

  /**
   * @param {object} query
   * @param {String} accept
   * @param {boolean} forceReload
   *
   * @returns {Promise<object>}
   */
  getAll (query, accept, forceReload) {
    const updatedQuery = this._options.mergeQuery && this._options.query
      ? this._options.mergeQuery(query, this._options.query)
      : query

    const data = CachedService.cache.getValues(this._entityClass, this.getURL(updatedQuery))

    if (!data.isExpired && !forceReload) {
      return Promise.resolve(data.value)
    }

    return this._httpClient.get(this._url, updatedQuery, accept)
      .then(this._middleware)
      .then(data => {
        return {
          ...data,
          items: data.items.map(this._loadPhones)
        }
      })
      .then(data => {
        CachedService.cache.dispatchValues(this._entityClass, this.getURL(updatedQuery), data)
        return data
      })
  }

  /**
   * @param {object} data
   * @param {String | Number | undefined} id
   * @param {object | undefined} headers
   * @param {object | undefined} query
   *
   * @returns {Promise<object>}
   */
  save (data, id, headers, query = {}) {
    const updatedData = { ...data }

    if (typeof updatedData.phone === 'string') {
      updatedData.phones = updatedData.phone.split(',').reduce((acc, id) => {
        const value = id.trim()

        if (value) {
          acc.push(value)
        }

        return acc
      }, [])

      updatedData.phone = undefined
    }

    return this._httpClient.saveRequest(this._url, updatedData, id, undefined, undefined, headers, query)
      .then(this._loadPhones)
      .then(item => {
        CachedService.cache.dispatchValue(this._entityClass, item)
        return item
      })
  }
}

export const clientProfileService = new ClientProfileService(HALApiService, createItemsData)
