import { authHeader } from '../helpers'
import { DataSource } from '../../../packages/data-source'
import { store } from '../vueX/store'
import { getMessage } from './messages'

const saveActions = ['POST', 'PUT', 'PATCH']
const bannedAPIs = ['calculator', 'event', 'message', 'bulk']

function createCURL (data) {
  let str = `curl -X ${data.method} ${Object.entries(data.headers).map(([key, value]) => `-H "${key}: ${value}"`).join(' ')}`

  if (data.method !== 'GET' && data.method !== 'DELETE') {
    str += ` -d '${data.body}'`
  }

  str += ` "${data.fullURL}"`

  return str
}

/**
 * Request Wrapper
 *
 * @param {object} options
 * @param {Promise} promise
 *
 * @returns {Promise<any>}
 */
function requestWrapper (options, promise) {
  const loadingPrefix = createLoadingPrefix(options.url)
  commitStartLoading(loadingPrefix)

  return promise()
    .then(data => {
      if (window.appOptions.debug) {
        store.commit('addDebugData', { ...options, data, curl: createCURL(options) })
      }

      commitSuccess(options.method, options.url)
      return data
    })
    .catch(err => {
      if (window.appOptions.debug) {
        store.commit('addDebugData', { ...options, error: err, curl: createCURL(options) })
      }

      commitError(err, options.url, loadingPrefix)
      return Promise.reject(err)
    })
    .finally(() => {
      commitStopLoading(loadingPrefix)
    })
}

/**
 * Commit Success
 *
 * @param {string} method
 * @param {string} url
 */
function commitSuccess (method, url) {
  if (saveActions.includes(method)) {
    const arr = url.split('/')
    const str = method === 'PATCH'
      ? arr[arr.length - 2]
      : arr[arr.length - 1]

    const message = str.replace('-', ' ')

    if (!bannedAPIs.includes(message)) {
      store.commit('addNotification', `You successful save ${message}!`)
    }
  }
}

/**
 * Commit Error
 *
 * @param {object} err
 * @param {string} url
 * @param {string} loadingPrefix
 */
function commitError (err, url, loadingPrefix) {
  const validStatusCodes = [503, 409, 423]

  if (err.code && err.code === 499) {
    console.debug(err.message)
    if (window.appOptions.debug) {
      console.trace(err.message)
    }
  } else if (validStatusCodes.includes(err.status)) {
    store.commit('addWarningNotification', getMessage(err, url))
  } else {
    store.commit('addErrorNotification', getMessage(err, url))
  }

  commitStopLoading(loadingPrefix)
}

/**
 * Create Loading Prefix
 *
 * @param {String} url
 * @param {Object | Undefined} query
 *
 * @returns {String}
 */
function createLoadingPrefix (url) {
  const index = url.lastIndexOf('/') + 1
  const str = url.slice(index)

  return str
    .split('-')
    .map(x => {
      return x[0]
        ? x[0].toUpperCase() + x.slice(1)
        : x
    })
    .join('')
}

/**
 * Commit Start Loading
 *
 * @param {String} prefix
 *
 * @returns {Void}
 */
function commitStartLoading (prefix) {
  const mutation = `start${prefix}Loading`

  if (store.getters.isInterceptedItemsLoading) {
    return
  }

  if (store._mutations[mutation]) {
    store.commit(mutation)
  } else {
    store.commit('startLoading')
  }
}

/**
 * Commit Stop Loading
 *
 * @param {String} prefix
 *
 * @returns {Void}
 */
function commitStopLoading (prefix) {
  const mutation = `stop${prefix}Loading`

  if (store.getters.isInterceptedItemsLoading) {
    return
  }

  if (store._mutations[mutation]) {
    store.commit(mutation)
  } else {
    store.commit('stopLoading')
  }
}

export const HALApiService = new DataSource(window, window.appOptions, authHeader, requestWrapper)
