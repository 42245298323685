import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    length: null,
    cleanLength: null,
    lengths: [],
    totalLengthsNumber: 0,
    lengthsPage: 1,
    lengthsTotalPages: 1,
    lengthsLoading: false
  },
  getters: {
    length: state => state.length,
    cleanLength: state => state.cleanLength,
    lengths: state => state.lengths,
    totalLengthsNumber: state => state.totalLengthsNumber,
    lengthsPage: state => state.lengthsPage,
    lengthsTotalPages: state => state.lengthsTotalPages,
    lengthsLoading: state => state.lengthsLoading
  },
  mutations: {
    setLengths (state, payload) {
      state.lengths = payload
    },
    setLength (state, payload) {
      state.length = payload
      state.cleanLength = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanLength (state, payload) {
      state.cleanLength = payload
    },
    setNewLength (state) {
      state.length = {
        title: null,
        state: 'active',
        unit: null
      }
    },
    updateLength (state, payload) {
      state.length = {
        ...state.length,
        ...payload
      }
    },
    setLengthData (state, { items, page, totalPages, totalItems }) {
      state.lengths = items
      state.totalLengthsNumber = totalItems
      state.lengthsPage = page
      state.lengthsTotalPages = totalPages
    },
    setTotalLengthsNumber (state, payload) {
      state.totalLengthsNumber = payload
    },
    setLengthsPage (state, payload) {
      state.lengthsPage = payload
    },
    setLengthsTotalPages (state, payload) {
      state.lengthsTotalPages = payload
    },
    startLengthLoading (state) {
      state.lengthsLoading = true
    },
    stopLengthLoading (state) {
      state.lengthsLoading = false
    }
  },
  actions: {
    loadLengths ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.length)
      return services.length.getAll(query)
        .then(data => {
          commit('setLengthData', data)
          return data
        })
    },
    loadLength ({ commit }, payload) {
      return services.length.get(payload)
        .then(item => {
          commit('setLength', item)
          return item
        })
    },
    saveLength ({ commit, state }) {
      const data = state.length.id
        ? convertEmbedded(difference(state.length, state.cleanLength))
        : convertEmbedded(state.length)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.length)
      }

      const header = state.length.id && state.length.updated
        ? { 'X-Entity-Updated': state.length.updated }
        : {}

      return services.length.save(data, state.length.id, header)
    }
  }
}
