import qs from 'qs'
import routes from './config/PageRoutes'
import { createRouter, createWebHashHistory } from 'vue-router'

export const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  parseQuery (query) {
    return qs.parse(query)
  },
  stringifyQuery (query) {
    let result = qs.stringify(query)

    return result ? (result) : ''
  }
})
