export const replacerRuEn = {
  'й': 'q',
  'ц': 'w',
  'у': 'e',
  'к': 'r',
  'е': 't',
  'н': 'y',
  'г': 'u',
  'ш': 'i',
  'щ': 'o',
  'з': 'p',
  'х': '[',
  'ъ': ']',
  'ф': 'a',
  'ы': 's',
  'в': 'd',
  'а': 'f',
  'п': 'g',
  'р': 'h',
  'о': 'j',
  'л': 'k',
  'д': 'l',
  'ж': ';',
  'э': '\'',
  'ё': '\\',
  'я': 'z',
  'ч': 'x',
  'с': 'c',
  'м': 'v',
  'и': 'b',
  'т': 'n',
  'ь': 'm',
  'б': ',',
  'ю': '.',
  ';': '*',
  '/': '/'
}

export function recodeKeyboardLayout (str) {
  return str.replace(/[А-я/,.;'\][]/g, function (x) {
    return x === x.toLowerCase() ? replacerRuEn[x] : replacerRuEn[x.toLowerCase()].toUpperCase()
  })
}

export function getBarcodeType (barcode) {
  if (!barcode.match(/\d+/g)) {
    return null
  } else {
    var expr = /([A-z/]+)\/[0-9A-z]+/
    var value = barcode.match(expr)

    if (value !== null && barcode.indexOf('*') > 0) {
      return value[1].toUpperCase()
    }
  }

  return false
}

export function getCarrier (trackingNumber) {
  if (trackingNumber.match(/\b(1Z ?[0-9A-Z]{3} ?[0-9A-Z]{3} ?[0-9A-Z]{2} ?[0-9A-Z]{4} ?[0-9A-Z]{3} ?[0-9A-Z]|[\dT]\d\d\d ?\d\d\d\d ?\d\d\d)\b/)) {
    return { name: 'UPS', extId: 'ups', id: 45951 }
  } else if (trackingNumber.match(/\b([A-Z]{2}\d{9}[A-Z]{2}|(420\d{9}(9[2345])?)?\d{20}|(420\d{5})?(9[12345])?(\d{24}|\d{20})|82\d{8})\b/)) {
    return { name: 'USPS', extId: 'usps', id: 45886 }
  } else if (trackingNumber.match(/\b([0-9]{12}|100\d{31}|\d{15}|\d{18}|96\d{20}|96\d{32})\b/)) {
    return { name: 'FedEx', extId: 'fedex', id: 45952 }
  } else {
    return {}
  }
}

export function getBarcodeValue (barcode) {
  if (!barcode.match(/\d+/g)) {
    return barcode
  } else {
    var expr = /[A-z/]+\/([0-9A-z/]+)/
    var value = barcode.match(expr)

    if (value !== null && barcode.indexOf('*') > 0) {
      value = value[1].split('/')

      if (value.length === 1) {
        return value[0]
      } else {
        return value
      }
    } else {
      return barcode
    }
  }
}
