import { HALApiService } from './../hal-api.service'
import { createItemsData } from './../../helpers/request-helpers'
import { Service } from './base-service'

class EventsService extends Service {
  constructor (httpClient, middleware) {
    super('/notifications/events', httpClient, middleware)
  }

  /**
   * @param {object} query
   *
   * @returns {Promise<object>}
  */
  getAll (query) {
    return this._httpClient
      .get(this._url, query)
      .then(this._middleware)
      .then(data => {
        data.items = data.items.map(item => {
          if (!item.fields) {
            item.fields = []
          }

          return item
        })

        return data
      })
  }

  /**
   * @param {String | Number} id
   *
   * @returns {Promise<object>}
  */
  get (id) {
    const query = {
      filter: [
        { type: 'eq', field: 'id', value: id }
      ]
    }

    return this.getAll(query)
      .then(({ items }) => {
        return items[0]
          ? items[0]
          : Promise.reject(new Error('Event is not found!'))
      })
  }
}

export const eventsService = new EventsService(HALApiService, createItemsData)
