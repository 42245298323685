import { HALApiService } from "./../hal-api.service";

/**
 * Load Products Orders Statistics
 *
 * @param {Object} query
 *
 * @return {Object}
 */
export function loadProductsOrdersStatisticsService(query) {
  return HALApiService.get(
    "/reports/statistics/products/products-orders",
    query
  );
}

/**
 * Load Products Orders Statistics
 *
 * @param {Object} query
 *
 * @return {Object}
 */
export function loadProductsOfferStatisticsService(query) {
  return HALApiService.get(
    "/statistics/products/product-offer-movements",
    query
  );
}

/**
 * Load Delivery Requests Statistics
 *
 * @param {Object} query
 *
 * @return {Object}
 */
export function loadDeliveryRequestStatisticsService(query) {
  return HALApiService.get(
    "/reports/statistics/delivery-services/delivery-services-delivery-requests",
    query
  );
}

/**
 * Load Delivery Requests Statistics
 *
 * @param {Object} query
 *
 * @return {Object}
 */
 export function loadWarehouseInventoryStatisticsService(query) {
  return HALApiService.get(
    "/storage/stats/warehouse-inventory",
    query
  );
}
