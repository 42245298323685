<template>
  <q-dialog v-model="isOpen" :maximized="true">
    <q-card style="min-width: 45vw; max-width: 100vw; min-height: 80vh; max-height: 100vh;">
      <q-card-section class="row items-center q-py-sm">
        <div class="text-h6">
          {{ $t('Product') }}
        </div>

        <q-space />

        <q-btn
          icon="close"
          flat
          round
          dense
          @click="close"
        />
      </q-card-section>

      <q-card-section class="q-px-none q-py-xs">
        <dynamic-counter
          :data="counterData"
          @click="handleClick"
        />
      </q-card-section>

      <q-card-section class="q-px-xs q-pt-xs">
        <dynamic-tasks-collection
          :data="tasks"
          hide-amount
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Components
import DynamicCounter from './dynamic-counter.vue'
import dynamicTasksCollection from './dynamic-tasks-collection.vue'

export default {
  name: 'DynamicCounterModal',
  emits: ['click'],
  components: {
    dynamicTasksCollection,
    DynamicCounter
  },
  data () {
    return {
      isOpen: false,
      tasks: [],
      counterData: {
        max: 0,
        current: 0
      }
    }
  },
  methods: {
    getReducedTasks (collection) {
      return collection.reduce((acc, task) => {
        let isExist = false

        const tasks = acc.map(t => {
          if (t._embedded.productOffer && task._embedded.productOffer && t._embedded.productOffer.id === task._embedded.productOffer.id) {
            isExist = true

            return {
              ...t,
              count: (t.count || 0) + (task.count || 0),
              scannedCount: (t.scannedCount || 0) + (task.scannedCount || 0)
            }
          }

          return t
        })

        if (isExist) {
          return tasks
        }

        acc.push(task)
        return acc
      }, [])
    },
    reduceOffers (collection) {
      return collection.map(offer => {
        return {
          ...offer,
          count: offer.count || 0,
          scannedCount: (offer.count || 0) - (offer.maxCount || 0)
        }
      })
    },
    getBarcodeFromInstructions (product) {
      return ((product.instructions || []).find(({ type }) => type === 'barcode-scan') || { data: { barcode: [] } }).data.barcode
    },
    getBarcodes (product) {
      const barcodesFromInstructions = this.getBarcodeFromInstructions(product)

      if (barcodesFromInstructions.length <= 0) {
        if (product._embedded && product._embedded.productOffer) {
          return product._embedded.productOffer.barcodes || []
        } else {
          let barcodes = (Array.isArray(product.barcodes) && product.barcodes) || []

          if (product.sku) {
            barcodes.push(product.sku)
          }

          return barcodes
        }
      }

      return barcodesFromInstructions
    },
    findTaskByBarcode (tasks, barcode) {
      return tasks.find(task => {
        const hasBarcode = !!this.getBarcodes(task).find(value => value === barcode)
        return hasBarcode && task.count > task.scannedCount
      })
    },
    handleClick (data) {
      this.$emit('click', data)

      if (data.event === 'counterSubmit') {
        this.isOpen = false
      }
    },
    open (collection, barcode, type = 'tasks') {
      const types = {
        tasks: () => {
          return this.getReducedTasks(collection)
        },
        offers: () => {
          return this.reduceOffers(collection)
        }
      }

      const task = this.findTaskByBarcode(types[type](), barcode)

      if (!task) {
        this.$emit('error', 'Product with scanned barcode is not found!')
        return
      }

      this.tasks = [task]

      this.counterData = {
        max: task.count,
        current: task.scannedCount,
        barcode
      }

      this.isOpen = true
    },
    close () {
      this.isOpen = false
      this.$emit('close')
    }
  }
}
</script>
