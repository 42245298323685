import { HALApiService } from '../hal-api.service'
import { createItemsData, mergeQuery } from '../../helpers/request-helpers'
import { CachedService } from './base-service'
import entities from '../../../../config/Entities'

export const deliveryServicesService = new CachedService(
  entities.Orderadmin_DeliveryServices_Entity,
  '/delivery-services',
  HALApiService,
  createItemsData,
  { mergeQuery, query: window.appOptions.deliveryServices }
)
