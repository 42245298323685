<template>
  <q-dialog
    v-model="isOpen"
    persistent
  >
    <q-card class="text-center">
      <q-card-section class="text-h6 q-pb-none">
        {{ $t('Authentication') }}
      </q-card-section>

      <q-card-section class="border-top">
        {{ $t('You are already logged in with this account on another device.') }}
      </q-card-section>

      <q-card-section class="border-top">
        <q-btn
          color="light-blue-9"
          text-color="white"
          class="q-mr-sm"
          :label="$t('Log out all other devices')"
          @click="handleLogOutOthers"
        />

        <q-btn
          color="negative"
          text-color="white"
          class="q-mr-sm"
          :label="$t('Log out')"
          @click="handleLogOut"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'AuthModal',
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    ...mapGetters([
      'isOpenAuthModal'
    ])
  },
  watch: {
    isOpenAuthModal (newValue) {
      this.isOpen = newValue
    }
  },
  methods: {
    ...mapMutations([
      'closeAuthModal'
    ]),
    handleLogOutOthers () {
      return this.$channels.user.logOut()
        .then(() => {
          this.closeAuthModal()
          this.$createSocket()
          this.$centrifuge.connect()
          this.$updateSocket(this.$centrifuge)
          this.$channels.user.subscribe()
        })
    },
    handleLogOut () {
      this.$centrifuge.disconnect()
      this.$channels.user.unsubscribe()
      localStorage.clear()
      this.$eventBus.update('rules', { user: null, app: 'security' })
    }
  }
}
</script>
