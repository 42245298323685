import { HALApiService } from './hal-api.service'
import { createItemsData } from './../utils/request.utils'
import { Service } from './base-service'

class AcceptanceItemsService extends Service {
  constructor (httpClient, middleware) {
    super('/storage/movements/document/item', httpClient, middleware)
  }

  /**
   * @param {object} data
   * @param {String | Number | undefined} id
   * @param {string} state
   * @param {string | number | undefined} place
   * @param {object | undefined} headers
   * @param {object | undefined} query
   *
   * @returns {Promise<object>}
   */
  save (data, id, state, place, headers, query = {}) {
    const updatedData = this._transformBackItem(data, state)

    const updatedHeaders = { ...headers || {} }

    if (place) {
      updatedHeaders['X-Storage-Location'] = place
    }

    return super.save(updatedData, id, updatedHeaders, query).then(x => this._transformItem(x, state))
  }

  /**
   * @param {object} item
   *
   * @returns {object}
   */
  _transformBackItem (item, state) {
    const product = {
      ...item,
      count: undefined,
      expected: undefined
    }

    if (state === 'new') {
      product.quantityExpected = item.count
    } else {
      product.quantityReal = item.count
      product.quantityExpected = item.expected
    }

    return product
  }

  /**
   * @param {object} item
   * @param {string} state
   *
   * @returns {object}
   */
  _transformItem (item, state) {
    const product = {
      ...item._embedded.productOffer,
      ...item,
    }

    if (state === 'new') {
      product.count = item.quantityExpected
      product.expected = 0
    } else {
      product.count = item.quantityReal
      product.expected = item.quantityExpected - item.quantityReal

      if (product.expected < 0) {
        product.expected = 0
      }
    }

    return product
  }

  /**
   * @param {object} query
   * @param {string} state
   * @param {string|number|undefined} place
   * @param {object} headers
   *
   * @returns {Promise<object>}
   */
  getAll (query, state, place, headers = {}) {
    if (place) {
      headers['X-Storage-Location'] = place
    }

    return super.getAll(query, undefined, headers)
      .then(data => {
        return {
          ...data,
          items: data.items.map(x => this._transformItem(x, state))
        }
      })
  }

  /**
   * @param {array} items
   * @param {string} state
   *
   * @returns {Promise<array>}
   */
  saveAll (items, state) {
    return this._httpClient.post(`${this._url}/bulk`, items.map(x => {
      return x.id
        ? { update: this._transformBackItem(x, state) }
        : { create: this._transformBackItem(x, state) }
    }))
  }
}

export const acceptanceItemsService = new AcceptanceItemsService(HALApiService, createItemsData)
