import { HALApiService } from '../hal-api.service'
import { createItemsData } from './../../helpers/request-helpers'

/**
 * Load History
 *
 * @param {Object} query
 *
 * @returns {Promise<Object>}
 */
export function loadHistoryService (query) {
  return HALApiService.get('/entity-versions', query).then(createItemsData)
}
