import store from '../store'

export const storeUtils = {
  mergeModules (modules = []) {
    const store = {
      state: {},
      getters: {},
      mutations: {},
      actions: {}
    }

    modules.forEach(module => {
      store.state = { ...store.state, ...(module.state || {}) }
      store.getters = { ...store.getters, ...(module.getters || {}) }
      store.mutations = { ...store.mutations, ...(module.mutations || {}) }
      store.actions = { ...store.actions, ...(module.actions || {}) }
    })

    return store
  },
  getLockedPlaceEvent (place) {
    return store.getters.storagePlaceEvents.find(x => {
      return `${x.entity}` === `${place}`
    })
  }
}
