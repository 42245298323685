import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    template: null,
    cleanTemplate: null,
    templates: [],
    totalTemplatesNumber: 0,
    templatesPage: 1,
    templatesTotalPages: 1,
    templatesLoading: false
  },
  getters: {
    template: state => state.template,
    cleanTemplate: state => state.cleanTemplate,
    templates: state => state.templates,
    totalTemplatesNumber: state => state.totalTemplatesNumber,
    templatesPage: state => state.templatesPage,
    templatesTotalPages: state => state.templatesTotalPages,
    templatesLoading: state => state.templatesLoading
  },
  mutations: {
    setTemplate (state, payload) {
      state.template = payload
      state.cleanTemplate = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanTemplate (state, payload) {
      state.cleanTemplate = payload
    },
    setTemplatesData (state, { items, page, totalPages, totalItems }) {
      state.templates = items
      state.totalTemplatesNumber = totalItems
      state.templatesPage = page
      state.templatesTotalPages = totalPages
    },
    setTemplates (state, payload) {
      state.templates = payload
    },
    setTotalTemplatesNumber (state, payload) {
      state.totalTemplatesNumber = payload
    },
    setTemplatesPage (state, payload) {
      state.templatesPage = payload
    },
    setTemplatesTotalPages (state, payload) {
      state.templatesTotalPages = payload
    },
    setNewTemplate (state) {
      state.template = {
        name: null,
        state: null,
        event: null,
        template: null,
        _embedded: {
          owner: null,
          channel: null
        }
      }
    },
    updateTemplate (state, payload) {
      state.template = {
        ...state.template,
        ...payload
      }
    },
    updateTemplateEmbedded (state, payload) {
      state.template._embedded = {
        ...state.template._embedded,
        ...payload
      }
    },
    startTemplatesLoading (state) {
      state.templatesLoading = true
    },
    stopTemplatesLoading (state) {
      state.templatesLoading = false
    }
  },
  actions: {
    loadTemplates ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.shops)
      return services.notificationTemplate.getAll(query)
        .then(data => {
          commit('setTemplatesData', data)
          return data
        })
    },
    loadTemplate ({ commit }, payload) {
      return services.notificationTemplate.get(payload)
        .then(item => {
          commit('setTemplate', item)
          return item
        })
    },
    saveTemplate ({ commit, state }) {
      const ignoredFields = ['channel']
      const data = state.template.id
        ? convertEmbedded(difference(state.template, state.cleanTemplate), ignoredFields)
        : convertEmbedded(state.template)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.template)
      }

      const header = state.template.id && state.template.updated
        ? { 'X-Entity-Updated': state.template.updated }
        : {}

      return services.notificationTemplate.save(data, state.template.id, header)
    }
  }
}
