import { errorCatcher, handleResponse } from '../../services/handlers'
import { getToken } from '../../helpers'

let lastUserdata = null
let lastUser = null

/**
 * Get Userdata
 *
 * @returns {object}
 */
 function getUserdata () {
  const userdata = JSON.parse(localStorage.getItem('userData')) || lastUserdata

  // On logout we clean userdata but sometimes event is sended after that
  // So we save value of userdata
  lastUserdata = userdata
  return userdata
}

/**
 * Get User
 *
 * @returns {object}
 */
 function getUser () {
  const user = JSON.parse(localStorage.getItem('user')) || lastUser

  // On logout we clean user but sometimes event is sended after that
  // So we save value of user
  lastUser = user
  return user
}

export function authHeader () {
  // return authorization header with jwt token
  let user = getUser()

  if (user && user.access_token) {
    return {'Authorization': 'Bearer ' + user.access_token}
  } else {
    return {}
  }
}

/**
 * Create Channels
 *
 * @param {Object} socket
 * @param {Object} channels
 * @param {Object} store
 * @param {Object} adapters
 *
 * @returns {Object}
 */
function createChannels (socket, channels, store = {}, adapters = {}) {
  const subcriptions = {}
  const newChannels = {}

  /**
   * Add Channel
   *
   * @param {String} key
   *
   * @returns {Void}
   */
  function addChannel (key) {
    newChannels[key] = {
      subscribe (fn) {
        const channel = typeof adapters[key] === 'function'
          ? adapters[key]()
          : adapters[key] || key

        // Plugin has different options for subscribe
        // Some of them as { subscribe () {}, error () {} } is broken
        // socket.on('subscribe', fn) also not work as expected
        subcriptions[key] = socket.newSubscription(channel, {
          getToken: getToken
        })
        subcriptions[key].subscribe()

        subcriptions[key].on('error', payload => {
          if (channels[key].error) {
            channels[key].error(socket, store, payload, fn)
          }
        })

        subcriptions[key].on('unsubscribe', payload => {
          if (channels[key].unsubscribe) {
            channels[key].unsubscribe(socket, store, payload, fn)
          }
        })
      },
      unsubscribe () {
        subcriptions[key].unsubscribe(key)
      },
      logOut (...value) {
        const data = channels[key].logOut(...value)

        const requestOptions = {
          method: 'POST',
          body: JSON.stringify(data),
          headers: authHeader()
        }

        return fetch(`${window.appOptions.defaultServer || 'https://alpha.orderadmin.eu'}/apps/centrifugo/unsubscribe`, requestOptions)
          .then(data => handleResponse(data, 'POST'))
          .catch(errorCatcher)
      },
      publish (action, ...value) {
        if (!channels[key].publish[action]) {
          console.error(`Channel ${key} don't have publish action ${action}`)
          return
        }

        const data = channels[key].publish[action](...value)

        if (data.data) {
          const userdata = getUserdata()

          data.data.user = userdata.id
          data.data.email = userdata.email
        }

        const requestOptions = {
          method: 'POST',
          body: JSON.stringify(data),
          headers: authHeader()
        }

        return fetch(`${window.appOptions.defaultServer || 'https://alpha.orderadmin.eu'}/apps/centrifugo/event`, requestOptions)
          .then(data => handleResponse(data, 'POST'))
          .catch(errorCatcher)
      }
    }
  }

  Object.keys(channels).forEach(addChannel)

  return newChannels
}

const SocketHelper = {
  install (app, options) {
    app.config.globalProperties.$channels = createChannels(options.socket, options.channels, options.store, options.adapters)

    app.config.globalProperties.$updateSocket = function (socket) {
      app.config.globalProperties.$channels = createChannels(socket, options.channels, options.store, options.adapters)
      return app.config.globalProperties.$channels
    }
  }
}

export default SocketHelper
