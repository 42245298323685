import qs from 'qs'
import i18n from './../../../../../i18n'

export default {
  state: {
    localNotifications: []
  },
  getters: {
    localNotifications: state => state.localNotifications
  },
  mutations: {
    addOrderNotification (state, payload) {
      return

      const isFounded = state.localNotifications.find(x => x.payload && x.payload.order == payload)

      if (isFounded) {
        return
      }

      state.localNotifications = [
        ...state.localNotifications,
        {
          id: new Date().getUTCMilliseconds() + Math.floor(Math.random() * (999999999999999999999999) + 1),
          data: {
            message: `New Order ${payload}`
          },
          payload: {
            order: payload
          }
        }
      ]

      const notification = {
        id: new Date().getUTCMilliseconds(),
        type: 'success',
        payload: {
          order: payload
        },
        message: `New order ${payload}`
      }

      state.notifications.push(notification)
    },
    addLocalNotification (state, payload) {
      state.localNotifications = [
        ...state.localNotifications,
        {
          id: new Date().getUTCMilliseconds() + Math.floor(Math.random() * (999999999999999999999999) + 1),
          data: payload
        }
      ]
    },
    addRequestDebugNotification (state, payload) {
      const notification = {
        id: new Date().getUTCMilliseconds() + Math.floor(Math.random() * (999999999999999999999999) + 1),
        event: 'notifications.request.saved',
        data: {
          ...payload,
          message: i18n.global.tc(`Request to: ` + payload.url),
          event: 'notifications.request.saved'
        }
      }

      state.localNotifications = [
        ...state.localNotifications,
        notification
      ]

      const newNotification = {
        id: notification.id,
        type: 'warning',
        payload: notification.data,
        message: notification.data.message
      }

      state.notifications.push(newNotification)
    },
    addDebugData (state, payload) {
      const notification = {
        id: new Date().getUTCMilliseconds() + Math.floor(Math.random() * (999999999999999999999999) + 1),
        event: 'notifications.request.saved',
        data: {
          ...payload,
          message: i18n.global.tc(`Request: ` + payload.url),
          event: 'notifications.request.saved'
        }
      }

      state.localNotifications = [
        ...state.localNotifications,
        notification
      ]

      const newNotification = {
        id: notification.id,
        type: 'warning',
        payload: notification.data,
        message: notification.data.message
      }

      state.notifications.push(newNotification)
    },
    addResponseDebugNotification (state, payload) {
      const urlData = payload.response.url.split('?')
      const partsFromUrl = urlData[0].split('/api/')
      const url = `/${partsFromUrl[1]}` || urlData
      const query = qs.parse(urlData[1])
      const data = {
        url: partsFromUrl[1]
          ? url
          : urlData[0],
        query,
        data: payload.data,
        response: payload.response
      }

      const notification = {
        id: new Date().getUTCMilliseconds() + Math.floor(Math.random() * (999999999999999999999999) + 1),
        event: 'notifications.response.saved',
        data: {
          ...data,
          message: i18n.global.tc(`Response from: ` + data.url),
          event: 'notifications.response.saved'
        }
      }

      state.localNotifications = [
        ...state.localNotifications,
        notification
      ]

      const newNotification = {
        id: notification.id,
        type: 'warning',
        payload: notification.data,
        message: notification.data.message
      }

      state.notifications.push(newNotification)
    },
    removeLocalNotification (state, payload) {
      state.localNotifications = state.localNotifications.filter(({ id }) => id !== payload)
    },
    removeAllLocalNotifications (state) {
      state.localNotifications = []
    }
  },
  actions: {}
}
