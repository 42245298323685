import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    shops: [],
    shop: null,
    cleanShop: null,
    totalShopsNumber: 0,
    shopsPage: 1,
    shopsTotalPages: 1,
    shopsLoading: false
  },
  getters: {
    shops: state => state.shops,
    shopsLoading: state => state.shopsLoading,
    shop: state => state.shop,
    cleanShop: state => state.cleanShop,
    totalShopsNumber: state => state.totalShopsNumber,
    shopsPage: state => state.shopsPage,
    shopsTotalPages: state => state.shopsTotalPages
  },
  mutations: {
    setShop (state, payload) {
      state.shop = payload
      state.cleanShop = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanShop (state, payload) {
      state.cleanShop = payload
    },
    setShopsData (state, { items, page, totalPages, totalItems }) {
      state.shops = items
      state.totalShopsNumber = totalItems
      state.shopsPage = page
      state.shopsTotalPages = totalPages
    },
    setShops (state, payload) {
      state.shops = payload
    },
    setTotalShopsNumber (state, payload) {
      state.totalShopsNumber = payload
    },
    setShopsPage (state, payload) {
      state.shopsPage = payload
    },
    setShopsTotalPages (state, payload) {
      state.shopsTotalPages = payload
    },
    setNewShop (state) {
      state.shop = {
        name: '',
        state:'active',
        eav:{'call-center-server-address': '', 'interaction-adapter': ''},
        _embedded: {
          owner: null
        }
      }
    },
    updateShop (state, payload) {
      state.shop = {
        ...state.shop,
        ...payload
      }
    },
    updateShopEmbedded (state, payload) {
      state.shop = {
        ...state.shop,
        _embedded: {
          ...state.shop._embedded,
          ...payload
        }
      }
    },
    startShopsLoading (state) {
      state.shopsLoading = true
    },
    stopShopsLoading (state) {
      state.shopsLoading = false
    },
    upsertShop (state, payload) {
      let isExist = false

      state.shops = state.shops.map(x => {
        if (x.id === payload.id) {
          isExist = true
          return payload
        }

        return x
      })

      if (!isExist) {
        state.shops.unshift(payload)
      }
    }
  },
  actions: {
    loadShops ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.shops)
      return services.shop.getAll(query)
        .then(({ items, totalItems, page, totalPages }) => {
          commit('setShopsData', { items, totalItems, page, totalPages })

          if (totalItems === 1) {
            commit('setShop', items[0])
          }

          return { items, totalItems, page, totalPages }
        })
    },
    loadShop ({ commit }, payload) {
      return services.shop.get(payload)
        .then(item => {
          commit('setShop', item)
          return item
        })
    },
    saveShop ({ commit, state }) {
      const data = state.shop.id
        ? convertEmbedded(difference(state.shop, state.cleanShop))
        : convertEmbedded(state.shop)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.shop)
      }

      const header = state.shop.id && state.shop.updated
        ? { 'X-Entity-Updated': state.shop.updated }
        : {}

      return services.shop.save(data, state.shop.id, header)
    }
  }
}
