import XLSX from 'xlsx'

class Factory {
    /**
     * @returns {Promise<function>}
     */
    getBarcodeLib() {
        return import('bwip-js').then(module => module.default)
    }

    /**
     * @returns {Promise<function>}
     */
    getPDFLib() {
        return import('jspdf').then(module => module.default)
    }

    /**
     * @param {string} file
     * @returns {Promise<string>}
     */
    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }

    /**
     * @param {array} data
     *
     * @returns {any}
     */
    createTableFile(data) {
        const filename = 'example.xlsx'
        const wsName = 'SheetJS'
        const wb = XLSX.utils.book_new()
        const ws = XLSX.utils.aoa_to_sheet(data)
        XLSX.utils.book_append_sheet(wb, ws, wsName)
        return XLSX.writeFile(wb, filename)
    }

    /**
     * Get table plugin
     *
     * @returns {object}
     */
    getXLSX() {
        return XLSX
    }

    /**
     * @param {object} data
     *
     * @returns {object}
     */
    createTable(data) {
        return XLSX.read(data, {type: 'binary'})
    }

    /**
     * @param {array} sheet
     *
     * @returns {array}
     */
    createTableArray(sheet) {
        return XLSX.utils.sheet_to_row_object_array(sheet)
    }

    /**
     * @returns {object}
     */
    createFileReader() {
        return new FileReader()
    }

    /**
     * @param {string} url
     *
     * @returns {object}
     */
    createWebsocket(url) {
        return new WebSocket(url)
    }

    /**
     * @param {object} element
     * @param {object} options
     *
     * @returns {object}
     */
    createBarcodeCanvas(element, options) {
        return new Promise((resolve, reject) => {
            this.getBarcodeLib()
                .then(lib => {
                    try {
                        const canvas = lib.toCanvas(element, options)
                        resolve(canvas)
                    } catch (e) {
                        reject(e)
                    }
                })
        })
    }

    /**
     * @param {object} options
     *
     * @returns {object}
     */
    createPDF(options) {
        return this.getPDFLib()
            .then(PDF => {
                return new PDF(options)
            })
            .then(pdf => {
                return import('./../assets/fonts/roboto.json')
                    .then(data => {
                        pdf.addFileToVFS('roboto.ttf', data.default.roboto)
                        pdf.addFont('roboto.ttf', 'roboto', 'normal')
                        pdf.setFont('roboto')
                        return pdf
                    })
            })
    }
}

export const factory = new Factory()
