import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    permission: null,
    cleanPermission: null,
    permissions: [],
    totalPermissionsNumber: 0,
    permissionsPage: 1,
    permissionsTotalPages: 1,
    permissionsLoading: false
  },
  getters: {
    permission: state => state.permission,
    cleanpermission: state => state.cleanPermission,
    permissions: state => state.permissions,
    totalPermissionsNumber: state => state.totalPermissionsNumber,
    permissionsPage: state => state.permissionsPage,
    permissionsTotalPages: state => state.permissionsTotalPages,
    permissionsLoading: state => state.permissionsLoading
  },
  mutations: {
    setPermission (state, payload) {
      state.permission = payload
      state.cleanPermission = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanPermission (state, payload) {
      state.cleanPermission = payload
    },
    setNewPermission (state) {
      state.permission = {}
    },
    updatePermission (state, payload) {
      state.permission = {
        ...state.permission,
        ...payload
      }
    },
    updatePermissionEmbedded (state, payload) {
      state.permission._embedded = {
        ...state._embedded,
        ...payload
      }
    },
    setPermissionsData (state, { items, page, totalPages, totalItems }) {
      state.totalPermissionsNumber = totalItems
      state.permissions = items
      state.permissionsPage = page
      state.permissionsTotalPages = totalPages
    },
    setPermissions (state, payload) {
      state.permissions = payload
    },
    setTotalPermissionsNumber (state, payload) {
      state.totalPermissionsNumber = payload
    },
    setPermissionsPage (state, payload) {
      state.permissionsPage = payload
    },
    setPermissionsTotalPages (state, payload) {
      state.permissionsTotalPages = payload
    },
    startPermissionLoading (state) {
      state.permissionsLoading = true
    },
    stopPermissionLoading (state) {
      state.permissionsLoading = false
    }
  },
  actions: {
    loadPermissions ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.permissions)
      return services.permission.getAll(query)
        .then(data => {
          commit('setPermissionsData', data)
          return data
        })
    },
    loadPermission ({ commit }, payload) {
      return services.permission.get(payload)
        .then(item => {
          commit('setPermission', item)
          return item
        })
    },
    savePermission ({ commit, state }) {
      const data = state.permission.id
        ? convertEmbedded(difference(state.permission, state.cleanPermission))
        : convertEmbedded(state.permission)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.permission)
      }

      const header = state.permission.id
        ? { 'X-Entity-Updated': state.permission.updated }
        : {}

      return services.permission.save(data, state.permission.id, header)
    }
  }
}
