<template>
  <q-layout
    view="hHh LpR fFf"
    class="relative"
    :class="appOptions.theme || ''"
    style="height: 100%"
  >
    <div>
      <portal-target
        ref="topMessage"
        name="top-message"
        @change="handleChange"
        multiple
      />

      <q-scroll-area
        class="relative"
        :style="`height: ${terminalContainerHeight}px; width: 100%;`"
      >
        <q-page-container>
          <router-view />
        </q-page-container>
      </q-scroll-area>

      <portal-target
        ref="bottomMessage"
        name="bottom-message"
        @change="handleChange"
        multiple
      />
    </div>

    <notifications />

    <loader />

    <terminal-menu />

    <auth-modal />
  </q-layout>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import AuthModal from './components/modals/AuthModal.vue'

// Quasar
import { debounce } from 'quasar'

export default {
  name: 'App',
  components: {
    AuthModal
  },
  data () {
    return {
      appOptions: this.$appOptions,
      sub: null,
      top: 0,
      bottom: 0
    }
  },
  computed: {
    ...mapGetters([
      'terminalContainerHeight'
    ])
  },
  mounted () {
    this.$q.dark.set(!!JSON.parse(localStorage.getItem('darkMode')))
    this.$createSocket()
    this.$centrifuge.connect()
    this.$updateSocket(this.$centrifuge)
    this.$channels.user.subscribe()

    this.sub = this.$eventBus.subscribe('rules', ({ appOptions }) => {
      this.appOptions = appOptions
    })

    this.updateHeight = debounce(this.updateHeight, 0.5)
    window.addEventListener('resize', this.updateHeight.bind(this))
  },
  unmounted () {
    window.removeEventListener('resize', this.updateHeight)
    this.sub.unsubscribe()
    this.$centrifuge.disconnect()
    this.$channels.user.unsubscribe()
  },
  methods: {
    ...mapMutations([
      'setTerminalContainerHeight'
    ]),
    updateHeight () {
      const height = window.innerHeight - 55 - this.top - this.bottom
      this.setTerminalContainerHeight(height)
    },
    handleChange () {
      const topElement = this.$refs.topMessage.children()[0]
      const bottomElement = this.$refs.bottomMessage.children()[0]

      if (topElement) {
        this.$nextTick(() => {
          if (topElement.el) {
            this.top = topElement.el.getBoundingClientRect().height
            this.updateHeight()
          }
        })
      } else {
        this.top = 0
        this.updateHeight()
      }

      if (bottomElement) {
        this.$nextTick(() => {
          if (bottomElement.el) {
            this.bottom = bottomElement.el.getBoundingClientRect().height

            if (this.bottom > 14) {
              this.bottom -= 14
            }

            this.updateHeight()
          }
        })
      } else {
        this.bottom = 0
        this.updateHeight()
      }
    }
  }
}
</script>
