export default [
  {
    path: '/terminal/items-info',
    component: () => import('./pages/ItemsInfo.vue')
  },
  {
    path: '/terminal/acceptances',
    component: () => import('./pages/acceptance/Acceptances.vue')
  },
  {
    path: '/terminal/acceptance',
    component: () => import('./pages/acceptance/Acceptance.vue')
  },
  {
    path: '/terminal/acceptance/:id',
    component: () => import('./pages/acceptance/AcceptanceProcessBulk.vue')
  },
  {
    path: '/terminal/move-items',
    component: () => import('./pages/MoveItems.vue')
  },
  {
    path: '/terminal/transfers',
    component: () => import('./pages/transfer/Transfer.vue')
  },
  {
    path: '/terminal/return-parcels',
    component: () => import('./pages/return-parcels/ReturnParcels.vue')
  },
  {
    path: '/terminal/return-parcel',
    component: () => import('./pages/return-parcels/ReturnParcel.vue')
  },
  {
    path: '/terminal/return-parcel/:id',
    component: () => import('./pages/return-parcels/ReturnParcelProcess.vue')
  },
  {
    path: '/terminal/attach-place',
    component: () => import('./pages/AttachPlace.vue')
  },
  {
    path: '/terminal/warehouses',
    component: () => import('./pages/measuring/Warehouses.vue')
  },
  {
    path: '/terminal/measure/:id',
    component: () => import('./pages/measuring/Measuring.vue')
  },
  {
    path: '/terminal/new-measure/:id',
    component: () => import('./pages/measuring/MeasuringNew.vue')
  },
  {
    path: '/terminal/picking',
    component: () => import('./pages/picking/Picking.vue')
  },
  {
    path: '/terminal/inventory-process',
    component: () => import('./pages/inventory-process/InventoryProcess.vue')
  },
  {
    path: '/terminal/place-linking',
    component: () => import('./pages/PlaceLinking.vue')
  },
  {
    path: '/terminal/items-change',
    component: () => import('./pages/ItemsChange.vue')
  },
  {
    path: '/terminal/place-items-change',
    component: () => import('./pages/PlaceItemsChange.vue')
  },
  {
    path: '/terminal/sorting',
    component: () => import('./pages/Sorting.vue')
  },
  {
    path: '/terminal/sorting-new',
    component: () => import('./pages/sorting/Sorting.vue')
  },
  {
    path: '/terminal/dashboard',
    component: () => import('./pages/Dashboard.vue')
  },
  {
    path: '/terminal/fifo-receiving',
    component: () => import('./pages/fifo-receiving/FifoReceiving.vue')
  },
  {
    path: '/terminal/pallet-receiving',
    component: () => import('./pages/pallet-receiving/PalletReceiving.vue')
  },
  {
    path: '/terminal/tracking-numbers-receiving',
    component: () => import('./pages/acceptance/TrackingNumbers.vue')
  },
  {
    path: '/terminal/fast-receiving',
    component: () => import('./pages/acceptance/FastReceiving.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('./pages/ItemsInfo.vue')
  },
  {
    path: '/terminal/ruled-allocation',
    component: () => import('./pages/transfer/RuledAllocation.vue')
  },
  {
    path: '/terminal/ruled-allocation/:id',
    component: () => import('./pages/transfer/RuledAllocationQueue.vue')
  }
]

export const groups = [
  {
    id: 'dashboard',
    path: '/terminal/dashboard',
    color: 'grey-5',
    title: 'Dashboard',
    icon: 'dashboard',
    image: 'assets/terminal/dashboard.png',
    mediumImage: 'assets/terminal/dashboard-medium.png'
  },
  {
    id: 'items-info',
    path: '/terminal/items-info',
    title: 'Info',
    color: 'purple-5',
    icon: 'info',
    image: 'assets/terminal/terminal6.png',
    mediumImage: 'assets/terminal/terminal6-medium.png'
  },
  {
    id: 'picking',
    path: '/terminal/picking',
    title: 'Picking',
    color: 'teal-12',
    icon: 'compare_arrows',
    image: 'assets/terminal/terminal5.png',
    mediumImage: 'assets/terminal/terminal5-medium.png'
  },
  // {
  //   id: 'move-items',
  //   path: '/terminal/move-items',
  //   color: 'secondary',
  //   title: 'Transfers',
  //   icon: 'timeline',
  //   image: 'assets/terminal/terminal11.png',
  //   mediumImage: 'assets/terminal/terminal11-medium.png'
  // },
  {
    id: 'transfers',
    title: 'Transfers',
    icon: 'timeline',
    color: 'secondary',
    image: 'assets/terminal/terminal11.png',
    mediumImage: 'assets/terminal/terminal11-medium.png',
    children: [
      {
        id: 'transfers',
        path: '/terminal/transfers',
        color: 'secondary',
        title: 'Stock',
        icon: 'timeline',
        image: 'assets/terminal/terminal11.png',
        mediumImage: 'assets/terminal/terminal11-medium.png'
      },
      {
        id: 'fifo-receiving',
        path: '/terminal/fifo-receiving',
        color: 'teal',
        title: 'Guided for stock',
        icon: 'description',
        image: 'assets/terminal/terminal11.png',
        mediumImage: 'assets/terminal/terminal11-medium.png'
      },
      {
        id: 'attach-place',
        path: '/terminal/attach-place',
        title: 'Storage units',
        color: 'secondary',
        icon: 'attach_file',
        image: 'assets/terminal/terminal8.png',
        mediumImage: 'assets/terminal/terminal8-medium.png'
      },
      {
        id: 'ruled_allocation',
        path: '/terminal/ruled-allocation',
        color: 'teal',
        title: 'Ruled Allocation',
        icon: 'description',
        image: 'assets/terminal/terminal11.png',
        mediumImage: 'assets/terminal/terminal11-medium.png'
      }
    ]
  },
  {
    id: 125431,
    title: 'Receiving',
    icon: 'description',
    color: 'teal',
    image: 'assets/terminal/terminal1.png',
    mediumImage: 'assets/terminal/terminal1-medium.png',
    children: [
      {
        id: 'acceptance',
        path: '/terminal/acceptances',
        color: 'teal',
        title: 'Manual',
        icon: 'description',
        image: 'assets/terminal/terminal1.png',
        mediumImage: 'assets/terminal/terminal1-medium.png'
      },
      {
        id: 'pallet',
        path: '/terminal/pallet-receiving',
        color: 'teal',
        title: 'Pallet',
        icon: 'description',
        image: 'assets/terminal/terminal1.png',
        mediumImage: 'assets/terminal/terminal1-medium.png'
      },
      {
        id: 'trackingNumber',
        path: '/terminal/tracking-numbers-receiving',
        color: 'teal',
        title: 'Tracking numbers',
        icon: 'description',
        image: 'assets/terminal/terminal1.png',
        mediumImage: 'assets/terminal/terminal1-medium.png'
      },
      {
        id: 'fast',
        path: '/terminal/fast-receiving',
        color: 'teal',
        title: 'Fast receiving',
        icon: 'maps_home_work',
        image: 'assets/terminal/terminal9.png',
        mediumImage: 'assets/terminal/terminal9.png'
      }
    ]
  },
  {
    id: 2124124,
    title: 'Sorting',
    icon: 'sort',
    color: 'yellow-6',
    image: 'assets/terminal/terminal7.png',
    mediumImage: 'assets/terminal/terminal7-medium.png',
    children: [
      {
        id: 'sorting-new',
        path: '/terminal/sorting-new',
        title: 'Sorting',
        color: 'orange-8',
        icon: 'sort',
        image: 'assets/terminal/terminal7.png',
        mediumImage: 'assets/terminal/terminal7-medium.png'
      },
      {
        id: 'sorting',
        path: '/terminal/sorting',
        title: 'Sorting (old)',
        color: 'orange-8',
        icon: 'sort',
        image: 'assets/terminal/terminal7.png',
        mediumImage: 'assets/terminal/terminal7-medium.png'
      }
    ]
  },
  {
    id: 'return-parcels',
    color: 'accent',
    path: '/terminal/return-parcels',
    title: 'Returns',
    icon: 'undo',
    image: 'assets/terminal/terminal4.png',
    mediumImage: 'assets/terminal/terminal4-medium.png'
  },
  {
    id: 2,
    title: 'Warehousing',
    icon: 'corporate_fare',
    color: 'negative',
    image: 'assets/terminal/terminal2.png',
    mediumImage: 'assets/terminal/terminal2-medium.png',
    children: [
      {
        id: 'itemsForMeasuringNew',
        path: '/terminal/warehouses?type=protocol',
        color: 'secondary',
        title: 'Measure',
        icon: 'square_foot',
        image: 'assets/terminal/terminal10.png',
        mediumImage: 'assets/terminal/terminal10-medium.png'
      },
      // {
      //   id: 'itemsForMeasuring',
      //   path: '/terminal/warehouses',
      //   color: 'secondary',
      //   title: 'Measure',
      //   icon: 'square_foot',
      //   image: 'assets/terminal/terminal10.png',
      //   mediumImage: 'assets/terminal/terminal10-medium.png'
      // },
      {
        id: 'inventory',
        path: '/terminal/inventory-process',
        color: 'secondary',
        title: 'Inventory',
        icon: 'maps_home_work',
        image: 'assets/terminal/terminal9.png',
        mediumImage: 'assets/terminal/terminal9-medium.png'
      },
      {
        id: 'places',
        path: '/terminal/place-linking',
        color: 'secondary',
        title: 'Place linking',
        icon: 'maps_home_work',
        image: 'assets/terminal/terminal9.png',
        mediumImage: 'assets/terminal/terminal9-medium.png'
      },
      {
        id: 'items-change',
        path: '/terminal/items-change',
        color: 'secondary',
        title: 'Items change',
        icon: 'maps_home_work',
        image: 'assets/terminal/terminal9.png',
        mediumImage: 'assets/terminal/terminal9-medium.png'
      },
      {
        id: 'place-items-change',
        path: '/terminal/place-items-change',
        color: 'secondary',
        title: 'Place items change',
        icon: 'maps_home_work',
        image: 'assets/terminal/terminal9.png',
        mediumImage: 'assets/terminal/terminal9-medium.png'
      }
    ]
  }
]

/**
 * Get Menu Options by Route
 *
 * @param {array} options
 * @param {string} route
 *
 * @returns {object|null}
 */
function findMenuByRoute (options, route) {
  for (let i = 0; i < options.length; i++) {
    const option = options[i]

    if (option.children) {
      const result = findMenuByRoute(option.children, route)

      if (result) {
        return result
      }
    } else if (option.path === route) {
      return option
    }
  }

  return null
}

/**
 * Get Menu Options by Route
 *
 * @param {string} route
 *
 * @returns {object|null}
 */
export function getMenuOptionByRoute (route) {
  return findMenuByRoute(groups, route)
}

/**
 * Find Menu Option by Parent ID
 *
 * @param {string|number} id
 *
 * @returns {object|undefined}
 */
export function findMenuOptionParent (id) {
  return groups.find(x => x.id === id || (x.children && x.children.find(y => y.id === id)))
}

/**
 * Get Name
 *
 * @param {string} path
 *
 * @returns {string}
 */
function getName (path) {
  if (path[path.length - 1] === 'y') {
    return `${path.slice(0, path.length - 1)}ies`
  }

  return `${path}s`
}

/**
 * Get Option
 *
 * @param {string} route
 *
 * @returns {object|undefined}
 */
export function getOption (route) {
  const parts = route.split('/')

  if (isNaN(Number(parts[parts.length - 1]))) {
    const option = getMenuOptionByRoute(route)

    if (!option) {
      return getMenuOptionByRoute(getName(route))
    }

    return option
  }

  const index = route.lastIndexOf('/')
  return getMenuOptionByRoute(getName(`${route.slice(0, index)}`))
}
