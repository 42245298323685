import { factory } from './factory'
import { deliveryRequestsService } from './requests/delivery-requests.service'
import { localitiesService } from './requests/locality.service'
import { offersService } from './requests/offers.service'
import { ordersService } from './requests/orders.service'
import { postcodesService } from './requests/postcodes.service'

class ImportService {
  _factory
  _localityService
  _postcodeService
  _deliveryRequestsService
  _ordersService
  _offersService

  _drExample
  _orderExample
  _acceptanceItemsExample

  constructor (
    factory,
    localityService,
    postcodeService,
    deliveryRequestsService,
    ordersService,
    offersService
  ) {
    this._factory = factory
    this._localityService = localityService
    this._postcodeService = postcodeService
    this._deliveryRequestsService = deliveryRequestsService
    this._ordersService = ordersService
    this._offersService = offersService
    this._drExample = [['ExtId', 'Name', 'Phone', 'Email', 'postcode', 'recipientLocality', 'street', 'house', 'sender', 'rate', 'servicePoint', 'numberOfPlaces', 'itemExtId', 'placeExtId', 'name', 'payment', 'estimatedCost', 'count']]
    this._orderExample = [
      ['ExtId', 'Name', 'Phone', 'Email', 'Warehouse', 'Shop', 'paymentState', 'postcode', 'locality', 'street', 'house', 'sender', 'deliveryService', 'rate', 'shipmentDate', 'servicePoint', 'offer', 'offerExtId', 'name', 'price', 'count']
    ]
    this._acceptanceItemsExample = [
      ['ProductOfferId', 'ProductOfferExtId', 'ProductOfferArticle', 'ProductOfferSku', 'Shop', 'Sku', 'Barcode', 'Count', 'Batch', 'Expires', 'Price', 'Weight', 'X', 'Y', 'Z']
    ]
  }

  getDRExample () {
    return this._factory.createTableFile(this._drExample)
  }

  getOrderExample () {
    return this._factory.createTableFile(this._orderExample)
  }

  getAcceptanceItemsExample () {
    return this._factory.createTableFile(this._acceptanceItemsExample)
  }

  _constructData (data) {
    return Object.keys(data).reduce((newItem, key) => {
      newItem[`${key[0].toLowerCase()}${key.slice(1)}`] = data[key]
      return newItem
    }, {})
  }

  _createOrderFromRows (data) {
    return data.reduce((acc, item) => {
      const lastOrder = acc[acc.length - 1]
      const convertedItem = this._constructData(item)

      if (!lastOrder || convertedItem.shop) {
        acc.push({ ...convertedItem, products: [] })
        return acc
      }

      lastOrder.products.push({ ...convertedItem, name: convertedItem.name_1 || convertedItem.name, name_1: undefined })
      return acc
    }, [])
  }

  _createDeliveryRequestFromRows (data) {
    return Object.values(
      data.reduce((acc, item) => {
        const currentItem = Object.keys(item).reduce((newItem, key) => {
          newItem[`${key[0].toLowerCase()}${key.slice(1)}`] = item[key]
          return newItem
        }, {})

        const key = `${currentItem.extId}`

        if (currentItem.itemExtId && acc[key]) {
          if (!acc[key].places) {
            acc[key].products = []
          }

          let hasPlace = false
          acc[key].places = acc[key].places.map(place => {
            if (place.extId === currentItem.placeExtId) {
              place.items.push({ ...currentItem, name: currentItem.name_1, name_1: undefined, placeExtId: undefined })
              hasPlace = true
            }

            return place
          })

          if (!hasPlace) {
            acc[key].places.push({ extId: currentItem.placeExtId, items: [{ ...currentItem, name: currentItem.name_1 || currentItem.name, name_1: undefined, placeExtId: undefined }] })
          }

          acc[key].payment = acc[key].places.reduce((acc, place) => {
            return acc + place.items.reduce((totalPrice, item) => {
              return totalPrice + (Number(item.count) * Number(item.payment || 0))
            }, 0)
          }, 0)

          acc[key].estimatedCost = acc[key].places.reduce((acc, place) => {
            return acc + place.items.reduce((totalPrice, item) => {
              return totalPrice + (Number(item.count) * Number(item.estimatedCost || 0))
            }, 0)
          }, 0)
        } else {
          acc[key] = currentItem
          acc[key].places = []
        }

        return acc
      }, {})
    )
  }

  _createAcceptanceItemsRows (data) {
    return data.map(item => {
      return Object.entries(item).reduce((acc, [key, value]) => {
        const actions = {
          productOfferArticle: () => {
            acc._embedded.productOffer.article = value
          },
          productOfferSku: () => {
            acc._embedded.productOffer.sku = value
          },
          productOfferId: () => {
            acc._embedded.productOffer.id = value
            acc.id = value
          },
          productOfferExtId: () => {
            acc._embedded.productOffer.extId = value
          },
          quantity: () => {
            acc.count = value
          },
          shop: () => {
            acc._embedded.shop = { id: value }
          },
          x: () => {
            acc.dimensions.x = value
          },
          y: () => {
            acc.dimensions.y = value
          },
          z: () => {
            acc.dimensions.z = value
          }
        }

        const field = `${key[0].toLocaleLowerCase()}${key.slice(1)}`.replace(' ', '')

        if (actions[field]) {
          actions[field]()
        } else {
          acc[field] = value
        }

        return acc
      }, { dimensions: { x: 0, z: 0, y: 0 }, _embedded: { productOffer: {} } })
    })
  }

  loadAddress (items, localityKey, middleware) {
    return this.loadLocalities(items, localityKey, middleware)
      .then(result => {
        return items.map(i => {
          const data = result.find(({ data }) => data.postcode === i.postcode && data.locality === i[localityKey])

          return {
            ...i,
            [localityKey]: data
              ? data.locality
              : null
          }
        })
      })
  }

  loadLocalities (items, localityKey, middleware) {
    const postcodes = items.reduce((acc, item) => {
      if (!acc.find(val => val.postcode === item.postcode && val.locality === item[localityKey])) {
        acc.push({ postcode: item.postcode, locality: item[localityKey] })
      }

      return acc
    }, [])

    return this.loadQueueWithLocalities(postcodes, [], middleware)
  }

  loadQueueWithLocalities (data, results = [], middleware) {
    return Promise.resolve(true)
      .then(() => {
        const query = this._getQuery(data[0])

        return Promise.all([this._postcodeService.getAll(query.postcode), this._localityService.getAll(query.locality)])
      })
      .then(result => {
        const entity = {
          locality: this._getLocality(data[0], ...result),
          data: data[0]
        }

        if (middleware) {
          middleware()
        }

        const updatedResults = [...results, entity]
        const updatedData = data.slice(1)

        if (updatedData.length > 0) {
          return this.loadQueueWithLocalities(updatedData, updatedResults, middleware)
        }

        return updatedResults
      })
  }

  _getProductParams (product) {
    return Object.entries(product).reduce((acc, [key, value]) => {
      acc.push(`${key}: ${value}`)
      return acc
    }, []).join(', ')
  }

  loadOffersFromAcceptanceItems (rows, middleware, results = [], errors = []) {
    if (rows.length <= 0) {
      return Promise.resolve({
        results,
        errors
      })
    }

    const product = rows[0]
    
    if (!product._embedded.productOffer) {
      errors.push(`Not enough info in product!`)
      return this.loadOffersFromAcceptanceItems(rows.slice(1), middleware, results, errors)
    }

    if (product.expires) {
      const max = new Date('3000-01-01').getTime()
      const current = new Date(product.expires).getTime()

      if (current > max) {
        errors.push(`Maximum expiration date is 3000-01-01. Params of product -> ${this._getProductParams(product._embedded.productOffer)}`)
        return this.loadOffersFromAcceptanceItems(rows.slice(1), middleware, results, errors)
      }
    }

    if (!product._embedded.shop) {
      errors.push(`Store is missing from product! Params of product -> ${this._getProductParams(product._embedded.productOffer)}`)
      return this.loadOffersFromAcceptanceItems(rows.slice(1), middleware, results, errors)
    }

    if (
      !product._embedded.productOffer.extId &&
      !product._embedded.productOffer.id &&
      !product._embedded.productOffer.article &&
      !product._embedded.productOffer.sku
    ) {
      errors.push(`ProductOfferId, ProductOfferExtId, ProductOfferArticle or ProductOfferSku is required! Params of product -> ${this._getProductParams(product._embedded.productOffer)}`)
      return this.loadOffersFromAcceptanceItems(rows.slice(1), middleware, results, errors)
    }

    const query = {
      per_page: 1,
      page: 1,
      filter: [
        { type: 'eq', field: 'shop', value: product._embedded.shop.id }
      ]
    }

    if (product._embedded.productOffer.extId) {
      query.filter.push({ type: 'eq', field: 'extId', value: product._embedded.productOffer.extId })
    } else if (product._embedded.productOffer.id) {
      query.filter.push({ type: 'eq', field: 'id', value: product._embedded.productOffer.id })
    } else if (product._embedded.productOffer.sku) {
      query.filter.push({ type: 'eq', field: 'sku', value: product._embedded.productOffer.sku })
    } else {
      query.filter.push({ type: 'like', field: 'article', value: product._embedded.productOffer.article })
    }

    return this._offersService.getAll(query)
      .then(({ totalItems, items }) => {
        if (totalItems <= 0) {
          errors.push(`Product with params ${this._getErrorParams(query.filter)} is not found`)
          return this.loadOffersFromAcceptanceItems(rows.slice(1), middleware, results, errors)
        }

        if (totalItems > 1) {
          errors.push(`More than one product is found with params ${this._getErrorParams(query.filter)}!`)
          return this.loadOffersFromAcceptanceItems(rows.slice(1), middleware, results, errors)
        }

        const updatedItem = {
          ...items[0],
          ...product,
          id: items[0].id,
          _embedded: {
            productOffer: items[0],
            shop: items[0]._embedded.shop
          }
        }

        // If user dont set manual sku, we use barcode from offer if it is only one
        if (!product.sku && Array.isArray(items[0].barcodes) && items[0].barcodes.length === 1) {
          updatedItem.sku = items[0].barcodes[0]
        }

        results.push(updatedItem)

        if (middleware) {
          middleware(updatedItem)
        }

        return this.loadOffersFromAcceptanceItems(rows.slice(1), middleware, results, errors)
      })
  }

  _getErrorParams (filter) {
    return filter.reduce((acc, filter) => {
      acc.push(`${filter.field}: ${filter.value}`)
      return acc
    }, []).join(', ')
  }

  _getQuery (data) {
    return {
      locality: {
        per_page: 1,
        page: 1,
        search: data.locality,
        filter: [
          {
            type: 'andx',
            conditions: [
              { type: 'eq', field: 'state', value: 'active' },
              {
                type: 'orx',
                conditions: [
                  { type: 'eq', field: 'postcode', value: data.postcode },
                  { type: 'isNull', field: 'postcode' }
                ],
                where: 'or'
              }
            ],
            where: 'and'
          }
        ]
      },
      postcode: {
        page: 1,
        per_page: 1,
        filter: [
          { type: 'eq', field: 'state', value: 'active' },
          { type: 'eq', field: 'extId', value: data.postcode }
        ]
      }
    }
  }

  _getLocality (data, postcode, locality) {
    if (!data.locality) {
      if (!postcode.items[0]) {
        return null
      }

      return postcode.items[0]._embedded.locality
    }

    if (locality.items[0]) {
      return locality.items[0]
    }

    if (postcode.items[0]) {
      return postcode.items[0]._embedded.locality
    }

    return null
  }

  _generateDeliveryRequestFromTable (data) {
    const places = data.places.map(place => {
      return {
        items: place.items.map(item => {
          return {
            extId: item.extId,
            barcodes: [],
            count: item.count,
            dimensions: {
              x: 0,
              y: 0,
              z: 0
            },
            estimatedCost: item.estimatedCost,
            name: item.name,
            payment: item.payment,
            state: 'active',
            weight: item.weight || 1
          }
        })
      }
    })

    const numberOfPlaces = (data.numberOfPlaces || 0) - places.length > 0
      ? data.numberOfPlaces
      : 0

    const additionalPlaces = Array(numberOfPlaces).fill({ items: [] })

    return {
      extId: data.extId,
      recipientName: data.name,
      recipientPhone: data.phone,
      recipientComment: data.recipientComment,
      payment: data.payment,
      estimatedCost: data.estimatedCost,
      sender: data.sender,
      rate: data.rate,
      deliveryDate: data.deliveryDate,
      sendDate: new Date().toDateString(),
      weight: data.weight || 1,
      recipientLocality: data.recipientLocality
        ? data.recipientLocality.id
        : undefined,
      servicePoint: data.servicePoint
        ? data.servicePoint
        : undefined,
      senderName: data.senderName
        ? data.senderName
        : undefined,
      eav: {},
      places: [...places, ...additionalPlaces],
      dimensions: {
        x: 100,
        y: 100,
        z: 100
      },
      recipient: {
        email: data.email,
        name: data.name
      },
      recipientAddress: {
        notFormal: data.notFormal || data.address,
        quarter: data.quarter,
        postcode: data.postcode,
        street: data.street,
        house: data.house,
        locality: data.locality
          ? data.locality.id
          : undefined
      }
    }
  }

  _generateOrderFromTable (data) {
    const orderProducts = data.products.map(product => {
      return {
        count: product.count,
        price: product.price,
        shop: data.shop,
        productOffer: product.offer,
        payment: product.price
      }
    })

    const price = orderProducts.reduce((acc, product) => {
      return acc + (Number(product.count) * Number(product.price))
    }, 0)

    const recipientAddress = {
      postcode: data.postcode,
      street: data.street,
      notFormal: data.notFormal,
      quarter: data.quarter,
      house: data.house,
      locality: this._extractId(data.locality)
    }

    const deliveryRequest = {
      sender: this._extractId(data.sender),
      deliveryService: this._extractId(data.deliveryService),
      sendDate: new Date().toDateString(),
      rate: this._extractId(data.rate),
      servicePoint: this._extractId(data.servicePoint),
      retailPrice: data.retailPrice,
      deliveryDate: data.deliveryDate,
      deliveryTimeStart: data.deliveryTimeStart,
      deliveryTimeEnd: data.deliveryTimeEnd,
      recipientComment: data.recipientComment
    }

    const order = {
      state: data.type === 'return'
        ? 'return'
        : 'pending_queued',
      extId: data.extId,
      date: new Date().toJSON().slice(0, 10).replace(/-/g, '/'),
      paymentState: this._extractId(data.paymentState),
      orderProducts,
      orderPrice: price,
      totalPrice: price,
      eav: {
        'order-reserve-warehouse': this._extractId(data.warehouse)
      },
      shop: this._extractId(data.shop),
      profile: {
        name: data.name,
        email: data.email
      },
      phone: data.phone,
      type: data.type || 'retail',
      shipmentDate: data.shipmentDate
    }

    if (this._hasValues(deliveryRequest) && this._hasValues(recipientAddress)) {
      order.deliveryRequest = {
        ...deliveryRequest,
        recipientAddress: { ...recipientAddress, locality: undefined },
        recipientLocality: recipientAddress.locality
      }
    }

    return order
  }

  _hasValues (obj) {
    return Object.values(obj).find(x => x !== undefined && x !== null)
  }

  _extractId (data) {
    return !!data && typeof data === 'object'
      ? data.id
      : data
  }

  _findOffer (product, offers) {
    let offer = offers.find(x => {
      return x.extId == product.offerExtId
    })

    if (!offer) {
      offer = offers.find(x => {
        return x.article == product.article
      })
    }

    if (!offer) {
      offer = offers.find(x => {
        return x.sku == product.sku
      })
    }

    return offer
  }

  loadProducts (rows, middleware, results = [], errors = []) {
    if (rows.length <= 0) {
      return Promise.resolve({
        results,
        errors
      })
    }

    const product = rows[0]
    
    if (!product) {
      errors.push(`Not enough info in product!`)
      return this.loadProducts(rows.slice(1), middleware, results, errors)
    }

    if (!product.shop) {
      errors.push(`Store is missing from product! Params of product -> ${this._getProductParams(product)}`)
      return this.loadProducts(rows.slice(1), middleware, results, errors)
    }

    if (
      !product.extId &&
      !product.id &&
      !product.article &&
      !product.sku
    ) {
      errors.push(`ProductOfferId, ProductOfferExtId, ProductOfferArticle or ProductOfferSku is required! Params of product -> ${this._getProductParams(product)}`)
      return this.loadProducts(rows.slice(1), middleware, results, errors)
    }

    const query = {
      page: 1,
      per_page: 25,
      filter: [
        { type: 'eq', field: 'shop', value: product.shop }
      ]
    }

    if (product.extId) {
      query.filter.push({ type: 'eq', field: 'extId', value: product.extId })
    } else if (product.id) {
      query.filter.push({ type: 'eq', field: 'id', value: product.id })
    } else if (product.sku) {
      query.filter.push({ type: 'eq', field: 'sku', value: product.sku })
    } else {
      query.filter.push({ type: 'like', field: 'article', value: product.article })
    }

    return this._offersService.getAll(query)
      .then(({ totalItems, items }) => {
        if (totalItems <= 0) {
          errors.push(`Product with params ${this._getErrorParams(query.filter)} is not found`)
          return this.loadProducts(rows.slice(1), middleware, results, errors)
        }

        if (totalItems > 1) {
          errors.push(`More than one product is found with params ${this._getErrorParams(query.filter)}!`)
          return this.loadProducts(rows.slice(1), middleware, results, errors)
        }

        results.push(items[0])

        if (middleware) {
          middleware(items[0])
        }

        return this.loadProducts(rows.slice(1), middleware, results, errors)
      })
  }

  loadProductsForOrders (orders, middleware) {
    const products = orders.reduce((acc, order) => {
      order.products.forEach(x => {
        if (!x.offer) {
          acc.push({ id: x.offer, extId: x.offerExtId, article: x.article, sku: x.sku, shop: order.shop })
        }
      })

      return acc
    }, [])

    return this.loadProducts(products, middleware)
      .then(({ results, errors }) => {
        const res = orders.map(x => {
          x.products = x.products.map(product => {
            if (!product.offer) {
              const offer = this._findOffer(product, results)
  
              if (offer) {
                product.offer = offer.id
              }
            }

            return product
          })

          return x
        })

        return { results: res, errors }
      })
  }

  getOrders (rows) {
    return this._createOrderFromRows(rows)
  }

  getDeliveryRequests (rows) {
    return this._createDeliveryRequestFromRows(rows)
  }

  getAcceptanceItems (rows) {
    return this._createAcceptanceItemsRows(rows)
  }

  getItems (rows, type) {
    const types = {
      orders: () => this.getOrders(rows),
      deliveryRequests: () => this.getDeliveryRequests(rows),
      items: () => this.getAcceptanceItems(rows)
    }

    if (!types[type]) {
      throw new Error(`No action for import ${type}!`)
    }

    return Promise.resolve(types[type]())
  }

  loadFile (file, type) {
    return new Promise((resolve, reject) => {
      const reader = this._factory.createFileReader()

      reader.onload = e => {
        const data = e.target.result
        const table = this._factory.createTable(data)

        if (!table.SheetNames[0]) {
          reject(new Error('Invalid table!'))
          return
        }

        const rows = this._factory.createTableArray(table.Sheets[table.SheetNames[0]])

        this.getItems(rows, type)
          .then(items => {
            resolve(items)
          })
          .catch(err => {
            reject(err)
          })
      }

      reader.readAsBinaryString(file)
    })
  }

  saveDeliveryRequest (row, middleware) {
    const item = this._generateDeliveryRequestFromTable(row)

    return this._deliveryRequestsService.save(item)
      .then(item => {
        if (middleware) {
          middleware(item)
        }

        return item
      })
  }

  saveOrder (row, middleware) {
    const item = this._generateOrderFromTable(row)

    return this._ordersService.save(item, undefined, item.type)
      .then(item => {
        if (middleware) {
          middleware(item)
        }

        return item
      })
  }

  processQueueWithItems (saveFn, items, middleware, results = []) {
    if (items.length <= 0) {
      return Promise.resolve(results)
    }

    return saveFn(items[0], middleware)
      .then((result) => {
        results.push(result)
        return this.processQueueWithItems(saveFn, items.slice(1), middleware, results)
      })
  }

  processQueueWithDeliveryRequests (items, middleware) {
    return this.processQueueWithItems(this.saveDeliveryRequest.bind(this), items, middleware)
  }

  processQueueWithOrders (items, middleware) {
    return this.processQueueWithItems(this.saveOrder.bind(this), items, middleware)
  }
}

export const importService = new ImportService(
  factory,
  localitiesService,
  postcodesService,
  deliveryRequestsService,
  ordersService,
  offersService
)
