import { createItemsData } from '../utils/request.utils'
import { Service } from './base-service'
import { HALApiService } from './hal-api.service'

class SenderService extends Service {
  constructor (httpClient, middleware) {
    super('/delivery-services/senders', httpClient, middleware)
  }

  /**
   * @param {String | Number} id
   *
   * @returns {Promise<object>}
   */
  get (id) {
    const query = {
      per_page: 1,
      page: 1,
      filter: [{ type: 'eq', field: 'id', value: id }]
    }

    return this.getAll(query)
      .then(({ items }) => {
        return items[0]
          ? items[0]
          : Promise.reject(new Error('Sender is not found!'))
      })
  }
}

export const senderService = new SenderService(HALApiService, createItemsData)
