import _ from 'lodash'
import services from './../../../services/services'

import {
    convertEmbedded,
    difference,
    mergeQuery
} from './../../../helpers/request-helpers'

export default {
    state: {
        movements: [],
        movement: null,
        cleanMovement: null,
        totalMovementsNumber: 0,
        movementsPage: 1,
        movementsTotalPages: 1,
        movementsLoading: false
    },
    getters: {
        movements: state => state.movements,
        movement: state => state.movement,
        cleanMovement: state => state.cleanMovement,
        totalMovementsNumber: state => state.totalMovementsNumber,
        movementsPage: state => state.movementsPage,
        movementsTotalPages: state => state.movementsTotalPages,
        movementsLoading: state => state.movementsLoading
    },
    mutations: {
        setMovement (state, payload) {
            state.movement = payload
            state.cleanMovement = typeof payload === 'object'
                ? _.cloneDeep(payload)
                : payload
        },
        setCleanMovement (state, payload) {
            state.cleanMovement = payload
        },
        setMovementsData (state, { items, page, totalPages, totalItems }) {
            state.movements = items
            state.totalMovementsNumber = totalItems
            state.movementsPage = page
            state.movementsTotalPages = totalPages
        },
        setMovements (state, payload) {
            state.movements = payload
        },
        setTotalMovementsNumber (state, payload) {
            state.totalMovementsNumber = payload
        },
        setMovementsPage (state, payload) {
            state.movementsPage = payload
        },
        setMovementsTotalPages (state, payload) {
            state.movementsTotalPages = payload
        },
        setNewMovement (state) {
            state.movement = null
            state.movement = {
                sender: null,
                state: 'new',
                taskState: 'new'
            }
        },
        updateMovement (state, payload) {
            state.movement = {
                ...state.movement,
                ...payload
            }
        },
        updateMovementEmbedded (state, payload) {
            state.movement = {
                ...state.movement,
                _embedded: {
                    ...state.movement._embedded,
                    ...payload
                }
            }
        },
        startMovementsLoading (state) {
            state.MovementsLoading = true
        },
        stopMovementsLoading (state) {
            state.MovementsLoading = false
        },
        upsertMovement (state, payload) {
            let isExist = false

            state.movements = state.movements.map(x => {
                if (x.id === payload.id) {
                    isExist = true
                    return payload
                }

                return x
            })

            if (!isExist) {
                state.movements.unshift(payload)
            }
        }
    },
    actions: {
        loadMovements ({ commit }, payload) {
            const query = mergeQuery(payload, window.appOptions.movements)

            return services.movement.getAll(query)
                .then(({ items, totalItems, page, totalPages }) => {

                    commit('setMovementsData', { items, totalItems, page, totalPages })

                    if (totalItems === 1) {
                        commit('setMovement', items[0])
                    }

                    return { items, totalItems, page, totalPages }
                })
        },
        loadMovement ({ commit }, payload) {
            return services.movement.get(payload)
                .then(item => {
                    commit('setMovement', item)
                    return item
                })
        },
        saveMovement ({ commit, state } , isNew = false) {
            let data
            if(isNew) {
                data = {sender: state.movement.sender , state: 'new', taskState: 'new'}
            } else {
                data = state.movement.id
                    ? convertEmbedded(difference(state.movement, state.cleanMovement))
                    : convertEmbedded(state.movement)
            }

            if (Object.keys(data).length === 0) {
                commit('addWarningNotification', 'No changes!')
                return Promise.resolve(state.movement)
            }

            const header = state.movement.id && state.movement.updated
                ? { 'X-Entity-Updated': state.movement.updated }
                : {}
            if(isNew) {
                return services.movement.save(data, undefined , header)
            }

            return services.movement.save(data, state.movement.id, header)
        }
    }
}