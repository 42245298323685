const permissions = {
  admin: {
    'Orderadmin\\Products\\Entity\\Shop': {
      entity: 'Orderadmin\\Products\\Entity\\Shop',
      global: {
        read: true,
        write: true
      },
      states: [
        {
          states: ['create'],
          fields: {
            name: {
              permissions: ['read', 'write'],
              options: {
                required: true
              }
            },
            owner: {
              permissions: ['read', 'write'],
              options: {
                required: true
              }
            }
          }
        },
        {
          states: ['active', 'inactive'],
          fields: {
            name: {
              permissions: ['read', 'write'],
              options: {
                required: true
              }
            },
            owner: {
              permissions: ['read'],
              options: {
                required: true
              }
            }
          }
        }
      ]
    }
  },
  employee: {
  },
  client: {
    'Orderadmin\\Products\\Entity\\Shop': {
      entity: 'Orderadmin\\Products\\Entity\\Shop',
      global: {
        read: true,
        write: true
      },
      states: [
        {
          states: ['create'],
          fields: {
            name: {
              permissions: ['read', 'write'],
              options: {
                required: true
              }
            },
            owner: {
              permissions: [],
              options: {
                required: true
              }
            }
          }
        },
        {
          states: ['active', 'inactive'],
          fields: {
            name: {
              permissions: ['read', 'write'],
              options: {
                required: true
              }
            },
            owner: {
              permissions: [],
              options: {
                required: true
              }
            }
          }
        }
      ]
    }
  },
  supervisior: {
    'Orderadmin\\Products\\Entity\\Shop': {
      entity: 'Orderadmin\\Products\\Entity\\Shop',
      global: {
        read: true,
        write: true
      },
      states: [
        {
          states: ['create'],
          fields: {
            name: {
              permissions: ['read', 'write'],
              options: {
                required: true
              }
            },
            owner: {
              permissions: ['read', 'write'],
              options: {
                required: true
              }
            }
          }
        },
        {
          states: ['active', 'inactive'],
          fields: {
            name: {
              permissions: ['read', 'write'],
              options: {
                required: true
              }
            },
            owner: {
              permissions: ['read'],
              options: {
                required: true
              }
            }
          }
        }
      ]
    }
  }
}

function getPermissions (map, entities) {
  return entities.reduce((acc, entity) => {
    if (map[entity]) {
      acc[entity] = { ...map[entity] }
    }

    return acc
  }, {})
}

export function getUserPermissionsByRole (entities) {
  const roles = JSON.parse(localStorage.getItem('userData')).roles.map(x => x.id)

  if (roles.includes(3)) {
    return getPermissions(permissions.admin, entities)
  }

  if (roles.includes(29)) {
    return getPermissions(permissions.supervisior, entities)
  }

  if (roles.includes(8)) {
    return getPermissions(permissions.client, entities)
  }

  if (roles.includes(7)) {
    return getPermissions(permissions.employee, entities)
  }

  return {}
}
