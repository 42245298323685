import { Printer } from '../services'
import { factory } from './../services/factory'
import { store } from './../vueX/store'

class PrinterUtils {
  _ctx
  _settings
  _storage
  _printer
  _factory
  _blob

  get settings () {
    return this._settings
  }

  static onError (err) {
    store.commit('addErrorNotification', err)
  }

  constructor (ctx, storage, printer, factory) {
    this._ctx = ctx
    this._storage = storage
    this._settings = this.getPrinterSettings()
    this._printer = printer
    this._factory = factory
  }

  /**
   * @param {number} timeout
   *
   * @returns {void}
   */
  turnOnReconnect (timeout = 5000) {
    this._printer.setTimeout(timeout)
  }

  /**
   * @returns {void}
   */
  turnOffReconnect () {
    this._printer.setTimeout(0)
  }

  /**
   * @returns {void}
   */
  clearBlob () {
    this._blob = null
  }

  /**
   * @returns {object}
   */
  getPrinterSettings () {
    const defaultSettings = {
      autoclose: false,
      forceModal: false,
      timeout: 4500
    }

    const settings = JSON.parse(this._storage.getItem('printerSettings')) || {}

    return { ...defaultSettings, ...settings }
  }

  /**
   * @param {object} update
   *
   * @returns {object}
   */
  updateSettings (update) {
    this._settings = {
      ...this._settings,
      ...update
    }

    this._storage.setItem('printerSettings', JSON.stringify(this._settings))

    return this._settings
  }

  /**
   * @param {Array} data
   *
   * @returns {boolean}
   */
  generatePDFBarcodes (data) {
    return this._factory
      .createPDF({ orientation: 'landscape' })
      .then(pdf => {
        return Promise.all(data.map(this.buildBarcodeCanvas.bind(this)))
          .then(images => {
            pdf.deletePage(1)

            images.forEach(({ fontSize, image, format, title, barcode, label, count = 1 }) => {

              for (let i = 0; i < count; i++) {
                pdf.addPage(format)
                pdf.setFontSize(fontSize || 8)
                pdf.addImage(image, 'PNG', 0, 0, format[0], Math.floor(format[1] / 2.4))

                const options = {
                  align: 'center',
                  maxWidth: Math.floor(format[0] - format[0] / 4)
                }

                pdf.text(`${title || barcode} \n ${label || ''}`, Math.floor(format[0] / 2), Math.floor(format[1] / 2), options)
              }
            })

            const content = pdf.output('datauristring')
            return this.print(content, undefined, undefined)
          })
      })
  }

  /**
   * @param {object} data
   *
   * @returns {boolean}
   */
  buildBarcodeCanvas (data) {
    const format = data.size.split('x')
    const canvas = this._ctx.document.createElement('canvas')
    const type = 'image/png'

    const canvasOptions = {
      bcid: 'code128',
      text: `${data.barcode}`,
      scale: 1,
      paddingtop: Math.floor(format[1] / 6),
      paddingwidth: Math.floor(format[0] / 4),
      height: format[1] / 2,
      includetext: false,
      textxalign: 'center'
    }

    return this._factory.createBarcodeCanvas(canvas, canvasOptions).then(() => ({
      ...data,
      format,
      image: canvas.toDataURL(type)
    }))
  }

  /**
   * @param {object} data
   *
   * @returns {boolean}
   */
  generateBarcode (data) {
    console.debug('BARCODE')

    // Todo: OAAPP-258 If bridge is used we need to post generated label X times
    let pages = 1
    if (data.count) {
      pages = data.count
    }

    if (data.printCount) {
      pages = data[data.printCount]
    }

    const format = data.size.split('x')
    const canvas = this._ctx.document.createElement('canvas')
    const type = 'image/png'

    let paddingTop = 5
    let paddingWidth = 5
    if (data.unit && data.unit === 'in') {
      paddingTop = 1
      paddingWidth = Math.floor(format[0] / 0.25)
    }

    const canvasOptions = {
      bcid: 'code128',
      text: `${data.barcode}`,
      scale: 1,
      paddingtop: paddingTop,
      paddingwidth: paddingWidth,
      height: format[1],
      includetext: false,
      textxalign: 'center'
    }

    this._factory.createBarcodeCanvas(canvas, canvasOptions)
      .then(() => {
        const img = canvas.toDataURL(type)

        this._factory
          .createPDF({
            orientation: data.orientation ?? 'landscape',
            unit: data.unit ?? 'mm',
            format
          })
          .then(pdf => {
            pdf.setFontSize(data.fontSize || 7)

            if (!data.font) {
              pdf.setFont('Arial', data.fontStyle || 'bold')
            }

            for (let i = 1; i <= pages; i++) {
              pdf.addImage(img, 'PNG', 0, 0, format[0], format[1] / 3)

              const options = {
                align: 'center',
                maxWidth: Math.floor(format[0]),
              }

              let label
              if (!data.noTitle) {
                if (data.label) {
                  label = data.barcode + '\n' + data.label
                } else {
                  label = data.barcode
                }
              }

              if (label) {
                if (data.unit && data.unit === 'in') {
                  pdf.text(`${label}`, Math.floor(format[0] / 2) + 0.11, 1 / 2 + 0.1, options)
                } else {
                  pdf.text(`${label}`, Math.floor(format[0] / 2), (format[1] / 2) - 1, options)
                  // pdf.text(`${label}`, Math.floor(format[0] / 2) + 0.11, Math.floor(format[1] / 1.05), options)
                }
                // pdf.text(`${data.noTitle ? '' : `${data.title || data.barcode} \n`} ${data.label || ''}`, Math.floor(format[0] / 2), Math.floor(format[1] / 2), options)
              }

              if (pages > 1 && i !== pages) {
                pdf.addPage()
              }
            }

            const content = pdf.output('datauristring')
            this.print(content, data.printer ?? undefined, undefined, data.forceNoAutoClose)
          })
      })

    return true
  }

  /**
   * @param {String} content
   * @param {String | undefined} size
   * @param {Boolean} noAutoSubmit
   *
   * @returns {Boolean}
   */
  print (content, type = 'LABEL', noAutoSubmit = false, forceNoAutoClose) {
    const isValidForPrint = this._printer && this._printer.isConnected && !content.match('<html') && !this._settings.forceModal

    console.debug(type)
    console.trace('LABEL')
    if (isValidForPrint && !noAutoSubmit) {
      this._printer.submit({
        type: type || 'LABEL',
        url: 'file.pdf',
        'file_content': content.split(',')[1]
      })

      return true
    } else {
      return this.createWindow(content, forceNoAutoClose)
    }
  }

  /**
   * @param {object} template
   *
   * @returns {Promise<boolean>}
   */
  createWindowFromTemplate (template) {
    const typeOfWindows = {
      'application/pdf': () => {
        const requestOptions = {
          method: 'GET',
          headers: {
            'Accept': template.contentType
          }
        }

        return this._ctx.fetch(template.file, requestOptions)
          .then(response => response.text())
          .then(str => {
            return this.createWindow(str, true, template.contentType)
          })
      },
      'text/html': () => {
        const requestOptions = {
          method: 'GET',
          headers: {
            'Accept': template.contentType
          }
        }

        return this._ctx.fetch(template.file, requestOptions)
          .then(response => response.text())
          .then(str => {
            return this.createWindow(str, true, template.contentType)
          })
      },
      default: () => {
        this._ctx.open(template.file, '_blank', 'width=800,height=600', 'rel="noopener"')
        return Promise.resolve(true)
      }
    }

    if (typeOfWindows[template.contentType]) {
      return typeOfWindows[template.contentType]()
    }

    return typeOfWindows.default()
  }

  /**
   * @param {String} content
   * @param {Boolean} forceNoAutoClose
   * @param {string} contentType
   *
   * @returns {Boolean}
   */
  createWindow (content, forceNoAutoClose, contentType) {
    if (content.match('<html') || content.match('<div')) {
      return this.openTextWindow(content, forceNoAutoClose)
    }

    if (content.split(',')[0].match(/:(.*?);/)) {
      return this.openPDFWindow(content, forceNoAutoClose)
    }

    return this.createPDFWindowFromRawPDF(content, forceNoAutoClose, contentType)
  }

  /**
   * @param {String} content
   *
   * @returns {Promise<Boolean>}
   */
  createPDFWindowFromRawPDF (content, forceNoAutoClose, type) {
    this._blob = new Blob([content], { type })
    return this._factory.getBase64(this._blob)
      .then(file => {
        const a = this._ctx.document.createElement('a')
        a.href = file
        a.download = 'document.xlsx'
        a.click()
        return true
      })
  }

  /**
   * @param {String} content
   * @param {Boolean} forceNoAutoClose
   *
   * @returns {Boolean}
   */
  openTextWindow (content, forceNoAutoClose) {
    this._blob = new Blob([content], { type: 'text/html' })
    return this.openWindow(this._ctx.URL.createObjectURL(this._blob), forceNoAutoClose)
  }

  /**
   * @param {String} content
   * @param {Boolean} forceNoAutoClose
   *
   * @returns {Boolean}
   */
  openPDFWindow (content, forceNoAutoClose) {
    let arr = content.split(',')
    let mime = arr[0].match(/:(.*?);/)[1]
    let bstr = atob(arr[1])
    let n = bstr.length
    let u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    this._blob = new Blob([u8arr], { type: mime })
    return this.openWindow(this._ctx.URL.createObjectURL(this._blob), forceNoAutoClose)
  }

  /**
   * @param {String} content
   * @param {Boolean} forceNoAutoClose
   *
   * @returns {Boolean}
   */
  openWindow (content, forceNoAutoClose) {
    const window = this._ctx.open(content, '', 'width=800,height=600', 'rel="noopener"')

    if (!window) {
      PrinterUtils.onError('Pop up is blocked! Please check your browser settings.')
      return false
    }

    if (this._settings.autoclose && this._settings.timeout > 0 && !forceNoAutoClose) {
      window.addEventListener('DOMContentLoaded', () => {
        setTimeout(() => {
          if (window) {
            window.close()
          }
        }, this._settings.timeout)
      })
    }

    window.print()

    return true
  }
}

export const printerUtils = new PrinterUtils(
  window,
  localStorage,
  new Printer('ws://127.0.0.1:12212/printer', factory),
  factory
)
