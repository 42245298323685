import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    locality: null,
    cleanLocality: null,
    localities: [],
    localitiesLoading: false,
    totalLocalitiesNumber: 0,
    localitiesPage: 1,
    localitiesTotalPages: 1
  },
  getters: {
    locality: state => state.locality,
    cleanLocality: state => state.cleanLocality,
    localities: state => state.localities,
    localitiesLoading: state => state.localitiesLoading,
    totalLocalitiesNumber: state => state.totalLocalitiesNumber,
    localitiesPage: state => state.localitiesPage,
    localitiesTotalPages: state => state.localitiesTotalPages
  },
  mutations: {
    setLocality (state, payload) {
      state.locality = payload
      state.cleanLocality = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanLocality (state, payload) {
      state.cleanLocality = payload
    },
    setNewLocality (state) {
      state.locality = {}
    },
    updateLocality (state, payload) {
      state.locality = {
        ...state.locality,
        ...payload
      }
    },
    setLocalitiesData (state, { items, page, totalPages, totalItems }) {
      state.localities = items
      state.totalLocalitiesNumber = totalItems
      state.localitiesPage = page
      state.localitiesTotalPages = totalPages
    },
    setLocalities (state, payload) {
      state.localities = payload
    },
    setTotalLocalitiesNumber (state, payload) {
      state.totalLocalitiesNumber = payload
    },
    setLocalitiesPage (state, payload) {
      state.localitiesPage = payload
    },
    setLocalitiesTotalPages (state, payload) {
      state.localitiesTotalPages = payload
    },
    startLocalitiesLoading (state) {
      state.localitiesLoading = true
    },
    stopLocalitiesLoading (state) {
      state.localitiesLoading = false
    }
  },
  actions: {
    loadLocalities ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.locality)
      return services.locality.getAll(query)
        .then(data => {
          commit('setLocalitiesData', data)
          return data
        })
    },
    loadAccount ({ commit }, payload) {
      return services.locality.get(payload)
        .then(item => {
          commit('setLocality', item)
          return item
        })
    },
    saveLocality ({ commit, state }) {
      const data = state.locality.id
        ? convertEmbedded(difference(state.locality, state.cleanLocality))
        : convertEmbedded(state.locality)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.locality)
      }

      const header = state.locality.id && state.locality.updated
        ? { 'X-Entity-Updated': state.locality.updated }
        : {}

      return services.locality.save(data, state.locality.id, header)
    }
  }
}
