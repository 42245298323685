import { loadHistoryService } from './../../../services/requests/history.service'

export default {
  state: {
    history: [],
    historyItemsNumber: 0,
    historyPage: 1,
    historyTotalPages: 1,
    historyLoading: false
  },
  getters: {
    history: state => state.history,
    historyLoading: state => state.historyLoading,
    historyItemsNumber: state => state.historyItemsNumber,
    historyPage: state => state.historyPage,
    historyTotalPages: state => state.historyTotalPages
  },
  mutations: {
    setHistoryData (state, { items, page, totalPages, totalItems }) {
      state.history = items
      state.historyItemsNumber = totalItems
      state.historyPage = page
      state.historyTotalPages = totalPages
    },
    setHistory (state, payload) {
      state.history = payload
    },
    setHistoryItemsNumber (state, payload) {
      state.historyItemsNumber = payload
    },
    setHistoryPage (state, payload) {
      state.historyPage = payload
    },
    setHistoryTotalPages (state, payload) {
      state.historyTotalPages = payload
    },
    startHistoryLoading (state) {
      state.historyLoading = true
    },
    stopHistoryLoading (state) {
      state.historyLoading = false
    }
  },
  actions: {
    loadRequestHistory ({ commit }, payload) {
      return loadHistoryService(payload)
        .then(data => {
          commit('setHistoryData', data)
          return data
        })
    }
  }
}
