import _ from 'lodash'
import services from './../../../services/services'

import { convertEmbedded, difference, mergeQuery } from './../../../helpers/request-helpers'

export default {
  state: {
    places: [],
    place: null,
    cleanPlace: null,
    totalPlacesNumber: 0,
    placesPage: 1,
    placesTotalPages: 1,
    placesLoading: false
  },
  getters: {
    places: state => state.places,
    placesLoading: state => state.placesLoading,
    place: state => state.place,
    cleanPlace: state => state.cleanPlace,
    totalPlacesNumber: state => state.totalPlacesNumber,
    placesPage: state => state.placesPage,
    placesTotalPages: state => state.placesTotalPages
  },
  mutations: {
    setPlace (state, payload) {
      state.place = payload
      state.cleanPlace = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanPlace (state, payload) {
      state.cleanPlace = payload
    },
    setNewPlace (state) {
      state.place = {}
    },
    updatePlace (state, payload) {
      state.place = {
        ...state.place,
        ...payload
      }
    },
    setPlacesData (state, { items, page, totalPages, totalItems }) {
      state.places = items
      state.totalPlacesNumber = totalItems
      state.placesPage = page
      state.placesTotalPages = totalPages
    },
    setPlaces (state, payload) {
      state.places = Object.values(payload)
    },
    setPlaceByIndex (state, { index, updatedPlace }) {
      state.places[index] = updatedPlace
    },
    setPlaceUpdate (state, updatedPlace) {
      let index = state.places.findIndex(place => {
        return place.id === updatedPlace.id
      })

      if (index !== -1) {
        state.places[index] = updatedPlace
      }
    },
    setTotalPlacesNumber (state, payload) {
      state.totalPlacesNumber = payload
    },
    setPlacesPage (state, payload) {
      state.placesPage = payload
    },
    setPlacesTotalPages (state, payload) {
      state.placesTotalPages = payload
    },
    startPlacesLoading (state) {
      state.placesLoading = true
    },
    stopPlacesLoading (state) {
      state.placesLoading = false
    }
  },
  actions: {
    loadPlaces ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.places)
      return services.deliveryServicePlace.getAll(query)
        .then(data => {
          commit('setPlacesData', data)
          return data
        })
    },
    loadPlace ({ commit }, payload) {
      return services.deliveryServicePlace.get(payload)
        .then(item => {
          commit('setPlace', item)
          return item
        })
    },
    savePlace ({ commit, state }) {
      const data = state.place.id
        ? convertEmbedded(difference(state.place, state.cleanPlace))
        : convertEmbedded(state.place)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.place)
      }

      const header = state.place.id && state.place.updated
        ? { 'X-Entity-Updated': state.place.updated }
        : {}

      return services.deliveryServicePlace.save(data, state.place.id, header)
    }
  }
}
