export class Validation {
  _options

  constructor (options) {
    this._options = options
  }

  /**
   * @param {object} data
   *
   * @returns {string}
  */
  notificationTask (data) {
    if (!data.name) {
      return 'Please fill name of task!'
    }

    if (!data.template) {
      return 'Please fill template of task!'
    }

    return ''
  }

  /**
   * @param {object} data
   *
   * @returns {string}
  */
  notificationChannel (data) {
    if (!data.name) {
      return 'Please fill name!'
    }

    if (!data.handler) {
      return 'Please set handler!'
    }

    if (!data.type) {
      return 'Please set type!'
    }

    if (!data.access) {
      return 'Please set access type!'
    }

    return ''
  }

  /**
   * @param {object} order
   *
   * @returns {string}
  */
  profile (data) {
    if (!data.name) {
      return 'Please fill in the Name field.'
    }

    return ''
  }

  /**
   * @param {object} order
   *
   * @returns {string}
  */
  order (data, orderType) {
    if (orderType && window.appOptions.orderTypes[orderType]?.validators) {
      const errors = [];

      window.appOptions.orderTypes[orderType]?.validators.forEach(validator => {
        if (validator.required && !data[validator.field] && !data._embedded[validator.field]) {
          errors.push(`The '${validator.field}' field is required.`);
        }
      })

      if (errors.length > 0) {
        return errors
      }
    }

    if (data.comment && `${data.comment}`.length > 255) {
      return 'Please use maximum 255 characters for comment.'
    }

    if (!data._embedded.shop) {
      return 'Please set shop.'
    }

    return ''
  }

  /**
   * @param {object} order
   * @param {boolean} isCourier
   *
   * @returns {string}
   */
  domesticAddress (address, isCourier) {
    if (!address.postcode) {
      if (this._options.disabledPostcodeVerification) {
        return ''
      }

      return 'Postcode is not set!'
    }

    if (!address._embedded || !address._embedded.locality) {
      if (this._options.disabledLocalityVerification) {
        return ''
      }

      return 'Locality is not set!'
    }

    if (isCourier) {
      if (!address.street) {
        if (this._options.disabledStreetVerification) {
          return ''
        }

        return 'Address line 1 is not set!'
      }

      if (!address.notFormal) {
        if (this._options.disabledNotFormalVerification) {
          return ''
        }

        return 'Address line 2 is not set!'
      }
    }

    return ''
  }

  /**
   * @param {object} order
   * @param {boolean} isCourier
   *
   * @returns {string}
   */
  internationalAddress (address, isCourier) {
    if (!address) {
      if (this._options.disabledAddressVerification) {
        return ''
      }

      return 'Address is not set!'
    }

    if (!address.postcode) {
      if (this._options.disabledPostcodeVerification) {
        return ''
      }

      return 'Postcode is not set!'
    }

    if (!address._embedded || !address._embedded.locality) {
      if (this._options.disabledLocalityVerification) {
        return ''
      }

      return 'Locality is not set!'
    }

    if (isCourier) {
      if (!address.street) {
        if (this._options.disabledStreetVerification) {
          return ''
        }

        return 'Street is not set!'
      }

      // if (!address.house) {
      //   if (this._options.disabledHouseerification) {
      //     return ''
      //   }
      //
      //   return 'House is not set!'
      // }
    }

    return ''
  }

  /**
   * @param {object} order
   * @param {boolean} isCourier
   * @param {boolean} isDomesticOrder
   *
   * @returns {string}
   */
  address (address, isCourier, isDomesticOrder) {
    if (this._options.disabledAllVerifications) {
      return ''
    }

    if (isDomesticOrder) {
      return this.domesticAddress(address, isCourier)
    }

    return this.internationalAddress(address, isCourier)
  }

  /**
   * @param {object} deliveryRequest
   *
   * @returns {string}
   */
  internationalDeliveryRequest (deliveryRequest) {
    if (!deliveryRequest._embedded.recipientAddress) {
      if (this._options.disabledRecipientAddressVerification) {
        return ''
      }

      return 'Recipient address is not set!'
    }

    if (!deliveryRequest._embedded.rate) {
      if (this._options.disabledRateVerification) {
        return ''
      }

      return 'Rate is not set!'
    }

    if (!deliveryRequest._embedded.recipientAddress.postcode) {
      if (this._options.disabledPostcodeVerification) {
        return ''
      }

      return 'Postcode is not set!'
    }

    if (!deliveryRequest._embedded.sender) {
      if (this._options.disabledSenderVerification) {
        return ''
      }

      return 'Sender is not set!'
    }

    if (!deliveryRequest._embedded.recipientAddress._embedded || !deliveryRequest._embedded.recipientAddress._embedded.locality) {
      if (this._options.disabledLocalityVerification) {
        return ''
      }

      return 'Locality is not set!'
    }

    const types = ['service_point', 'self_service_point']

    if (types.includes(deliveryRequest._embedded.rate.type) && !deliveryRequest._embedded.servicePoint && !this._options.disabledServicePointsVerification) {
      return 'Service Point is not set!'
    }

    if (
      (deliveryRequest._embedded.rate.type === 'simple' && !deliveryRequest._embedded.servicePoint) ||
      deliveryRequest._embedded.rate.type === 'courier'
    ) {
      if (!deliveryRequest._embedded.recipientAddress.street) {
        if (this._options.disabledStreetVerification) {
          return ''
        }

        return 'Street is not set!'
      }

      // if (!deliveryRequest._embedded.recipientAddress.house) {
      //   if (this._options.disabledHouseVerification) {
      //     return ''
      //   }
      //
      //   return 'House is not set!'
      // }
    }

    return ''
  }

  /**
   * @param {object} deliveryRequest
   *
   * @returns {string}
   */
  domasticDeliveryRequest (deliveryRequest) {
    if (!deliveryRequest._embedded.recipientAddress) {
      if (this._options.disabledRecipientAddressVerification) {
        return ''
      }

      return 'Recipient address is not set!'
    }

    if (!deliveryRequest._embedded.rate) {
      if (this._options.disabledRateVerification) {
        return ''
      }

      return 'Rate is not set!'
    }

    if (!deliveryRequest._embedded.recipientAddress.postcode) {
      if (this._options.disabledPostcodeVerification) {
        return ''
      }

      return 'Postcode is not set!'
    }

    if (!deliveryRequest._embedded.sender) {
      if (this._options.disabledSenderVerification) {
        return ''
      }

      return 'Sender is not set!'
    }

    if (!deliveryRequest._embedded.recipientAddress._embedded || !deliveryRequest._embedded.recipientAddress._embedded.locality) {
      if (this._options.disabledLocalityVerification) {
        return ''
      }

      return 'Locality is not set!'
    }

    const types = ['service_point', 'self_service_point']

    if (types.includes(deliveryRequest._embedded.rate.type) && !deliveryRequest._embedded.servicePoint && !this._options.disabledServicePointsVerification) {
      return 'Service Point is not set!'
    }

    if (
      (deliveryRequest._embedded.rate.type === 'simple' && !deliveryRequest._embedded.servicePoint) ||
      deliveryRequest._embedded.rate.type === 'courier'
    ) {
      if (!deliveryRequest._embedded.recipientAddress.street) {
        if (this._options.disabledStreetVerification) {
          return ''
        }

        return 'Address line 1 is not set!'
      }

      if (!deliveryRequest._embedded.recipientAddress.notFormal) {
        if (this._options.disabledNotFormalVerification) {
          return ''
        }

        return 'Address line 2 is not set!'
      }
    }

    return ''
  }

  /**
   * @param {object} deliveryRequest
   * @param {boolean} isDomestic
   *
   * @returns {string}
   */
  deliveryRequest (deliveryRequest, isDomestic) {
    if (this._options.disabledAllVerifications) {
      return ''
    }

    if (isDomestic) {
      return this.domasticDeliveryRequest(deliveryRequest)
    }

    return this.internationalDeliveryRequest(deliveryRequest)
  }

  /**
   * @param {object} deliveryRequest
   * @param {boolean} isReturn
   *
   * @returns {string}
   */
  baseDeliveryRequest (deliveryRequest, isReturn) {
    const types = ['service_point', 'self_service_point']

    if (deliveryRequest.trackingNumber && isReturn) {
      if (!deliveryRequest._embedded.sender) {
        return 'Recipient is required!'
      }

      if (!deliveryRequest._embedded.deliveryService) {
        return 'Shipping carrier is required!'
      }

      return ''
    }

    if (!deliveryRequest._embedded.rate) {
      return 'Rate is not set!'
    }

    if (!deliveryRequest._embedded.sender) {
      return 'Sender is not set!'
    }

    if (types.includes(deliveryRequest._embedded.rate.type) && !deliveryRequest._embedded.servicePoint) {
      return 'Service Point is not set!'
    }

    if (deliveryRequest.recipientComment && `${deliveryRequest.recipientComment}`.length > 255) {
      return 'Please use maximum 255 characters for comment for the shipping carrier.'
    }

    return ''
  }

  /**
   * @param {object} account
   *
   * @returns {string}
   */
  account (account) {
    if (!account.type) {
      return 'Type is required!'
    }

    if (account.type === 'deposit' && !account._embedded.rate) {
      return 'Billing account is required!'
    }

    if (!account._embedded.owner) {
      return 'Owner is required'
    }

    if (!account.name) {
      return 'Account name is required'
    }

    if (!account._embedded.contractor) {
      return 'Contractor is required!'
    }

    if (!account._embedded.customer) {
      return 'Customer is required!'
    }

    // if (!account._embedded.currency) {
    //   return 'Currency is required!'
    // }
    //
    return ''
  }

  /**
   * @param {object} data
   *
   * @returns {string}
   */
   wholesaleOrder (data) {
    if (!data.shop) {
      return 'Store is required!'
    }

    if (!data.source) {
      return 'Ecommerce platform is required!'
    }

    return ''
  }
}