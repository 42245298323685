import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    event: null,
    cleanEvent: null,
    events: [],
    eventsLoading: false,
    totalEventsNumber: 0,
    eventsPage: 1,
    eventsTotalPages: 1
  },
  getters: {
    event: state => state.event,
    cleanEvent: state => state.cleanEvent,
    events: state => state.events,
    eventsLoading: state => state.eventsLoading,
    totalEventsNumber: state => state.totalEventsNumber,
    eventsPage: state => state.eventsPage,
    eventsTotalPages: state => state.eventsTotalPages
  },
  mutations: {
    setEvent (state, payload) {
      state.event = payload

      state.cleanEvent = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanEvent (state, payload) {
      state.cleanEvent = payload
    },
    setNewEvent (state) {
      state.event = {
        entity: null,
        entityType: null,
        eventInitializer: null,
        result: null,
        state: null,
        template: null,
        fields: [],
        _embedded: {
          owner: null,
          channel: null,
          task: null
        }
      }
    },
    updateEvent (state, payload) {
      state.event = {
        ...state.event,
        ...payload
      }
    },
    updateEventEmbedded (state, payload) {
      state.event._embedded = {
        ...state.event._embedded,
        ...payload
      }
    },
    setEventsData (state, { items, page, totalPages, totalItems }) {
      state.events = items
      state.totalEventsNumber = totalItems
      state.eventsPage = page
      state.eventsTotalPages = totalPages
    },
    setEvents (state, payload) {
      state.events = payload
    },
    setTotalEventsNumber (state, payload) {
      state.totalEventsNumber = payload
    },
    setEventsPage (state, payload) {
      state.eventsPage = payload
    },
    setEventsTotalPages (state, payload) {
      state.eventsTotalPages = payload
    },
    startEventsLoading (state) {
      state.eventsLoading = true
    },
    stopEventsLoading (state) {
      state.eventsLoading = false
    }
  },
  actions: {
    loadEvents ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.events)
      return services.notificationEvent.getAll(query)
        .then(data => {
          commit('setEventsData', data)
          return data
        })
    },
    loadEvent ({ commit }, payload) {
      return services.notificationEvent.get(payload)
        .then(item => {
          commit('setEvent', item)
          return item
        })
    },
    saveEvent ({ commit, state }) {
      const ignoredFields = ['channel', 'task']

      const data = state.event.id
        ? convertEmbedded(difference(state.event, state.cleanEvent), ignoredFields)
        : convertEmbedded(state.event)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.event)
      }

      const header = state.event.id && state.event.updated
        ? { 'X-Entity-Updated': state.event.updated }
        : {}

      return services.notificationEvent.save(data, state.event.id, header)
    }
  }
}
