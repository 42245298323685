import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  createAccountStatisticsData,
  createDate,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    accountStatistics: [],
    digitalMarks: [],
    digitalMark: null,
    cleanDigitalMark: null,
    totalDigitalMarksNumber: 0,
    digitalMarksPage: 1,
    digitalMarksTotalPages: 1,
    digitalMarksLoading: false
  },
  getters: {
    digitalMarks: state => state.digitalMarks,
    digitalMarksLoading: state => state.digitalMarksLoading,
    digitalMark: state => state.digitalMark,
    cleanDigitalMark: state => state.cleanDigitalMark,
    totalDigitalMarksNumber: state => state.totalDigitalMarksNumber,
    digitalMarksPage: state => state.digitalMarksPage,
    digitalMarksTotalPages: state => state.digitalMarksTotalPages,
    accountStatistics: state => state.accountStatistics
  },
  mutations: {
    setDigitalMark (state, payload) {
      state.digitalMark = payload
      state.cleanDigitalMark = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanDigitalMark (state, payload) {
      state.cleanDigitalMark = payload
    },
    setNewDigitalMark (state) {
      state.digitalMark = {
        comment: null,
        entity: null,
        entityType: null,
        extId: null,
        value: null,
        digitalMarkDate: createDate(null),
        _embedded: {
          account: null,
          currency: null,
          rateModifier: null
        }
      }
    },
    updateDigitalMark (state, payload) {
      state.digitalMark = {
        ...state.digitalMark,
        ...payload
      }
    },
    setDigitalMarksData (state, { items, page, totalPages, totalItems }) {
      state.digitalMarks = items
      state.totalDigitalMarksNumber = totalItems
      state.digitalMarksPage = totalPages
      state.digitalMarksTotalPages = page
    },
    setDigitalMarks (state, payload) {
      state.digitalMarks = payload
    },
    setTotalDigitalMarkNumber (state, payload) {
      state.totalDigitalMarksNumber = payload
    },
    setDigitalMarksPage (state, payload) {
      state.digitalMarksPage = payload
    },
    setDigitalMarksTotalPages (state, payload) {
      state.digitalMarksTotalPages = payload
    },
    setAccountStatistics (state, payload) {
      state.accountStatistics = payload
    },
    startDigitalMarkLoading (state) {
      state.digitalMarksLoading = true
    },
    stopDigitalMarkLoading (state) {
      state.digitalMarksLoading = false
    },
    upsertDigitalMark (state, payload) {
      let isExist = false

      state.digitalMarks = state.digitalMarks.map(x => {
        if (x.id === payload.id) {
          isExist = true
          return payload
        }

        return x
      })

      if (!isExist) {
        state.digitalMarks.unshift(payload)
      }
    }
  },
  actions: {
    loadDigitalMarks ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.digitalMarks)
      return services.digitalMark.getAll(query)
        .then(data => {
          commit('setDigitalMarksData', data)
          return data
        })
    },
    saveDigitalMark ({ commit, state }) {
      const data = state.digitalMark.id
        ? convertEmbedded(difference(state.digitalMark, state.cleanDigitalMark))
        : convertEmbedded(state.digitalMark)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.digitalMark)
      }

      const header = state.digitalMark.id && state.digitalMark.updated
        ? { 'X-Entity-Updated': state.digitalMark.updated }
        : {}

      return services.digitalMark.save(data, state.digitalMark.id, header)
    }
  }
}
