<template>
  <q-tr
      :props="data"
      :class="bg"
      class="clickable"
      @dblclick="handleClick"
  >
    <q-td
        key="offer"
        :props="data"
        style="max-width: 200px; white-space: pre-wrap !important;"
    >
      <div
          v-if="data.row._embedded && data.row._embedded.productOffer"
          class="row"
      >
        <div
            v-if="$q.screen.width > 599"
            class="q-mr-sm"
            @click="handleImage"
        >
          <img
              style="height: 80px; width: 80px; object-fit: contain;"
              :src="data.row._embedded.productOffer.image || fallbackImage"
              :alt="data.row._embedded.productOffer.name"
              @error="onImageLoadFailure"
          >
        </div>

        <div class="col">
          <div
              class="text-subtitle2"
              @click="handleImage"
          >
            {{ `${data.row._embedded.productOffer.name} (${data.row._embedded.productOffer.id})` }}
          </div>

          <q-btn
              color="positive"
              size="sm"
              class="text-caption q-mt-sm"
              :label="barcodes.length <= 0 ? $t('No barcodes') : $t('Last Barcode') + ': ' + barcodes[barcodes.length - 1]"
              no-caps
              @click="handleExpand"
          />

          <q-slide-transition>
            <div
                v-if="isExpanded"
                class="q-py-xs text-caption text-center"
            >
              {{ $t('Barcodes') + ': ' + barcodes.join(', ') }}
            </div>
          </q-slide-transition>
        </div>

        <q-dialog
            v-model="isOpenImage"
            :maximized="true"
            transition-show="slide-up"
            transition-hide="slide-down"
        >
          <q-card
              class="row items-center justify-center"
              style="background: rgba(0, 0, 0, .7);"
              @click="handleImage"
          >
            <img
                style="height: 90vh; width: 90vw; object-fit: contain;"
                :src="data.row._embedded.productOffer.image || fallbackImage"
                :alt="data.row._embedded.productOffer.name"
                @error="onImageLoadFailure"
            >
          </q-card>
        </q-dialog>
      </div>

      <div v-else>
        <div class="text-subtitle2">{{ $t('Task') + ': ' + data.row.id }}</div>

        <q-btn
            color="positive"
            size="sm"
            class="text-caption q-mt-sm"
            :label="barcodes.length <= 0 ? $t('No barcodes') : $t('Last Barcode') + ': ' + barcodes[barcodes.length - 1]"
            no-caps
            @click="handleExpand"
        />

        <q-slide-transition>
          <div
              v-if="isExpanded"
              class="q-py-xs text-caption text-center"
          >
            {{ $t('Barcodes') + ': ' + barcodes.join(', ') }}
          </div>
        </q-slide-transition>
      </div>
    </q-td>

    <q-td
        key="quantity"
        :props="data"
        auto-width
    >
      <strong class="text-subtitle2 text-weight-bold">{{ (data.row.scannedCount || 0) + '/' + data.row.count }}</strong>
    </q-td>
  </q-tr>
</template>

<script>
export default {
  name: 'DynamicTasksRow',
  emits: ['click'],
  props: {
    rawData: {
      type: Object,
      default () {
        return null
      }
    },
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      fallbackImage: 'assets/img/fallback-image/package.png',
      isExpanded: false,
      isOpenImage: false
    }
  },
  computed: {
    bg () {
      if ((this.data.row.scannedCount || 0) === 0) {
        return 'bg-negative'
      }

      if ((this.data.row.scannedCount || 0) >= this.data.row.count) {
        return 'bg-positive'
      }

      return 'bg-warning'
    },
    barcodes () {
      return this.getBarcodes(this.data.row)
    },
    isClickEnabled () {
      if (!this.rawData || this.rawData.options.clickable === false) {
        return false
      }

      return true
    }
  },
  methods: {
    handleImage () {
      this.isOpenImage = !this.isOpenImage
    },
    handleExpand () {
      if (this.barcodes.length <= 1) {
        return
      }

      this.isExpanded = !this.isExpanded
    },
    handleClick () {
      if (!this.isClickEnabled) {
        return
      }

      if (this.rawData.options.selectable && this.data.row?.eav?.barcode) {
        this.$emit( 'click', {
          event: 'storage.tasks.task.offer.opened',
          barcodes: [this.data.row.id],
          barcodeID: this.data.row.eav.barcode,
          id: this.data.row.eav.barcode
        })
        return;
      } else if ( this.rawData.options.selectable && !this.data.row?.eav?.barcode ) {
        this.$emit( 'click', {
          event: 'storage.tasks.task.offer.opened',
          barcodes: [this.data.row.id],
          barcodeID: this.data.row._embedded.productOffer.id,
          id: this.data.row._embedded.productOffer.id
        })
        return;
      } else if (!this.rawData.options.selectable && this.data.row?.eav?.barcode) {
        this.$emit('click', {
          id: this.data.row.eav.barcode,
          event: 'storage.tasks.task.offer.opened',
          barcodes: this.barcodes
        })
        return
      } else {
        this.$emit('click', {
          id: this.data.row?._embedded?.productOffer?.id,
          event: 'storage.tasks.task.offer.opened',
          barcodes: this.barcodes
        })
      }

    },
    onImageLoadFailure (e) {
      e.target.src = this.fallbackImage
    },
    getBarcodeFromInstructions (product) {
      return ((product.instructions || []).find(({ type }) => type === 'barcode-scan') || { data: { barcode: [] } }).data.barcode
    },
    getBarcodes (product) {
      const barcodesFromInstructions = this.getBarcodeFromInstructions(product)

      if (barcodesFromInstructions.length <= 0 && product._embedded && product._embedded.productOffer) {
        return product._embedded.productOffer.barcodes || []
      }

      return barcodesFromInstructions
    }
  }
}
</script>
