import { HALApiService } from './../hal-api.service'
import { createItemsData } from './../../helpers/request-helpers'
import { Service } from './base-service'

class UserService extends Service {
    _router

    constructor(httpClient, middleware, router) {
        super('/users/users', httpClient, middleware)

        this._router = router
    }

    /**
     * @returns {Promise<object>}
     */
    getCurrentUser() {
        return this.get(0)
    }

    getAuthentication(id) {
        return this._httpClient.get(`/users/authenticate/${id}`)
    }

    /**
     * @param {object} userdata
     *
     * @returns {promise<object>}
     */
    authAs(userdata) {
        this.getAuthentication(userdata.id).then((auth) => {
            console.log(auth)
        })
    }

    getPDF(id) {
        return this._httpClient.get(`/users/badge/${id}`, {}, 'application/document')
    }

    /**
     * @param {object} data
     * @param {String | Number | undefined} id
     * @param {object | undefined} headers
     * @param {object | undefined} query
     *
     * @returns {Promise<object>}
     */
    save(data, id, headers, query = {}) {
        const updatedData = {
            ...data
        }

        if (updatedData.domains) {
            updatedData.domains = updatedData.domains.map(x => {
                return typeof x === 'object'
                    ? x.id
                    : x
            })
        }

        if (updatedData.roles) {
            updatedData.roles = updatedData.roles.map(x => {
                return typeof x === 'object'
                    ? x.id
                    : x
            })
        }

        if (updatedData.allowedAdapters) {
            updatedData.allowedAdapters = updatedData.allowedAdapters.map(x => {
                return typeof x === 'object'
                    ? x.id
                    : x
            })
        }

        return super.save(updatedData, id, headers, query)
    }
}

export const userService = new UserService(HALApiService, createItemsData)
