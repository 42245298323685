import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    role: null,
    cleanRole: null,
    roles: [],
    totalRolesNumber: 0,
    rolesPage: 1,
    rolesTotalPages: 1,
    rolesLoading: false
  },
  getters: {
    currentRole: state => state.currentRole,
    role: state => state.role,
    cleanRole: state => state.cleanRole,
    roles: state => state.roles,
    totalRolesNumber: state => state.totalRolesNumber,
    rolesPage: state => state.rolesPage,
    rolesTotalPages: state => state.rolesTotalPages,
    rolesLoading: state => state.rolesLoading
  },
  mutations: {
    setRoles (state, payload) {
      state.roles = payload
    },
    setRolesData (state, { items, page, totalPages, totalItems }) {
      state.roles = items
      state.totalrolesNumber = totalItems
      state.rolesPage = page
      state.rolesTotalPages = totalPages
    },
    setRole (state, payload) {
      state.role = payload
      state.cleanRole = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanRole (state, payload) {
      state.cleanRole = payload
    },
    setNewRole (state) {
      state.role = {}
    },
    updateRole (state, payload) {
      state.role = {
        ...state.role,
        ...payload
      }
    },
    setTotalRolesNumber (state, payload) {
      state.totalRolesNumber = payload
    },
    setRolesPage (state, payload) {
      state.rolesPage = payload
    },
    setRolesTotalPages (state, payload) {
      state.rolesTotalPages = payload
    },
    startRolesLoading (state) {
      state.rolesLoading = true
    },
    stopRolesLoading (state) {
      state.rolesLoading = false
    }
  },
  actions: {
    loadRole ({ commit }) {
      return services.userRoles.getCurrentrole()
        .then(role => {
          commit('setRole', role)
          return role
        })
    },
    loadRoles ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.role)
      return services.userRoles.getAll(query)
        .then(data => {
          commit('setRolesData', data)
          return data
        })
    },
    saveRole ({ commit, state }) {
      const data = state.role.id
        ? convertEmbedded(difference(state.role, state.cleanRole))
        : convertEmbedded(state.role)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.role)
      }

      const header = state.role.id && state.role.updated
        ? { 'X-Entity-Updated': state.role.updated }
        : {}

      return services.userRoles.save(data, state.role.id, header)
    }
  }
}
