import { HALApiService } from '../hal-api.service'
import { createItemsData } from '../../helpers/request-helpers'
import { CachedService } from './base-service'
import entities from '../../../../config/Entities'

export const domainService = new CachedService(
  entities.Orderadmin_Settings_Entity_Domain,
  '/users/domains',
  HALApiService,
  createItemsData
)
