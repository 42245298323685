import { createItemsData } from '../utils/request.utils'
import { Service } from './base-service'
import { HALApiService } from './hal-api.service'

class OrderService extends Service {
  constructor (httpClient, middleware) {
    super('/products/order', httpClient, middleware)
  }

  /**
   * @param {array} values
   *
   * @returns {Promise<object>}
   */
  getReserves (values, shops) {
    const query = {
      per_page: 250,
      page: 1,
      fields: [
        'id',
        'reserves'
      ],
      filter: [{ type: 'in', field: 'id', values }]
    }

    if (shops && shops.length > 0) {
      query.filter.push({ type: 'in', field: 'shop', values: shops })
    }

    return this.getAll(query)
  }
}

export const orderService = new OrderService(HALApiService, createItemsData)
