import { HALApiService } from '../hal-api.service'
import { createItemsData } from './../../helpers/request-helpers'
import { CachedService } from './base-service'
import entities from '../../../../config/Entities'

class ExpectedOffersService extends CachedService {
  constructor (httpClient, middleware) {
    super(entities.Orderadmin_Products_Entity_Product_ExpectedOffer, '/integrations/expected-offer', httpClient, middleware)
  }

  /**
   * Patch Expected Offer
   *
   * @param {object | Array} data
   * @param {String | Number} id
   *
   * @returns {object}
  */
  patch (data, id) {
    return this._httpClient.patch(`${this._url}${id ? `/${id}` : ''}`, data)
      .then(item => {
      CachedService.cache.dispatchValue(this._entityClass, item)
      return item
    })
  }
}

export const expectedOffersService = new ExpectedOffersService(HALApiService, createItemsData)
