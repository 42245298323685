import { HALApiService } from '../hal-api.service'
import { createItemsData } from './../../helpers/request-helpers'
import { CachedService } from './base-service'
import entities from '../../../../config/Entities'

class SenderService extends CachedService {
  constructor (httpClient, middleware) {
    super(entities.Orderadmin_DeliveryServices_Entity_Sender, '/delivery-services/senders', httpClient, middleware)
  }

  _loadPhones (item) {
    if (item._embedded && item._embedded.defaultSenderProfile) {
      item._embedded.defaultSenderProfile.phone = (item._embedded.defaultSenderProfile.phones || []).map(x => `+${x.id}`).join(', ')
    }

    return item
  }

  /**
   * @param {object} query
   * @param {String} accept
   * @param {boolean} forceReload
   *
   * @returns {Promise<object>}
   */
   getAll (query, accept, forceReload) {
    const updatedQuery = this._options.mergeQuery && this._options.query
      ? this._options.mergeQuery(query, this._options.query)
      : query

    const data = CachedService.cache.getValues(this._entityClass, this.getURL(updatedQuery))

    if (!data.isExpired && !forceReload) {
      return Promise.resolve(data.value)
    }

    return this._httpClient.get(this._url, updatedQuery, accept)
      .then(this._middleware)
      .then(data => {
        return {
          ...data,
          items: data.items.map(this._loadPhones)
        }
      })
      .then(data => {
        CachedService.cache.dispatchValues(this._entityClass, this.getURL(updatedQuery), data)
        return data
      })
  }

  /**
   * @param {object} data
   * @param {String | Number | undefined} id
   * @param {object | undefined} headers
   * @param {object | undefined} query
   *
   * @returns {Promise<object>}
   */
  save (data, id, headers, query = {}) {
    return this._httpClient.saveRequest(this._url, data, id, undefined, undefined, headers, query)
      .then(this._loadPhones)
      .then(item => {
        CachedService.cache.dispatchValue(this._entityClass, item)
        return item
      })
  }

  /**
   * @param {String | Number} id
   *
   * @returns {Promise<object>}
   */
  get (id) {
    const value = CachedService.cache.getValue(this._entityClass, id)

    if (value) {
      return Promise.resolve(value)
    }

    const query = {
      per_page: 1,
      page: 1,
      filter: [{ type: 'eq', field: 'id', value: id }]
    }

    return this.getAll(query)
      .then(({ items }) => {
        return items[0]
          ? items[0]
          : Promise.reject(new Error('Sender is not found!'))
      })
      .then(this._loadPhones)
      .then(item => {
        CachedService.cache.dispatchValue(this._entityClass, item)
        return item
      })
  }
}

export const senderService = new SenderService(HALApiService, createItemsData)
