import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  createAccountStatisticsData,
  createDate,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    accountStatistics: [],
    transactions: [],
    transaction: null,
    cleanTransaction: null,
    totalTransactionsNumber: 0,
    transactionsPage: 1,
    transactionsTotalPages: 1,
    transactionsLoading: false
  },
  getters: {
    transactions: state => state.transactions,
    transactionsLoading: state => state.transactionsLoading,
    transaction: state => state.transaction,
    cleanTransaction: state => state.cleanTransaction,
    totalTransactionsNumber: state => state.totalTransactionsNumber,
    transactionsPage: state => state.transactionsPage,
    transactionsTotalPages: state => state.transactionsTotalPages,
    accountStatistics: state => state.accountStatistics
  },
  mutations: {
    setTransaction (state, payload) {
      state.transaction = payload
      state.cleanTransaction = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanTransaction (state, payload) {
      state.cleanTransaction = payload
    },
    setNewTransaction (state) {
      state.transaction = {
        comment: null,
        entity: null,
        entityType: null,
        extId: null,
        value: null,
        transactionDate: createDate(null),
        _embedded: {
          account: null,
          currency: null,
          rateModifier: null
        }
      }
    },
    updateTransaction (state, payload) {
      state.transaction = {
        ...state.transaction,
        ...payload
      }
    },
    setTransactionsData (state, { items, page, totalPages, totalItems }) {
      state.transactions = items
      state.totalTransactionsNumber = totalItems
      state.transactionsPage = totalPages
      state.transactionsTotalPages = page
    },
    setTransactions (state, payload) {
      state.transactions = payload
    },
    setTotalTransactionNumber (state, payload) {
      state.totalTransactionsNumber = payload
    },
    setTransactionsPage (state, payload) {
      state.transactionsPage = payload
    },
    setTransactionsTotalPages (state, payload) {
      state.transactionsTotalPages = payload
    },
    setAccountStatistics (state, payload) {
      state.accountStatistics = payload
    },
    startTransactionLoading (state) {
      state.transactionsLoading = true
    },
    stopTransactionLoading (state) {
      state.transactionsLoading = false
    },
    upsertTransaction (state, payload) {
      let isExist = false

      state.transactions = state.transactions.map(x => {
        if (x.id === payload.id) {
          isExist = true
          return payload
        }

        return x
      })

      if (!isExist) {
        state.transactions.unshift(payload)
      }
    }
  },
  actions: {
    loadTransactions ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.transactions)
      return services.transaction.getAll(query)
        .then(data => {
          commit('setTransactionsData', data)
          return data
        })
    },
    loadAccountStatistics ({ commit }, payload) {
      return services.transaction.getStatistic(payload)
        .then(({ totalItems, items, page, totalPages }) => {
          const data = createAccountStatisticsData(items)
          commit('setAccountStatistics', data)
          return { totalItems, items, page, totalPages }
        })
    },
    saveTransaction ({ commit, state }) {
      const data = state.transaction.id
        ? convertEmbedded(difference(state.transaction, state.cleanTransaction))
        : convertEmbedded(state.transaction)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.transaction)
      }

      const header = state.transaction.id && state.transaction.updated
        ? { 'X-Entity-Updated': state.transaction.updated }
        : {}

      return services.transaction.save(data, state.transaction.id, header)
    }
  }
}
