const Settings = () => import('../../pages/Settings')
const Channels = () => import('../../pages/notifications/Channels')
const NotificationChannels = () => import('../../pages/notifications/NotificationChannels')
const NotificationChannel = () => import('../../pages/notifications/NotificationChannel')
const NotificationTasks = () => import('../../pages/notifications/NotificationTasks')
const NotificationTask = () => import('../../pages/notifications/NotificationTask')
const Events = () => import('../../pages/notifications/Events')
const Tasks = () => import('../../pages/notifications/Tasks')
const Templates = () => import('../../pages/notifications/Templates')
const CRMCallCenter = () => import('../../pages/crm/CallCenter')
const Offers = () => import('../../pages/wms/Offers')
const Documents = () => import('./../../pages/settings/Documents')
const Roles = () => import('./../../pages/settings/Roles')
const NotificationsHub = () => import('../../pages/notifications/NotificationsHub')
const Channel = () => import('../../pages/notifications/Channel')
const Event = () => import('../../pages/notifications/Event')
const Task = () => import('../../pages/notifications/Task')
const Template = () => import('../../pages/notifications/Template')
const Document = () => import('./../../pages/settings/Document')
const HelpSections = () => import('./../../pages/settings/HelpSections')
const HelpSection = () => import('./../../pages/settings/HelpSection')
const Rules = () => import('./../../pages/settings/Rules')


export default [
  {
    entityClass: 'Settings',
    path: '/settings',
    name: 'Settings',
    icon: 'settings',
    noChild: true,
    component: Settings,
    children: [
      {
        entityClass: 'Orderadmin\\Automation',
        path: 'automation/rules',
        name: 'Automation Rules',
        details: [
          'Manage your automation rules.'
        ],
        icon: 'gavel',
        hideChildren: true,
        component: Rules
      },
      {
        entityClass: 'Orderadmin\\Notifications',
        path: 'notifications',
        name: 'Notifications',
        details: [
          'Manage your notification channels, rules and templates.'
        ],
        icon: 'notifications_none',
        hideChildren: true,
        component: NotificationsHub,
        children: [
          {
            entityClass: 'Orderadmin\\Notifications\\Entity\\Channel',
            path: 'notification-channels',
            icon: 'account_tree',
            name: 'Notifications Manager (new)',
            description: 'Manage your notifications',
            component: NotificationChannels,
            options: {
              query: {
                per_page: 25,
                page: 1,
                'order-by': [
                  { type: 'field', field: 'created', direction: 'desc' }
                ],
                filter: [
                  { type: 'eq', field: 'state', value: 'active' }
                ]
              }
            },
            children: [
              {
                entityClass: 'Orderadmin\\Notifications\\Entity\\Channel',
                path: 'entity',
                component: NotificationChannel
              },
              {
                entityClass: 'Orderadmin\\Notifications\\Entity\\Channel',
                path: 'entity/:id',
                component: NotificationChannel
              }
            ]
          },
          {
            entityClass: 'Orderadmin\\Notifications\\Entity\\Event',
            path: 'notification-tasks',
            icon: 'event_busy',
            name: 'Notifications Manager (new tasks)',
            description: 'Manage your notifications',
            component: NotificationTasks,
            options: {
              query: {
                per_page: 25,
                page: 1,
                'order-by': [
                  { type: 'field', field: 'created', direction: 'desc' }
                ],
                filter: [
                  { type: 'eq', field: 'state', value: 'active' }
                ]
              }
            },
            children: [
              {
                entityClass: 'Orderadmin\\Notifications\\Entity\\Event',
                path: 'entity',
                component: NotificationTask
              },
              {
                entityClass: 'Orderadmin\\Notifications\\Entity\\Event',
                path: 'entity/:id',
                component: NotificationTask
              }
            ]
          },
          {
            entityClass: 'Orderadmin\\Notifications\\Entity\\Channel',
            path: 'channels',
            icon: 'account_tree',
            name: 'Notification channels',
            description: 'Create and manage your notification channels.',
            component: Channels,
            options: {
              query: {
                per_page: 25,
                page: 1,
                'order-by': [
                  { type: 'field', field: 'created', direction: 'desc' }
                ],
                filter: [
                  { type: 'eq', field: 'state', value: 'active' }
                ]
              }
            },
            children: [
              {
                entityClass: 'Orderadmin\\Notifications\\Entity\\Channel',
                path: 'entity',
                component: Channel
              },
              {
                entityClass: 'Orderadmin\\Notifications\\Entity\\Channel',
                path: 'entity/:id',
                component: Channel
              }
            ]
          },
          {
            entityClass: 'Orderadmin\\Notifications\\Entity\\Event',
            path: 'logs',
            icon: 'event_busy',
            name: 'Notification logs',
            description: 'View your notification history.',
            component: Events,
            options: {
              query: {
                per_page: 25,
                page: 1,
                'order-by': [
                  { type: 'field', field: 'created', direction: 'desc' }
                ]
              }
            },
            children: [
              {
                entityClass: 'Orderadmin\\Notifications\\Entity\\Event',
                path: 'entity',
                component: Event
              },
              {
                entityClass: 'Orderadmin\\Notifications\\Entity\\Event',
                path: 'entity/:id',
                component: Event
              }
            ]
          },
          {
            entityClass: 'Orderadmin\\Notifications\\Entity\\Task',
            path: 'rules',
            icon: 'notifications_none',
            name: 'Notification rules',
            description: 'Create and edit rules to automate notifications for your customers.',
            component: Tasks,
            options: {
              query: {
                per_page: 25,
                page: 1,
                'order-by': [
                  { type: 'field', field: 'created', direction: 'desc' }
                ],
                filter: [
                  { type: 'eq', field: 'state', value: 'active' }
                ]
              }
            },
            children: [
              {
                entityClass: 'Orderadmin\\Notifications\\Entity\\Task',
                path: 'entity',
                component: Task
              },
              {
                entityClass: 'Orderadmin\\Notifications\\Entity\\Task',
                path: 'entity/:id',
                component: Task
              }
            ]
          },
          {
            entityClass: 'Orderadmin\\Notifications\\Entity\\Template',
            path: 'templates',
            icon: 'mark_chat_read',
            name: 'Notification templates',
            details: [
              'View and edit e-mails and SMS templates.'
            ],
            component: Templates,
            options: {
              query: {
                per_page: 25,
                page: 1,
                'order-by': [
                  { type: 'field', field: 'created', direction: 'desc' }
                ]
              }
            },
            children: [
              {
                entityClass: 'Orderadmin\\Notifications\\Entity\\Template',
                path: 'entity',
                component: Template
              },
              {
                entityClass: 'Orderadmin\\Notifications\\Entity\\Template',
                path: 'entity/:id',
                component: Template
              }
            ]
          }
        ]
      },
      {
        entityClass: 'Orderadmin\\Notifications\\Entity\\Crm\\Interaction',
        path: 'notifications/interactions',
        icon: 'notifications',
        name: 'Interactice notification',
        details: [
          'Keep in touch with your customers with calls and live chat.'
        ],
        component: CRMCallCenter,
        options: {
          query: {
            per_page: 25,
            page: 1,
            'order-by': [
              { type: 'field', field: 'created', direction: 'desc' }
            ]
          }
        }
      },
      {
        entityClass: 'Orderadmin\\Products\\Entity\\Product\\ExpectedOffer',
        path: 'market-sync',
        icon: 'local_offer',
        name: 'Marketplace sync',
        details: [
          'Synchronize your offers with marketplaces.'
        ],
        component: Offers
      },
      {
        entityClass: 'Application\\Entity\\Documents\\Template',
        path: 'documents',
        icon: 'receipt_long',
        name: 'Documents',
        details: [
          'Manage your documents.'
        ],
        component: Documents,
        options: {
          query: {
            per_page: 25,
            page: 1,
            'order-by': [
              { type: 'field', field: 'created', direction: 'desc' }
            ],
            filter: [
              { type: 'eq', field: 'state', value: 'active' }
            ]
          }
        },
        children: [
          {
            entityClass: 'Application\\Entity\\Documents\\Template',
            path: 'entity',
            component: Document
          },
          {
            entityClass: 'Application\\Entity\\Documents\\Template',
            path: 'entity/:id',
            component: Document
          }
        ]
      },
      {
        entityClass: 'Users\\Entity\\Permission',
        path: 'permissions',
        icon: 'accessibility',
        name: 'Permissions',
        details: [
          'Set permissions.'
        ],
        component: Roles
      },
      {
        entityClass: 'Application\\Entity\\Help\\Section',
        path: 'help-sections',
        icon: 'help_center',
        name: 'Help Center',
        details: [
          'Create and manage helper.'
        ],
        component: HelpSections,
        options: {
          query: {
            per_page: 25,
            page: 1,
            'order-by': [
              { type: 'field', field: 'created', direction: 'desc' }
            ],
            filter: [
              { type: 'eq', field: 'state', value: 'active' }
            ]
          }
        },
        children: [
          {
            entityClass: 'Application\\Entity\\Help\\Section',
            path: 'entity',
            component: HelpSection
          },
          {
            entityClass: 'Application\\Entity\\Help\\Section',
            path: 'entity/:id',
            component: HelpSection
          }
        ]
      },
    ]
  }
]