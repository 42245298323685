export class PermissionTypeService {
  _items

  constructor () {
    this._items = [
      {
        id: 5,
        name: 'Order',
        object: 'Orderadmin\\Products\\Entity\\AbstractOrder',
        fields: ['id'],
        states: ['pending_queued', 'reserved']
      }
    ]
  }

  getAll () {
    return Promise.resolve({
      page: 1,
      totalPages: 1,
      items: this._items,
      totalItems: this._items.length
    })
  }
}
