import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    weight: null,
    cleanWeight: null,
    weights: [],
    totalWeightsNumber: 0,
    weightsPage: 1,
    weightsTotalPages: 1,
    weightsLoading: false
  },
  getters: {
    weight: state => state.weight,
    cleanWeight: state => state.cleanWeight,
    weights: state => state.weights,
    totalWeightsNumber: state => state.totalWeightsNumber,
    weightsPage: state => state.weightsPage,
    weightsTotalPages: state => state.weightsTotalPages,
    weightsLoading: state => state.weightsLoading
  },
  mutations: {
    setWeights (state, payload) {
      state.weights = payload
    },
    setWeightsData (state, { items, page, totalPages, totalItems }) {
      state.weights = items
      state.totalWeightsNumber = totalItems
      state.weightsPage = page
      state.weightsTotalPages = totalPages
    },
    setWeight (state, payload) {
      state.weight = payload
      state.cleanWeight = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanWeight (state, payload) {
      state.cleanWeight = payload
    },
    setNewWeight (state) {
      state.weight = {
        title: null,
        state: 'active',
        unit: null
      }
    },
    updateWeight (state, payload) {
      state.weight = {
        ...state.weight,
        ...payload
      }
    },
    setTotalWeightsNumber (state, payload) {
      state.totalWeightsNumber = payload
    },
    setWeightsPage (state, payload) {
      state.weightsPage = payload
    },
    setWeightsTotalPages (state, payload) {
      state.weightsTotalPages = payload
    },
    startWeightLoading (state) {
      state.weightsLoading = true
    },
    stopWeightLoading (state) {
      state.weightsLoading = false
    }
  },
  actions: {
    loadWeights ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.warehouse)
      return services.weight.getAll(query)
        .then(data => {
          commit('setWeightsData', data)
          return data
        })
    },
    loadWeight ({ commit }, payload) {
      return services.weight.get(payload)
        .then(item => {
          commit('setWeight', item)
          return item
        })
    },
    saveWeight ({ commit, state }) {
      const data = state.weight.id
        ? convertEmbedded(difference(state.weight, state.cleanWeight))
        : convertEmbedded(state.weight)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.weight)
      }

      const header = state.weight.id && state.weight.updated
        ? { 'X-Entity-Updated': state.weight.updated }
        : {}

      return services.weight.save(data, state.weight.id, header)
    }
  }
}
