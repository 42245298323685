import _ from 'lodash'
import services from '../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    currency: null,
    cleanCurrency: null,
    currencies: [],
    totalCurrencyNumber: 0,
    currencyTotalPages: 1,
    currencyPage: 1,
    currencyLoading: false
  },
  getters: {
    currency: state => state.currency,
    cleanCurrency: state => state.cleanCurrency,
    currencies: state => state.currencies,
    totalCurrencyNumber: state => state.totalCurrencyNumber,
    currencyTotalPages: state => state.currencyTotalPages,
    currencyPage: state => state.currencyPage,
    currencyLoading: state => state.currencyLoading
  },
  mutations: {
    setCurrency (state, payload) {
      state.currency = payload
      state.cleanCurrency = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setNewCurrency (state) {
      state.currency = {}
    },
    updateCurrency (state, payload) {
      state.currency = {
        ...state.currency,
        ...payload
      }
    },
    setCleanCurrency (state, payload) {
      state.cleanCurrency = payload
    },
    setCurrenciesData (state, { items, page, totalPages, totalItems }) {
      state.currencies = items
      state.totalCurrencyNumber = totalItems
      state.currencyPage = page
      state.currencyTotalPages = totalPages
    },
    setCurrencies (state, payload) {
      state.currencies = payload
    },
    setTotalCurrencyNumber (state, payload) {
      state.totalCurrencyNumber = payload
    },
    setCurrencyPage (state, payload) {
      state.currencyPage = payload
    },
    setCurrencyTotalPages (state, payload) {
      state.currencyTotalPages = payload
    },
    startCurrencyLoading (state) {
      state.currencyLoading = true
    },
    stopCurrencyLoading (state) {
      state.currencyLoading = false
    }
  },
  actions: {
    loadCurrencies ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.currency)
      return services.currency.getAll(query)
        .then(data => {
          commit('setCurrenciesData', data)
          return data
        })
    },
    loadCurrency ({ commit }, payload) {
      return services.currency.get(payload)
        .then(item => {
          commit('setCurrency', item)
          return item
        })
    },
    saveCurrency ({ commit, state }) {
      const data = state.currency.id
        ? convertEmbedded(difference(state.currency, state.cleanCurrency))
        : convertEmbedded(state.currency)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.currency)
      }

      const header = state.currency.id && state.currency.updated
        ? { 'X-Entity-Updated': state.currency.updated }
        : {}

      return services.currency.save(data, state.currency.id, header)
    }
  }
}
