import _ from 'lodash'

/**
 * Create Items Data
 *
 * @param {Object} data
 *
 * @returns {Object}
 */
export function createItemsData (data) {
  const keys = Object.keys(data._embedded)

  return {
    items: data._embedded[keys[0]],
    totalItems: data.total_items,
    page: data.page,
    totalPages: data.page_count
  }
}

/**
 * Extract Acceptance Items
 *
 * @param {object} itemsObject
 *
 * @returns {array}
 */
export function extractAcceptanceItems (itemsObject) {
  return Object.values(itemsObject)
    .filter(x => !x.self)
    .map(i => {
      if (!i.dimensions) {
        i.dimensions = {
          x: '',
          y: '',
          z: ''
        }
      }

      return i
    })
}

/**
 * Convert Embedded
 *
 * @param {Object} object
 * @param {Array} ignoredFields
 * @param {Boolean} deep
 *
 * @returns {Object}
 */
export function convertEmbedded (object, ignoredFields = [], deep = true) {
  let obj = _.cloneDeep(object)

  if (obj._embedded) {
    Object.keys(obj._embedded)
      .forEach(val => {
        if (ignoredFields.includes(val) && obj._embedded[val] && !obj._embedded[val].id) {
          delete obj._embedded[val]
          return
        }

        if (obj._embedded[val] && obj._embedded[val].id) {
          obj[val] = obj._embedded[val].id
        } else {
          obj[val] = obj._embedded[val]
        }

        if (obj._embedded[val] && obj[val]._embedded) {
          obj[val] = convertEmbedded(obj[val])
        }

        if (!deep && obj[val] && obj[val]._embedded) {
          delete obj[val]._embedded
        }

        if (obj[val] && typeof obj[val] === 'object' && Object.keys(obj[val]).length === 0) {
          delete obj[val]
        }

        delete obj._embedded[val]
      })

    delete obj._embedded
  }

  return obj
}

/**
 * Extract Changes between Objects
 * @param {Object} obj
 * @param {Object} b
 *
 * @returns {Object}
 */
export function difference (obj, b) {
  let object = _.cloneDeep(obj)
  let base = _.cloneDeep(b)
  return _.transform(object, (result, value, key) => {
    if (!_.isEqual(value, base[key])) {
      result[key] = _.isObject(value) && _.isObject(base[key])
        ? difference(value, base[key])
        : value
    }
  })
}

/**
 * Merge Query
 *
 * @param {Object | Undefined} firstQ
 * @param {*} secondQ
 *
 * @returns {Object}
 */
export function mergeQuery (firstQ, secondQ) {
  if (!secondQ && firstQ) {
    firstQ.filter = (firstQ.filter || []).reduce((acc, x) => {
      if (x.type === 'in' && x.values.length === 0) {
        return acc
      }

      acc.push(x)
      return acc
    }, [])

    return firstQ
  }

  if (!firstQ && secondQ) {
    return secondQ
  }

  if (!firstQ && !secondQ) {
    return {}
  }

  const query = _.cloneDeep(firstQ)
  const secondQuery = _.cloneDeep(secondQ)

  if (!query.filter) {
    query.filter = []
  }

  if (!secondQuery.filter) {
    secondQuery.filter = []
  }

  const filter = (query.filter || [])
    .reduce((acc, x) => {
      if (x.type === 'in' && x.values.length === 0) {
        return acc
      }

      if (secondQuery.filter.find(({ field }) => field === x.field)) {
        return acc
      }

      acc.push(x)
      return acc
    }, [])

  query.filter = [
    ...secondQuery.filter || [],
    ...filter
  ]

  return query
}
