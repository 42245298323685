import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    reportType: null,
    cleanReportType: null,
    totalReportTypesNumber: 0,
    reportTypesPage: 1,
    reportTypesTotalPages: 1,
    reportTypesLoading: false
  },
  getters: {
    reportType: state => state.reportType,
    cleanReportType: state => state.cleanReportType,
    reportTypes: state => state.reportTypes,
    totalReportTypesNumber: state => state.totalReportTypesNumber,
    reportTypesPage: state => state.reportTypesPage,
    reportTypesTotalPages: state => state.reportTypesTotalPages,
    reportTypesLoading: state => state.reportTypesLoading
  },
  mutations: {
    setReportType (state, payload) {
      state.reportType = payload
      state.cleanReportType = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanReportType (state, payload) {
      state.cleanReportType = payload
    },
    setNewReportType (state) {
      state.reportType = {}
    },
    updateReportTypeEmbedded (state, payload) {
      state.reportType._embedded = {
        ...state.reportType._embedded,
        ...payload
      }
    },
    updateReportType (state, payload) {
      state.reportType = {
        ...state.reportType,
        ...payload
      }
    },
    setReportTypesData (state, { items, page, totalPages, totalItems }) {
      state.reportTypes = items
      state.totalReportTypesNumber = totalItems
      state.reportTypesPage = page
      state.reportTypesTotalPages = totalPages
    },
    startReportTypeLoading (state) {
      state.reportTypesLoading = true
    },
    stopReportTypeLoading (state) {
      state.reportTypesLoading = false
    }
  },
  actions: {
    loadReportTypes ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.reportTypes)
      return services.reportType.getAll(query)
        .then(data => {
          commit('setReportTypesData', data)
          return data
        })
    },
    loadReportType ({ commit }, payload) {
      return services.reportType.get(payload)
        .then(item => {
          commit('setReportType', item)
          return item
        })
    },
    saveReportType ({ commit, state }) {
      const data = state.reportType.id
        ? convertEmbedded(difference(state.reportType, state.cleanReportType))
        : convertEmbedded(state.reportType)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.reportType)
      }

      const header = state.reportType.id && state.reportType.updated
        ? { 'X-Entity-Updated': state.reportType.updated }
        : {}

      return services.reportType.save(data, state.reportType.id, header)
    }
  }
}
