import { HALApiService } from './hal-api.service'
import { createItemsData } from '../utils/request.utils'
import { acceptanceService } from './acceptance.service'
import { drService } from './delivery-request.service'
import { storageItemsService } from './item-service.service'
import { orderService } from './order.service'
import { storagePlacesService } from './place.service'
import { shopsService } from './shops.service'
import { storageItemEntityService } from './storage-items.service'
import { warehouseService } from './warehouse.service'
import { offersService } from './offers.service'
import { returnParcelsService } from './return-parcel.service'
import { senderService } from './senders.service'
import { wholesaleConsumptionService } from './wholesale-consumption.service'
import { inventoriesService } from './inventory.service'
import { storageInventoryService } from './storage-inventory.service'
import { storageSortingService } from './sorting.service'
import { pickingQueueService } from './picking-queue.service'
import { pickingTasksService } from './picking-tasks.service'
import { Service } from './base-service'
import { acceptanceItemsService } from './acceptance-items.service'
import { devLogService } from './developer-log.service'
import {assemblingQueueService} from "../../app/services/requests/queue.service.js";
import { locationLogService } from "./location-log.service";

function getUrl (api) {
  return api.startsWith('/api')
    ? api.slice(4)
    : api
}

export function createService (api) {
  return new Service(getUrl(api), HALApiService, createItemsData)
}

export default {
  createService,
  storagePlace: storagePlacesService,
  storageItem: storageItemsService,
  acceptance: acceptanceService,
  assemblingQueue: assemblingQueueService,
  warehouse: warehouseService,
  shop: shopsService,
  offer: offersService,
  storageItemEntity: storageItemEntityService,
  wholesaleConsumption: wholesaleConsumptionService,
  sender: senderService,
  returnParcel: returnParcelsService,
  order: orderService,
  deliveryRequest: drService,
  inventory: inventoriesService,
  storageInventory: storageInventoryService,
  sorting: storageSortingService,
  picking: pickingQueueService,
  pickingTask: pickingTasksService,
  acceptanceItem: acceptanceItemsService,
  devLog: devLogService,
  locationLog: locationLogService,
}
