import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    postcodes: [],
    postcode: null,
    cleanPostcode: null,
    totalPostcodesNumber: 0,
    postcodesPage: 1,
    postcodesTotalPages: 1,
    postcodesLoading: false
  },
  getters: {
    postcodes: state => state.postcodes,
    postcode: state => state.postcode,
    cleanPostcode: state => state.cleanPostcode,
    totalPostcodesNumber: state => state.totalPostcodesNumber,
    postcodesPage: state => state.postcodesPage,
    postcodesTotalPages: state => state.postcodesTotalPages,
    postcodesLoading: state => state.postcodesLoading
  },
  mutations: {
    setPostcode (state, payload) {
      state.postcode = payload
      state.cleanPostcode = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setCleanPostcode (state, payload) {
      state.cleanPostcode = payload
    },
    setNewPostcode (state) {
      state.postcode = {}
    },
    updatePostcode (state, payload) {
      state.postcode = {
        ...state.postcode,
        ...payload
      }
    },
    setPostcodesData (state, { items, page, totalPages, totalItems }) {
      state.postcodes = items
      state.totalPostcodesNumber = totalItems
      state.postcodesPage = page
      state.postcodesTotalPages = totalPages
    },
    setPostcodes (state, payload) {
      state.postcodes = payload
    },
    setTotalPostcodesNumber (state, payload) {
      state.totalPostcodesNumber = payload
    },
    setPostcodesPage (state, payload) {
      state.postcodesPage = payload
    },
    setPostcodesTotalPages (state, payload) {
      state.postcodesTotalPages = payload
    },
    startPostcodesLoading (state) {
      state.postcodesLoading = true
    },
    stopPostcodesLoading (state) {
      state.postcodesLoading = false
    }
  },
  actions: {
    loadPostcodes ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.postcodes)
      return services.postcode.getAll(query)
        .then(data => {
          commit('setPostcodesData', data)
          return data
        })
    },
    loadPostcode ({ commit }, payload) {
      return services.postcode.get(payload)
        .then(item => {
          commit('setPostcode', item)
          return item
        })
    },
    savePostcode ({ commit, state }) {
      const data = state.Postcode.id
        ? convertEmbedded(difference(state.postcode, state.cleanPostcode))
        : convertEmbedded(state.postcode)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.Postcode)
      }

      const header = state.postcode.id && state.postcode.updated
        ? { 'X-Entity-Updated': state.postcode.updated }
        : {}

      return services.postcode.save(data, state.postcode.id, header)
    }
  }
}
