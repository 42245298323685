import _ from 'lodash'
import services from '../../../services/services'

import {
    mergeQuery
} from './../../../helpers/request-helpers'

export default {
    state: {
        shippingPlans: [],
        lockedShippingPlans: [],
        shippingPlanItemsNumber: 0,
        shippingPlansTotalPages: 1,
        shippingPlansPage: 1,
        shippingPlansLoading: false,
        shippingPlan: null,
        cleanShippingPlan: null,
        shippingPlanItems: [],
        cleanShippingPlanItems: [],
        shippingPlanItemsLoading: false,
        hasShippingPlanChange: false,
    },
    getters: {
        hasShippingPlanChange: state => state.hasShippingPlanChange,
        shippingPlans: state => state.shippingPlans,
        lockedShippingPlans: state => state.lockedShippingPlans,
        isShippingPlanLocked: state => {
            if (!state.shippingPlan || !state.shippingPlan.id) {
                return false
            }

            return state.lockedShippingPlans.includes(state.shippingPlan.id)
        },
        shippingPlansLoading: state => state.shippingPlansLoading,
        shippingPlanItemsNumber: state => state.shippingPlanItemsNumber,
        shippingPlansTotalPages: state => state.shippingPlansTotalPages,
        shippingPlansPage: state => state.shippingPlansPage,
        shippingPlan: state => state.shippingPlan,
        cleanShippingPlan: state => state.cleanShippingPlan,
        shippingPlanItems: state => state.shippingPlanItems,
        cleanShippingPlanItems: state => state.cleanShippingPlanItems,
        shippingPlanItemsLoading: state => state.shippingPlanItemsLoading,
    },
    mutations: {
        setShippingPlansData (state, { items, page, totalPages, totalItems }) {
            state.shippingPlans = items
            state.shippingPlanItemsNumber = totalItems
            state.shippingPlansTotalPages = totalPages
            state.shippingPlansPage = page
        },
        setShippingPlans (state, payload) {
            state.shippingPlans = payload
        },
        setShippingPlanItemsNumber (state, payload) {
            state.shippingPlanItemsNumber = payload
        },
        setShippingPlansTotalPages (state, payload) {
            state.shippingPlansTotalPages = payload
        },
        setShippingPlansPage (state, payload) {
            state.shippingPlansPage = payload
        },
        setShippingPlan (state, payload) {
            state.shippingPlan = payload
            state.cleanShippingPlan = typeof payload === 'object'
                ?_.cloneDeep(payload)
                :payload
        },
        setCleanShippingPlan (state, payload) {
            state.cleanShippingPlan = payload ? _.cloneDeep(payload) : payload
        },
        updateCleanShippingPlan (state, payload) {
            state.shippingPlan = {
                ...state.cleanShippingPlan,
                ...payload
            }
        },
        updateShippingPlan (state, payload) {
            state.shippingPlan = {
                ...state.shippingPlan,
                ...payload
            }
            state.hasShippingPlanChange = true
        },
        updateShippingPlanEmbedded (state, payload) {
            state.shippingPlan = {
                ...state.shippingPlan,
                _embedded: {
                    ...state.shippingPlan._embedded,
                    ...payload
                }
            }

            state.hasShippingPlanChange = true
        },
        updateShippingPlanSource (state, payload) {
            state.shippingPlan = {
                ...state.shippingPlan,
                _embedded: {
                    ...state.shippingPlan._embedded,
                    source: payload
                }
            }

            state.hasShippingPlanChange = true
        },
        setHasShippingPlanChange (state, payload) {
            state.hasShippingPlanChange = payload
        },
        addShippingPlanToList (state, payload) {
            state.shippingPlans = [payload, ...state.shippingPlans]
        },
        setShippingPlanFromList (state, payload) {
            state.shippingPlans = state.shippingPlans.map(shippingPlan => {
                return Number(shippingPlan.id) === Number(payload.id)
                    ? payload
                    : shippingPlan
            })
        },
        setShippingPlanItems (state, payload) {
            state.shippingPlanItems = payload
        },
        setCleanShippingPlanItems (state, payload) {
            state.cleanShippingPlanItems = _.cloneDeep(payload)
        },
        startShippingPlansLoading (state) {
            state.shippingPlansLoading = true
        },
        stopShippingPlansLoading(state) {
            state.shippingPlansLoading = false
        },
        startShippingPlanItemsLoading (state) {
            state.shippingPlansLoading = true
        },
        stopShippingPlanItemsLoading(state) {
            state.shippingPlansLoading = false
        },
        startShippingPlanLoading (state) {
            state.shippingPlanLoading = true
        },
        stopShippingPlanLoading (state) {
            state.shippingPlanLoading = false
        },
        addLockedShippingPlan (state, payload) {
            if (state.lockedShippingPlans.find(id => id === payload)) {
                return
            }

            state.lockedShippingPlans = [...state.lockedShippingPlans, payload]
        },
        removeLockedShippingPlans (state, payload) {
            state.lockedShippingPlans = state.lockedShippingPlans.filter(id => id !== payload)
        },
        upsertShippingPlan (state, payload) {
            let isExist = false

            state.shippingPlans = state.shippingPlans.map(x => {
                if (x.id == payload.id) {
                    isExist = true
                    return payload
                }

                return x
            })

            if (!isExist) {
                state.shippingPlans.unshift(payload)
            }
        }
    },
    actions: {
        resetShippingPlan ({ commit }) {
            commit('setShippingPlan', null)
            commit('setDeliveryRequest', null)
            commit('setAddress', null)
            commit('setShippingPlanItems', [])
        },

        loadShippingPlans ({commit}, payload) {
            const query = mergeQuery(payload)
            return services.shippingPlan.getAll(query)
                .then(({ items, totalItems, page, totalPages }) => {
                    commit('setShippingPlans', items)
                    return {items, totalItems, page, totalPages}
                })
        },
        loadShippingPlanItems ({commit}, payload) {
            const query = mergeQuery(payload)
            return services.shippingPlan.getShippingPlanItem(query)
                .then(({ items, totalItems, page, totalPages }) => {
                    commit('setShippingPlans', items)
                    return {items, totalItems, page, totalPages}
                })
        }
    }
}
