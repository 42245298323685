import {
  loadProductsOrdersStatisticsService,
  loadDeliveryRequestStatisticsService,
} from './../../../services/requests/statistics.service'

import JSONstat from 'jsonstat'
import i18n from '../../../../../i18n'

/**
 * Create Trend Statistic
 *
 * @param {Object} state
 *
 * @returns {Array}
 */
function createTrendStatistic (state) {
  return state
    .filter(val => val.concept === 'total')
    .map(({ time, value }) => {
      return {
        label: time,
        value
      }
    })
    .filter(({ label }) => label !== 'Total')
}

/**
 * Create Total Values Statistic
 *
 * @param {Array} state
 *
 * @returns {Array}
 */
function createTotalValuesStatistic (state) {
  const values = state
    .filter(val => val.concept === 'total')
    .map(({ time, value }) => {
      const i = time.lastIndexOf('-') + 1

      return {
        label: time.slice(i),
        value
      }
    })

  return [{ values: values.slice(0, values.length - 1) }]
}

/**
 * Create Statistic Table
 *
 * @param {Array} state
 *
 * @returns {Object}
 */
function createStatisticTable (state) {
  return state.reduce((acc, { concept, time, value }) => {
    const colIndex = acc.columns.findIndex(x => x.id === concept)

    if (colIndex === -1) {
      acc.columns.push({
        id: concept,
        label: concept,
        field: concept
      })
    }

    const rowIndex = acc.rows.findIndex(x => x.id === time)

    if (rowIndex === -1) {
      acc.rows.push({
        id: time,
        time,
        [concept]: value
      })

      acc.totalRows++
    } else {
      acc.rows[rowIndex][concept] = value
    }

    return acc
  },
  {
    rows: [],
    columns: [{ id: 'time', label: i18n.global.tc('Time'), field: 'time' }],
    totalRows: 0
  })
}

export default {
  state: {
    statistics: []
  },
  getters: {
    statistics: state => state.statistics,
    statisticsTotal: state => createTotalValuesStatistic(state.statistics),
    statisticsTable: state => createStatisticTable(state.statistics),
    trandStatistics: state => createTrendStatistic(state.statistics)
  },
  mutations: {
    setStatistics (state, payload) {
      state.statistics = payload
    }
  },
  actions: {
    loadProductsOrdersStatistics ({ commit }, payload) {
      return loadProductsOrdersStatisticsService(payload)
        .then(data => {
          const values = JSONstat(data).Dataset(1).toTable({ type: 'arrobj' })
          commit('setStatistics', values)
          return data
        })
    },
    loadDeliveryRequestsStatistics ({ commit }, payload) {
      return loadDeliveryRequestStatisticsService(payload)
        .then(data => {
          const values = JSONstat(data).Dataset(1).toTable({ type: 'arrobj' })
          commit('setStatistics', values)
          return data
        })
    },

  }
}
