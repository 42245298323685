import i18n from '../../../../../i18n'
import HeadsOptions from '../../../config/HeadsOptions'

export default {
  state: {
    instructions: [],
    isOpenQuickstart: false,
    isLoadingBottomHeader: false,
    headsOptions: HeadsOptions,
    storagePickingTasksEvents: [],
    storagePlaceEvents: [],
    appOptions: JSON.parse(localStorage.getItem('appOptions')) || window.appOptions || {},
    loadingNow: false,
    countLoading: 0,
    notification: null,
    notifications: [],
    isInterceptedItemsLoading: false,
    cache: {},
    isOpenDebugModal: false,
    debugData: null,
    isOpenSidebar: false,
    routeShortcuts: JSON.parse(localStorage.getItem('routeShortcuts')) || [],
    isOpenSupport: false
  },
  getters: {
    instructions: state => state.instructions,
    isOpenQuickstart: state => state.isOpenQuickstart,
    isLoadingBottomHeader: state => state.isLoadingBottomHeader,
    isOpenSupport: state => state.isOpenSupport,
    routeShortcuts: state => state.routeShortcuts,
    headsOptions: state => state.headsOptions,
    rejectedTasks: state => state.storagePickingTasksEvents.filter(val => val.data.state === 'rejected'),
    storagePickingTasksEvents: state => state.storagePickingTasksEvents,
    storagePlaceEvents: state => state.storagePlaceEvents,
    appOptions: state => state.appOptions,
    loadingNow: state => state.loadingNow,
    countLoading: state => state.countLoading,
    notification: state => state.notification,
    notifications: state => state.notifications,
    isInterceptedItemsLoading: state => state.isInterceptedItemsLoading,
    cache: state => state.cache,
    isOpenDebugModal: state => state.isOpenDebugModal,
    debugData: state => state.debugData,
    isOpenSidebar: state => state.isOpenSidebar
  },
  mutations: {
    addInstruction (state, payload) {
      state.instructions.push(payload)
    },
    setInstructions (state, paylod) {
      state.instructions = paylod
    },
    toggleIsOpenQuickstart (state) {
      state.isOpenQuickstart = !state.isOpenQuickstart
    },
    setIsLoadingBottomHeader (state, payload) {
      state.isLoadingBottomHeader = !!payload
    },
    hideShowSupport (state) {
      state.isOpenSupport = !state.isOpenSupport
    },
    hideSupport (state) {
      state.isOpenSupport = false
    },
    showSupport (state) {
      state.isOpenSupport = true
    },
    addRouteToShortcuts (state, payload) {
      state.routeShortcuts = [{ ...payload, app: 'app' }, ...state.routeShortcuts]
      localStorage.setItem('routeShortcuts', JSON.stringify(state.routeShortcuts))
    },
    removeRouteToShortcuts (state, payload) {
      state.routeShortcuts = state.routeShortcuts.filter(({ route }) => route !== payload.route)
      localStorage.setItem('routeShortcuts', JSON.stringify(state.routeShortcuts))
    },
    deleteAllRouteShortcuts (state) {
      state.routeShortcuts = []
      localStorage.setItem('routeShortcuts', JSON.stringify(state.routeShortcuts))
    },
    addStoragePickingTask (state, payload) {
      state.storagePickingTasksEvents.push(payload)
    },
    removeStoragePickingTask (state, payload) {
      state.storagePickingTasksEvents = state.storagePickingTasksEvents.filter(({ entity }) => entity !== payload)
    },
    removeAllStoragePickingTasks (state) {
      state.storagePickingTasksEvents = []
    },
    addStoragePlaceEvent (state, payload) {
      state.storagePlaceEvents.push(payload)
    },
    removeStoragePlaceEvent (state, payload) {
      state.storagePlaceEvents = state.storagePlaceEvents.filter(({ entity }) => entity !== payload)
    },
    removeAllStoragePlaceEvents (state) {
      state.storagePlaceEvents = []
    },
    updateAppOptions (state, payload) {
      if (!state.appOptions || !state.appOptions.id) {
        state.appOptions = window.appOptions
      }

      state.appOptions = {
        ...state.appOptions,
        ...payload
      }

      window.appOptions = {
        ...state.appOptions,
        ...payload
      }

      localStorage.setItem('appOptions', JSON.stringify(window.appOptions))
    },
    openCloseSidebar (state) {
      state.isOpenSidebar = !state.isOpenSidebar
    },
    startLoading (state) {
      state.loadingNow = true

      state.countLoading = state.countLoading + 1
    },
    stopLoading (state) {
      if (state.countLoading > 0) {
        state.countLoading = state.countLoading - 1
      }

      if (state.countLoading === 0) {
        state.loadingNow = false
      }
    },
    closeDebugModal (state, payload) {
      state.isOpenDebugModal = false
      state.debugData = payload
    },
    openDebugModal (state, payload) {
      state.isOpenDebugModal = true
      state.debugData = payload
    },
    removeNotificationByEntity (state, entity) {
      state.notifications = state.notifications.filter(notification => {
        return `${notification.payload.entity}` !== `${entity}`
      })
    },
    addErrorNotification (state, payload) {
      const sound = state.appOptions?.notificationsSounds?.error || state.appOptions?.notificationsSounds?.default
      if (sound) {
        const audio = new Audio(sound)

        audio.play()
          .catch(error => {
            console.debug(error.message)
          })
      }

      const id = new Date().getUTCMilliseconds()
      const message = typeof payload === 'object' && payload.message
        ? payload.message
        : payload

      const notification = {
        id,
        type: 'danger',
        payload,
        message: typeof message === 'object'
          ? message
          : i18n.global.tc(message)
      }

      if (notification.message === 'The user aborted a request.') {
        console.debug(notification)
      } else {
        state.notifications.push(notification)
      }
    },
    addWarningNotification (state, payload) {
      const id = new Date().getUTCMilliseconds()
      const message = typeof payload === 'object' && payload.message
        ? payload.message
        : payload

      const notification = {
        id,
        type: 'warning',
        payload,
        message: typeof message === 'object'
          ? message
          : i18n.global.tc(message)
      }

      state.notifications.push(notification)
    },
    addNotification (state, payload) {
      const id = new Date().getUTCMilliseconds()
      const message = typeof payload === 'object' && payload.message
        ? payload.message
        : payload

      const notification = {
        id,
        type: 'success',
        payload,
        message: typeof message === 'object'
          ? message
          : i18n.global.tc(message)
      }

      state.notifications.push(notification)
    },
    setNotifications (state, payload) {
      state.notifications = payload
    },
    removeNotification (state, payload) {
      state.notifications = state.notifications.filter(({ id }) => payload !== id)
    },
    interceptItemsLoading (state, payload) {
      state.isInterceptedItemsLoading = payload
    },
    addCache (state, { query, data }) {
      state.cache[query] = data
    },
    removeCache (state, payload) {
      state.cache[payload] = undefined
    }
  },
  actions: {}
}
