import _ from 'lodash'
import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    acceptanceItem: null,
    cleanAcceptanceItem: null,
    acceptanceItems: [],
    acceptanceItemsItemsNumber: 0,
    acceptanceItemsPage: 1,
    acceptanceItemsTotalPages: 1,
    acceptanceItemsLoading: false
  },
  getters: {
    acceptanceItem: state => state.acceptanceItem,
    cleanAcceptanceItem: state => state.cleanAcceptanceItem,
    acceptanceItems: state => state.acceptanceItems,
    acceptanceItemsLoading: state => state.acceptanceItemsLoading,
    acceptanceItemsItemsNumber: state => state.acceptanceItemsItemsNumber,
    acceptanceItemsPage: state => state.acceptanceItemsPage,
    acceptanceItemsTotalPages: state => state.acceptanceItemsTotalPages
  },
  mutations: {
    setAcceptanceItem (state, payload) {
      state.acceptanceItem = payload
      state.cleanAcceptanceItem = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setAcceptanceItemsData (state, { items, totalItems, page, totalPages }) {
      state.acceptanceItems = items
      state.acceptanceItemsItemsNumber = totalItems
      state.acceptanceItemsPage = page
      state.acceptanceItemsTotalPages = totalPages
    },
    setCleanAcceptanceItem (state, payload) {
      state.cleanAcceptanceItem = payload
    },
    setNewAcceptanceItem (state) {
      state.acceptanceItem = {
        // extId: Math.floor(Math.random() * (9999999999) + 1),
        state: 'normal',
        type: 'simple',
        dimensions: {
          x: 0,
          y: 0,
          z: 0
        },
        name: '',
        price: 0,
        image: null,
        barcodes: [],
        sku: null,
        _embedded: {
          shop: null
        }
      }
    },
    updateAcceptanceItem (state, payload) {
      state.acceptanceItem = {
        ...state.acceptanceItem,
        ...payload
      }
    },
    updateAcceptanceItemEmbedded (state, payload) {
      state.acceptanceItem._embedded = {
        ...state.acceptanceItem._embedded,
        ...payload
      }
    },
    updateAcceptanceItemList (state, payload) {
      state.acceptanceItems = state.acceptanceItems.map(x => {
        return x.id === payload.id
          ? payload
          : x
      })
    },
    setAcceptanceItems (state, payload) {
      state.acceptanceItems = payload
    },
    setAcceptanceItemsItemsNumber (state, payload) {
      state.acceptanceItemsItemsNumber = payload
    },
    setAcceptanceItemsPage (state, payload) {
      state.acceptanceItemsPage = payload
    },
    setAcceptanceItemsTotalPages (state, payload) {
      state.acceptanceItemsTotalPages = payload
    },
    startAcceptanceItemLoading (state) {
      state.acceptanceItemsLoading = true
    },
    stopAcceptanceItemLoading (state) {
      state.acceptanceItemsLoading = false
    },
    upsertAcceptanceItem (state, payload) {
      let isExist = false

      state.acceptanceItems = state.acceptanceItems.map(x => {
        if (`${x.id}` === `${payload.id}`) {
          isExist = true
          return payload
        }

        return x
      })

      if (!isExist) {
        state.acceptanceItems.unshift(payload)
      }
    }
  },
  actions: {
    loadAcceptanceItems ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.acceptanceItems)
      return services.acceptanceItem.getAll(query)
        .then(data => {
          commit('setAcceptanceItemsData', data)
          return data
        })
    },
    loadAcceptanceItem ({ commit }, { id, shop }) {
      return services.acceptanceItem.get(id, shop)
        .then(item => {
          commit('setAcceptanceItem', item)
          return item
        })
    },
    saveAcceptanceItem ({ commit, state }) {
      const ignoredFields = ['shop']
      const data = state.acceptanceItem.id
        ? convertEmbedded(difference(state.acceptanceItem, state.cleanAcceptanceItem), ignoredFields)
        : convertEmbedded(state.acceptanceItem)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.acceptanceItem)
      }

      if (data.eav && data.eav['products-acceptanceItem-bundle']) {
        data.eav['products-acceptanceItem-bundle'] = state.acceptanceItem.eav['products-acceptanceItem-bundle']
      }

      // Difference extract only changed values in array
      // But we need to pass the whole array to back-end
      if (data.barcodes) {
        data.barcodes = state.acceptanceItem.barcodes
      }

      if (data.price !== undefined) {
        data.price = Number(data.price).toFixed(2)
      }

      const header = state.acceptanceItem.id && state.acceptanceItem.updated
        ? { 'X-Entity-Updated': state.acceptanceItem.updated }
        : {}

      return services.acceptanceItem.save(data, state.acceptanceItem.id, (state.cleanAcceptanceItem || { _embedded: { shop: {} } })._embedded.shop.id, header)
    }
  }
}
