import entities from '../../../../config/Entities'
import { Cache } from '../../../../packages/cache'
import qs from 'qs'

export class CachedService {
  _httpClient
  _url
  _middleware
  _options
  _entityClass

  static cache = new Cache()
  static entities = entities
  static qs = qs

  constructor (entityClass, url, httpClient, middleware, options = {}) {
    this._url = url
    this._entityClass = entityClass
    this._httpClient = httpClient
    this._middleware = middleware
    this._options = options
  }

  /**
   * @param {object} get
   *
   * @returns {string}
   */
  getURL (get) {
    return `${this._url}${
      Object.keys(get).length > 0 ? '?' + Service.qs.stringify(get) : ''
    }`
  }

  /**
   * @param {String | Number} id
   * @param {object} get
   * @param {String} accept
   *
   * @returns {Promise<object>}
   */
  get (id, get, accept) {
    const value = CachedService.cache.getValue(this._entityClass, id)

    if (value) {
      return Promise.resolve(value)
    }

    let url = `${this._url}/${id}`
    if (typeof id === 'string' && id.startsWith('https')) {
      url = id
    }

    return this._httpClient
      .get(`${url}`, get, accept)
      .then((item) => {
        CachedService.cache.dispatchValue(this._entityClass, item)
        return item
      })
  }

  /**
   * @param {object} query
   * @param {String} accept
   *
   * @returns {Promise<object>}
   */
  getAll (query, accept) {
    const updatedQuery =
      this._options.mergeQuery && this._options.query
        ? this._options.mergeQuery(query, this._options.query)
        : query

    const data = CachedService.cache.getValues(
      this._entityClass,
      this.getURL(updatedQuery)
    )

    if (!data.isExpired && !query.forceReload) {
      return Promise.resolve(data.value)
    }

    delete updatedQuery.forceReload

    return this._httpClient
      .get(this._url, updatedQuery, accept)
      .then(this._middleware)
      .then((data) => {
        CachedService.cache.dispatchValues(
          this._entityClass,
          this.getURL(updatedQuery),
          data
        )
        return data
      })
  }

  /**
   * @param {object} data
   * @param {String | Number | undefined} id
   * @param {object | undefined} headers
   * @param {object | undefined} query
   *
   * @returns {Promise<object>}
   */
  save (data, id, headers, query = {}) {
    return this._httpClient
      .saveRequest(this._url, data, id, undefined, undefined, headers, query)
      .then((item) => {
        CachedService.cache.dispatchValue(this._entityClass, item)
        return item
      })
  }

  /**
   * @param {String | Number} id
   * @param {String | Number} template
   *
   * @returns {Promise<String>}
   */
  getTemplate (id, template) {
    return this._httpClient
      .getRaw(
        `${this._url}${id ? `/${id}` : ''}`,
        {},
        `application/${template}+document`
      )
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return Promise.reject(new Error(response.statusText))
        }

        return response.text().then((data) => {
          if (data.match('<div')) {
            data = `<html lang='en-us' dir='ltr'><head><meta charset='utf-8'></head><body>${data}</body></html>`
          }

          return data
        })
      })
  }

  /**
   * @param {String | Number} id
   *
   * @returns {Promise<object>}
   */
  delete (id) {
    return this._httpClient.delete(`${this._url}/${id}`)
  }

  /**
   * @param {String | Number} id
   * @param {object} data
   * @param {String} field
   *
   * @returns {Promise <object>}
   */
  upload (id, data, field) {
    return this._httpClient.uploadPatch(`${this._url}/${id}`, data, undefined, field)
  }
}

export class Service {
  _httpClient
  _url
  _middleware
  _options

  _lastResponse

  static cache = new Cache()
  static entities = entities
  static qs = qs

  get lastResponse () {
    return this._lastResponse
  }

  constructor (url, httpClient, middleware, options = {}) {
    this._url = url
    this._httpClient = httpClient
    this._middleware = middleware
    this._options = options
    this._lastResponse = {
      page: 0,
      totalPages: 0,
      items: [],
      totalItems: 0,
    }
  }

  /**
   * @param {object} get
   *
   * @returns {string}
   */
  getURL (get) {
    return `${this._url}${
      Object.keys(get).length > 0 ? '?' + Service.qs.stringify(get) : ''
    }`
  }

  /**
   * @param {String | Number} id
   * @param {object} get
   * @param {String} accept
   *
   * @returns {Promise<object>}
   */
  get (id, get, accept) {
    return this._httpClient.get(`${this._url}/${id}`, get, accept)
  }

  /**
   * @param {object} query
   * @param {String} accept
   *
   * @returns {Promise<object>}
   */
  getAll (query, accept) {
    const updatedQuery =
      this._options.mergeQuery && this._options.query
        ? this._options.mergeQuery(query, this._options.query)
        : query

    return this._httpClient
      .get(this._url, updatedQuery, accept)
      .then(this._middleware)
      .then((data) => {
        this._lastResponse = data
        return data
      })
  }

  /**
   * @param {object} data
   * @param {String | Number | undefined} id
   * @param {object | undefined} headers
   * @param {object | undefined} query
   *
   * @returns {Promise<object>}
   */
  save (data, id, headers, query = {}) {
    return this._httpClient.saveRequest(this._url, data, id, undefined, undefined, headers, query)
  }

  /**
   * @param {object} entity
   * @param {String | Number} template
   *
   * @returns {Promise<String>}
   */
  getTemplate (entity, template) {
    return this._httpClient
      .getRaw(
        `${this._url}${entity.id ? `/${entity.id}` : ''}`,
        {},
        `application/${template}+document`
      )
      .then((response) => {
        if (response.status !== 200 && response.status !== 201) {
          return Promise.reject(new Error(response.statusText))
        }

        return response.text().then((data) => {
          if (data.match('<div')) {
            data = `<html lang='en-us' dir='ltr'><head><meta charset='utf-8'></head><body>${data}</body></html>`
          }

          return data
        })
      })
  }

  /**
   * @param {String | Number} id
   *
   * @returns {Promise<object>}
   */
  delete (id) {
    return this._httpClient.delete(`${this._url}/${id}`)
  }
}

export class ItemsService {
  _httpClient
  _url
  _qs

  constructor (url, httpClient, qs) {
    this._url = url
    this._httpClient = httpClient
    this._qs = qs
  }

  /**
   * @param {object} query
   *
   * @returns {Promise<object>}
   */
  getAll (query) {
    console.log(query)
    return this._httpClient.get(this._url, query)
  }

  /**
   * Patch Items from Sorting
   *
   * @param {number} id
   * @param {object} data
   * @param {object} query
   *
   * @returns {Promise<object>}
   */
  patch (id, data, query = {}) {
    const url = `${this._url}/${id}${
      Object.keys(query).length > 0 ? '?' + this._qs.stringify(query) : ''
    }`
    return this._httpClient.patch(url, data)
  }
}

export class QueueService {
  _httpClient
  _url
  _qs
  _middleware
  _utils
  _adapters

  constructor (url, httpClient, middleware, utils, qs) {
    this._url = url
    this._httpClient = httpClient
    this._middleware = middleware
    this._utils = utils
    this._qs = qs
    this._adapters = {}
  }

  /**
   * @param {String | Number} id
   * @param {String} adapter
   *
   * @returns {Promise<object>}
   */
  getSettings (id, adapter) {
    if (this._adapters[adapter]) {
      return Promise.resolve(this._adapters[adapter])
    }

    return this._httpClient
      .get(`${this._url}/${id}`, {}, 'application/settings+json')
      .then(({ result }) => {
        this._adapters[adapter] = Object.values(result || {}).reduce(
          (acc, group) => {
            return [
              ...acc,
              ...group.filter(
                ({ name }) =>
                  !this._utils.filterKeys.find((key) => name.match(key))
              ),
            ]
          },
          []
        )

        return this._adapters[adapter]
      })
  }

  /**
   * @param {String | Number} id
   *
   * @returns {Promise<object>}
   */
  get (id) {
    let url = `${this._url}/${id}`
    if (typeof id === 'string' && id.startsWith('https')) {
      url = id
    }

    return this._httpClient.get(url).then((item) => {
      return this._utils.convertQueue(item)
    })
  }

  /**
   * @param {String | Number} id
   *
   * @returns {Promise<object>}
   */
  getRawSettings (id) {
    return this._httpClient.get(`${this._url}/${id}`).then((item) => {
      return item.settings
    })
  }

  /**
   * @param {object} query
   *
   * @returns {Promise<object>}
   */
  getAll (query) {
    return this._httpClient
      .get(this._url, query)
      .then(this._middleware)
      .then((result) => {
        result.items = result.items.map((item) =>
          this._utils.convertQueue(item)
        )
        return result
      })
  }

  /**
   * @param {object} settings
   * @param {String | Number} id
   * @param {Boolean} not-filters
   *
   * @returns {Promise<object>}
   */
  saveFilters (settings, id, not) {
    const filters = this._utils.convertObjectToFilter(settings.filters, not)
    const data = {
      ...settings,
      filters,
    }

    return this._httpClient
      .saveRequest(this._url, { settings: data }, id)
      .then((item) => {
        return this._utils.convertQueue(item)
      })
  }

  /**
   * @param {object} settings
   * @param {String | Number} id
   * @param {Boolean} not-filters
   *
   * @returns {Promise<object>}
   */
  saveFiltersNoOrder (settings, id, not) {
    const filters = this._utils.convertObjectToFilterRevision(settings.filters, not)
    const data = {
      ...settings,
      filters,
    }

    return this._httpClient
      .saveRequest(this._url, { settings: data }, id)
      .then((item) => {
        return this._utils.convertQueue(item)
      })
  }

  /**
   * @param {object} data
   * @param {String | Number | undefined} id
   * @param {object | undefined} headers
   *
   * @returns {Promise<object>}
   */
  save (data, id, headers) {
    return this._httpClient
      .saveRequest(this._url, data, id, undefined, undefined, headers)
      .then((item) => {
        return this._utils.convertQueue(item)
      })
  }

  /**
   * @param {String | Number | Undefined} id
   * @param {object} data
   * @param {object} query
   *
   * @returns {Promise<object>}
   */
  process (id, data, query = {}) {
    return this._httpClient.post(
      `${this._url}/${id}` +
      (Object.keys(query).length > 0 ? '?' + this._qs.stringify(query) : ''),
      data
    )
  }
}
