import _ from 'lodash'
import services from '../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    interaction: null,
    cleanInteraction: null,
    interactions: [],
    totalInteractionsNumber: 0,
    interactionsPage: 1,
    interactionsTotalPages: 1,
    interactionsLoading: false,
    isOpenInteraction: false,
    interactionId: null
  },
  getters: {
    interaction: state => state.interaction,
    cleanInteraction: state => state.cleanInteraction,
    interactions: state => state.interactions,
    interactionsLoading: state => state.interactionsLoading,
    totalInteractionsNumber: state => state.totalInteractionsNumber,
    interactionsPage: state => state.interactionsPage,
    interactionsTotalPages: state => state.interactionsTotalPages,
    isOpenInteraction: state => state.isOpenInteraction,
    interactionId: state => state.interactionId
  },
  mutations: {
    openInteraction (state, payload) {
      state.isOpenInteraction = true
      state.interactionId = payload
    },
    closeInteraction (state) {
      state.isOpenInteraction = false
      state.interactionId = null
    },
    setInteraction (state, payload) {
      state.interaction = payload
      state.cleanInteraction = typeof payload === 'object'
        ? _.cloneDeep(payload)
        : payload
    },
    setNewInteraction (state) {
      state.interaction = {
        enityClass: null,
        entity: null,
        _embedded: {
          task: null
        }
      }
    },
    updateInteraction (state, payload) {
      state.interaction = {
        ...state.interaction,
        ...payload
      }
    },
    updateInteractionEmbedded (state, payload) {
      state.interaction._embedded = {
        ...state.interaction._embedded,
        ...payload
      }
    },
    setCleanInteraction (state, payload) {
      state.cleanInteraction = payload
    },
    setInteractionsData (state, { items, page, totalPages, totalItems }) {
      state.interactions = items
      state.totalInteractionsNumber = totalItems
      state.interactionsPage = page
      state.interactionsTotalPages = totalPages
    },
    setInteractions (state, payload) {
      state.interactions = payload
    },
    setTotalInteractionsNumber (state, payload) {
      state.totalInteractionsNumber = payload
    },
    setInteractionsPage (state, payload) {
      state.interactionsPage = payload
    },
    setInteractionsTotalPages (state, payload) {
      state.interactionsTotalPages = payload
    },
    startCrmInteractionsLoading (state) {
      state.interactionsLoading = true
    },
    stopCrmInteractionsLoading (state) {
      state.interactionsLoading = false
    }
  },
  actions: {
    loadInteractions ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.interaction)
      return services.notificationInteraction.getAll(query)
        .then(data => {
          commit('setInteractionsData', data)
          return data
        })
    },
    loadInteraction ({ commit }, payload) {
      return services.notificationInteraction.get(payload)
        .then(item => {
          commit('setInteraction', item)
          return item
        })
    },
    saveInteraction ({ commit, state }) {
      const data = state.interaction.id
        ? convertEmbedded(difference(state.interaction, state.cleanInteraction), ['task'])
        : convertEmbedded(state.interaction)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.interaction)
      }

      const header = state.interaction.id && state.interaction.updated
        ? { 'X-Entity-Updated': state.interaction.updated }
        : {}

      return services.notificationInteraction.save(data, state.interaction.id, header)
    }
  }
}
