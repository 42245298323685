import _ from 'lodash'

import services from './../../../services/services'

import {
  convertEmbedded,
  difference,
  mergeQuery
} from './../../../helpers/request-helpers'

export default {
  state: {
    placeItem: null,
    cleanPlaceItem: null,
    placeItems: [],
    placeItemsNumber: 0,
    placeItemsPage: 1,
    placeItemsTotalPages: 1,
    placeItemsLoading: false
  },
  getters: {
    placeItem: state => state.placeItem,
    cleanPlaceItem: state => state.cleanPlaceItem,
    placeItems: state => state.placeItems,
    placeItemsNumber: state => state.placeItemsNumber,
    placeItemsPage: state => state.placeItemsPage,
    placeItemsTotalPages: state => state.placeItemsTotalPages,
    placeItemsLoading: state => state.placeItemsLoading
  },
  mutations: {
    setPlaceItem (state, payload) {
      state.placeItem = payload
      state.cleanPlaceItem = _.cloneDeep(payload)
    },
    setCleanPlaceItem (state, payload) {
      state.cleanPlaceItem = payload
    },
    setNewPlaceItem (state) {
      state.payment = {}
    },
    updatePlaceItem (state, payload) {
      state.placeItem = {
        ...state.placeItem,
        ...payload
      }
    },
    setPlaceItemsData (state, { items, page, totalPages, totalItems }) {
      state.placeItems = items
      state.placeItemsNumber = totalItems
      state.placeItemsPage = page
      state.placeItemsTotalPages = totalPages
    },
    setPlaceItems (state, payload) {
      state.placeItems = payload
    },
    setTotalPlaceItemsNumber (state, payload) {
      state.placeItemsNumber = payload
    },
    setPlaceItemsPage (state, payload) {
      state.placeItemsPage = payload
    },
    setPlaceItemsTotalPages (state, payload) {
      state.placeItemsTotalPages = payload
    },
    startPlaceItemsLoading (state) {
      state.placeItemsLoading = true
    },
    stopPlaceItemsLoading (state) {
      state.placeItemsLoading = false
    }
  },
  actions: {
    loadPlaceItems ({ commit }, payload) {
      const query = mergeQuery(payload, window.appOptions.placeItems)
      return services.deliveryServicePlaceItems.getAll(query)
        .then(data => {
          commit('setPlaceItemsData', data)
          return data
        })
    },
    loadPlaceItem ({ commit }, payload) {
      return services.deliveryServicePlaceItems.get(payload)
        .then(item => {
          commit('setPlaceItem', item)
          return item
        })
    },
    savePlaceItem ({ commit, state }) {
      const data = state.placeItem.id
        ? convertEmbedded(difference(state.placeItem, state.cleanPlaceItem))
        : convertEmbedded(state.placeItem)

      if (Object.keys(data).length === 0) {
        commit('addWarningNotification', 'No changes!')
        return Promise.resolve(state.placeItem)
      }

      const header = state.placeItem.id && state.placeItem.updated
        ? { 'X-Entity-Updated': state.placeItem.updated }
        : {}

      return services.deliveryServicePlaceItems.save(data, state.placeItem.id, header)
    },
    savePlaceItemByPayload (_, payload) {
      return services.deliveryServicePlaceItems.save(payload.data, payload.id, payload.header)
    },
    savePlaceItems (_, payload) {
      return services.deliveryServicePlaceItems.put(payload)
    }
  }
}
